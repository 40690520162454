import {
  Component,
  OnInit
} from '@angular/core';
import * as XLSX from 'xlsx'; 
import {
  SarthiListService
} from './services/sarthi-list.service';
import {
  ProgramService
} from '../common/program.service';
import {
  Program
} from '../models/program';
import {
  HospitalService
} from '../common/hospital.service';
import {
  HospitalFormData
} from '../models/hospital-form-data';
import {
  Visa
} from '../models/visa';
import {
  ToastrService
} from 'ngx-toastr';
import { AuthenticationService } from '../common/authentication.service';

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

@Component({
  selector: 'app-sarthi-list',
  templateUrl: './sarthi-list.component.html',
  styleUrls: ['./sarthi-list.component.scss']
})
export class SarthiListComponent implements OnInit {
  loading: boolean;
  landing: string;
  selectedPId: string;
  selectedHospitalData: any;
  message: string;
  showAlert: boolean;
  programList: Program[];
  visaObject: any = {};
  programObject: any = {};
  hospitalsByProgram: any = {};
  hospitalsDataByProgram: any = {};
  bestMatches: any = {};
  possibleMatches: any = {};
  difficultMatches: any = {};
  shownList: any = [];
  favorites: any = {};
  favoritesObject: any = {};
  notes: any = {};
  notesObject: any = {};
  updateDashboard: boolean = true;
  others: any = {};
  allLandings = ["Specialities", "Dashboard", "Favorites", "My Notes"];
  allTabs = ["Best", "Possible", "Difficult", "Others"];
  totalNo = [0, 0, 0, 0];
  allPanes = ["Information from Frieda", "Score Information", "Additional Information", "Interview Profiles", "Matched Profiles"];
  customVisa: any = { "1": { Type: "GC/US citizen/H4 EAD", VId: "1" }, "2": { Type: "Need H1", VId: "2" }, "3": { Type: "Need J1", VId: "3" }, "4": { Type: "Other", VId: "4" } };
  showPane = "Information from Frieda";
  showTab: string;
  userProfile: any = {
    uid: 8,
    Step1Score: 210,
    Step2Score: 210,
    USCE: 6,
    YOG: 1,
    Visas: [1],
  }
  naVals = ["-99", -99, "-999", -999, "NA", "na", "N/A"];
  newNote: String = "";
  search: String = "";
  isNotesCollapsed: boolean = true;
  shownStates : any[];
  shownCities : any[];
  shownVisas  = ["J1", "H1", "H4 EAD", "No Visa Sponsorship", "F1 (OPT 1st year)"];
  shownNrmp = ["Yes", "No"];
  shownEcfmg = ["Yes", "No", "Required for IMGs"];
  shownYog = [
    "Within 2 year",
    "Within 3 year",
    "Within 4 year",
    "Within 5 year",
    "Within 6 year",
    "YOG not a constrain",
  ];
  shownStep = [
    "200 and below",
    "210 and below",
    "220 and below",
    "230 and below",
    "240 and below",
    "250 and below",
  ];
  shownPercentage = [
    "30 and above",
    "50 and above",
    "80 and above",
  ]
  selectedCities : any[] = [];
  selectedStates : any[] = [];
  selectedVisas : any[] = [];
  selectedNrmp : any;
  selectedEcfmg : any;
  selectedYog : any;
  selectedStep1 : any;
  selectedStep2 : any;
  selectedUsImg  : any;
  selectedNonUsImg : any;
  selectedDataNotAvailable : any;

  constructor(private dbservice: SarthiListService, private programApi: ProgramService, private hospitalApi: HospitalService, private toastr: ToastrService, private authService: AuthenticationService) {
    this.showTab = "Others";
    this.programList = [];
    this.showAlert = false;
  }

  async ngOnInit() {
    try {
      this.userProfile = await this.authService.userData;
      if (this.userProfile.Locked === "0") {
        this.toastr.info("Please navigate to your profile(on top right) and complete the form");
        this.landing = "";
        this.loading = true;
      } else {
        await this.takeMeToSpeciality();
        this.visaObject = await this.dbservice.getVisaObject();
        this.userProfile.Step1Score = parseInt(this.userProfile.Step1Score);
        this.userProfile.Step2Score = parseInt(this.userProfile.Step2Score);
        this.userProfile.USCE = parseInt(this.userProfile.USCE);
      }
    } catch (err) {
      this.loading = true;
      this.toastr.error("Error while fetching hospitals data, please try again");
    }

  }
  async takeMeToSpeciality() {
    try {
      this.loading = true;
      this.landing = "Specialities";
      const hideProgramIds = [8];
      if (this.programList.length == 0) {
        this.programList = await this.programApi.getProgramList();
        this.programObject = {};
        for (let i in this.programList) {
          this.programObject[this.programList[i].PId] = this.programList[i];
        }
        if (this.userProfile.Role !== "Admin")
          this.programList = this.programList.filter(program => !hideProgramIds.includes(parseInt(program.PId)));
      }
      this.loading = false;
    } catch (err) {
      this.loading = true;
      this.toastr.error("Error while fetching data, please try again");
    }
  }

  async takeMeToDashboard(event) {
    try {
      this.selectedPId = event;
      this.loading = true;
      this.landing = "Dashboard";
      this.hospitalsByProgram[this.selectedPId] = await this.hospitalApi.getHospitalsObjectByProgram(this.selectedPId);
      this.hospitalsDataByProgram[this.selectedPId] = await this.dbservice.getHospitalsDataByPId(this.selectedPId);
      if (Object.keys(this.hospitalsDataByProgram[this.selectedPId]).length == 0) {
        this.toastr.info("Selected speciality doesn't have any hospital data. Choose any other program.");
        this.selectedPId = undefined;
        this.landing = "Specialities";
      } else {
        this.favorites = await this.dbservice.getFavoritesByUId(this.userProfile.uid.toString());
        this.favoritesObject = {};
        for (let i in this.favorites) {
          let data = this.favorites[i];
          let key = data.HId + '.' + data.PId;
          this.favoritesObject[key] = data;
        }
        if (!(this.selectedPId in this.bestMatches) || this.updateDashboard) {
          this.bestMatches[this.selectedPId] = [];
          this.possibleMatches[this.selectedPId] = [];
          this.difficultMatches[this.selectedPId] = [];
          this.others[this.selectedPId] = [];
          for (let hpinfoid in this.hospitalsDataByProgram[this.selectedPId]) {
            let hospitalData = this.hospitalsDataByProgram[this.selectedPId][hpinfoid];
            if (hospitalData.TimeStamp){
              const timeStamp = new Date(hospitalData.TimeStamp);
              hospitalData.Date = monthNames[timeStamp.getMonth()] + " " + timeStamp.getFullYear();
            }
            let key = hospitalData.HId + '.' + hospitalData.PId;
            if (key in this.favoritesObject) {
              hospitalData.favorite = this.favoritesObject[key];
            } else {
              delete hospitalData.favorite;
            }
            let category = this.bifurcateHospital(hospitalData);
            switch (category) {
              case "Best":
                this.bestMatches[this.selectedPId].push(hospitalData);
                break;
              case "Possible":
                this.possibleMatches[this.selectedPId].push(hospitalData);
                break;
              case "Difficult":
                this.difficultMatches[this.selectedPId].push(hospitalData);
                break;
              case "Others":
                this.others[this.selectedPId].push(hospitalData);
                break;
            }
          }
          this.updateDashboard = false;
        }
        this.totalNo[0] = this.bestMatches[this.selectedPId].length;
        this.totalNo[1] = this.possibleMatches[this.selectedPId].length;
        this.totalNo[2] = this.difficultMatches[this.selectedPId].length;
        this.totalNo[3] = this.others[this.selectedPId].length;
        this.filterSearch();
      }
      this.loading = false;
    } catch (err) {
      await this.takeMeToSpeciality();
      console.log(err.message);
      this.toastr.error("Error while fetching hospitals data, please try again");
    }
  }

  sortDataOnFilter(filterName, data) {
    data.sort((a, b) => this.hospitalsByProgram[this.selectedPId][a.HId][filterName] > this.hospitalsByProgram[this.selectedPId][b.HId][filterName] ? 1 : -1);
  }

  bifurcateHospital(hospitalData: HospitalFormData) {
    return "Others";

    let USCECondition = this.checkForUsceCondition(hospitalData);
    let YOGCondition = this.checkForYogCondition(hospitalData);
    let visaCondition = this.checkForVisaCondition(hospitalData);

    let step1Condition = this.checkForStep1Condition(hospitalData, "best");
    let step2Condition = this.checkForStep2Condition(hospitalData, "best");
    if (visaCondition && ((step1Condition && step2Condition) || ((step1Condition || step2Condition) && (USCECondition || YOGCondition))))
      return "Best";

    step1Condition = this.checkForStep1Condition(hospitalData, "possible");
    step2Condition = this.checkForStep2Condition(hospitalData, "possible");
    if (visaCondition && ((step1Condition && step2Condition) || ((step1Condition || step2Condition) && (USCECondition || YOGCondition))))
      return "Possible";

    step1Condition = this.checkForStep1Condition(hospitalData, "difficult");
    step2Condition = this.checkForStep2Condition(hospitalData, "difficult");
    if (visaCondition && ((step1Condition && step2Condition) || ((step1Condition || step2Condition) && (USCECondition || YOGCondition))))
      return "Difficult";

    return "Others";
  }
  checkForVisaCondition(hospitalData: HospitalFormData) {
    if ("Visas" in hospitalData) {
      if (this.userProfile.Visas.indexOf("1") != -1 || this.userProfile.Visas.indexOf("6") != -1 || this.userProfile.Visas.indexOf("5") != -1)
        return true;
      else if (hospitalData.Visas.indexOf("7") != -1 || hospitalData.Visas.length == 0)
        return false;
      else if (hospitalData.Visas.indexOf("2") != -1 && (this.userProfile.Visas.indexOf("2") != -1 || this.userProfile.Visas.indexOf("3") != -1))
        return true;
      else if (hospitalData.Visas.indexOf("3") != -1 && this.userProfile.Visas.indexOf("3") != -1)
        return true;
    }
    return false;
  }
  checkForUsceCondition(hospitalData: HospitalFormData) {
    let usce = this.userProfile.USCE.toString();
    usce = usce.match(/(\d+)/)[0];
    if (hospitalData.USCEReq == "" || hospitalData.USCEReq == "-99")
      return false;
    else if (usce && parseInt(usce) >= parseInt(hospitalData.USCEReq))
      return true;
    return false;
  }
  convertYOG() {
    if (parseInt(this.userProfile.YOG) >= 2000)
      return Math.abs(new Date().getFullYear() - this.userProfile.YOG);
    return parseInt(this.userProfile.YOG);
  }
  checkForYogCondition(hospitalData: HospitalFormData) {
    let YOG = hospitalData.YOG;
    if (!YOG || YOG == "" || YOG == "Data not available")
      return false;
    else if (YOG == "YOG not a constrain")
      return true;
    else {
      let YOGNum = parseInt(YOG.split(" ")[1]);
      let todayYear = new Date(Date.now()).getFullYear();
      let userYear = this.convertYOG();
      if (YOGNum && userYear && (todayYear + YOG) >= userYear && (todayYear - YOG) <= userYear)
        return true;
    }
    return false;
  }
  checkForStep1Condition(hospitalData: HospitalFormData, type: string) {
    if (hospitalData.Step1ScoreLastYearMin <= 0)
      return false;
    switch (type) {
      case "best":
        if (hospitalData.Step1ScoreLastYearMin <= this.getStep1Score())
          return true;
        break;
      case "possible":
        if (hospitalData.Step1ScoreLastYearMin <= this.getStep1Score() + 10)
          return true;
        break;
      case "difficult":
        if (hospitalData.Step1ScoreLastYearMin <= this.getStep1Score() + 20)
          return true;
        break;
    }
    return false;
  }
  checkForStep2Condition(hospitalData: HospitalFormData, type: string) {
    if (hospitalData.Step2Min <= 0)
      return false;
    switch (type) {
      case "best":
        if (hospitalData.Step2Min <= this.userProfile.Step2Score)
          return true;
        break;
      case "possible":
        if (hospitalData.Step2Min <= this.userProfile.Step2Score + 10)
          return true;
        break;
      case "difficult":
        if (hospitalData.Step2Min <= this.userProfile.Step2Score + 20)
          return true;
        break;
    }
    return false;
  }
  changeTab(tab: any) {
    if (this.allTabs.indexOf(tab) != -1) {
      this.showTab = tab;
      this.setShownListToInitial();
    }
    return false;
  }

  async takeMeTo(landing: string) {
    switch (landing) {
      case "Specialities":
        await this.takeMeToSpeciality();
        break;
      case "Dashboard":
        if (this.selectedPId === undefined || this.selectedPId == "")
          this.toastr.info("Choose a speciality first");
        else
          await this.takeMeToDashboard(this.selectedPId);
        break;
      case "Favorites":
        await this.takeMeToFavorites();
        break;
      case "My Notes":
        await this.takeMeToNotes();
        break;
    }
  }

  async takeMeToFavorites() {
    try {
      this.loading = true;
      this.favorites = await this.dbservice.getFavoritesByUId(this.userProfile.uid.toString());
      if (this.favorites.length == 0)
        this.toastr.info("You do not have any favorites yet.");
      else {
        this.landing = "Favorites";
      }
      this.loading = false;
    } catch (err) {
      await this.takeMeToSpeciality();
      this.toastr.error("Error while fetching your favorites, please try again");
    }
  }

  async takeMeToNotes() {
    try {
      this.loading = true;
      this.notes = await this.dbservice.getNotesByUId(this.userProfile.uid.toString());
      if (this.notes.length == 0)
        this.toastr.info("You do not have any notes yet.");
      else {
        this.landing = "My Notes";
        for (let i in this.notes) {
          let note = this.notes[i];
          if (note.PId in this.hospitalsByProgram)
            note.hospital = this.hospitalsByProgram[note.PId][note.HId];
        }
      }
      this.loading = false;
    } catch (err) {
      await this.takeMeToSpeciality();
      this.toastr.error("Error while fetching your notes, please try again");
    }
  }

  async updateNote(index, type) {
    try {
      if (type == "general") {
        let unid = this.notes[index].UNId;
        let newNote = this.notes[index].Notes;

        if (newNote == "") {
          await this.dbservice.deleteNoteById(unid);
          await this.takeMeToNotes();
        } else {
          await this.dbservice.updateNoteById(unid, newNote);
        }
        this.toastr.success("Success! Notes updated.");
        return;
      } else {
        let notes = this.selectedHospitalData.myNotes;
        let unid = notes.UNId;
        let newNote = notes.Notes;
        if (newNote == "") {
          await this.dbservice.deleteNoteById(unid);
          delete this.selectedHospitalData.myNotes;
        } else {
          await this.dbservice.updateNoteById(unid, newNote);
        }
        this.toastr.success("Success! Notes updated.");
        return;
      }
    } catch (err) {
      this.toastr.error("Error while updating notes, please try again");
    }
  }

  async addNote() {
    let docRef;
    try {
      if (this.newNote != "" && !(this.selectedHospitalData.myNotes)) {
        docRef = await this.dbservice.addNotesByUId(this.userProfile.uid.toString(), this.selectedHospitalData.HId, this.selectedHospitalData.PId, this.newNote);
        this.selectedHospitalData.myNotes = {
          UNId: docRef.id,
          Notes: this.newNote,
          PId: this.selectedHospitalData.PId,
          HId: this.selectedHospitalData.HId
        }
        this.toastr.success("Success! Notes updated.");
      }
    } catch (err) {
      this.toastr.error("Error while updating notes, please try again");
    }
  }
  async addToFavorite(pid: any, hid: any, dataObject: any) {
    try {
      let uid = this.userProfile.uid.toString();
      let doc = await this.dbservice.addFavoriteByUId(uid, hid, pid);
      if (doc != null) {
        dataObject.favorite = {
          UId: uid,
          PId: pid,
          HId: hid,
          UFId: doc.id,
        }
      }
      this.toastr.success("Added to favorites");
    } catch (err) {
      this.toastr.error("Error while adding to Favorites, please try again");
    }

  }
  async deleteFromFavorite(ufid: any, dataObject: any) {
    try {
      await this.dbservice.deleteFavoriteById(ufid);
      if (dataObject !== undefined)
        delete dataObject.favorite;
      this.toastr.success("Removed from favorites");
    } catch (err) {
      this.toastr.error("Error while removing from Favorites, please try again");
    }
  }
  async deleteFromFavoritesPage(ufid: any) {
    try {
      await this.dbservice.deleteFavoriteById(ufid);
      for (let i in this.favorites) {
        if (this.favorites[i].UFId == ufid) {
          this.favorites.splice(i, 1);
          break;
        }
      }
      this.updateDashboard = true;
      this.toastr.success("Removed from favorites");
    }
    catch (err) {
      this.toastr.error("Error while removing from Favorites, please try again");
    }
  }


  async takeMeToDisplayInfo(event) {
    const stringsForDataNotAvailable = ["na", "data not available", "-99", "-999", "999"];
    const numbersForDataNotAvailable = [-99, -999, 99]
    const dataNotAvailable = "Data not available";
    try {
      this.loading = true;
      this.landing = "Display";
      let hpinfoid = event.target.value;
      let hid = this.hospitalsDataByProgram[this.selectedPId][hpinfoid].HId;
      this.selectedHospitalData = await this.dbservice.getLatestHospital(this.userProfile.uid, this.selectedPId, hid, true);
      Object.keys(this.selectedHospitalData).forEach((column)=>{
        const value = this.selectedHospitalData[column]
        if (typeof value === "string"){
          if (stringsForDataNotAvailable.includes(value.toLowerCase())){
            this.selectedHospitalData[column] = dataNotAvailable
          }
        }
        else if (typeof value === "number"){
          if (numbersForDataNotAvailable.includes(value)){
            this.selectedHospitalData[column] = dataNotAvailable
          }
        }
      })
      if (this.selectedHospitalData.HPInfoId != hpinfoid) {
        this.toastr.info("To access the latest info of this hospital, please contribute to the hospitals and complete the tasks if given", "Latest Info Available");
      }
      await this.makeDataReadyToDisplay(this.selectedHospitalData.HPInfoId);
      this.selectedHospitalData.hospital = this.hospitalsByProgram[this.selectedPId][this.selectedHospitalData.HId];
      this.loading = false;
    } catch (err) {
      console.log(err);
      await this.takeMeToDashboard(this.selectedPId);
      this.toastr.error("Error while fetching hospitals details, please try again");
    }
  }

  async makeDataReadyToDisplay(hpinfoid: any) {
    try {
      const timeStamp = new Date(this.selectedHospitalData.TimeStamp);
      this.selectedHospitalData.Date = monthNames[timeStamp.getMonth()] + " " + timeStamp.getFullYear();
      this.selectedHospitalData.VisaNames = [];
      this.newNote = "";
      if ("Visas" in this.selectedHospitalData) {
        for (let id of this.selectedHospitalData.Visas) {
          if (id in this.visaObject)
            this.selectedHospitalData.VisaNames.push(this.visaObject[id].Type);
        }
      }
      let index = undefined;
      this.notes = await this.dbservice.getNotesByUId(this.userProfile.uid.toString());
      for (let ind in this.notes) {
        let note = this.notes[ind];
        if (note.PId == this.selectedHospitalData.PId && note.HId == this.selectedHospitalData.HId) {
          index = ind;
          break;
        }
      }
      if (index != undefined)
        this.selectedHospitalData.myNotes = this.notes[index];
      let comments = this.dbservice.getAllCommentsByHPInfoId(hpinfoid, this.selectedHospitalData);
      let profiles = this.dbservice.getInterviewProfiles(this.selectedHospitalData);
      let matches = this.dbservice.getMatchedProfiles(this.selectedHospitalData);
      await Promise.all([comments, profiles, matches]);
      for (let i in this.selectedHospitalData) {
        if (typeof (this.selectedHospitalData[i]) === "string" && (this.selectedHospitalData[i].toLowerCase() == "na" || this.selectedHospitalData[i].toLowerCase() == "n/a"))
          this.selectedHospitalData[i] = "";
      }
    } catch (err) {
      await this.takeMeToDashboard(this.selectedPId);
      console.log(err);
      this.toastr.error("Error while fetching hospitals details, please try again");
    }
  }

  async takeMeFromFavNotToDisplay(pid: any, hid: any, hospital: any) {
    try {
      this.loading = true;
      this.landing = "Display";
      this.selectedHospitalData = await this.dbservice.getHospitalsDataByPIdHId(hid, pid);
      await this.makeDataReadyToDisplay(this.selectedHospitalData.HPInfoId);
      this.selectedHospitalData.hospital = hospital;
      this.loading = false;
    } catch (err) {
      await this.takeMeToSpeciality();
      this.toastr.error("Error while fetching hospitals details, please try again");
    }
  }

  createFilters(){
    let statesObj = [];
    let citiesObj = []
    this.shownList.forEach(hospital => {
      statesObj[this.hospitalsByProgram[this.selectedPId][hospital.HId].State] = 1;
      citiesObj[this.hospitalsByProgram[this.selectedPId][hospital.HId].City] = 1;
    })
    this.shownStates = Object.keys(statesObj);
    this.shownCities = Object.keys(citiesObj);
  }

  setShownListToInitial() {
    this.shownList = this.showTab === "Best" ? this.bestMatches[this.selectedPId] : this.showTab === "Possible" ? this.possibleMatches[this.selectedPId] : this.showTab === "Difficult" ? this.difficultMatches[this.selectedPId] : this.others[this.selectedPId];
    this.sortDataOnFilter("State", this.shownList);
    this.createFilters();
  }
  checkValue (val, toCheck) {
    const toCheckNoValue = this.selectedDataNotAvailable;
    if (toCheckNoValue){
      return !val || val=== 'Data not available' || val === toCheck;
    }
    return val === toCheck;
  }
  filterSearch() {
    let text = this.search.trim().toLowerCase();
    this.setShownListToInitial();
    if (text)
      this.shownList = this.shownList.filter((item) => this.hospitalsByProgram[this.selectedPId][item.HId].HName.toLowerCase().includes(text));
    if (this.selectedCities.length)
      this.shownList = this.shownList.filter((item) => this.selectedCities.includes(this.hospitalsByProgram[this.selectedPId][item.HId].City));
    if (this.selectedStates.length)
      this.shownList = this.shownList.filter((item) => this.selectedStates.includes(this.hospitalsByProgram[this.selectedPId][item.HId].State));
    if (this.selectedVisas.length){
      const checkVisaFilter = (programData) => {
        if(this.selectedVisas.includes(this.shownVisas[0]) && (this.checkValue(programData.j1VisaNew, "Yes"))) 
          return true;
        if(this.selectedVisas.includes(this.shownVisas[1]) && (this.checkValue(programData.h1VisaNew, "Yes"))) 
          return true;
        if(this.selectedVisas.includes(this.shownVisas[2]) && (this.checkValue(programData.h4VisaNew, "Yes"))) 
          return true;
        if(this.selectedVisas.includes(this.shownVisas[3])&& (this.checkValue(programData.noVisaNew, "Yes"))) 
          return true;
        if(this.selectedVisas.includes(this.shownVisas[4])&& (this.checkValue(programData.f1VisaNew, "Yes"))) 
          return true;
        return false;
      }
      this.shownList = this.shownList.filter((item) => checkVisaFilter(item))
    }
    if (this.selectedNrmp){
      this.shownList = this.shownList.filter((item) => this.checkValue(item.Nrmp, this.selectedNrmp))
    }
    if (this.selectedEcfmg){
      this.shownList = this.shownList.filter((item) => this.checkValue(item.ECFMGReq, this.selectedEcfmg))
    }
    const yogCondition = (field, toCheck) => {
      const toCheckNoValue = this.selectedDataNotAvailable;
      if (toCheckNoValue && (!field || field=== 'Data not available')){
        return true;
      }
      if (field === "YOG not a constrain")
        return true;
      if (toCheck === "YOG not a constrain")
        return field === toCheck;
      let parts = [];
      if (field){
        parts = field.split(" ");
      }
      let val = 0;
      if (parts.length > 1){
        val = parseInt(parts[1]);
      }
      const toCheckValue = parseInt(toCheck.split(' ')[1]);
      return val >= toCheckValue;
    };
    if (this.selectedYog){
      this.shownList = this.shownList.filter((item) => yogCondition(item.YOG, this.selectedYog))
    }
    const stepCondition = (field, toCheck) => {
      const parts = toCheck.split(" ");
      const score = parseInt(parts[0]);
      const fieldScore = parseInt(field);
      if (!field || fieldScore < 0 || Number.isNaN(fieldScore)) 
        return this.selectedDataNotAvailable;
      return fieldScore <= score;
    }
    if (this.selectedStep1){
      this.shownList = this.shownList.filter((item) => stepCondition(item.Step1ScoreLastYearMin, this.selectedStep1))
    }
    if (this.selectedStep2){
      this.shownList = this.shownList.filter((item) => stepCondition(item.Step2Min, this.selectedStep2))
    }
    const percentageCondition = (field, toCheck) => {
      const parts = toCheck.split(" ");
      const percentage = parseInt(parts[0]);
      const fieldPercentage = parseInt(field);
      if (!field || fieldPercentage < 0 || Number.isNaN(fieldPercentage)) 
        return this.selectedDataNotAvailable;
      return fieldPercentage >= percentage;
    }
    if (this.selectedUsImg){
      this.shownList = this.shownList.filter((item) => percentageCondition(item.imgpercentage, this.selectedUsImg))
    }
    if (this.selectedNonUsImg){
      this.shownList = this.shownList.filter((item) => percentageCondition(item.nonUsImgPercentage, this.selectedNonUsImg))
    }
  }

  getStep1Score(){
    if (this.userProfile.Step1Result === "Score")
      return this.userProfile.Step1Score;

    const avgScores = {
      1 : { noVisa : 225, visa : 238},
      2	: { noVisa :211, visa :	219},
      4	: { noVisa :225, visa :	239},
      7	: { noVisa :219, visa :	232},
      3	: { noVisa :220, visa :	229},
      5	: { noVisa :216, visa :	219},
      10 : { noVisa :236, visa :	238},
      12 : { noVisa :226, visa :	236},
      15 : { noVisa :220, visa :	230}
    };

    const needVisa = this.userProfile.Visas.indexOf("1") == -1 && this.userProfile.Visas.indexOf("6") == -1;
    if (needVisa)
      return avgScores[this.selectedPId] ? avgScores[this.selectedPId].visa : 0;
    return avgScores[this.selectedPId] ? avgScores[this.selectedPId].noVisa : 0;

  }

  exportToExcel(key): void 
  {
    if (key === "sarthi-list"){
      if (this.shownList.length === 0){
        this.toastr.info("You cannot export an empty table");
        return;
      }
    }
    else{
      if(this.favorites.length==0 && !this.favorites.adminNotes){
        this.toastr.info("You cannot export an empty table");
        return;
      }
    }
    let fileName = `${key}.xlsx`;
    /* table id is passed over here */   
    let element = document.getElementById(key); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);
    
  }
}
