import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NgbCalendar, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ObservershipService } from "../observership/services/observership.service";
import { ToastrService } from "ngx-toastr";
import { debounceTime, first } from "rxjs/operators";

import * as firebase from "firebase";
import { AngularFireFunctions } from "@angular/fire/functions";
import { RotationsService } from "./services/rotations.service";
import { AuthenticationService } from "../common/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-rotations",
  templateUrl: "./rotations.component.html",
  styleUrls: ["./rotations.component.scss"],
})
export class RotationsComponent implements OnInit {
  landing: string;
  loading: boolean;
  isFiltersCollapsed: boolean = false;
  specialities = [];
  locationCodes = [];
  title = [];
  cities = [];
  states = [];
  types = [];
  rotationSettings = [];
  visaLetters = ["Yes", "No"];
  lorTypes = [];
  selectedLocationCode = [];
  selectedTitle = [];
  selectedSpecialities = [];
  selectedTypes = [];
  selectedCities = [];
  selectedStates = [];
  selectedRotationSettings = [];
  selectedVisaLetters = [];
  selectedLorTypes = [];
  selectedOffers = false;
  selectedPD = false;
  selectedCombo = false;
  sortFees: boolean = false;
  rotationsObject: any = {};
  hospitals: any = [];
  rotationCode: string | null = null;
  refinedHospitals: any[] = [];
  pageHospitals: any = [];
  selectedHospital: any;
  pageStart: number = 0;
  pageEnd: number = 50;
  step: number = 50;
  input: any = {
    query: "",
    date: this.calendar.getToday(),
    duration: "4 Weeks",
    sarthi: "",
    phone: "",
  };
  userEnquiries: any = [];
  currentStatus: any = "";
  userType: any = "";
  phoneError: boolean = false;

  @ViewChild("instructions", { static: false })
  instructionModal: TemplateRef<any>;
  @ViewChild("content", { static: false }) rotationsModal: TemplateRef<any>;
  @ViewChild("reviews", { static: false }) reviewsModal: TemplateRef<any>;

  constructor(
    public modalService: NgbModal,
    private dbService: RotationsService,
    private toastr: ToastrService,
    private afn: AngularFireFunctions,
    public auth: AuthenticationService,
    public router: Router,
    public calendar: NgbCalendar,
    public route: ActivatedRoute
  ) {}

  async ngOnInit() {
    try {
      this.route.paramMap.subscribe(params => {
        this.rotationCode = params.get('rotationcode');
        console.log('Rotation Code:', this.rotationCode);
      });
      console.log('Rotation Code:', this.rotationCode);
      this.loading = true;
      this.landing = "list";
      const kommunicateWidget = document.getElementById("kommunicate-widget-iframe");
    if (kommunicateWidget) {
      kommunicateWidget.style.display = "none";
    }
      let results = await Promise.all([
        this.dbService.getAllHospitals(),
        this.dbService.getEnquiriesByUId(this.auth.userData),
      ]);
      this.rotationsObject = results[0];
      this.hospitals = Object.values(this.rotationsObject);
      this.userEnquiries = results[1];
      this.refinedHospitals = Array.from(this.hospitals);
      this.refinedHospitals = this.refinedHospitals.sort(
        (a, b) => parseInt(a.rank) - parseInt(b.rank)
      );
      this.pageEnd =
        this.pageStart + this.step > this.refinedHospitals.length
          ? this.refinedHospitals.length
          : this.pageStart + this.step;
      this.pageHospitals = this.refinedHospitals.slice(
        this.pageStart,
        this.pageEnd
      );
      let specialities = {};
      let types = {};
      let cities = {};
      let states = {};
      let visaLetters = {};
      let rotationSettings = {};
      let lorTypes = {};
      let locationCodes = {};
      let title = {};
      for (let hospital of this.hospitals) {
        specialities[hospital.specialty] = 1;
        cities[hospital.city] = 1;
        states[hospital.state] = 1;
        types[hospital.type] = 1;
        locationCodes[hospital.location_code] = 1;
        title[hospital.title] = 1;
        visaLetters[hospital.visa_letter] = 1;
        rotationSettings[hospital.rotation_setting] = 1;
        lorTypes[hospital.lor_type] = 1;
      }
      this.locationCodes = Object.keys(locationCodes).sort();
      this.title = Object.keys(title).sort();
      this.specialities = Object.keys(specialities).sort();
      this.types = Object.keys(types).sort();
      this.cities = Object.keys(cities).sort();
      this.states = Object.keys(states).sort();
      this.rotationSettings = Object.keys(rotationSettings).sort();
      this.lorTypes = Object.keys(lorTypes).sort();
      this.loading = false;
      let  selectedId = this.route.snapshot.queryParamMap.get("rotation");
      const view = this.route.snapshot.queryParamMap.get("view");
      if(selectedId===null && this.rotationCode!=null)
      {
        selectedId=this.rotationCode;
      }
      console.log("view=========",view)
      console.log("selectedId=========",selectedId)
      const selectedRotation = this.refinedHospitals.find(
        (r) => r.location_code === selectedId
      );
      console.log("selectedId------------------------->",selectedId)
      console.log("selectedRotation------------------------->",selectedRotation)
      if (selectedRotation) {
        if (view === "testimonials") {
          this.openReviews(this.reviewsModal, selectedRotation);
        } else {
          this.open(this.rotationsModal, selectedRotation);
        }
      } else this.openInstructions(this.instructionModal);
    } catch (err) {
      console.log(err.message);
      this.toastr.error("Error while fetching the data, please try again");
    }
  }
  redirectToLogin(modal: any): void {
    modal.close(); // Close the modal
    const currentUrl = this.router.url;
    console.log("currentUrl----->",currentUrl)
    console.log("this.router----->",this.router)
    localStorage.setItem('redirectUrl', currentUrl);
    this.router.navigate(['/authenticate']); // Redirect to /authenticate
  }
  onNextPage() {
    if (this.pageEnd + 1 < this.refinedHospitals.length) {
      this.pageStart = this.pageEnd;
      this.pageEnd =
        this.pageStart + this.step > this.refinedHospitals.length
          ? this.refinedHospitals.length
          : this.pageStart + this.step;
      this.pageHospitals = this.refinedHospitals.slice(
        this.pageStart,
        this.pageEnd
      );
    }
  }

  onPreviousPage() {
    if (this.pageStart > 0) {
      this.pageEnd = this.pageStart;
      this.pageStart =
        this.pageEnd - this.step > 0 ? this.pageEnd - this.step : 0;
      this.pageHospitals = this.refinedHospitals.slice(
        this.pageStart,
        this.pageEnd
      );
    }
  }

  openInstructions(content) {
    this.modalService.open(content, { size: "lg" });
  }
  open(content, hospital) {
    this.selectedHospital = hospital;
    this.modalService.open(content, { size: "lg" });
    let enquiry = this.userEnquiries.find(
      (item) => item.rotationId === hospital.id
    );
    if (enquiry) {
      if (enquiry.status === "Pending") this.currentStatus = "pending";
      else if (enquiry.status === "Accepted") this.currentStatus = "enquired";
      if (enquiry.status === "Rejected") this.currentStatus = "rejected";
    } else this.currentStatus = "fresh";
    this.input = {
      query: "",
      date: null,
      duration: "4 Weeks",
      email: "",
      name: "",
      isNewUser: false,
      sarthi: "",
    };
    if (this.auth.isLoggedIn) this.userType = "logged";
    else this.userType = "guest";
    window.scrollTo(0, 0);
    this.router.navigate([], {
      queryParams: {
        rotation: hospital.location_code,
      },
    });
  }

  async openReviews(content, hospital) {
    try {
      this.selectedHospital = hospital;
      this.selectedHospital.reviews = await this.dbService.getReviews(
        hospital.location_code
      );
      if (
        this.selectedHospital.reviews &&
        this.selectedHospital.reviews.length == 0
      ) {
        this.toastr.info(
          "We currently do not have testimonials for this rotation. Stay tuned for updates"
        );
        return;
      }
      this.modalService.open(content, { size: "lg" });
      window.scrollTo(0, 0);
      this.router.navigate([], {
        queryParams: {
          rotation: hospital.location_code,
          view: "testimonials",
        },
      });
    } catch (err) {
      console.log(err);
      this.toastr.error(
        "Error while searching for testimonials, Please try again"
      );
    }
  }

  applyFilters() {
    this.refinedHospitals = this.hospitals.filter((hospital) => {
      let include = true;
      if (this.selectedSpecialities.length)
        include =
          include &&
          this.selectedSpecialities.indexOf(hospital.specialty) != -1;
      if (this.selectedLocationCode.length)
        include =
          include &&
          this.selectedLocationCode.indexOf(hospital.location_code) != -1;
      if (this.selectedTitle.length)
        include =
          include &&
          this.selectedTitle.indexOf(hospital.title) != -1;
      if (this.selectedTypes.length)
        include = include && this.selectedTypes.indexOf(hospital.type) != -1;
      if (this.selectedStates.length)
        include = include && this.selectedStates.indexOf(hospital.state) != -1;
      if (this.selectedCities.length)
        include = include && this.selectedCities.indexOf(hospital.city) != -1;
      if (this.selectedRotationSettings.length)
        include =
          include &&
          this.selectedRotationSettings.indexOf(hospital.rotation_setting) !=
            -1;
      if (this.selectedLorTypes.length)
        include =
          include && this.selectedLorTypes.indexOf(hospital.lor_type) != -1;
      if (this.selectedOffers) include = include && hospital.offers;
      if (this.selectedPD) include = include && hospital.program_director;
      if (this.selectedCombo) include = include && hospital.combo_rotation;
      // if(this.selectedVisaLetters.length && this.selectedVisaLetters.length==1){
      //   this.selectedVisaLetters.forEach((v) => {
      //     let isProviding = hospital.visa_letter.toLowerCase().indexOf("not")===-1;
      //     if (v==="Yes")
      //       include = include && isProviding;
      //     else
      //       include = include && !isProviding;
      //   })
      // }
      return include;
    });
    for (let hospital of this.refinedHospitals) {
      let feeString = hospital.fee;
      let feeNum = 0;
      for (let f of feeString) {
        if (f >= "0" && f <= "9") {
          feeNum = feeNum * 10 + parseInt(f);
        }
      }
      hospital.feeNum = feeNum;
    }
    if (this.sortFees) {
      this.refinedHospitals.sort((a, b) => a.feeNum - b.feeNum);
    }
    this.pageStart = 0;
    this.pageEnd =
      this.pageStart + this.step > this.refinedHospitals.length
        ? this.refinedHospitals.length
        : this.pageStart + this.step;
    this.pageHospitals = this.refinedHospitals.slice(
      this.pageStart,
      this.pageEnd
    );
  }

  hasError(errEvent) {
    this.phoneError = !errEvent;
  }

  getNumber(number) {
    this.input.phone = number;
  }

  async doEnquire(rotationId,LocationCode="") {
    try {
      if (!this.auth.isLoggedIn) {
        this.input.isNewUser = true;
      }
      if (
        !this.calendar.isValid(this.input.date) ||
        this.calendar.getToday().after(this.input.date)
      ) {
        this.toastr.error("Date is invalid");
        return;
      }
      if (this.phoneError) {
        this.toastr.error("Phone is invalid");
        return;
      }
      if (!this.validateInputs()) return;
      await this.dbService.enquireRotation(
        this.auth.userData,
        rotationId,
        LocationCode,
        this.input
      );
     this.userEnquiries = await this.dbService.getEnquiriesByUId(
        this.auth.userData
      );
      this.toastr.success(
        "We have received your request. We will let you know within 2 business days, if there is availability"
      );
      this.modalService.dismissAll();
      console.log("toaster ===Worked")
    } catch (err) {
      this.toastr.error(
        "Error while processing your enquiry, Please try again"
      );
      console.log(err);
    }
  }

  validateInputs() {
    let input = this.input;
    if (input.isNewUser) {
      if (
        input.email.trim() === "" ||
        input.name.trim() === "" ||
        input.phone.trim() === ""
      )
        return false;
    } else {
      if (!this.auth.userData) return false;
    }
    if (input.duration.trim() === "") return false;
    if (input.sarthi.trim() === "") return false;
    return true;
  }
}
