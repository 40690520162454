import {
  Injectable
} from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore
} from '@angular/fire/firestore';
import {
  Hospital
} from '../models/hospital';
import * as firebase from 'firebase';
import {
  HospitalProgram
} from '../models/hospital-program';
@Injectable({
  providedIn: 'root'
})
export class HospitalService {
  collectionRef: AngularFirestoreCollection < Hospital[] > ;
  hospitalsList: Hospital[];
  hospitalsByProgram: any = {};
  hospitals: any = [];

  constructor(private firestore: AngularFirestore) {
    this.collectionRef = this.firestore.collection('Hospital');
  }

  async getHospitalsByProgram(id: any): Promise < Hospital[] > {
    let programId = id.toString();
    if (!Object.keys(this.hospitalsByProgram[programId] || {}).length){
      this.hospitalsByProgram[programId] = [];
      let hids = [];
      let hid_hpid = {};
      let hospitaldocs = await this.firestore.collection < Hospital > ("Hospital", ref => {
        return ref
          .where("PIds", "array-contains", programId).orderBy("HName", "asc");
      }).get().toPromise();
      let doc, hid, data;
      for (var i in hospitaldocs.docs) {
        doc = hospitaldocs.docs[i];
        data = doc.data();
        data.HId = doc.id;
        this.hospitalsByProgram[programId].push(data as Hospital);
      }
    }
    return this.hospitalsByProgram[programId];
  }

  /*async getHospitalByHPId(hids: any, dataObject: any) {
    let doc = await this.firestore.doc(`HospitalProgram/${hpid}`).get().toPromise();
    let hid = doc.data().HId;
    let hospital = await this.firestore.doc(`Hospital/${hid}`).get().toPromise();
    return hospital.data() as Hospital;
    let docRefs = await this.firestore.collection < Hospital > (`Hospital`, ref => ref.where(firebase.firestore.FieldPath.documentId(), "in", hids)).get().toPromise();
    for (var i in docRefs.docs) {
      let doc = docRefs.docs[i];
      let data = doc.data();
      data.HId = doc.id;
      hospitals[doc.id] = data;
    }


    return hospitals;
  }*/

  async getHospitalsObjectByProgram(pid: any): Promise <any>
  {
    let hospitalsList = await this.getHospitalsByProgram(pid);
    let hospitals = {};
    for(let i in hospitalsList)
    {
      let hospital = hospitalsList[i];
      hospitals[hospital.HId] = hospital;
    }
    return hospitals;
  }

  async getHospitalsByHIds(hidsObj: any[], dataObject): Promise <any>
  {
    let hids      = [...hidsObj];
    let spliced   = [];
    let hospitals = {};
    let calls     = [];
    while( hids.length> 0)
    {
      if (hids.length > 10)
        spliced = hids.splice(0, 10);
      else  
        spliced = hids.splice(0, hids.length);
      calls.push(this.firestore.collection < Hospital > (`Hospital`, ref => ref.where(firebase.firestore.FieldPath.documentId(), "in", spliced)).get().toPromise());
    }
    let results = await Promise.all(calls);
    for(let result of results){
      for( let i in result.docs)
        {
          let doc = result.docs[i];
          hospitals[doc.id] = doc.data();
        }
    }
    for( let i in dataObject)
    {
      let object = dataObject[i];
      if( object.HId in hospitals)
        object.hospital = hospitals[object.HId];
    }
    return dataObject;
  }
  async getSomeHospitals(val)
  {
    let limitedHospitals =  {};
    let docsRef = await this.firestore.collection("Hospital", ref=> ref.orderBy("HName").startAt(val).endAt(val+'\uf8ff')).get().toPromise();
    for( let i in docsRef.docs)
    {
      let doc = docsRef.docs[i];
      limitedHospitals[doc.id]= {...doc.data(), ...{HId: doc.id}};
    }
    docsRef = await this.firestore.collection("Hospital", ref=> ref.orderBy("City").startAt(val).endAt(val+'\uf8ff')).get().toPromise();
    for( let i in docsRef.docs)
    {
      let doc = docsRef.docs[i];
      limitedHospitals[doc.id]= {...doc.data(), ...{HId: doc.id}};
    }
    docsRef = await this.firestore.collection("Hospital", ref=> ref.orderBy("State").startAt(val).endAt(val+'\uf8ff')).get().toPromise();
    for( let i in docsRef.docs)
    {
      let doc = docsRef.docs[i];
      limitedHospitals[doc.id]= {...doc.data(), ...{HId: doc.id}};
    }
    return limitedHospitals;
  }
}
