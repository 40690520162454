
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { NgbModule, NgbSlide } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from "@angular/forms";
import { 
    PerfectScrollbarModule, 
    PERFECT_SCROLLBAR_CONFIG, 
    PerfectScrollbarConfigInterface
  } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireFunctions, AngularFireFunctionsModule } from "@angular/fire/functions";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationService } from "./common/authentication.service";
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng5SliderModule } from 'ng5-slider';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { HttpClientModule } from '@angular/common/http';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };
  

  @NgModule({
    declarations: [AppComponent, routingComponents],
    imports: [
      BrowserAnimationsModule,
      AppRoutingModule,
      FormsModule,
      NgSelectModule,
      NgxSpinnerModule,
      Ng5SliderModule,
      NgbModule.forRoot(),
      ToastrModule.forRoot({
        timeOut: 3500,
        positionClass: "toast-bottom-left",
        preventDuplicates: true,
      }),
      PerfectScrollbarModule,
      AngularFireAuthModule, 
      AngularFireModule.initializeApp(environment.firebaseConfig),
      AngularFireDatabaseModule,
      Ng2TelInputModule,
      AngularFireFunctionsModule,
      HttpClientModule,
    ],
    providers: [
      {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      },
      AuthenticationService,
      CookieService,
      AngularFirestore,
      { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ],
    bootstrap: [AppComponent]
  })
  export class AppModule {}
  