import {
  Injectable,
  NgZone
} from '@angular/core';
import {
  auth,
  firestore
} from 'firebase/app';
import {
  AngularFireAuth
} from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { CookieService } from 'ngx-cookie-service';
import * as firebase from 'firebase/app';
const timestamp = firebase.firestore.Timestamp.now();
import {
  Router,ActivatedRoute
} from "@angular/router";
import {
  merge
} from 'rxjs';
import {
  ToastrService
} from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  userData: any; // Save logged in user data
  private referrer: string;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.route.queryParams.subscribe(params => {
      this.referrer = params['referrer']  || 'Unknown';
      console.log("Referrer:", this.referrer);
      if(this.referrer!=="Unknown")
      sessionStorage.setItem('referrer', this.referrer); // Store for later use
    });
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        await this.readUserData(user);
        
        const uid = user.uid;

    // Call backend to get custom token
      const token = await this.getCustomToken(uid);
        // Store token in cookies accessible across subdomains
        this.cookieService.set('authToken', token, 7000, '/', '.usmlesarthi.com', true, 'Strict');
        
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
        this.userData = undefined;
        
        
        if (!this.router.url.includes("authenticate")) 
        {
          router.navigate(['']);
        }
      }
    })
  }
  async getCustomToken(uid: string): Promise<string | null> {
    try {
      const response = await this.http.post<{ customToken: string }>(
        'https://us-central1-usmlesarthi-residency-match.cloudfunctions.net/generateCustomToken',
        { uid }
      ).toPromise();
      
      console.log("Custom Token:", response.customToken);
      return response.customToken;
    } catch (error) {
      console.error("Error getting custom token:", error.message);
      return null;
    }
  }
  // Sign in with email/password
  async SignIn(email: any, password: any) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        await this.readUserData(result.user);
        const token = await this.getCustomToken(result.user.uid);
        // Store token in cookies accessible across subdomains
        this.cookieService.set('authToken', token, 7000, '/', '.usmlesarthi.com', true, 'Strict');
        const referrer = sessionStorage.getItem('referrer') || 'Unknown';
        console.log("redirectUrl---->",referrer)
        if(referrer==="aichat")
        {
            window.location.href = 'https://ai.usmlesarthi.com/'; 
            return;
        }
        else
        {
          const redirectUrl = localStorage.getItem('redirectUrl') || '';
          console.log("redirectUrl---->",redirectUrl)
          if (redirectUrl && redirectUrl !== '/authenticate') 
          {
            localStorage.removeItem('redirectUrl'); // Clean up
            console.log("redirectUrl---->",redirectUrl)
            //redirectUrl.replace("home","rotations");
            this.router.navigateByUrl(redirectUrl.replace("home","rotations"));
          }
          else
          {
            this.router.navigate(['']);
          }
        }
        
        return result.user;
      })
  }

  // Sign up with email/password
  async SignUp(email: any, password: any, name: any, phone : string, medicalSchool: string, yearOfApplying: string) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        let userData = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: name,
          createdAt:timestamp,
          updatedAt:timestamp,
          source:'register residency',
          phone,
          yearOfApplying,
          medicalSchool,
          emailVerified: result.user.emailVerified,
          Locked: "0",
        }
        await this.setProfileData(userData);
        const redirectUrl = localStorage.getItem('redirectUrl') || '';
          console.log("redirectUrl---->",redirectUrl)
          if (redirectUrl && redirectUrl !== '/authenticate') 
          {
            localStorage.removeItem('redirectUrl'); // Clean up
            console.log("redirectUrl---->",redirectUrl)
            //redirectUrl.replace("home","rotations");
            this.router.navigateByUrl(redirectUrl.replace("home","rotations"));
          }
          else
          {
            this.router.navigate(['']);
          }
        //this.router.navigate(['']);
      })
  }

  // Send email verfificaiton when new user sign up
  async SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['authenticate']);
      })
  }

  // Reset Forggot password
  async ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = this.userData;
    return this.userData !== undefined ? true : false;
  }

  get isEmailVerified(): boolean {
    const user = this.userData;
    if (!user || !user.emailVerified)
      return false;
    else
      return true;
  }
  get isUserVerified(): boolean {
    const user = this.userData;
    if (!user || !user.Role || user.Role == "NA" || user.Role == "Default")
      return false;
    else
      return true;
  }
  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  async readUserData(user: any) {
    let results = await Promise.all([this.afs.doc(`Users/${user.uid}`).get().toPromise(), this.afs.doc(`UsersRoles/${user.uid}`).get().toPromise(), this.afs.doc(`UsersExtraData/${user.uid}`).get().toPromise()]);
    let docRef = results[0];
    let userRoleDocRef = results[1];
    if (!docRef.exists) { //Added to accomodate users who have registered aleady to Planner Login System
      let docRef2 = await this.afs.doc(`userInfo/${user.uid}`).get().toPromise();
      let display = user.email;
      if (docRef2.exists) {
        let data = docRef2.data();
        display = data.fname + " " + data.lname;
      }
      this.userData = {
        uid: user.uid,
        email: user.email,
        displayName: display,
        emailVerified: user.emailVerified,
        Locked: "0",
        Role: "NA",
      }
      this.setProfileData(this.userData);
    } else {
      this.userData = {
        ...docRef.data(),
        ...{
          uid: user.uid,
          emailVerified: user.emailVerified
        },
        ...userRoleDocRef.data()
      };
    }
    let extraDocRef = results[2];
    if (!extraDocRef.exists) {
      this.userData.extraUsersData = false;
    } else {
      this.userData = {
        ...extraDocRef.data(),
        ...this.userData,
      };
      this.userData.extraUsersData = true;
    }
    localStorage.setItem('user', JSON.stringify(this.userData));
    JSON.parse(localStorage.getItem('user'));
  }
  async getMaxStudentUniqueId(collectionName: string): Promise<number> {
    try {
      const q = this.afs.collection(collectionName, ref =>
        ref.orderBy("StudentUniqueId", "desc").limit(1) // 🔹 Get highest StudentUniqueId
      );
  
      const querySnapshot = await q.get().toPromise();
      if (querySnapshot.empty) {
        console.log("No records found. Returning default 0.");
        return 0;
      }
  
      const maxStudentUniqueId = querySnapshot.docs[0].data().StudentUniqueId;
      console.log(`🎉 Max StudentUniqueId: ${maxStudentUniqueId}`);
      return maxStudentUniqueId;
    } catch (error) {
      console.error("❌ Error fetching max StudentUniqueId:", error);
      return 0;
    }
  }
  async setProfileData(userData: any) {
    const maxStudentUniqueId = await this.getMaxStudentUniqueId("Users");
    const newStudentUniqueId = maxStudentUniqueId ? maxStudentUniqueId + 1 : 1;
    let batch = this.afs.firestore.batch();
    console.log("=======================")
    let docRef = this.afs.doc(`Users/${userData.uid}`).ref;
    let userRoleDocRef = this.afs.doc(`UsersRoles/${userData.uid}`).ref;
    userData['StudentUniqueId']=newStudentUniqueId,
    batch.set(docRef, userData, {
      merge: true
    });
    batch.set(userRoleDocRef, {
      uid: userData.uid,
      email: userData.email,
      source: 'register residency',
      createdAt:timestamp,
      StudentUniqueId:newStudentUniqueId,
      updatedAt:timestamp,
      displayName: userData.displayName,
      Role: "Default"
    }, {
      merge: true
    });
    await batch.commit();
    this.userData = userData;
    localStorage.setItem('user', JSON.stringify(userData));
    JSON.parse(localStorage.getItem('user'));
    const referrer = sessionStorage.getItem('referrer') || 'Unknown';
        if(referrer==="aichat")
        {
          window.location.href = 'https://ai.usmlesarthi.com/'; 
          return;
        }
    return;
  }
  // Sign out 
  async SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.toastr.success("Signed out successfully");
      this.cookieService.delete('authToken', '/', '.usmlesarthi.com');
      this.router.navigate(['']);
    }).catch(err => {
      this.toastr.error("Error while logging out");
    })
  }

}
