export const medicalSchoolOptions : string[]  =  [
  "A C S Medical College And Hospital (Chennai, India)",
  "A C S R Government Medical College (Nellore, India)",
  "A J Institute Of Medical Sciences And Research Centre (Mangalore, India)",
  "Aarupadai Veedu Medical College (Pondicherry, India)",
  "Acharya Shri Chander College Of Medical Sciences (Sidhra, India)",
  "Adesh Institute Of Medical Sciences & Research (Bathinda, India)",
  "Adesh Medical College And Hospital, Shahabad (Kurukshetra, India)",
  "Adichunchanagiri Institute Of Medical Sciences (Bellur, India)",
  "Agartala Government Medical College (Agartala, India)",
  "Akash Institute Of Medical Sciences & Research Centre (Bangalore, India)",
  "Al-ameen Medical College (Bijapur, India)",
  "Al-azhar Medical College And Super Speciality Hospital (Thodupuzha, India)",
  "Al-falah School Of Medical Sciences & Research Centre (Faridabad, India)",
  "All India Institute Of Medical Sciences, Bhatinda (Bathinda, India)",
  "All India Institute Of Medical Sciences, Bhopal (Bhopal, India)",
  "All India Institute Of Medical Sciences, Bhubaneswar (Bhubaneswar, India)",
  "All India Institute Of Medical Sciences, Bibinagar (Bibinagar, India)",
  "All India Institute Of Medical Sciences, Deoghar (Deoghar, India)",
  "All India Institute Of Medical Sciences, Gorakhpur (Gorakhpur, India)",
  "All India Institute Of Medical Sciences, Jodhpur (Jodhpur, India)",
  "All India Institute Of Medical Sciences, Kalyani (Kalyani, India)",
  "All India Institute Of Medical Sciences, Mangalagiri (Mangalagiri, India)",
  "All India Institute Of Medical Sciences, Nagpur (Nagpur, India)",
  "All India Institute Of Medical Sciences, New Delhi (New Delhi, India)",
  "All India Institute Of Medical Sciences, Patna (Patna, India)",
  "All India Institute Of Medical Sciences, Raebareli (Raebareli, India)",
  "All India Institute Of Medical Sciences, Raipur (Raipur, India)",
  "All India Institute Of Medical Sciences, Rajkot (Rajkot, India)",
  "All India Institute Of Medical Sciences, Rishikesh (Rishikesh, India)",
  "Alluri Sitaram Raju Academy Of Medical Sciences (Eluru, India)",
  "Amala Institute Of Medical Sciences (Thrissur, India)",
  "Amaltas Institute Of Medical Sciences (Dewas, India)",
  "American International Institute Of Medical Sciences (Udaipur, India)",
  "Amrita School Of Medicine (Kochi, India)",
  "Ananta Institute Of Medical Sciences & Research Centre (Rajsamand, India)",
  "Andaman & Nicobar Islands Institute Of Medical Sciences (Port Blair, India)",
  "Andhra Medical College (Visakhapatnam, India)",
  "Annapoorna Medical College And Hospital (Salem, India)",
  "Anugrah Narayan Magadh Medical College And Hospital (Gaya, India)",
  "Apollo Institute Of Medical Sciences And Research, Chittoor (Chittoor, India)",
  "Apollo Institute Of Medical Sciences And Research, Hyderabad (Hyderabad, India)",
  "Armed Forces Medical College (Pune, India)",
  "Army College Of Medical Sciences (New Delhi, India)",
  "Arunai Medical College And Hospital (Tiruvannamalai, India)",
  "Ashwini Rural Medical College, Hospital And Research Centre (Solapur, India)",
  "Assam Medical College (Dibrugarh, India)",
  "Atal Bihari Vajpayee Institute Of Medical Sciences And Dr Ram Manohar Lohia Hospital (New Delhi, India)",
  "Autonomous State Medical College And Hospital Firozabad (Firozabad, India)",
  "Autonomous State Medical College Basti (Rampur, India)",
  "Autonomous State Medical College Etah (Etah, India)",
  "Autonomous State Medical College Fatehpur (Fatehpur, India)",
  "Autonomous State Medical College Ghazipur (Ghazipur, India)",
  "Autonomous State Medical College Hardoi (Hardoi, India)",
  "Autonomous State Medical College Mirzapur (Mirzapur, India)",
  "Autonomous State Medical College Pratapgarh (Pratapgarh, India)",
  "Autonomous State Medical College Siddharthnagar (Siddharthnagar, India)",
  "Ayaan Institute Of Medical Sciences (Kanaka Mamidi, India)",
  "Azeezia Institute Of Medical Science (Kollam, India)",
  "B G S Global Institute Of Medical Sciences (Bangalore, India)",
  "B J Government Medical College, Pune (Pune, India)",
  "B J Medical College, Ahmedabad (Ahmedabad, India)",
  "B K L Walawalkar Rural Medical College (Chiplun, India)",
  "B P S Government Medical College For Women (Sonepat, India)",
  "Baba Raghav Das Medical College (Gorakhpur, India)",
  "Banas Medical College And Research Institute (Palanpur, India)",
  "Bangalore Medical College And Research Institute (Bangalore, India)",
  "Bankura Sammilani Medical College (Bankura, India)",
  "Basaveshwara Medical College And Hospital (Chitradurga, India)",
  "Belgaum Institute Of Medical Sciences (Belgaum, India)",
  "Believers Church Medical College And Hospital (Thiruvalla, India)",
  "Bhaarath Medical College And Hospital (Chennai, India)",
  "Bhagwan Mahavir Institute Of Medical Sciences (Pawapuri, India)",
  "Bharat Ratna Late Shri Atal Bihari Vajpyee Memorial Medical College (Rajnandgaon, India)",
  "Bharati Vidyapeeth Medical College And Hospital, Sangli (Sangli, India)",
  "Bharati Vidyapeeth Medical College, Pune (Pune, India)",
  "Bhaskar Medical College (Yenkapally, India)",
  "Bhima Bhoi Medical College & Hospital (Balangir, India)",
  "Bidar Institute Of Medical Sciences (Bidar, India)",
  "Bundelkhand Medical College (Sagar, India)",
  "Burdwan Medical College (Burdwan, India)",
  "C U Shah Medical College (Surendra Nagar, India)",
  "Calcutta National Medical College (Kolkata, India)",
  "Career Institute Of Medical Sciences & Hospital (Lucknow, India)",
  "Chalmeda Anand Rao Institute Of Medical Sciences (Karimnagar, India)",
  "Chamarajanagar Institute Of Medical Sciences (Chamarajanagar, India)",
  "Chandulal Chandrakar Memorial Medical College (Durg, India)",
  "Chengalpattu Medical College (Chengalpattu, India)",
  "Chettinad Hospital & Research Institute (Kancheepuram, India)",
  "Chhattisgarh Institute Of Medical Sciences (Bilaspur, India)",
  "Chikkaballapura Institute Of Medical Sciences (Chikkaballap, India)",
  "Chintpurni Medical College And Hospital, Gurdaspur (Pathankot, India)",
  "Chirayu Medical College And Hospital (Bhopal, India)",
  "Christian Medical College, Ludhiana (Ludhiana, India)",
  "Christian Medical College, Vellore (Vellore, India)",
  "Coimbatore Medical College (Coimbatore, India)",
  "College Of Medicine And J N M Hospital (Kalyani, India)",
  "College Of Medicine And Sagore Dutta Hospital (Kolkata, India)",
  "Coochbehar Government Medical College & Hospital (Coochbehar, India)",
  "D M Wayanad Institute Of Medical Sciences (Arapetta, India)",
  "D Y Patil Medical College, Kolhapur (Kolhapur, India)",
  "D Y Patil University School Of Medicine (Navi Mumbai, India)",
  "Darbhanga Medical College And Hospital (Leheriasarai, India)",
  "Datta Meghe Medical College Nagpur (Nagpur, India)",
  "Dayanand Medical College (Ludhiana, India)",
  "Deccan College Of Medical Sciences (Hyderabad, India)",
  "Dhanalakshmi Srinivasan Medical College And Hospital, Perambalur (Siruvachur, India)",
  "Diamond Harbour Government Medical College And Hospital (Diamond Harbour, India)",
  "Diphu Medical College And Hospital, Assam (Diphu, India)",
  "Dr B R Ambedkar Medical College (Bangalore, India)",
  "Dr B R Ambedkar State Institute Of Medical Sciences Sas Nagar Mohali (Mohali, India)",
  "Dr Baba Saheb Ambedkar Medical College (New Delhi, India)",
  "Dr Chandramma Dayananda Sagar Institute Of Medical Education And Research (Hubli, India)",
  "Dr D Y Patil Medical College, Hospital And Research Centre (Pimpri, India)",
  "Dr Kiran C Patel Medical College And Research Institute (Bharuch, India)",
  "Dr M K Shah Medical College & Research Centre (Ahmedabad, India)",
  "Dr N Y Tasgaonkar Institute Of Medical Science (Raigad, India)",
  "Dr N D Desai Faculty Of Medical Science And Research (Nadiad, India)",
  "Dr Panjabrao Deshmukh Memorial Medical College (Amravati, India)",
  "Dr Patnam Mahender Reddy Institute Of Medical Sciences (Chevella, India)",
  "Dr Pinnamaneni Siddhartha Institute Of Medical Sciences (Chinna Avutapalli, India)",
  "Dr Radhakrishnan Government Medical College (Hamirpur, India)",
  "Dr Rajendra Prasad Government Medical College (Tanda, India)",
  "Dr Ram Manohar Lohia Institute Of Medical Sciences (Lucknow, India)",
  "Dr S N Medical College (Jodhpur, India)",
  "Dr S S Tantia Medical College Hospital And Research Centre (Sri Ganganagar, India)",
  "Dr Shankarrao Chavan Government Medical College, Nanded (Nanded, India)",
  "Dr Somervell Memorial Csi Hospital & Medical College (Thiruvananthapuram, India)",
  "Dr Ulhas Patil Medical College & Hospital (Jalgaon, India)",
  "Dr V R K Women's Medical College (Aziznagar, India)",
  "Dr Vaishampayan Memorial Medical College (Solapur, India)",
  "Dr Vithalrao Vikhe Patil Foundation's Medical College (Ahmednagar, India)",
  "Dr Yashwant Singh Parmar Government Medical College Nahan (Nahan, India)",
  "Dr Vasantrao Pawar Medical College Hospital And Research Centre (Nashik, India)",
  "E S I C Medical College & Postgraduate Institute Of Medical Sciences And Research (Chennai, India)",
  "East Point College Of Medical Sciences & Research Centre, Bangalore (Bangalore, India)",
  "Employees State Insurance Corporation Medical College Alwar (Alwar, India)",
  "Employees' State Insurance Corporation Medical College, Bangalore (Bangalore, India)",
  "Employees' State Insurance Corporation Medical College, Faridabad (Faridabad, India)",
  "Employees' State Insurance Corporation Medical College, Gulbarga (Kalaburagi, India)",
  "Employees' State Insurance Corporation Medical College, Joka (Kolkata, India)",
  "Employees' State Insurance Corporation Medical College, Patna (Patna, India)",
  "Employees' State Insurance Corporation Medical College, Sanathnagar (Hyderabad, India)",
  "Era's Lucknow Medical College (Lucknow, India)",
  "F H Medical College & Hospital (Agra, India)",
  "Fakhruddin Ali Ahmed Medical College And Hospital (Barpeta, India)",
  "Fakir Mohan Medical College & Hospital, Balasore (Balasore, India)",
  "Father Muller Medical College (Mangalore, India)",
  "Fathima Institute Of Medical Sciences, Kadapa (Kadapa, India)",
  "G C S Medical College, Hospital And Research Centre (Ahmedabad, India)",
  "G M E R S Medical College And Hospital, Dharpur Patan (Patan, India)",
  "G M E R S Medical College And Hospital, Gandhinagar (Gandhinagar, India)",
  "G M E R S Medical College And Hospital, Himmatnagar (Himmatnagar, India)",
  "G M E R S Medical College And Hospital, Sola (Ahmedabad, India)",
  "G M E R S Medical College, Gotri (Vadodara, India)",
  "G M E R S Medical College, Junagadh (Junagadh, India)",
  "G M E R S Medical College, Vadnagar, Mehsana (Mehsana, India)",
  "G M E R S Medical College, Valsad (Valsad, India)",
  "G S Medical College & Hospital, Hapur (Hapur, India)",
  "G S L Medical College, Rajahmundry (Rajahmundry, India)",
  "G S V M Medical College (Kanpur, India)",
  "Gadag Institute Of Medical Sciences, Mallasamudra (Gadag, India)",
  "Gajra Raja Medical College (Gwalior, India)",
  "Gandhi Medical College And Hospital, Secunderabad (Secunderabad, India)",
  "Gandhi Medical College, Bhopal (Bhopal, India)",
  "Gauhati Medical College (Guwahati, India)",
  "Gayatri Vidya Parishad Institute Of Health Care & Medical Technology (Visakhapatnam, India)",
  "Geetanjali Medical College And Hospital (Udaipur, India)",
  "Gian Sagar Medical College (Patiala, India)",
  "Gitam Institute Of Medical Sciences And Research (Visakhapatnam, India)",
  "Goa Medical College (Panaji, India)",
  "Gold Field Institute Of Medical Sciences & Research (Faridabad, India)",
  "Gouri Devi Institute Of Medical Sciences And Hospital (Rajbandh, India)",
  "Government Allopathic Medical College Banda (Banda, India)",
  "Government Dharmapuri Medical College (Dharmapuri, India)",
  "Government Doon Medical College (Dehradun, India)",
  "Government Erode Medical College And Hospital Perundurai (Perundurai, India)",
  "Government Institute Of Medical Sciences, Uttar Pradesh (Greater Noida, India)",
  "Government Kilpauk Medical College (Chennai, India)",
  "Government Medical College & Esic Hospital, Coimbatore (Coimbatore, India)",
  "Government Medical College & General Hospital Baramati (Baramati, India)",
  "Government Medical College & Super Facility Hospital Azamgarh (Azamgarh, India)",
  "Government Medical College Akola (Akola, India)",
  "Government Medical College Alibag (Raigad, India)",
  "Government Medical College Ambikapur (Ambikapur, India)",
  "Government Medical College Amritsar (Amritsar, India)",
  "Government Medical College Anantapuramu (Anantapur, India)",
  "Government Medical College Anantnag (Anantnag, India)",
  "Government Medical College And Hospital Cuddalore (Chidambaram, India)",
  "Government Medical College Ariyalur (Ariyalur, India)",
  "Government Medical College Aurangabad (Aurangabad, India)",
  "Government Medical College Badaun (Badaun, India)",
  "Government Medical College Baramulla (Baramulla, India)",
  "Government Medical College Barmer (Barmer, India)",
  "Government Medical College Bettiah (Bettiah, India)",
  "Government Medical College Bharatpur (Bharatpur, India)",
  "Government Medical College Bhavnagar (Bhavnagar, India)",
  "Government Medical College Chandigarh (Chandigarh, India)",
  "Government Medical College Chandrapur (Chandrapur, India)",
  "Government Medical College Chhindwara (Chhindwara, India)",
  "Government Medical College Datia (Datia, India)",
  "Government Medical College Doda (Doda, India)",
  "Government Medical College Dungarpur (Dungarpur, India)",
  "Government Medical College Ernakulam (Kochi, India)",
  "Government Medical College Gondia (Gondia, India)",
  "Government Medical College Haldwani (Nainital, India)",
  "Government Medical College Jalgaon (Jalgaon, India)",
  "Government Medical College Jammu (Jammu, India)",
  "Government Medical College Kallakurichi (Kallakurichi, India)",
  "Government Medical College Kanker (Raipur, India)",
  "Government Medical College Kannauj (Kannauj, India)",
  "Government Medical College Kannur (Kannur, India)",
  "Government Medical College Karur (Karur, India)",
  "Government Medical College Kathua (Kathua, India)",
  "Government Medical College Khandwa (Khandwa, India)",
  "Government Medical College Kota (Kota, India)",
  "Government Medical College Kottayam (Kottayam, India)",
  "Government Medical College Kozhikode (Kozhikode, India)",
  "Government Medical College Krishnagiri (Krishnagiri, India)",
  "Government Medical College Latur (Latur, India)",
  "Government Medical College Mahabubnagar (Mahbubnagar, India)",
  "Government Medical College Manjeri (Manjeri, India)",
  "Government Medical College Miraj (Miraj, India)",
  "Government Medical College Nagapattinam (Nagapattinam, India)",
  "Government Medical College Nagpur (Nagpur, India)",
  "Government Medical College Nalgonda (Nalgonda, India)",
  "Government Medical College Namakkal (Namakkal, India)",
  "Government Medical College Nandurbar (Nandurbar, India)",
  "Government Medical College Nizamabad (Nizamabad, India)",
  "Government Medical College Omandurar (Chennai, India)",
  "Government Medical College Palakkad (Palakkad, India)",
  "Government Medical College Pali (Pali, India)",
  "Government Medical College Parippally, Kollam (Kollam, India)",
  "Government Medical College Patiala (Patiala, India)",
  "Government Medical College Pudukottai (Pudukottai, India)",
  "Government Medical College Rajouri (Rajouri, India)",
  "Government Medical College Ramanathapuram (Ramanathapuram, India)",
  "Government Medical College Ratlam (Ratlam, India)",
  "Government Medical College Satara (Satara, India)",
  "Government Medical College Shahdol (Shahdol, India)",
  "Government Medical College Siddipet (Siddipet, India)",
  "Government Medical College Sindhudurg (Sindhudurg, India)",
  "Government Medical College Srinagar (Srinagar, India)",
  "Government Medical College Surat (Surat, India)",
  "Government Medical College Suryapet (Suryapet, India)",
  "Government Medical College The Nilgiris (Udhagamandalam, India)",
  "Government Medical College Thiruvallur (Thiruvallur, India)",
  "Government Medical College Thrissur (Thrissur, India)",
  "Government Medical College Tiruppur (Tiruppur, India)",
  "Government Medical College Vellore (Vellore, India)",
  "Government Medical College Vidisha (Vidisha, India)",
  "Government Medical College Virudhunagar (Virudhunagar, India)",
  "Government Medical College, Kadapa (Kadapa, India)",
  "Government Medical College, Srikakulam (Srikakulam, India)",
  "Government Sivagangai Medical College (Sivagangai, India)",
  "Government Thiruvannamalai Medical College (Thiruvannamalai, India)",
  "Government Thiruvarur Medical College And Hospital (Thiruvarur, India)",
  "Government Villupuram Medical College (Villupuram, India)",
  "Grant Government Medical College, Mumbai (Mumbai, India)",
  "Great Eastern Medical School And Hospital (Srikakulam, India)",
  "Gujarat Adani Institute Of Medical Sciences (Bhuj, India)",
  "Gulbarga Institute Of Medical Sciences (Kalaburagi, India)",
  "Guntur Medical College (Guntur, India)",
  "Guru Gobind Singh Medical College (Faridkot, India)",
  "H B T Medical College & Dr R N Cooper Municipal General Hospital (Mumbai, India)",
  "Hamdard Institute Of Medical Sciences & Research, New Delhi (New Delhi, India)",
  "Hassan Institute Of Medical Sciences (Hassan, India)",
  "Heritage Institute Of Medical Sciences, Varanasi (Varanasi, India)",
  "Hi-tech Medical College And Hospital, Bhubaneswar (Bhubaneswar, India)",
  "Hi-tech Medical College And Hospital, Rourkela (Rourkela, India)",
  "Himalayan Institute Of Medical Sciences (Dehradun, India)",
  "Hind Institute Of Medical Sciences, Lucknow (Lucknow, India)",
  "Hind Institute Of Medical Sciences, Sitapur (Mau, India)",
  "Icare Institute Of Medical Science And Research (Haldia, India)",
  "Index Medical College & Research Centre (Indore, India)",
  "Indian Institute Of Medical Science & Research (Jalna, India)",
  "Indira Gandhi Government Medical College, Nagpur (Nagpur, India)",
  "Indira Gandhi Institute Of Medical Sciences (Sheikhpura, India)",
  "Indira Gandhi Medical College And Research Institute (Pondicherry, India)",
  "Indira Gandhi Medical College, Shimla (Shimla, India)",
  "Indira Medical College And Hospitals Thiruvallur (Pandur, India)",
  "Institute Of Medical Science And Research, Vidyagiri (Satara, India)",
  "Institute Of Medical Sciences & Sum Hospital (Bhubaneswar, India)",
  "Institute Of Medical Sciences, Varanasi (Varanasi, India)",
  "Institute Of Postgraduate Medical Education & Research, Kolkata (Kolkata, India)",
  "Integral Institute Of Medical Sciences & Research (Lucknow, India)",
  "Iq City Medical College (Burdwan, India)",
  "J J M Medical College, Davangere (Davangere, India)",
  "J S S Medical College (Mysore, India)",
  "Jagadguru Gangadhar Mahaswamigalu Moorusavirmath Medical College (jgmmmc) (Hubballi, India)",
  "Jagannath Gupta Institute Of Medical Sciences & Hospital (Kolkata, India)",
  "Jaipur National University Institute For Medical Sciences And Research Centre (Jaipur, India)",
  "Jannayak Karpoori Thakur Medical College And Hospital Madhepura (Madhepura, India)",
  "Jawaharlal Institute Of Post-graduate Medical Education And Research (jipmer) (Pondicherry, India)",
  "Jawaharlal Nehru Institute Of Medical Sciences, Porompat (Porompat, India)",
  "Jawaharlal Nehru Medical College, Ajmer (Ajmer, India)",
  "Jawaharlal Nehru Medical College, Aligarh (Aligarh, India)",
  "Jawaharlal Nehru Medical College, Belgaum (Belgaum, India)",
  "Jawaharlal Nehru Medical College, Bhagalpur (Bhagalpur, India)",
  "Jawaharlal Nehru Medical College, Wardha (Wardha, India)",
  "Jehlum Valley College Of Medical Sciences (Srinagar, India)",
  "Jhalawar Medical College (Jhalawar, India)",
  "Jmf's A C P M Medical College (Dhule, India)",
  "Jorhat Medical College And Hospital (Jorhat, India)",
  "Jubilee Mission Medical College And Research Institute (Thrissur, India)",
  "K A P Viswanatham Government Medical College (Tiruchirappalli, India)",
  "K D Medical College Hospital & Research Centre (Mathura, India)",
  "K J Somaiya Medical College (Mumbai, India)",
  "K M C H Institute Of Health Sciences And Research (Coimbatore, India)",
  "K M C T Medical College (Kozhikode, India)",
  "K P C Medical College And Hospital (Kolkata, India)",
  "K S Hegde Medical Academy (Mangalore, India)",
  "K V G Medical College (Sullia, India)",
  "Kakatiya Medical College (Warangal, India)",
  "Kalinga Institute Of Medical Sciences (Bhubaneswar, India)",
  "Kalpana Chawla Government Medical College, Karnal (Karnal, India)",
  "Kamineni Academy Of Medical Sciences & Research Center (Hyderabad, India)",
  "Kamineni Institute Of Medical Sciences (Narketpally, India)",
  "Kanachur Institute Of Medical Sciences (Mangalore, India)",
  "Kannur Medical College (Kannur, India)",
  "Kanyakumari Government Medical College (Kanyakumari, India)",
  "Karnataka Institute Of Medical Sciences (Hubballi, India)",
  "Karpaga Vinayaga Institute Of Medical Sciences (Maduranthagam, India)",
  "Karpagam Faculty Of Medical Sciences And Research (Coimbatore, India)",
  "Karuna Medical College (Palakkad, India)",
  "Karwar Institute Of Medical Sciences (Karwar, India)",
  "Kasturba Medical College Mangalore (Mangalore, India)",
  "Kasturba Medical College Manipal (Manipal, India)",
  "Katihar Medical College (Katihar, India)",
  "Katuri Medical College, Guntur (Guntur, India)",
  "Kempegowda Institute Of Medical Sciences (Bangalore, India)",
  "Kesarsal Medical College And Research Institute (Ahmedabad, India)",
  "Khaja Bandanawaz University Faculty Of Medical Sciences (Gulbarga, India)",
  "King George's Medical University (Lucknow, India)",
  "Kodagu Institute Of Medical Sciences (Madikeri, India)",
  "Kolkata Medical College & Hospital (Kolkata, India)",
  "Konaseema Institute Of Medical Sciences & Research Foundation (Amalapuram, India)",
  "Koppal Institute Of Medical Sciences (Koppal, India)",
  "Krishna Mohan Medical College And Hospital (Mathura, India)",
  "Krishna Vishwa Vidyapeeth Faculty Of Medical Sciences (Karad, India)",
  "Kurnool Medical College (Kurnool, India)",
  "L N Medical College And Research Centre (Bhopal, India)",
  "Lady Hardinge Medical College (New Delhi, India)",
  "Lakhimpur Medical College (Chowkham, India)",
  "Lala Lajpat Rai Memorial Medical College (Meerut, India)",
  "Late Baliram Kashyap Memorial Government Medical College (Jagdalpur, India)",
  "Late Shri Lakhi Ram Agrawal Memorial Government Medical College (Raigarh, India)",
  "Laxmi Chandravansi Medical College And Hospital (Bishrampur, India)",
  "Lnct Medical College And Sewakunj Hospital (Indore, India)",
  "Lokmanya Tilak Municipal Medical College (Mumbai, India)",
  "Lord Buddha Koshi Medical College And Hospital (Saharsa, India)",
  "M E S Medical College (Kolathur, India)",
  "M N R Medical College (Sangareddy, India)",
  "M P Shah Government Medical College (Jamnagar, India)",
  "M S Ramaiah Medical College (Bangalore, India)",
  "M V J Medical College And Research Hospital (Hoskote, India)",
  "Madha Medical College And Research Institute (Chennai, India)",
  "Madhubani Medical College (Madhubani, India)",
  "Madras Medical College (Chennai, India)",
  "Madurai Medical College (Madurai, India)",
  "Mahadevappa Rampure Medical College (Gulbarga, India)",
  "Mahamaya Rajkiya Allopathic Medical College (Ambedkarnagar, India)",
  "Maharaja Agrasen Medical College (Agroha, India)",
  "Maharaja Krishna Chandra Gajapati Medical College (Berhampur, India)",
  "Maharaja Suhel Dev Autonomous State Medical College (Bahraich, India)",
  "Maharajah's Institute Of Medical Sciences, Vizianagaram (Vizianagaram, India)",
  "Maharani Laxmi Bai Medical College (Jhansi, India)",
  "Maharashtra Institute Of Medical Education & Research (mimer) (Pune, India)",
  "Maharashtra Institute Of Medical Sciences & Research (mimsr) (Latur, India)",
  "Maharishi Markandeshwar Institute Of Medical Sciences & Research (Ambala, India)",
  "Maharishi Markandeshwar Medical College & Hospital, Solan (Solan, India)",
  "Maharshi Devraha Baba Autonomous State Medical College Deoria (Deoria, India)",
  "Mahatma Gandhi Institute Of Medical Sciences, Wardha (Wardha, India)",
  "Mahatma Gandhi Medical College And Hospital, Jaipur (Jaipur, India)",
  "Mahatma Gandhi Medical College And Research Institute (Pondicherry, India)",
  "Mahatma Gandhi Memorial Medical College, Indore (Indore, India)",
  "Mahatma Gandhi Memorial Medical College, Jamshedpur (Jamshedpur, India)",
  "Mahatma Gandhi Mission's Medical College, Aurangabad (Aurangabad, India)",
  "Mahatma Gandhi Mission's Medical College, Navi Mumbai (Navi Mumbai, India)",
  "Mahaveer Institute Of Medical Sciences And Research Bhopal (Bhopal, India)",
  "Mahavir Institute Of Medical Sciences (Vikarabad, India)",
  "Maheshwara Medical College (Patancheru, India)",
  "Major S D Singh Medical College And Hospital (Fathehgarh, India)",
  "Malabar Medical College (Kozhikode, India)",
  "Malankara Orthodox Syrian Church Medical College (Kolenchery, India)",
  "Malda Medical College And Hospital (Malda, India)",
  "Malla Reddy Institute Of Medical Sciences (Hyderabad, India)",
  "Mallareddy Medical College For Women (Hyderabad, India)",
  "Mamata Academy Of Medical Sciences (Bachupally, India)",
  "Mamata Medical College (Khammam, India)",
  "Mandya Institute Of Medical Sciences (Mandya, India)",
  "Manipal Tata Medical College Baridih Jameshedpur (Jamshedpur, India)",
  "Mata Gujri Memorial Medical College (Purnea, India)",
  "Maulana Azad Medical College (New Delhi, India)",
  "Mayo Institute Of Medical Sciences (Barabanki, India)",
  "Medical College Baroda (Vadodara, India)",
  "Medical College Thiruvananthapuram (Thiruvananthapuram, India)",
  "Mediciti Institute Of Medical Sciences (Ghanpur, India)",
  "Meenakshi Medical College Hospital And Research Institute (Kancheepuram, India)",
  "Melmaruvathur Adhiparasakthi Institute Of Medical Sciences & Research (Kancheepuram, India)",
  "Midnapore Medical College (Midnapore, India)",
  "Mohan Kumaramangalam Medical College (Salem, India)",
  "Moti Lal Nehru Medical College, Allahabad (Allahabad, India)",
  "Mount Zion Medical College (Adoor, India)",
  "Murshidabad Medical College And Hospital (Murshidabad, India)",
  "Muzaffarnagar Medical College (Muzaffarnagar, India)",
  "Mysore Medical College And Research Institute (Mysore, India)",
  "N C Medical College & Hospital (Panipat, India)",
  "N K P Salve Institute Of Medical Sciences (Nagpur, India)",
  "N R I Institute Of Medical Sciences, Visakhapatnam (Visakhapatnam, India)",
  "N R I Medical College, Guntur (Guntur, India)",
  "Nalanda Medical College (Patna, India)",
  "Namo Medical Education And Research Institute (Silvassa, India)",
  "Naraina Medical College And Research Centre (Kanpur, India)",
  "Narayan Medical College And Hospital (Jamuhar, India)",
  "Narayana Medical College (Nellore, India)",
  "Narendra Modi Medical College Ahmedabad (Ahmedabad, India)",
  "National Capital Region Institute Of Medical Sciences, Meerut (Meerut, India)",
  "National Institute Of Medical Sciences And Research Medical College And Hospital (Jaipur, India)",
  "Navodaya Medical College (Raichur, India)",
  "Netaji Subhas Medical College And Hospital Amhara (Patna, India)",
  "Netaji Subhash Chandra Bose Medical College (Jabalpur, India)",
  "Nilratan Sircar Medical College (Kolkata, India)",
  "Nimra Institute Of Medical Sciences (Vijayawada, India)",
  "Noida International Institute Of Medical Sciences (Noida, India)",
  "Nootan Medical College And Research Centre (Visnagar, India)",
  "North Bengal Medical College (Darjeeling, India)",
  "North Delhi Municipal Corporation Medical College (New Delhi, India)",
  "North Eastern Indira Gandhi Regional Institute Of Health And Medical Sciences (Shillong, India)",
  "Osmania Medical College (Hyderabad, India)",
  "P E S Institute Of Medical Sciences And Research (Kuppam, India)",
  "P K Das Institute Of Medical Sciences (Palakkad, India)",
  "P S G Institute Of Medical Sciences And Research (Coimbatore, India)",
  "Pacific Institute Of Medical Sciences (Udaipur, India)",
  "Pacific Medical College & Hospital (Udaipur, India)",
  "Pandit Deendayal Upadhyay Medical College, Rajkot (Rajkot, India)",
  "Pandit Deendayal Upadhyaya Medical College Churu (Churu, India)",
  "Pandit Jawahar Lal Nehru Medical College And Hospital (Chamba, India)",
  "Pandit Raghunath Murmu Medical College And Hospital (Baripada, India)",
  "Panimalar Medical College Hospital And Research Institute Chennai (Chennai, India)",
  "Parul Institute Of Medical Sciences & Research (Waghodia, India)",
  "Patna Medical College (Patna, India)",
  "People's College Of Medical Sciences & Research Centre (Bhopal, India)",
  "Pondicherry Institute Of Medical Sciences And Research (Pondicherry, India)",
  "Prakash Institute Of Medical Sciences & Research, Urun-islampur (Islampur, India)",
  "Pramukhswami Medical College (Karamsad, India)",
  "Prasad Institute Of Medical Sciences (Lucknow, India)",
  "Prathima Institute Of Medical Sciences (Karimnagar, India)",
  "Pt B D S Postgraduate Institute Of Medical Sciences (Rohtak, India)",
  "Pt Jawahar Lal Nehru Memorial Medical College Raipur (Raipur, India)",
  "Punjab Institute Of Medical Sciences (Jalandhar, India)",
  "Purulia Government Medical College And Hospital (Purulia, India)",
  "Pushpagiri Institute Of Medical Sciences And Research Centre (Thiruvalla, India)",
  "R G Kar Medical College (Kolkata, India)",
  "R K D F Medical College Hospital & Research Centre, Jatkhedi (Bhopal, India)",
  "R U H S College Of Medical Sciences (Jaipur, India)",
  "R V M Institute Of Medical Sciences And Research Center (Laxmakkapally, India)",
  "Radha Devi Jageshwari Memorial Medical College And Hospital (Muzaffarpur, India)",
  "Raichur Institute Of Medical Sciences (Raichur, India)",
  "Raiganj Government Medical College & Hospital (Raiganj, India)",
  "Raipur Institute Of Medical Sciences (Raipur, India)",
  "Rajarajeswari Medical College & Hospital (Bangalore, India)",
  "Rajarshi Chhatrapati Shahu Maharaj Government Medical College Kolhapur (Kolhapur, India)",
  "Rajendra Institute Of Medical Sciences, Ranchi (Ranchi, India)",
  "Rajiv Gandhi Institute Of Medical Sciences, Adilabad (Adilabad, India)",
  "Rajiv Gandhi Institute Of Medical Sciences, Ongole (Ongole, India)",
  "Rajiv Gandhi Medical College And Chhatrapati Shivaji Maharaj Hospital (Thane, India)",
  "Rajkiya Medical College, Jalaun (Orai, India)",
  "Rajmata Vijaya Raje Scindia Medical College (Bhilwara, India)",
  "Rajshree Medical Research Institute (Bareilly, India)",
  "Rama Medical College And Hospital, Hapur (Gaziabad, India)",
  "Rama Medical College And Hospital, Kanpur (Kanpur, India)",
  "Rampurhat Government Medical College & Hospital (Rampurhat, India)",
  "Rangaraya Medical College (Kakinada, India)",
  "Ravindra Nath Tagore Medical College (Udaipur, India)",
  "Regional Institute Of Medical Sciences (Imphal, India)",
  "Rohilkhand Medical College & Hospital (Bareilly, India)",
  "Rural Medical College Of Pravara Medical Trust (Loni, India)",
  "Ruxmaniben Deepchand Gardi Medical College (Ujjain, India)",
  "S Nijalingappa Medical College And H S K Hospital & Research Centre (Bagalkot, India)",
  "S D M Medical College (Dharwad, India)",
  "S G T Medical College, Hospital And Research Institute (Gurgaon, India)",
  "S M B T Institute Of Medical Sciences & Research Centre (Igatpuri, India)",
  "S R M Medical College Hospital And Research Centre (Kancheepuram, India)",
  "S S Institute Of Medical Sciences & Research Centre, Davangere (Davangere, India)",
  "S V S Medical College (Mahbubnagar, India)",
  "Saheed Laxman Nayak Medical College And Hospital (Koraput, India)",
  "Santhiram Medical College (Kurnool, India)",
  "Santiniketan Medical College Bolpur (Bolpur, India)",
  "Santosh Medical College (Ghaziabad, India)",
  "Sapthagiri Institute Of Medical Sciences & Research Centre (Bangalore, India)",
  "Saraswathi Institute Of Medical Sciences (Hapur, India)",
  "Saraswati Medical College (Unnao, India)",
  "Sardar Patel Medical College (Bikaner, India)",
  "Sarojini Naidu Medical College (Agra, India)",
  "Saveetha Medical College And Hospital (Chennai, India)",
  "Sawai Man Singh Medical College (Jaipur, India)",
  "School Of Medical Sciences And Research, Greater Noida (Greater Noida, India)",
  "Seth G S Medical College (Mumbai, India)",
  "Shadan Institute Of Medical Sciences Teaching Hospital & Research Centre (Hyderabad, India)",
  "Shahed Nirmal Mahto Medical College And Hospital (Dhanbad, India)",
  "Shaheed Hasan Khan Mewati Government Medical College (Nalhar, India)",
  "Shaikh-ul-hind Maulana Mahmood Hasan Medical College (Saharanpur, India)",
  "Shantabaa Medical College And General Hospital (Amreli, India)",
  "Sher-i-kashmir Institute Of Medical Sciences (skims) (Srinagar, India)",
  "Shija Academy Of Health Sciences (Imphal, India)",
  "Shimoga Institute Of Medical Sciences (Shimoga, India)",
  "Shree Narayan Medical Institute And Hospital (Saharsa, India)",
  "Shri Atal Bihari Vajpayee Medical College And Research Institution (Bangalore, India)",
  "Shri B M Patil Medical College Hospital & Research Centre, B L D E University (Bijapur, India)",
  "Shri Balaji Institute Of Medical Science (Raipur, India)",
  "Shri Bhausaheb Hire Government Medical College (Dhule, India)",
  "Shri Guru Ram Rai Institute Of Medical & Health Sciences (Dehradun, India)",
  "Shri Kalyan Government Medical College Sikar (Sikar, India)",
  "Shri Lal Bahadur Shastri Government Medical College (Mandi, India)",
  "Shri Ram Murti Smarak Institute Of Medical Sciences (Bareilly, India)",
  "Shri Ramkrishna Institute Of Medical Sciences & Sanaka Hospitals (Durgapur, India)",
  "Shri Sathya Sai Medical College And Research Institute (Kancheepuram, India)",
  "Shri Shankaracharya Institute Of Medical Sciences (Bhilai, India)",
  "Shri Vasantrao Naik Government Medical College (Yavatmal, India)",
  "Shridevi Institute Of Medical Sciences & Research Hospital (Tumkur, India)",
  "Shrimant Rajmata Vijayaraje Scindia Medical College And Hospital (Shivpuri, India)",
  "Shyam Shah Medical College (Rewa, India)",
  "Siddhartha Medical College (Vijayawada, India)",
  "Sikkim Manipal Institute Of Medical Sciences, Sikkim Manipal University (Gangtok, India)",
  "Silchar Medical College (Silchar, India)",
  "Sindhudurg Shikshan Prasarak Mandal Medical College And Lifetime Hospital Padave (Ranbambuli, India)",
  "Smt B K Shah Medical Institute And Research Centre (Vadodara, India)",
  "Smt Kashibai Navale Medical College And Hospital (Pune, India)",
  "Smt N H L Municipal Medical College (Ahmedabad, India)",
  "Soban Singh Jeena Government Institute Of Medical Science And Research Almora (Almora, India)",
  "Sree Balaji Medical College And Hospital (Chennai, India)",
  "Sree Gokulam Medical College & Research Foundation (Thiruvananthapuram, India)",
  "Sree Mookambika Institute Of Medical Sciences (Kanyakumari, India)",
  "Sree Narayana Institute Of Medical Sciences (Chalakka, India)",
  "Sree Uthradom Thirunal Academy Of Medical Sciences (Thiruvananthapuram, India)",
  "Sri Aurobindo Institute Of Medical Sciences (Indore, India)",
  "Sri Devaraj Urs Medical College (Kolar, India)",
  "Sri Guru Ram Das Institute Of Medical Sciences & Research (Amritsar, India)",
  "Sri Jagannath Medical College And Hospital Puri (Puri, India)",
  "Sri Krishna Medical College (Muzaffarpur, India)",
  "Sri Lakshmi Narayana Institute Of Medical Sciences (Pondicherry, India)",
  "Sri Lalithambigai Medical College And Hospital (Chennai, India)",
  "Sri Manakula Vinayagar Medical College & Hospital (Pondicherry, India)",
  "Sri Muthukumaran Medical College Hospital And Research Institute (Chennai, India)",
  "Sri Padmavathi Medical College For Women (Tirupati, India)",
  "Sri Ramachandra Institute Of Higher Education And Research (deemed University) (Chennai, India)",
  "Sri Siddhartha Institute Of Medical Sciences & Research Centre (Bangalore, India)",
  "Sri Siddhartha Medical College (Tumkur, India)",
  "Sri Venkateshwaraa Medical College Hospital & Research Centre (Pondicherry, India)",
  "Sri Venkateswara Medical College (Tirupati, India)",
  "Srinivas Institute Of Medical Sciences And Research Centre (Mangalore, India)",
  "Srinivasan Medical College And Hospital (Tiruchirappalli, India)",
  "Sriram Chandra Bhanja Medical College (Cuttack, India)",
  "St John's Medical College (Bangalore, India)",
  "St Peters Medical College Hospital And Research Institute (Krishnagiri, India)",
  "Stanley Medical College (Chennai, India)",
  "State Medical College Shahjahanpur (Shahjahanpur, India)",
  "Subbaiah Institute Of Medical Sciences (Shimoga, India)",
  "Subharti Medical College (Meerut, India)",
  "Sukh Sagar Medical College And Hospital Jabalpur (Jabalpur, India)",
  "Surabhi Institute Of Medical Sciences (Siddipet, India)",
  "Surat Municipal Institute Of Medical Education And Research (Surat, India)",
  "Swami Ramanand Teerth Rural Medical College (Ambajogai, India)",
  "Swami Vivekanand Institute Of Medical Sciences And Research (Valia, India)",
  "Swamy Vivekanandha Medical College Hospital And Research Institute (Tiruchengode, India)",
  "Symbiosis Medical College For Women Pune (Pune, India)",
  "T D Medical College (Alappuzha, India)",
  "T S Misra Medical College & Hospital (Lucknow, India)",
  "Tagore Medical College And Hospital (Chennai, India)",
  "Teerthankar Mahaveer Medical College (Moradabad, India)",
  "Terna Medical College (Navi Mumbai, India)",
  "Tezpur Medical College & Hospital (Tezpur, India)",
  "Thanjavur Medical College (Thanjavur, India)",
  "The Oxford Medical College, Hospital & Research Centre (Bangalore, India)",
  "Theni Government Medical College (Theni, India)",
  "Thoothukudi Medical College (Thoothukudi (tuticorin), India)",
  "Tirunelveli Medical College (Tirunelveli, India)",
  "Tomo Riba Institute Of Health & Medical Sciences (Naharlagun, India)",
  "Topiwala National Medical College (Mumbai, India)",
  "Travancore Medical College (Kollam, India)",
  "Trichy Srm Medical College Hospital And Research Centre (Tiruchirappalli, India)",
  "Tripura Medical College & Dr B R Ambedkar Memorial Teaching Hospital (Agartala, India)",
  "Trr Institute Of Medical Sciences Patancheru (Sangareddy, India)",
  "Uma Nath Singh Autonomous State Medical College Jaunpur (Jaunpur, India)",
  "United Institute Of Medical Sciences Allahabad (Prayagraj, India)",
  "University College Of Medical Sciences And Guru Teg Bahadur Hospital (New Delhi, India)",
  "Uttar Pradesh University Of Medical Sciences (Etawah, India)",
  "Vardhman Mahavir Medical College & Safdarjung Hospital (New Delhi, India)",
  "Varun Arjun Medical College & Rohilkhand Hospital (Banthra, India)",
  "Vedantaa Institute Of Medical Sciences (Dahanu, India)",
  "Veer Chandra Singh Garhwali Government Institute Of Medical Science & Research (Srinagar, India)",
  "Veer Surendra Sai Institute Of Medical Sciences And Research (Burla, India)",
  "Velammal Medical College Hospital And Research Institute (Madurai, India)",
  "Vels Medical College And Hospital (Tiruvallur, India)",
  "Venkateshwara Institute Of Medical Sciences (Gajraula, India)",
  "Vijayanagar Institute Of Medical Sciences (Bellary, India)",
  "Vinayaka Mission's Kirupananda Variyar Medical College, Salem (Salem, India)",
  "Vinayaka Mission's Medical College, Karaikal (Karaikal, India)",
  "Viswabharathi Medical College (Kurnool, India)",
  "Vydehi Institute Of Medical Sciences & Research Centre, Bangalore (Bangalore, India)",
  "World College Of Medical Sciences & Research (Jhajjar, India)",
  "Yenepoya Medical College (Mangalore, India)",
  "Zoram Medical College (Falkawn, India)",
  "Zydus Medical College And Hospital (Dahod, India)",
  "Abbottabad International Medical College (Abbottabad, Pakistan)",
  "Abu Umara Medical And Dental College (Lahore, Pakistan)",
  "Abwa Medical College (Faisalabad, Pakistan)",
  "Aga Khan University Medical College (Karachi, Pakistan)",
  "Akhtar Saeed Medical & Dental College (Lahore, Pakistan)",
  "Akhtar Saeed Medical College Rawalpindi (Rawalpindi, Pakistan)",
  "Al-aleem Medical College (Lahore, Pakistan)",
  "Al-nafees Medical College (Islamabad, Pakistan)",
  "Al-tibri Medical College (Karachi, Pakistan)",
  "Allama Iqbal Medical College (Lahore, Pakistan)",
  "Ameer-ud-din Medical College (Lahore, Pakistan)",
  "Aminuddin Medical College (Quetta, Pakistan)",
  "Amna Inayat Medical College (Sheikhupura, Pakistan)",
  "Army Medical College (Rawalpindi, Pakistan)",
  "Avicenna Medical College (Lahore, Pakistan)",
  "Ayub Medical College (Abbottabad, Pakistan)",
  "Azad Jammu & Kashmir Medical College (Muzaffarabad, Pakistan)",
  "Aziz Fatimah Medical & Dental College (Faisalabad, Pakistan)",
  "Azra Naheed Medical College (Lahore, Pakistan)",
  "Bacha Khan Medical College (Mardan, Pakistan)",
  "Bahria University Medical & Dental College (Karachi, Pakistan)",
  "Bakhtawar Amin Medical And Dental College (Multan, Pakistan)",
  "Bannu Medical College (Bannu, Pakistan)",
  "Baqai Medical College (Karachi, Pakistan)",
  "Bilawal Medical College (Jamshoro, Pakistan)",
  "Bolan Medical College (Quetta, Pakistan)",
  "C M H Institute Of Medical Sciences, Bahawalpur (Bahawalpur, Pakistan)",
  "C M H Kharian Medical College (Kharian, Pakistan)",
  "C M H Lahore Medical College And Institute Of Dentistry (Lahore, Pakistan)",
  "C M H Multan Institute Of Medical Sciences (Multan, Pakistan)",
  "C M S Medical College (Hyderabad, Pakistan)",
  "Central Park Medical College (Lahore, Pakistan)",
  "Chandka Medical College (Larkana, Pakistan)",
  "Continental Medical College (Lahore, Pakistan)",
  "D G Khan Medical College (Dera Ghazi Khan, Pakistan)",
  "Dow International Medical College (dimc) (Karachi, Pakistan)",
  "Dow Medical College (Karachi, Pakistan)",
  "F M H College Of Medicine And Dentistry (Lahore, Pakistan)",
  "Faisalabad Medical University (Faisalabad, Pakistan)",
  "Fatima Jinnah Medical University (Lahore, Pakistan)",
  "Fazaia Medical College (Islamabad, Pakistan)",
  "Fazaia Ruth Pfau Medical College (Karachi, Pakistan)",
  "Federal Medical And Dental College (Islamabad, Pakistan)",
  "Foundation University Medical College (fumc) (Islamabad, Pakistan)",
  "Frontier Medical & Dental College, Abbottabad (Abbottabad, Pakistan)",
  "Gajju Khan Medical College (Swabi, Pakistan)",
  "Gambat Medical College Of Pir Abdul Qadir Shah Jeelani Institute Of Medical Sciences (paqsjims) (Khairpur Mirs, Pakistan)",
  "Ghulam Muhammad Mahar Medical College (Sukkur, Pakistan)",
  "Gomal Medical College (Dera Ismail Khan, Pakistan)",
  "Gujranwala Medical College (Gujranwala, Pakistan)",
  "H B S Medical & Dental College (Islamabad, Pakistan)",
  "Hamdard College Of Medicine & Dentistry (Karachi, Pakistan)",
  "Hashmat Medical & Dental College (Gujrat, Pakistan)",
  "Hitec Institute Of Medical Sciences (Taxila, Pakistan)",
  "Independent Medical College (Faisalabad, Pakistan)",
  "Indus Medical College (Tando Muhammad Khan, Pakistan)",
  "Islam Medical College (Sialkot, Pakistan)",
  "Islamabad Medical & Dental College (Islamabad, Pakistan)",
  "Islamic International Medical College (Rawalpindi, Pakistan)",
  "Isra University, Hyderabad Faculty Of Medicine And Allied Medical Sciences (Hyderabad, Pakistan)",
  "Jhalawan Medical College (Khuzdar, Pakistan)",
  "Jinnah Medical And Dental College (Karachi, Pakistan)",
  "Jinnah Medical College (Peshawar, Pakistan)",
  "Jinnah Sindh Medical University (Karachi, Pakistan)",
  "Kabir Medical College (Peshawar, Pakistan)",
  "Karachi Institute Of Medical Sciences (kims) (Karachi, Pakistan)",
  "Karachi Medical And Dental College (Karachi, Pakistan)",
  "Khairpur Medical College (Khairpur Mir’s, Pakistan)",
  "Khawaja Muhammad Safdar Medical College (Sialkot, Pakistan)",
  "Khyber Girls Medical College (Peshawar, Pakistan)",
  "Khyber Medical College (Peshawar, Pakistan)",
  "King Edward Medical University (Lahore, Pakistan)",
  "Kmu Institute Of Medical Sciences (Kohat, Pakistan)",
  "Lahore Medical And Dental College (Lahore, Pakistan)",
  "Liaquat College Of Medicine And Dentistry (Karachi, Pakistan)",
  "Liaquat National Hospital And Medical College (Karachi, Pakistan)",
  "Liaquat University Of Medical & Health Sciences Jamshoro (Jamshoro, Pakistan)",
  "Loralai Medical College (Loralai, Pakistan)",
  "M Islam Medical And Dental College (Gujranwala, Pakistan)",
  "Mekran Medical College (Turbat, Pakistan)",
  "Mohiuddin Islamic Medical College (Mirpur, Pakistan)",
  "Mohtarma Benazir Bhutto Shaheed Medical College (Mirpur, Pakistan)",
  "Muhammad College Of Medicine (Peshawar, Pakistan)",
  "Muhammad Medical College (Mirpurkhas, Pakistan)",
  "Multan Medical And Dental College (Multan, Pakistan)",
  "Nawaz Sharif Medical College (Gujrat, Pakistan)",
  "Niazi Medical And Dental College (Sargodha, Pakistan)",
  "Nishtar Medical University (Multan, Pakistan)",
  "Northwest School Of Medicine (Peshawar, Pakistan)",
  "Nowshera Medical College (Nowshera, Pakistan)",
  "Pak International Medical College (Peshawar, Pakistan)",
  "Pak Red Crescent Medical & Dental College (Kasur, Pakistan)",
  "People's University Of Medical & Health Sciences For Women (Nawabshah, Pakistan)",
  "Peshawar Medical College (Peshawar, Pakistan)",
  "Poonch Medical College (Rawalakot, Pakistan)",
  "Quaid-e-azam Medical College (Bahawalpur, Pakistan)",
  "Quetta Institute Of Medical Sciences (Quetta, Pakistan)",
  "Rahbar Medical And Dental College (Lahore, Pakistan)",
  "Rai Medical College (Sargodha, Pakistan)",
  "Rashid Latif Medical College (Lahore, Pakistan)",
  "Rawal Institute Of Health Sciences (Islamabad, Pakistan)",
  "Rawalpindi Medical University (Rawalpindi, Pakistan)",
  "Rehman Medical College (Peshawar, Pakistan)",
  "Sahara Medical College (Narowal, Pakistan)",
  "Sahiwal Medical College (Sahiwal, Pakistan)",
  "Saidu Medical College (Swat, Pakistan)",
  "Sargodha Medical College (Sargodha, Pakistan)",
  "Services Institute Of Medical Sciences (Lahore, Pakistan)",
  "Shaheed Mohtarma Benazir Bhutto Medical College, Lyari (Karachi, Pakistan)",
  "Shahida Islam Medical College (Lodhran, Pakistan)",
  "Shaikh Khalifa Bin Zayed Al-nahyan Medical And Dental College (Lahore, Pakistan)",
  "Shalamar Medical And Dental College (Lahore, Pakistan)",
  "Sharif Medical And Dental College (Lahore, Pakistan)",
  "Sheikh Zayed Medical College And Hospital (Rahim Yar Khan, Pakistan)",
  "Shifa College Of Medicine (Islamabad, Pakistan)",
  "Sialkot Medical College (Sialkot, Pakistan)",
  "Sir Syed College Of Medical Sciences For Girls (Karachi, Pakistan)",
  "Suleman Roshan Medical College (Tando Adam, Pakistan)",
  "Swat Medical College (Swat, Pakistan)",
  "United Medical & Dental College (Karachi, Pakistan)",
  "University College Of Medicine & Dentistry Lahore (Lahore, Pakistan)",
  "University Medical & Dental College Faisalabad (Faisalabad, Pakistan)",
  "Wah Medical College (Wah Cantt, Pakistan)",
  "Watim Medical And Dental College (Rawalpindi, Pakistan)",
  "Women Medical & Dental College (Abbottabad, Pakistan)",
  "Yusra Medical & Dental College (Islamabad, Pakistan)",
  "Ziauddin Medical College (Karachi, Pakistan)",
  "Academy Of Military Medical Sciences Faculty Of Medicine (Beijing, China)",
  "Air Force Medical University (Xi'an, China)",
  "Anhui Medical University (Hefei, China)",
  "Anhui University Of Chinese Medicine (Hefei, China)",
  "Anhui University Of Science And Technology College Of Medicine (Huainan, China)",
  "Army Medical University (Chongqing, China)",
  "Baotou Medical College, Inner Mongolia University Of Science And Technology (Baotou, China)",
  "Beihua University School Of Medicine (Jilin, China)",
  "Beijing College Of Acupuncture And Orthopedics (Beijing, China)",
  "Beijing Medical College Branch Campus (Beijing, China)",
  "Beijing School Of Medicine (Beijing, China)",
  "Beijing Second Medical College, First Branch (Beijing, China)",
  "Bengbu Medical College (Bengbu, China)",
  "Binzhou Medical University (Binzhou, China)",
  "Capital Medical University (Beijing, China)",
  "Changchun University Of Chinese Medicine (Changchun, China)",
  "Changsha Medical University (Changsha, China)",
  "Changzhi Medical College (Changzhi, China)",
  "Chengde Medical University (Chengde, China)",
  "Chengdu Institute Of Physical Education (Chengdu, China)",
  "Chengdu Medical College (Chengdu, China)",
  "Chengdu University Of Traditional Chinese Medicine (Chengdu, China)",
  "Chengdu University School Of Medicine And Nursing (Chengdu, China)",
  "Chifeng University Medical College (Chifeng, China)",
  "China Academy Of Chinese Medical Sciences (Beijing, China)",
  "China Medical University (Shenyang, China)",
  "Chongqing Medical University (Chongqing, China)",
  "College Of Basic Medical Sciences Of Jilin University (Changchun, China)",
  "Dali University School Of Medicine (Dali, China)",
  "Dalian Medical University (Dalian, China)",
  "First Medical College Of The Navy (Qingdao, China)",
  "Fujian Medical University (Fuzhou, China)",
  "Fujian University Of Traditional Chinese Medicine (Fuzhou, China)",
  "Gannan Medical University (Ganzhou, China)",
  "Gansu University Of Chinese Medicine (Lanzhou, China)",
  "Guangdong Medical University (Zhanjiang, China)",
  "Guangdong Pharmaceutical University (Guangzhou, China)",
  "Guangdong Provincial Cardiovascular Institute (Guangzhou, China)",
  "Guangxi Medical University (Nanning, China)",
  "Guangxi University Of Chinese Medicine (Nanning, China)",
  "Guangzhou Medical University (Guangzhou, China)",
  "Guangzhou University Of Chinese Medicine (Guangzhou, China)",
  "Guilin Medical University (Guilin, China)",
  "Guizhou Medical University (Guiyang, China)",
  "Hainan Medical University (Haikou, China)",
  "Hangzhou Medical College (Hangzhou, China)",
  "Hangzhou School Of Medicine, Hangzhou Normal University (Hangzhou, China)",
  "Harbin Medical University (Harbin, China)",
  "He University School Of Clinical Medicine (Shenyang, China)",
  "Hebei College Of Traditional Chinese Medicine (Shijiazhuang, China)",
  "Hebei Medical University (Shijiazhuang, China)",
  "Hebei North University Faculty Of Medicine (Zhangjiakou, China)",
  "Hebei University Medical College (Baoding, China)",
  "Hebei University Of Engineering School Of Medicine (Handan, China)",
  "Henan Medical University (Zhengzhou, China)",
  "Henan University Of Science And Technology School Of Medicine (Luoyang, China)",
  "Henan University Of Traditional Chinese Medicine (Zhengzhou, China)",
  "Henan University School Of Medicine (Kaifeng, China)",
  "Hengyang Medical College (Hengyang, China)",
  "Heze Medical College (Heze, China)",
  "Huanghe Science And Technology College (Zhengzhou, China)",
  "Hubei Medical University (Wuhan, China)",
  "Hubei Minzu University Medical School (Enshi, China)",
  "Hubei Polytechnic University School Of Medicine (Huangshi, China)",
  "Hubei University Of Arts And Science Medical College (Xiangyang, China)",
  "Hubei University Of Chinese Medicine First Clinical College (Wuhan, China)",
  "Hubei University Of Medicine (Shiyan, China)",
  "Hubei University Of Science And Technology Faculty Of Medicine (Xianning, China)",
  "Hunan Medical University (Changsha, China)",
  "Hunan Normal University College Of Medicine (Changsha, China)",
  "Hunan University Of Chinese Medicine (Changsha, China)",
  "Huzhou University School Of Medicine And Nursing (Huzhou, China)",
  "Inner Mongolia Medical University (Hohhot, China)",
  "Inner Mongolia University For The Nationalities (Tong-liao, China)",
  "Jiamusi University School Of Medicine (Jiamusi, China)",
  "Jianghan University School Of Medicine (Wuhan, China)",
  "Jiangnan University Wuxi Medical College (Wuxi, China)",
  "Jiangsu University School Of Medicine (Zhenjiang, China)",
  "Jiangxi Medical College (Nanchang, China)",
  "Jiangxi Medical College Of Nanchang University (Nanchang, China)",
  "Jiaxing University College Of Medicine (Jiaxing, China)",
  "Jiayang University Medical College (Meizhou, China)",
  "Jilin Medical University (Jilin, China)",
  "Jinan University School Of Medicine (Guangzhou, China)",
  "Jinggangshan University Medical School (Ji'an, China)",
  "Jining Medical University (Jining, China)",
  "Jinzhou Medical University (Jinzhou, China)",
  "Jishou University School Of Medicine (Jishou, China)",
  "Jiujiang University Medical College (Jiujiang, China)",
  "Jixi Medical School For The Coal Industry (Jixi, China)",
  "Kunming Medical University (Kunming, China)",
  "Kunming University Of Science And Technology Medical School (Kunming, China)",
  "Lanzhou University Faculty Of Medicine (Lanzhou, China)",
  "Luoyang School Of Medicine (Luoyang, China)",
  "Medical College Of China Three Gorges University (Yichang, China)",
  "Medical College Of Dalian University (Dalian, China)",
  "Medical College Of Nanjing University (Nanjing, China)",
  "Medical College Of Wuhan University Of Science And Technology (Wuhan, China)",
  "Medical College Of Yan'an University (Yan'an, China)",
  "Mudanjiang Medical University (Mudanjiang, China)",
  "Nanjing Medical University (Nanjing, China)",
  "Nanjing University Of Chinese Medicine (Nanjing, China)",
  "Nankai University School Of Medicine (Tianjin, China)",
  "Nantong Medical College (Nantong, China)",
  "Nantong University Medical School (Nantong, China)",
  "Nantong University Xinglin College Faculty Of Medicine (Nantong, China)",
  "Naval Medical University (Shanghai, China)",
  "Navy School Of Medicine (Nanjing, China)",
  "Ningbo University Medical School (Ningbo, China)",
  "Ningxia Medical University (Yinchuan, China)",
  "Norman Bethune University Of Medical Sciences (Changchun, China)",
  "North China University Of Science And Technology College Of Medicine (Tangshan, China)",
  "North Sichuan Medical College (Nanchong, China)",
  "Northwest Minzu University (Lanzhou, China)",
  "Panzhihua University School Of Medical Science (Panzhihua, China)",
  "Peking Union Medical College (Beijing, China)",
  "Peking University Health Science Center (Beijing, China)",
  "Putian University School Of Medicine (Putian, China)",
  "Qilu Medical University (Zibo, China)",
  "Qingdao University Qingdao Medical College (Qingdao, China)",
  "Qinghai University Medical College (Xining, China)",
  "Qiqihar Medical University (Qiqihar, China)",
  "Sanquan Medical College (Xinxiang, China)",
  "School Of Life Sciences, Fudan University (Shanghai, China)",
  "School Of Medicine, General Logistics Department (Beijing, China)",
  "School Of Traditional Chinese Medicine Of Capital Medical University (Beijing, China)",
  "Shaanxi University Of Chinese Medicine (Xianyang, China)",
  "Shandong First Medical University (Tai'an, China)",
  "Shandong Medical College (Linyi, China)",
  "Shandong University Cheeloo College Of Medicine (Jinan, China)",
  "Shandong University Of Traditional Chinese Medicine (Jinan, China)",
  "Shanghai Jiao Tong University School Of Medicine (Shanghai, China)",
  "Shanghai Medical College, Fudan University (Shanghai, China)",
  "Shanghai Medical University (Shanghai, China)",
  "Shanghai Railway Medical University (Shanghai, China)",
  "Shanghai School Of Medicine (Shanghai, China)",
  "Shantou University Medical College (Shantou, China)",
  "Shanxi Datong University School Of Medicine (Datong, China)",
  "Shanxi Medical University (Taiyuan, China)",
  "Shaoxing University Medical School (Shaoxing, China)",
  "Shenyang Medical College (Shenyang, China)",
  "Shenzhen University College Of Medicine (Shenzhen, China)",
  "Shihezi University School Of Medicine (Shihezi, China)",
  "Soochow University Medical College (Suzhou, China)",
  "Southeast University Medical College (Nanjing, China)",
  "Southern Medical University (Guangzhou, China)",
  "Southwest Medical University (Luzhou, China)",
  "Suzhou Medical College (Suzhou, China)",
  "Taizhou University Medical School (Taizhou, China)",
  "Tianjin Medical University Clinical Medical College (Tianjin, China)",
  "Tianjin Medical University School Of Basic Medical Sciences (Tianjin, China)",
  "Tianjin Second Medical College (Tianjin, China)",
  "Tibet University Medical College (Lhasa, China)",
  "Tongji Medical College Of Huazhong University Of Science & Technology (Wuhan, China)",
  "Tongji University School Of Medicine (Shanghai, China)",
  "Tsinghua University School Of Medicine (Beijing, China)",
  "University Of South China Faculty Of Medicine (Hengyang, China)",
  "Wannan Medical College (Wuhu, China)",
  "Weifang Medical University (Weifang, China)",
  "Wenzhou Medical University (Wenzhou, China)",
  "West China School Of Medicine, Sichuan University (Chengdu, China)",
  "West China University Of Medical Sciences (Chengdu, China)",
  "Wuhan University School Of Medicine (Wuhan, China)",
  "Xi'an Jiaotong University College Of Medicine (Xi'an, China)",
  "Xi'an Medical University (Xi'an, China)",
  "Xiamen University School Of Medicine (Xiamen, China)",
  "Xiangnan University School Of Medicine (Chenzhou, China)",
  "Xiangya School Of Medicine, Central South University (Changsha, China)",
  "Xinjiang College Of Traditional Chinese Medicine (Urumqi, China)",
  "Xinjiang Medical University (Urumqi, China)",
  "Xinxiang Medical University (Xinxiang, China)",
  "Xuzhou Medical University (Xuzhou, China)",
  "Yanbian University Health Science Center (Yanji, China)",
  "Yangtze University Medical School (Jingzhou, China)",
  "Yangzhou University College Of Medicine (Yangzhou, China)",
  "Yichun University School Of Medicine (Yichun, China)",
  "Yishui Medical School (Yishui, China)",
  "Youjiang Medical University For Nationalities (Baise, China)",
  "Yuzhou University School Of Medicine (Chongqing, China)",
  "Zhang Zhongjing School Of Traditional Chinese Medicine (Nanyang, China)",
  "Zhangjiakou Medical College (Zhangjiakou, China)",
  "Zhejiang Chinese Medical University (Hangzhou, China)",
  "Zhejiang Medical University (Hangzhou, China)",
  "Zhejiang University School Of Medicine (Hangzhou, China)",
  "Zhengzhou University Medical School (Zhengzhou, China)",
  "Zhenjiang Medical College (Zhenjiang, China)",
  "Zhongshan School Of Medicine, Sun Yat-sen University (Guangzhou, China)",
  "Zunyi Medical University (Zunyi, China)",
  "Chinese University Of Hong Kong Faculty Of Medicine (Shatin, Hong Kong, S A R China)",
  "Li Ka Shing Faculty Of Medicine, University Of Hong Kong (Pokfulam, Hong Kong, S A R China)",
  "Macau University Of Science And Technology Faculty Of Medicine (Taipa, Macau, S A R China)",
  "Almazov National Medical Research Centre (St Petersburg, Russian Federation)",
  "Altai State Medical University (Barnaul, Russian Federation)",
  "Amur State Medical Academy (Blagoveshchensk, Russian Federation)",
  "Astrakhan State Medical University (Astrakhan, Russian Federation)",
  "Bashkir State Medical University (Ufa, Russian Federation)",
  "Belgorod National Research University Medical Institute (Belgorod, Russian Federation)",
  "Buryat State University Institute Of Medicine (Ulan-ude, Russian Federation)",
  "Chita State Medical Academy (Chita, Russian Federation)",
  "Chuvash State University Medical Institute (Cheboksary, Russian Federation)",
  "Dagestan State Medical University (Makhachkala, Russian Federation)",
  "Far Eastern Federal University School Of Medicine (Vladivostok, Russian Federation)",
  "Far Eastern State Medical University (Khabarovsk, Russian Federation)",
  "First Moscow State Medical University Named After I M Sechenov (Moscow, Russian Federation)",
  "Immanuel Kant Baltic Federal University Institute Of Medicine (Kaliningrad, Russian Federation)",
  "Ingush State University Faculty Of Medicine (Nazran, Russian Federation)",
  "Institute Of The Federal Security Service Of The Russian Federation (Nizhny Novgorod, Russian Federation)",
  "Irkutsk State Medical University (Irkutsk, Russian Federation)",
  "Ivanovo State Medical Academy (Ivanovo, Russian Federation)",
  "Izhevsk State Medical Academy (isma) (Izhevsk, Russian Federation)",
  "Kabardino-balkarian State University Faculty Of Medicine (Nalchik, Russian Federation)",
  "Kadyrov Chechen State University (Groznij, Russian Federation)",
  "Kaluga State University Named After K E Tsiolkovsky Medical Institute (Kaluga, Russian Federation)",
  "Kazan Federal University Institute Of Fundamental Medicine And Biology (Kazan, Russian Federation)",
  "Kazan State Medical University (Kazan, Russian Federation)",
  "Kemerovo State Medical University (Kemerovo, Russian Federation)",
  "Khakasia State University Named After N F Katanov Institute Of Medicine, Psychology And Sociology (Abakan, Russian Federation)",
  "Khanty-mansiysk State Medical Academy (Khanty-mansiysk, Russian Federation)",
  "Kirov State Medical University (Kirov, Russian Federation)",
  "Kirov State Medical University, Komi Branch (Syktyvkar, Russian Federation)",
  "Krasnoyarsk State Medical University Named After Professor V F Voino-yasenetsky (Krasnoyarsk, Russian Federation)",
  "Kuban Medical Institute (Krasnodar, Russian Federation)",
  "Kuban State Medical University (Krasnodar, Russian Federation)",
  "Kursk State Medical University (Kursk, Russian Federation)",
  "Lobachevsky State University Of Nizhny Novgorod Institute Of Biology And Biomedicine (Nizhny Novgorod, Russian Federation)",
  "Lomonosov Moscow State University Faculty Of Medicine (Moscow, Russian Federation)",
  "Mari State University Medical Faculty (Yoshkar-ola, Russian Federation)",
  "Maykop State Technological University Medical Institute (Maykop, Russian Federation)",
  "Medical Institute Of Tambov State University Named After G R Derzhavin (Tambov, Russian Federation)",
  "Medical University Reaviz Moscow (Moscow, Russian Federation)",
  "Medical University Reaviz Samara (Samara, Russian Federation)",
  "Medical University Reaviz Saratov (Saratov, Russian Federation)",
  "Medical University Reaviz St Petersburg (St Petersburg, Russian Federation)",
  "Military Medical Academy S M Kirov (St Petersburg, Russian Federation)",
  "Moscow Institute Of Medico-social Rehabilitation (Moscow, Russian Federation)",
  "Moscow Medical Institute Of Health Ministry (Moscow, Russian Federation)",
  "Moscow Region State University Faculty Of Medicine (Mytishi, Russian Federation)",
  "Moscow State University Of Medicine And Dentistry Named After A I Evdokimov (Moscow, Russian Federation)",
  "Murmansk Arctic State University (Murmansk, Russian Federation)",
  "National Nuclear Research University 'mephi' Engineering Physics Institute Of Biomedicine (Moscow, Russian Federation)",
  "National Research Ogarev Mordovia State University Medical Faculty (Saransk, Russian Federation)",
  "Naval Academy Of Medicine (St Petersburg, Russian Federation)",
  "North Caucasian State Academy (Cherkessk, Russian Federation)",
  "North Ossetian State Medical Academy (Vladikavkaz, Russian Federation)",
  "North-eastern Federal University Named After Maxim Kirovich Ammosov Institute Of Medicine (Yakutsk, Russian Federation)",
  "North-western State Medical University I I Mechnikov (St Petersburg, Russian Federation)",
  "Northern State Medical University (Arkhangelsk, Russian Federation)",
  "Novosibirsk State Medical University (Novosibirsk, Russian Federation)",
  "Novosibirsk State University Institute Of Medicine And Psychology V Zelman (Novosibirsk, Russian Federation)",
  "Obninsk Institute Of Atomic Energy, National Nuclear Research University 'mepi' Medical Faculty (Obninsk, Russian Federation)",
  "Omsk State Medical University (Omsk, Russian Federation)",
  "Orel State University Named After I S Turgenev (Oryol, Russian Federation)",
  "Orenburg State Medical University (Orenburg, Russian Federation)",
  "Pacific State Medical University (Vladivostok, Russian Federation)",
  "Penza State University Medical Institute (Penza, Russian Federation)",
  "People's Friendship University Of Russia Faculty Of Medicine (Moscow, Russian Federation)",
  "Perm State Medical University Named After Academician E A Wagner (Perm, Russian Federation)",
  "Petrozavodsk State University Institute Of Medicine (petrsu) (Petrozavodsk, Russian Federation)",
  "Pitirim Sorokin Syktyvkar State University Medical Institute (Syktyvkar, Russian Federation)",
  "Privolzhsky Research Medical University (Nizhny Novgorod, Russian Federation)",
  "Pskov State University Institute Of Medicine And Experimental Biology (Pskov, Russian Federation)",
  "Rostov State Medical University (Rostov, Russian Federation)",
  "Russian National Research Medical University Named After N I Pirogov (Moscow, Russian Federation)",
  "Ryazan State Ivan Petrovich Pavlov Medical University Medical Faculty (Ryazan, Russian Federation)",
  "Saint Petersburg Medical And Technical Institute (St Petersburg, Russian Federation)",
  "Saint Petersburg Medico-social Institute (St Petersburg, Russian Federation)",
  "Saint Petersburg Pavlov State Medical University (St Petersburg, Russian Federation)",
  "Saint Petersburg State University Faculty Of Medicine (St Petersburg, Russian Federation)",
  "Saint-petersburg State Pediatric-medical University (St Petersburg, Russian Federation)",
  "Samara Military Medical Institute (Samara, Russian Federation)",
  "Samara State Medical University (Samara, Russian Federation)",
  "Saratov Military Medical Institute (Saratov, Russian Federation)",
  "Saratov State Medical University Named After V I Razumovsky (Saratov, Russian Federation)",
  "Siberian State Medical University (Tomsk, Russian Federation)",
  "Smolensk State Medical University (Smolensk, Russian Federation)",
  "South-ural State Medical University (Chelyabinsk, Russian Federation)",
  "State Classical Academy Named For Maimonid Medical Faculty (Moscow, Russian Federation)",
  "Stavropol State Medical University (Stavropol, Russian Federation)",
  "Surgut State University Institute Of Medicine (Surgut, Russian Federation)",
  "Tomsk Military Medical Institute (Tomsk, Russian Federation)",
  "Tula State University Medical Institute (Tula, Russian Federation)",
  "Tver State Medical University (Tver, Russian Federation)",
  "Tyumen State Medical University (Tyumen, Russian Federation)",
  "Ulyanovsk State University Medical Faculty Named After T Z Biktimirov (Ulyanovsk, Russian Federation)",
  "Ural State Medical University (Yekaterinburg, Russian Federation)",
  "Volgograd State Medical University (Volgograd, Russian Federation)",
  "Voronezh State Medical University Named After N N Burdenko (Voronezh, Russian Federation)",
  "Yaroslav-the-wise Novgorod State University Institute Of Medical Education (Veliky Novgorod, Russian Federation)",
  "Yaroslavl State Medical University (Yaroslavl, Russian Federation)",
  "Abdul Malek Ukil Medical College (Noakhali, Bangladesh)",
  "Ad-din Akij Medical College (aamc) (Khulna, Bangladesh)",
  "Ad-din Sakina Medical College (Jessore, Bangladesh)",
  "Ad-din Women's Medical College (Dhaka, Bangladesh)",
  "Anwer Khan Modern Medical College (Dhaka, Bangladesh)",
  "Armed Forces Medical College (afmc) (Dhaka, Bangladesh)",
  "Army Medical College Bogura (Bogra, Bangladesh)",
  "Army Medical College Chattogram (Chittagong, Bangladesh)",
  "Army Medical College Cumilla (Cumilla, Bangladesh)",
  "Army Medical College Jessore (Jessore, Bangladesh)",
  "Army Medical College Rangpur (Rangpur, Bangladesh)",
  "Ashiyan Medical College (Dhaka, Bangladesh)",
  "B G C Trust Medical College (Chittagong, Bangladesh)",
  "Bangabandhu Sheikh Mujib Medical College (Faridpur, Bangladesh)",
  "Bangabandhu Sheikh Mujib Medical University & Hospital (Dhaka, Bangladesh)",
  "Bangladesh Medical College (bmsri) (Dhaka, Bangladesh)",
  "Barind Medical College And Hospital (Rajshahi, Bangladesh)",
  "Bashundhara Ad-din Medical College (Keranigonj, Bangladesh)",
  "Bikrampur Bhuiyan Medical College (Damla, Bangladesh)",
  "Brahmanbaria Medical College (Brahmanbaria, Bangladesh)",
  "Care Medical College (Dhaka, Bangladesh)",
  "Central Medical College, Comilla (Comilla, Bangladesh)",
  "Chattagram International Medical College And Hospital (Chittagong, Bangladesh)",
  "Chattagram Maa-o-shishu Hospital Medical College (Chittagong, Bangladesh)",
  "Chittagong Medical College And Hospital (Chittagong, Bangladesh)",
  "City Medical College (Gazipur, Bangladesh)",
  "Colonel Malek Medical College (Manikganj, Bangladesh)",
  "Comilla Medical College & Hospital (Comilla, Bangladesh)",
  "Community Based Medical College, Bangladesh (cbmcb) (Mymensingh, Bangladesh)",
  "Cox's Bazar Medical College (Cox's Bazar, Bangladesh)",
  "Delta Medical College (Dhaka, Bangladesh)",
  "Dhaka Central International Medical College And Hospital (Dhaka, Bangladesh)",
  "Dhaka Community Medical College (Dhaka, Bangladesh)",
  "Dhaka Medical College And Hospital (Dhaka, Bangladesh)",
  "Dhaka National Medical College & Hospital (Dhaka, Bangladesh)",
  "Diabetic Association Medical College (Faridpur, Bangladesh)",
  "Dr Sirajul Islam Medical College And Hospital (Dhaka, Bangladesh)",
  "East West Medical College (Dhaka, Bangladesh)",
  "Eastern Medical College, Comilla (Comilla, Bangladesh)",
  "Enam Medical College And Hospital (Dhaka, Bangladesh)",
  "Gazi Medical College (Khulna, Bangladesh)",
  "Gonoshasthaya Samaj Vittik Medical College (Dhaka, Bangladesh)",
  "Green Life Medical College And Hospital (Dhaka, Bangladesh)",
  "Holy Family Red Crescent Medical College (Dhaka, Bangladesh)",
  "Ibn Sina Medical College (Dhaka, Bangladesh)",
  "Ibrahim Medical College (Dhaka, Bangladesh)",
  "Institute Of Applied Health Sciences (iahs) (Chittagong, Bangladesh)",
  "International Medical College (Gazipur, Bangladesh)",
  "Islami Bank Medical College (Rajshahi, Bangladesh)",
  "Jahurul Islam Medical College & Hospital (Kishoreganj, Bangladesh)",
  "Jalalabad Ragib-rabeya Medical College (Sylhet, Bangladesh)",
  "Jessore Medical College (Jessore, Bangladesh)",
  "Khulna Medical College (Khulna, Bangladesh)",
  "Khwaja Yunus Ali Medical College (Sirajgonj, Bangladesh)",
  "Kumudini Women's Medical College (Dhaka, Bangladesh)",
  "Kushtia Medical College (Kushtia, Bangladesh)",
  "M Abdur Rahim Medical College (Dinajpur, Bangladesh)",
  "M H Samorita Hospital And Medical College (Dhaka, Bangladesh)",
  "Mainamoti Medical College (Comilla, Bangladesh)",
  "Marine City Medical College (Chittagong, Bangladesh)",
  "Marks Medical College And Hospital (Dhaka, Bangladesh)",
  "Medical College For Women & Hospital (Dhaka, Bangladesh)",
  "Monno Medical College (Monno City, Bangladesh)",
  "Monowara Sikder Medical College (Bhedergonj, Bangladesh)",
  "Mugda Medical College And Hospital (Dhaka, Bangladesh)",
  "Mymensingh Medical College (Mymensingh, Bangladesh)",
  "North Bengal Medical College (Sirajgonj, Bangladesh)",
  "North East Medical College (Sylhet, Bangladesh)",
  "Northern International Medical College (Dhaka, Bangladesh)",
  "Northern Private Medical College (Rangpur, Bangladesh)",
  "Pabna Medical College (Pabna, Bangladesh)",
  "Parkview Medical College (Sylhet, Bangladesh)",
  "Patuakhali Medical College (Patuakhali, Bangladesh)",
  "Popular Medical College And Hospital (Dhaka, Bangladesh)",
  "President Abdul Hamid Medical College And Hospital (Kishoreganj, Bangladesh)",
  "Prime Medical College (Rangpur, Bangladesh)",
  "Rajshahi Medical College & Hospital (Rajshahi, Bangladesh)",
  "Rangamati Medical College (Rangamati, Bangladesh)",
  "Rangpur Community Medical College (Rangpur, Bangladesh)",
  "Rangpur Medical College (Rangpur, Bangladesh)",
  "Satkhira Medical College (Satkhira, Bangladesh)",
  "Shahabuddin Medical College (Dhaka, Bangladesh)",
  "Shaheed M Monsur Ali Medical College Sirajganj (Sirajganj, Bangladesh)",
  "Shaheed Monsur Ali Medical College (Dhaka, Bangladesh)",
  "Shaheed Suhrawardy Medical College (Dhaka, Bangladesh)",
  "Shaheed Syed Nazrul Islam Medical College (Kishoregonj, Bangladesh)",
  "Shaheed Tajuddin Ahmad Medical College (Gazipur, Bangladesh)",
  "Shaheed Ziaur Rahman Medical College (Bogra, Bangladesh)",
  "Sheikh Hasina Medical College Jamalpur (Jamalpur, Bangladesh)",
  "Sheikh Hasina Medical College Tangail (Tangail, Bangladesh)",
  "Sheikh Sayera Khatun Medical College (Gopalganj, Bangladesh)",
  "Sher-e-bangla Medical College (Barisal, Bangladesh)",
  "Sir Salimullah Medical College (Dhaka, Bangladesh)",
  "Southern Medical College & Hospital (Chittagong, Bangladesh)",
  "Sylhet M A G Osmani Medical College (Sylhet, Bangladesh)",
  "Sylhet Women's Medical College And Hospital (Sylhet, Bangladesh)",
  "T M S S Medical College (Bogra, Bangladesh)",
  "Tairunnessa Memorial Medical College And Hospital (Gazipur, Bangladesh)",
  "Universal Medical College (Dhaka, Bangladesh)",
  "Us-bangla Medical College And Hospital (Rupgonj, Bangladesh)",
  "Uttara Adhunik Medical College (Dhaka, Bangladesh)",
  "Zainul Haque Sikder Women's Medical College & Hospital (Dhaka, Bangladesh)",
  "B P Koirala Institute Of Health Sciences (Dharan, Nepal)",
  "Birat Medical College (Biratangar, Nepal)",
  "Chitwan Medical College (Bharatpur, Nepal)",
  "College Of Medical Sciences, Bharatpur (Bharatpur, Nepal)",
  "Devdaha Medical College (Devdaha, Nepal)",
  "Gandaki Medical College (Pokhara, Nepal)",
  "Institute Of Medicine, Tribhuvan University (Kathmandu, Nepal)",
  "Janaki Medical College (Dhanusha, Nepal)",
  "Karnali Academy Of Health Sciences (kahs) (Jumla, Nepal)",
  "Kathmandu Medical College (Kathmandu, Nepal)",
  "Kathmandu University School Of Medical Sciences (Dhulikhel, Nepal)",
  "Kist Medical College (Lalitpur, Nepal)",
  "Lumbini Medical College And Teaching Hospital (Tansen, Nepal)",
  "Manipal College Of Medical Sciences (mcoms) (Pokhara, Nepal)",
  "National Medical College, Birgunj (Birgunj, Nepal)",
  "Nepal Medical College (Kathmandu, Nepal)",
  "Nepalese Army Institute Of Health Sciences College Of Medicine (Kathmandu, Nepal)",
  "Nepalgunj Medical College (Nepalgunj, Nepal)",
  "Nobel Medical College (Biratnagar, Nepal)",
  "Patan Academy Of Health Sciences School Of Medicine (Kathmandu, Nepal)",
  "Universal College Of Medical Sciences (Bhairahawa, Nepal)",
  "Ain Shams University Faculty Of Medicine (Cairo, Egypt)",
  "Al-azhar Faculty Of Medicine For Men In Asyut (Assiut, Egypt)",
  "Al-azhar University Faculty Of Medicine For Boys (Nasr City, Egypt)",
  "Al-azhar University Faculty Of Medicine For Girls (Nasr City, Egypt)",
  "Alexandria University Faculty Of Medicine (Alexandria, Egypt)",
  "Arab Academy For Science, Technology And Maritime Transport College Of Medicine (New El Alamein City, Egypt)",
  "Armed Forces College Of Medicine (afcm) (Cairo, Egypt)",
  "Assiut University Faculty Of Medicine (Assiut, Egypt)",
  "Aswan University Faculty Of Medicine (Aswan, Egypt)",
  "Badr University In Cairo School Of Medicine (Badr City, Egypt)",
  "Beni Suef University Faculty Of Medicine (Beni Suef, Egypt)",
  "Cairo University Faculty Of Medicine (Cairo, Egypt)",
  "College Of Human Medicine Benha University (Benha, Egypt)",
  "Damietta Faculty Of Medicine, Al-azhar University (Damietta, Egypt)",
  "Delta University For Science And Technology Faculty Of Medicine (Belqas, Egypt)",
  "Fayoum University Faculty Of Medicine (Al-fayoum, Egypt)",
  "Galala University Faculty Of Medicine (Attaka, Egypt)",
  "Helwan University Faculty Of Medicine (Helwan, Egypt)",
  "Horus University Faculty Of Medicine (New Damietta City, Egypt)",
  "Ibrahim Pasha University Faculty Of Medicine (Cairo, Egypt)",
  "Kafrelsheikh University Faculty Of Medicine (Kafrelsheikh, Egypt)",
  "King Salman International University Faculty Of Medicine (El Tur, Egypt)",
  "Mansoura University Faculty Of Medicine (Mansoura, Egypt)",
  "Menoufia University Faculty Of Medicine (Shebin El Kom, Egypt)",
  "Merit University Faculty Of Medicine (New Sohag, Egypt)",
  "Minia University Faculty Of Medicine (Minia, Egypt)",
  "Misr University For Science And Technology College Of Medicine (6th Of October City, Egypt)",
  "Modern University For Technology And Information (mti) Faculty Of Medicine (Cairo, Egypt)",
  "Nahda University In Beni Suef Faculty Of Medicine (Beni Suef, Egypt)",
  "New Mansoura University Faculty Of Medicine (New Mansoura City, Egypt)",
  "New Valley University Faculty Of Medicine (Kharga, Egypt)",
  "Newgiza University School Of Medicine (Giza, Egypt)",
  "October 6 University Faculty Of Medicine (6th Of October City, Egypt)",
  "Port Said University Faculty Of Medicine (Port Said, Egypt)",
  "Qena Faculty Of Medicine, South Valley University (Qena, Egypt)",
  "Sohag University Faculty Of Medicine (Sohag, Egypt)",
  "Suez Canal University Faculty Of Medicine (Ismailia, Egypt)",
  "Suez University Faculty Of Medicine (El Salam, Egypt)",
  "Tanta University Faculty Of Medicine (Tanta, Egypt)",
  "Zagazig University Faculty Of Medicine (Zagazig, Egypt)",
  "Ajman University College Of Medicine (Ajman, United Arab Emirates)",
  "Dubai Medical College For Girls (Dubai, United Arab Emirates)",
  "Gulf Medical University College Of Medicine (Ajman, United Arab Emirates)",
  "Khalifa University College Of Medicine And Health Sciences (Abu Dhabi, United Arab Emirates)",
  "Mohammed Bin Rashid University Of Medicine And Health Sciences (Dubai, United Arab Emirates)",
  "Rak College Of Medical Sciences (Ras Al Khaimah, United Arab Emirates)",
  "United Arab Emirates University College Of Medicine And Health Sciences (Al Ain, United Arab Emirates)",
  "University Of Sharjah College Of Medicine (Sharjah, United Arab Emirates)",
  "St Matthew's University School Of Medicine (grand Cayman) (West Bay, Cayman Islands)",
  "Seoul Central College Of Medicine (Mongo Hill, Montserrat)",
  "University Of Science, Arts & Technology (usat) Faculty Of Medicine (Olveston, Montserrat)",
  "Vanguard University School Of Medicine (Salem, Montserrat)",
  "Anglia Ruskin University School Of Medicine (Chelmsford, United Kingdom Of Great Britain And Northern Ireland)",
  "Aston Medical School (Birmingham, United Kingdom Of Great Britain And Northern Ireland)",
  "Barts And The London School Of Medicine And Dentistry (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Brighton And Sussex Medical School (Brighton, United Kingdom Of Great Britain And Northern Ireland)",
  "Bristol Medical School, University Of Bristol (Bristol, United Kingdom Of Great Britain And Northern Ireland)",
  "Brunel University London Brunel Medical School (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Cardiff University School Of Medicine (Cardiff, United Kingdom Of Great Britain And Northern Ireland)",
  "Charing Cross & Westminster Medical Schools (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Edge Hill University Medical School (Ormskirk, United Kingdom Of Great Britain And Northern Ireland)",
  "Edinburgh Medical School, College Of Medicine And Veterinary Medicine, University Of Edinburgh (Edinburgh, United Kingdom Of Great Britain And Northern Ireland)",
  "Guy's Hospital Medical School (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Hull York Medical School (Hull, United Kingdom Of Great Britain And Northern Ireland)",
  "Imperial College London Faculty Of Medicine (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Keele University School Of Medicine (Keele, United Kingdom Of Great Britain And Northern Ireland)",
  "Kent And Medway Medical School (Canterbury, United Kingdom Of Great Britain And Northern Ireland)",
  "King's College London Gkt School Of Medicine (London, United Kingdom Of Great Britain And Northern Ireland)",
  "King's College School Of Medicine (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Lancaster University Medical School (Lancaster, United Kingdom Of Great Britain And Northern Ireland)",
  "Leicester Medical School, University Of Leicester (Leicester, United Kingdom Of Great Britain And Northern Ireland)",
  "Leicester Warwick Medical Schools (Leicester, United Kingdom Of Great Britain And Northern Ireland)",
  "London Hospital Medical College (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Manchester Medical School (Manchester, United Kingdom Of Great Britain And Northern Ireland)",
  "Middlesex Hospital Medical School (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Newcastle University Faculty Of Medical Sciences (Newcastle Upon Tyne, United Kingdom Of Great Britain And Northern Ireland)",
  "Norwich Medical School, University Of East Anglia (Norwich, United Kingdom Of Great Britain And Northern Ireland)",
  "Peninsula College Of Medicine And Dentistry (Plymouth, United Kingdom Of Great Britain And Northern Ireland)",
  "Peninsula Medical School, University Of Plymouth (Plymouth, United Kingdom Of Great Britain And Northern Ireland)",
  "Polish School Of Medicine At The University Of Edinburgh (Edinburgh, United Kingdom Of Great Britain And Northern Ireland)",
  "Queen's University Belfast School Of Medicine, Dentistry And Biomedical Sciences (Belfast, United Kingdom Of Great Britain And Northern Ireland)",
  "Royal Free Hospital School Of Medicine (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Sheffield University School Of Medicine And Biomedical Sciences (Sheffield, United Kingdom Of Great Britain And Northern Ireland)",
  "St Bartholomew's Hospital Medical College (London, United Kingdom Of Great Britain And Northern Ireland)",
  "St George's University Of London (London, United Kingdom Of Great Britain And Northern Ireland)",
  "St Mary's Hospital Medical School (London, United Kingdom Of Great Britain And Northern Ireland)",
  "St Mary's Hospital Medical School, University Of London (London, United Kingdom Of Great Britain And Northern Ireland)",
  "St Thomas's Hospital Medical School (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Swansea University Medical School (Swansea, United Kingdom Of Great Britain And Northern Ireland)",
  "Ucl Medical School (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Ulster University School Of Medicine (Londonderry, United Kingdom Of Great Britain And Northern Ireland)",
  "United Medical & Dental Schools Of Guy's And St Thomas's (London, United Kingdom Of Great Britain And Northern Ireland)",
  "University College London School Of Medicine (London, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Aberdeen School Of Medicine, Medical Sciences And Nutrition (Aberdeen, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Birmingham College Of Medical And Dental Sciences (Birmingham, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Buckingham Medical School (Buckingham, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Cambridge School Of Clinical Medicine (Cambridge, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Central Lancashire School Of Medicine (Preston, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Dundee School Of Medicine (Dundee, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Exeter College Of Medicine And Health (Exeter, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Glasgow School Of Medicine, Dentistry And Nursing (Glasgow, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Leeds School Of Medicine (Leeds, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Liverpool School Of Medicine (Liverpool, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Nottingham School Of Medicine (Nottingham, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Oxford Medical Sciences Division (Oxford, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Southampton Faculty Of Medicine (Southampton, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of St Andrews School Of Medicine (St Andrews, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Sunderland School Of Medicine (Sunderland, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Wales School Of Medicine (Cardiff, United Kingdom Of Great Britain And Northern Ireland)",
  "University Of Worcester Three Counties Medical School (Worcester, United Kingdom Of Great Britain And Northern Ireland)",
  "Warwick Medical School (Coventry, United Kingdom Of Great Britain And Northern Ireland)",
  "Westminster Hospital Medical School (London, United Kingdom Of Great Britain And Northern Ireland)",
  "Adventist University Of The Philippines College Of Medicine (Silang, Philippines)",
  "Ama School Of Medicine (Makati, Philippines)",
  "Angeles University Foundation School Of Medicine (Angeles, Philippines)",
  "Ateneo De Zamboanga University College Of Medicine (Zamboanga, Philippines)",
  "Ateneo School Of Medicine And Public Health (Pasig City, Philippines)",
  "Baguio Central University College Of Medicine (Baguio, Philippines)",
  "Bicol Christian College Of Medicine (Legazpi, Philippines)",
  "Bicol University College Of Medicine (Legazpi, Philippines)",
  "Brokenshire College School Of Medicine (Davao City, Philippines)",
  "Cagayan State University College Of Medicine And Surgery (Tuguegarao, Philippines)",
  "Cebu Doctors' University College Of Medicine (Mandaue, Philippines)",
  "Cebu Institute Of Medicine (Cebu, Philippines)",
  "Central Philippine University College Of Medicine (Iloilo City, Philippines)",
  "Centro Escolar University College Of Medicine (Manila, Philippines)",
  "Chinese General Hospital Colleges (Manila, Philippines)",
  "Davao Medical School Foundation College Of Medicine (Davao, Philippines)",
  "De La Salle Medical And Health Sciences Institute College Of Medicine (Dasmarinas, Philippines)",
  "Divine Word University School Of Medicine (liceo Del Verbo Divino) (Tacloban, Philippines)",
  "Dr Francisco Q Duque Medical Foundation College Of Medicine (Dagupan, Philippines)",
  "Dr Jose P Rizal College Of Medicine (Cagayan De Oro City, Philippines)",
  "Emilio Aguinaldo College School Of Medicine (Manila, Philippines)",
  "Far Eastern University Institute Of Medicine, Nicanor Reyes Medical Foundation (Quezon City, Philippines)",
  "Gullas College Of Medicine, University Of The Visayas (Mandaue, Philippines)",
  "Iloilo Doctors' College Of Medicine (Iloilo City, Philippines)",
  "Jose Maria College Of Medicine Foundation (Davao City, Philippines)",
  "La Consolacion University Philippines College Of Medicine (Malolos, Philippines)",
  "Liceo De Cagayan University College Of Medicine (Cagayan De Oro City, Philippines)",
  "Manila Central University-filemon D Tanchoco Foundation College Of Medicine (Caloocan, Philippines)",
  "Manila Theological College Of Medicine (Manila, Philippines)",
  "Mariano Marcos State University College Of Medicine (Batac, Philippines)",
  "Matias H Aznar Memorial College Of Medicine (Cebu, Philippines)",
  "Metropolitan Medical Center College Of Medicine (Manila, Philippines)",
  "Mindanao State University College Of Medicine (Iligan, Philippines)",
  "New Era University College Of Medicine (Quezon City, Philippines)",
  "Our Lady Of Fatima University College Of Medicine (Valenzuela, Philippines)",
  "Philippine Muslim-christian College (Antipolo, Philippines)",
  "Plt College Inc College Of Medicine (Solano, Philippines)",
  "Remedios T Romualdez Medical Foundation College Of Medicine (Tacloban, Philippines)",
  "San Beda University College Of Medicine (Manila, Philippines)",
  "School Of Health Sciences, University Of The Philippines System (Palo, Philippines)",
  "Silliman University Medical School (Dumaguete, Philippines)",
  "Southwestern University School Of Medicine (Cebu City, Philippines)",
  "St Louis University School Of Medicine (Baguio, Philippines)",
  "St Luke's Medical Center College Of Medicine - William H Quasha Memorial (slmccm-whqm) (Quezon City, Philippines)",
  "St Paul University Philippines School Of Medicine (Tuguegarao City, Philippines)",
  "The Manila Times College Of Subic (tmtcs) College Of Medicine (Olongapo City, Philippines)",
  "Universidad De Santa Isabel College Of Medicine (Naga City, Philippines)",
  "University Of Cebu College Of Medicine (Mandaue City, Philippines)",
  "University Of Northern Philippines College Of Medicine (Ilocos Sur, Philippines)",
  "University Of Perpetual Help System Dalta Jonelta Foundation School Of Medicine (Las Piñas, Philippines)",
  "University Of Perpetual Help System Dr Jose G Tamayo Medical University (Binan, Philippines)",
  "University Of Saint La Salle College Of Medicine (Bacolod, Philippines)",
  "University Of Santo Tomas Faculty Of Medicine And Surgery (Manila, Philippines)",
  "University Of The City Of Manila College Of Medicine (Manila, Philippines)",
  "University Of The East/ramon Magsaysay Memorial Medical Center College Of Medicine (Quezon, Philippines)",
  "University Of The Philippines Manila College Of Medicine (Manila, Philippines)",
  "Virgen Milagrosa University Foundation College Of Medicine (San Carlos, Philippines)",
  "West Visayas State University College Of Medicine (Iloilo City, Philippines)",
  "Acibadem Mehmet Ali Aydinlar Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Adiyaman Üniversitesi Tıp Fakültesi (Adiyaman, Turkey)",
  "Adnan Menderes Üniversitesi Tıp Fakültesi (Aydin, Turkey)",
  "Afyonkarahisar Sağlık Bilimleri Üniversitesi Tıp Fakültesi (Afyonkarahisar, Turkey)",
  "Ahi Evran Üniversitesi Tıp Fakültesi (Kırşehir, Turkey)",
  "Akdeniz Üniversitesi Tıp Fakültesi (Antalya, Turkey)",
  "Aksaray Üniversitesi Tıp Fakültesi (Aksaray, Turkey)",
  "Alanya Alaaddin Keykubat Üniversitesi Tıp Fakültesi (Alanya, Turkey)",
  "Altınbaş Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Amasya Üniversitesi Tıp Fakültesi (Amasya, Turkey)",
  "Ankara Medipol Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Ankara Yildirim Beyazit Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Ankara Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Ataturk Üniversitesi Tıp Fakültesi (Erzurum, Turkey)",
  "Atılım Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Bahçeşehir Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Balikesir Üniversitesi Tıp Fakültesi (Balikesir, Turkey)",
  "Bandırma Onyedi Eylül Üniversitesi Tıp Fakültesi (Bandırma, Turkey)",
  "Baskent Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Beykent Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Bezmialem Vakif Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Biruni Üniversitesi Tıp Fakültesi (Istanbul, Turkey)",
  "Bolu Abant Izzet Baysal Üniversitesi Tıp Fakültesi (Bolu, Turkey)",
  "Bozok Üniversitesi Tıp Fakültesi (Yozgat, Turkey)",
  "Canakkale Onsekiz Mart Üniversitesi Tıp Fakültesi (Çanakkale, Turkey)",
  "Celal Bayar Üniversitesi Tıp Fakültesi (Manisa, Turkey)",
  "Cukurova Üniversitesi Tıp Fakültesi (Adana, Turkey)",
  "Cumhuriyet Üniversitesi Tıp Fakültesi (Sivas, Turkey)",
  "Dicle Üniversitesi Tıp Fakültesi (Diyarbakir, Turkey)",
  "Dokuz Eylül Üniversitesi Tıp Fakültesi (İzmir, Turkey)",
  "Düzce Üniversitesi Tıp Fakültesi (Duzce, Turkey)",
  "Ege Üniversitesi Tıp Fakültesi (İzmir, Turkey)",
  "Erciyes Üniversitesi Tıp Fakültesi (Kayseri, Turkey)",
  "Erzincan Binali Yıldırım Üniversitesi Tıp Fakültesi (Erzincan, Turkey)",
  "Eskişehir Osmangazi Üniversitesi Tıp Fakültesi (Eskisehir, Turkey)",
  "Fatih Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Firat Üniversitesi Tıp Fakültesi (Elazig, Turkey)",
  "Gazi Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Gaziantep Üniversitesi Tıp Fakültesi (Gaziantep, Turkey)",
  "Gaziosmanpasa Üniversitesi Tıp Fakültesi (Tokat, Turkey)",
  "Giresun Üniversitesi Tıp Fakültesi (Giresun, Turkey)",
  "Hacettepe Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Haliç Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Harran Üniversitesi Tıp Fakültesi (Şanliurfa, Turkey)",
  "Hitit Üniversitesi Tıp Fakültesi (Corum, Turkey)",
  "Istanbul Okan Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Kafkas Üniversitesi Tıp Fakültesi (Kars, Turkey)",
  "Kahramanmaras Sütçü Imam Üniversitesi Tıp Fakültesi (Kahramanmaras, Turkey)",
  "Karabük Üniversitesi Tıp Fakültesi (Karabük, Turkey)",
  "Karadeniz Teknik Üniversitesi Tıp Fakültesi (Trabzon, Turkey)",
  "Kastamonu Üniversitesi Tıp Fakültesi (Sıhhiye, Turkey)",
  "Kirikkale Üniversitesi Tıp Fakültesi (Kirikkale, Turkey)",
  "Kocaeli Üniversitesi Tip Fakültesi (Kocaeli, Turkey)",
  "Koç Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Kto Karatay Üniversitesi Tıp Fakültesi (Karatay, Turkey)",
  "Kütahya Sağlık Bilimleri Üniversitesi Tıp Fakültesi (Kütahya, Turkey)",
  "Lokman Hekim Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Maltepe Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Marmara Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Meram Tıp Fakültesi (Konya, Turkey)",
  "Mersin Üniversitesi Tıp Fakültesi (Mersin, Turkey)",
  "Mevlana Üniversitesi Tıp Fakültesi (Konya, Turkey)",
  "Muğla Sıtkı Koçman Üniversitesi Tıp Fakültesi (Muğla, Turkey)",
  "Namik Kemal Üniversitesi Tıp Fakültesi (Tekirdağ, Turkey)",
  "Niğde Ömer Halisdemir Üniversitesi Tıp Fakültesi (Niğde, Turkey)",
  "Ondokuz Mayis Üniversitesi Tıp Fakültesi (Samsun, Turkey)",
  "Ordu Üniversitesi Tıp Fakültesi (Ordu, Turkey)",
  "Pamukkale Üniversitesi Tıp Fakültesi (Denizli, Turkey)",
  "Recep Tayyip Erdogan Üniversitesi Tıp Fakültesi (Rize, Turkey)",
  "Sakarya Üniversitesi Tıp Fakültesi (Sakarya, Turkey)",
  "Samsun Üniversitesi Tıp Fakültesi (Samsun, Turkey)",
  "Sanko Üniversitesi Tıp Fakültesi (Gaziantep, Turkey)",
  "Sağlık Bilimleri Üniversitesi Bursa Tip Fakültesi (Bursa, Turkey)",
  "Sağlik Bilimleri Üniversitesi Erzurum Tip Fakültesi (Erzurum, Turkey)",
  "Sağlık Bilimleri Üniversitesi Gülhane Tıp Fakültesi (Ankara, Turkey)",
  "Sağlık Bilimleri Üniversitesi Hamidiye Tıp Fakültesi (İstanbul, Turkey)",
  "Sağlık Bilimleri Üniversitesi Hamidiye Uluslararası Tıp Fakültesi (İstanbul, Turkey)",
  "Sağlık Bilimleri Üniversitesi İzmir Tıp Fakültesi (Izmir, Turkey)",
  "Selçuk Üniversitesi Tıp Fakültesi (Selcuklu, Turkey)",
  "Sifa Üniversitesi Tıp Fakültesi (İzmir, Turkey)",
  "Siirt Üniversitesi Tıp Fakültesi (Siirt, Turkey)",
  "Süleyman Demirel Üniversitesi Tıp Fakültesi (Isparta, Turkey)",
  "Tayfur Ata Sökmen Tıp Fakültesi, Mustafa Kemal Üniversitesi (Antakya, Turkey)",
  "Tobb Ekonomi Ve Teknoloji Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Trakya Üniversitesi Tıp Fakültesi (Edirne, Turkey)",
  "Turgut Özal Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Ufuk Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Uludag Üniversitesi Tıp Fakültesi (Bursa, Turkey)",
  "Uşak Üniversitesi Tıp Fakültesi (Uşak, Turkey)",
  "Van Yüzüncü Yil Üniversitesi Tıp Fakültesi (Van, Turkey)",
  "Yalova Üniversitesi Tıp Fakültesi (Yalova, Turkey)",
  "Yeditepe Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Yeni Yüzyil Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "Yüksek İhtisas Üniversitesi Tıp Fakültesi (Ankara, Turkey)",
  "Zonguldak Bülent Ecevit University Tıp Fakültesi (Esenköy, Turkey)",
  "Üsküdar Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "İnönü Üniversitesi Tıp Fakültesi (Malatya, Turkey)",
  "İstanbul Atlas Üniversitesi Tıp Fakültesi (Istanbul, Turkey)",
  "İstanbul Aydın Üniversitesi Tıp Fakültesi (Istanbul, Turkey)",
  "İstanbul Bilim Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "İstanbul Medeniyet Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "İstanbul Medipol University International School Of Medicine (İstanbul, Turkey)",
  "İstanbul Medipol Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "İstanbul Sağlık Ve Teknoloji Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "İstanbul Üniversitesi - Cerrahpasa, Cerrahpasa Tıp Fakültesi (İstanbul, Turkey)",
  "İstanbul Üniversitesi, İstanbul Tıp Fakültesi (İstanbul, Turkey)",
  "İstinye Üniversitesi Tıp Fakültesi (İstanbul, Turkey)",
  "İzmir Bakırçay Üniversitesi Tıp Fakültesi (Izmir, Turkey)",
  "İzmir Demokrasi Üniversitesi Tıp Fakültesi (İzmir, Turkey)",
  "İzmir Ekonomi Üniversitesi Tıp Fakültesi (İzmir, Turkey)",
  "İzmir Kâtip Çelebi Üniversitesi Tıp Fakültesi (İzmir, Turkey)",
  "İzmir Tınaztepe Üniversitesi Tıp Fakültesi (İzmir, Turkey)",
  "İzmir Üniversitesi Tıp Fakültesi (İzmir, Turkey)",
  "Akademia Medyczna, Lodz (Lodz, Poland)",
  "Collegium Medicum Im Ludwika Rydygiera, Uniwersytet Mikolaja Kopernika W Toruniu (Bydgoszcz, Poland)",
  "Faculty Of Medicine And Dentistry In Zabrze (Zabrze, Poland)",
  "Jagiellonian University Medical College (Kraków, Poland)",
  "Jan Kochanowski University Collegium Medicum (Kielce, Poland)",
  "Krakowska Akademia Im Andrzeja Frycza Modrzewskiego Wydział Lekarski I Nauk O Zdrowiu (Kraków, Poland)",
  "Medical University Of Bialystok Faculty Of Medicine (Bialystok, Poland)",
  "Medical University Of Gdańsk Faculty Of Medicine (Gdańsk, Poland)",
  "Medical University Of Lodz Faculty Of Medicine (Lodz, Poland)",
  "Medical University Of Lublin (Lublin, Poland)",
  "Medical University Of Silesia In Katowice Faculty Of Medical Sciences Katowice (Katowice, Poland)",
  "Medical University Of Warsaw Faculty Of Medicine (Warsaw, Poland)",
  "Military Medical Academy (Lodz, Poland)",
  "Opole University Faculty Of Medicine (Opole, Poland)",
  "Pomorski Uniwersytet Medyczny W Szczecinie (Szczecin, Poland)",
  "Poznan University Of Medical Sciences Center For Medical Education In English (Poznan, Poland)",
  "Poznan University Of Medical Sciences, Faculty Of Medicine I (Poznan, Poland)",
  "Uczelnia Łazarskiego Wydział Medyczny (Warsaw, Poland)",
  "University Of Rzeszów Faculty Of Medicine (Rzeszow, Poland)",
  "University Of Warmia And Mazury In Olsztyn, Collegium Medicum, School Of Medicine (Olsztyn, Poland)",
  "University Of Zielona Góra Faculty Of Medicine And Health Sciences (Zielona Gora, Poland)",
  "Uniwersytet Kardynała Stefana Wyszyńskiego Wydział Lekarski (Warsaw, Poland)",
  "Wroclaw Medical University Faculty Of Medicine (Wroclaw, Poland)",
  "Wyższa Szkoła Techniczna W Katowicach Wydział Nauk Medycznych (Katowice, Poland)",
  "Akaki Tsereteli State University Faculty Of Medicine (Kutaisi, Georgia)",
  "Alte University School Of Medicine (Tbilisi, Georgia)",
  "Batumi Shota Rustaveli State University Faculty Of Education And Science (Batumi, Georgia)",
  "Bau International University Faculty Of Medicine (Batumi, Georgia)",
  "Caucasus International University Faculty Of Medicine (Tbilisi, Georgia)",
  "Caucasus University School Of Medicine And Healthcare Management (Tbilisi, Georgia)",
  "Classical And Traditional Medicine Academy (Tbilisi, Georgia)",
  "Critical Care Medicine Institute (Tbilisi, Georgia)",
  "David Aghmashenebeli University Of Georgia Faculty Of Medicine (Tbilisi, Georgia)",
  "David Tvildiani Medical University Aieti Medical School (Tbilisi, Georgia)",
  "East European University Faculty Of Healthcare Sciences (Tbilisi, Georgia)",
  "East-west Teaching University School Of Medicine (Tbilisi, Georgia)",
  "European University Faculty Of Medicine (Tbilisi, Georgia)",
  "Georgian American University (Tbilisi, Georgia)",
  "Georgian National University Seu Faculty Of Medicine (Tbilisi, Georgia)",
  "Grigol Robakidze University School Of Medicine (Tbilisi, Georgia)",
  "Ilia State University Faculty Of Natural Sciences And Medicine (Tbilisi, Georgia)",
  "Ivane Javakhishvili Tbilisi State University Faculty Of Medicine (Tbilisi, Georgia)",
  "Ken Walker International University Faculty Of Medicine And Stomatology (Tbilisi, Georgia)",
  "Kutaisi Institute Of Medicine (Kutaisi, Georgia)",
  "Kutaisi University Faculty Of Medicine (Kutaisi, Georgia)",
  "Medical Institute Of Reconstruction Plastic Surgery And Dermocosmetology (Tbilisi, Georgia)",
  "New Vision University School Of Medicine (Tbilisi, Georgia)",
  "Petre Shotadze Tbilisi Medical Academy Faculty Of Medicine (Tbilisi, Georgia)",
  "Quality Control Teaching University Of Georgia (Tbilisi, Georgia)",
  "Tbilisi Humanitarian Teaching University Faculty Of Health (Tbilisi, Georgia)",
  "Tbilisi Medical Institute 'vita' (Tbilisi, Georgia)",
  "Tbilisi Medical Teaching University 'hippocrates' (Tbilisi, Georgia)",
  "Tbilisi National University 'gaenati' (Tbilisi, Georgia)",
  "Tbilisi State Medical University Faculty Of Medicine (Tbilisi, Georgia)",
  "Tbilisi University 'metekhi' (Tbilisi, Georgia)",
  "Teaching University Geomedi Faculty Of Medicine (Tbilisi, Georgia)",
  "University Of Georgia School Of Health Sciences (Tbilisi, Georgia)",
  "University Of Legia And Company Faculty Of Medicine (Tbilisi, Georgia)",
  "University Sakartvelo Faculty Of Medicine (Tbilisi, Georgia)",
  "Medical School Name (City Name, Country Name)",
  "Afghan Swiss Medical Institute Of Higher Education (Kabul, Afghanistan)",
  "Ahmad Shah Abdali Institute Of Higher Education Faculty Of Curative Medicine (Khost, Afghanistan)",
  "Alberoni University Faculty Of Medicine (Kohistan, Afghanistan)",
  "Aria University Faculty Of Medicine (Mazar-i-sharif, Afghanistan)",
  "Ariana Institute Of Higher Education School Of Curative Medical Sciences, Kabul (Kabul, Afghanistan)",
  "Ariana Institute Of Higher Education School Of Curative Medical Sciences, Nangarhar (Jalalabad, Afghanistan)",
  "Balkh University Faculty Of Medicine (Mazar-i-sharif, Afghanistan)",
  "Bayazid Rokhan Institute Of Higher Education (Kabul, Afghanistan)",
  "Bi Bi Aisha Institute Of Higher Education Faculty Of Medicine (Kabul, Afghanistan)",
  "Bost University Faculty Of Medicine (Lashkar Gah, Afghanistan)",
  "Cheragh Medical Institute (Kabul, Afghanistan)",
  "Dawat University Faculty Of Medicine (Kabul, Afghanistan)",
  "Ghalib University Medical Science Faculty Herat (Herat, Afghanistan)",
  "Ghalib University Medical Science Faculty Kabul (Kabul, Afghanistan)",
  "Hakim Sanayee University Faculty Of Medicine (Pul-e Khomri, Afghanistan)",
  "Herat University Faculty Of Medicine (Herat, Afghanistan)",
  "Jami University Faculty Of Medicine (Herat, Afghanistan)",
  "Kabul University Of Medical Sciences Faculty Of Medicine (Kabul, Afghanistan)",
  "Kandahar University Faculty Of Medicine (Kandahar, Afghanistan)",
  "Kateb University Faculty Of Medicine (Kabul, Afghanistan)",
  "Khatam Al Nabieen University Kabul Faculty Of Medicine (Kabul, Afghanistan)",
  "Malalay Institute Of Higher Education (Kandahar, Afghanistan)",
  "Mawlana Jalaluddin Mohammad Balkhi University Faculty Of Medicine (Mazar-i-sharif, Afghanistan)",
  "Mili Institute Of Higher Education (Kabul, Afghanistan)",
  "Mirwais Neeka Institute Of Higher Education (Kandahar, Afghanistan)",
  "Muslim University Faculty Of Medicine (Ghazni, Afghanistan)",
  "Nangarhar University Faculty Of Medicine (Jalalabad, Afghanistan)",
  "Paktia University Faculty Of Medicine (Gardez, Afghanistan)",
  "Rahnaward Institute Of Higher Education Faculty Of Medicine (Mazar-i-sharif, Afghanistan)",
  "Rokhan Institute Of Higher Education Faculty Of Medicine (Jalalabad, Afghanistan)",
  "Shaikh Zayed Bin Sultan Al Nahyan University Faculty Of Medicine (Khost, Afghanistan)",
  "Spinghar Institute Of Higher Education, Kabul (Kabul, Afghanistan)",
  "Spinghar Institute Of Higher Education, Nangarhar (Jalalabad, Afghanistan)",
  "Taj Institute Of Higher Education Faculty Of Medicine (Mazar-i-sharif, Afghanistan)",
  "Wadi Helmand Institute Of Higher Education Faculty Of Medicine (Helmand, Afghanistan)",
  "Zawul Institute Of Higher Education Faculty Of Medicine (Kabul, Afghanistan)",
  "Our Lady Of Good Counsel University Faculty Of Medicine (Tiranë, Albania)",
  "University Of Medicine Of Tirana Faculty Of Medicine (Tiranë, Albania)",
  "Université Abou Bekr Belkaid Tlemcen Faculté De Médecine (Tlemcen, Algeria)",
  "Université Badji Mokhtar D'annaba Faculté De Médecine (Annaba, Algeria)",
  "Université Batna 2 Faculté De Médecine (Batna, Algeria)",
  "Université Constantine 3 Faculté De Médecine (Constantine, Algeria)",
  "Université D'alger Benyoucef Benkhedda Faculté Des Sciences Médicales (Algiers, Algeria)",
  "Université D'oran 1 Ahmed Ben Bella Faculté De Médecine (Oran, Algeria)",
  "Université De Béjaïa Faculté De Médecine (Béjaïa, Algeria)",
  "Université De Mostaganem Abdelhamid Ibn Badis Faculté De Médecine (Mostaganem, Algeria)",
  "Université Djillali Liabes De Sidi Bel-abbès Faculté De Sciences Médicales (Sidi-bel-abbès, Algeria)",
  "Université Ferhat Abbas De Sétif (ufas) Faculté De Médecine (Sétif, Algeria)",
  "Université Mouloud Mammeri De Tizi-ouzou Faculté De Médecine (Tizi-ouzou, Algeria)",
  "Université Saad Dahlab Blida Faculté De Médecine (Blida, Algeria)",
  "Instituto Superior Técnico Militar (Luanda, Angola)",
  "Universidade Agostinho Neto Faculdade De Medicina (Luanda, Angola)",
  "Universidade Jean Piaget De Angola Faculdade De Ciências Da Saúde (Luanda, Angola)",
  "Universidade José Eduardo Dos Santos Faculdade De Medicina (Huambo, Angola)",
  "Universidade Katyavala Bwila Faculdade De Medicina (Benguela, Angola)",
  "Universidade Lueji A'nkonde Faculdade De Medicina (Malanje, Angola)",
  "Universidade Mandume Ya Ndemufayo Faculdade De Medicina (Lubango, Angola)",
  "Universidade Onze De Novembro Faculdade De Medicina (Cabinda, Angola)",
  "Universidade Privada De Angola Faculdade De Ciências Da Saúde (Luanda, Angola)",
  "American University Of Antigua College Of Medicine (Coolidge, Antigua And Barbuda)",
  "Atlantic University School Of Medicine (St John's, Antigua And Barbuda)",
  "Metropolitan University College Of Medicine (St John's, Antigua And Barbuda)",
  "University Of Health Sciences Antigua School Of Medicine (St John's, Antigua And Barbuda)",
  "Progress' University Of Gyumri Faculty Of Medicine (Gyumri, Armenia)",
  "Armenian Medical Institute Faculty Of Medicine (Yerevan, Armenia)",
  "Armenian-russian International University 'mkhitar Gosh' (Yerevan, Armenia)",
  "Erebuni Medical Academy Foundation (Yerevan, Armenia)",
  "St Tereza Medical University (Yerevan, Armenia)",
  "Yerevan Gladzor University Faculty Of Medicine (Yerevan, Armenia)",
  "Yerevan Haybusak University Faculty Of Medicine (Yerevan, Armenia)",
  "Yerevan State Medical University Named For Mkhitar Heratsi (Yerevan, Armenia)",
  "Yerevan University Of Traditional Medicine (Yerevan, Armenia)",
  "Australian National University School Of Medicine And Psychology (Canberra, Australia)",
  "Bond University Faculty Of Health Sciences & Medicine (Gold Coast, Australia)",
  "Curtin Medical School (Perth, Australia)",
  "Deakin University School Of Medicine (Geelong, Australia)",
  "Flinders University Of South Australia School Of Medicine (Adelaide, Australia)",
  "Griffith University School Of Medicine (Southport, Australia)",
  "James Cook University College Of Medicine And Dentistry (Douglas, Australia)",
  "Macquarie University Faculty Of Medicine & Health Sciences (Sydney, Australia)",
  "Monash School Of Medicine (Clayton, Australia)",
  "The University Of Western Australia Faculty Of Health And Medical Sciences (Perth, Australia)",
  "University Of Adelaide Faculty Of Health And Medical Sciences (Adelaide, Australia)",
  "University Of Melbourne Faculty Of Medicine, Dentistry & Health Sciences (Parkville, Australia)",
  "University Of New England School Of Rural Medicine (Armidale, Australia)",
  "University Of New South Wales Faculty Of Medicine (Sydney, Australia)",
  "University Of Newcastle Faculty Of Health And Medicine (Callaghan, Australia)",
  "University Of Notre Dame Australia School Of Medicine, Fremantle (Fremantle, Australia)",
  "University Of Notre Dame Australia School Of Medicine, Sydney (Darlinghurst, Australia)",
  "University Of Queensland Faculty Of Medicine (Herston, Australia)",
  "University Of Sydney, Sydney Medical School (Sydney, Australia)",
  "University Of Tasmania School Of Medicine (Hobart, Australia)",
  "University Of Wollongong Graduate School Of Medicine (Wollongong, Australia)",
  "Western Sydney University School Of Medicine (Campbelltown, Australia)",
  "Azerbaijan International University (aiu) Faculty Of Medicine (Baku, Azerbaijan)",
  "Azerbaijan Medical University (Baku, Azerbaijan)",
  "Khazar University School Of Medicine, Dentistry And Public Health (Baku, Azerbaijan)",
  "Nakhchivan State University Faculty Of Medicine (Nakhchivan, Azerbaijan)",
  "Odlar Yurdu University Medical Faculty (Baku, Azerbaijan)",
  "Aalborg Universitet Det Sundhedsvidenskabelige Fakultet (Aalborg, Denmark)",
  "Aarhus University Faculty Of Health (Aarhus, Denmark)",
  "Københavns Universitet Det Sundhedsvidenskabelige Og Medicinsk Fakultet (Kobenhavn, Denmark)",
  "Syddansk Universitet Det Sundhedsvidenskabelige Fakultet Esbjerg (Esbjerg, Denmark)",
  "Syddansk Universitet Det Sundhedsvidenskabelige Fakultet Odense (Odense, Denmark)",
  "Université De Djibouti Faculté De Médecine (Djibouti, Djibouti)",
  "Instituto Tecnológico De Santo Domingo (intec) Facultad De Ciencias De La Salud (Santo Domingo, Dominican Republic)",
  "O&med Medical School, Universidad Dominicana O&m (Santo Domingo, Dominican Republic)",
  "Pontificia Universidad Católica Madre Y Maestra Facultad De Ciencias De La Salud Santiago (Santiago, Dominican Republic)",
  "Pontificia Universidad Católica Madre Y Maestra Facultad De Ciencias De La Salud Santo Domingo (Santo Domingo, Dominican Republic)",
  "Universidad Autónoma De Santo Domingo Facultad De Ciencias De La Salud (Santo Domingo, Dominican Republic)",
  "Universidad C E T E C Escuela De Medicina (Santo Domingo, Dominican Republic)",
  "Universidad C I F A S Escuela De Medicina (Santo Domingo, Dominican Republic)",
  "Universidad Católica Nordestana (ucne) Facultad De Ciencias Medicas (San Francisco De Macoris, Dominican Republic)",
  "Universidad Católica Tecnológica Del Cibao (ucateci) Escuela De Medicina (La Vega, Dominican Republic)",
  "Universidad Central Del Este (uce) Facultad De Medicina (San Pedro De Macoris, Dominican Republic)",
  "Universidad Eugenio Maria De Hostos Escuela De Medicina (Santo Domingo, Dominican Republic)",
  "Universidad Federico Henriquez Y Carvajal (ufhec) Facultad De Medicina (Santo Domingo, Dominican Republic)",
  "Universidad Iberoamericana (unibe) School Of Medicine, Santo Domingo (Santo Domingo, Dominican Republic)",
  "Universidad Internacional Eugenio Maria De Hostos (Santo Domingo, Dominican Republic)",
  "Universidad Mundial Dominicana Escuela De Medicina (Santo Domingo, Dominican Republic)",
  "Universidad Nacional Pedro Henríquez Ureña (unphu) Escuela De Medicina (Santo Domingo, Dominican Republic)",
  "Universidad Tecnológica De Santiago Escuela De Medicina, Santiago Campus (Santiago De Los Caballeros, Dominican Republic)",
  "Universidad Tecnológica De Santiago Escuela De Medicina, Santo Domingo Campus (Santo Domingo, Dominican Republic)",
  "Western Atlantic University, School Of Medicine (Freeport, Bahamas)",
  "Ama International University Bahrain College Of Medicine (Salmabad, Bahrain)",
  "Arabian Gulf University College Of Medicine And Medical Sciences (Manama, Bahrain)",
  "Royal College Of Surgeons In Ireland - Medical University Of Bahrain (Busaiteen, Bahrain)",
  "American University Of Barbados School Of Medicine (St Michael, Barbados)",
  "American University Of Integrative Sciences School Of Medicine (St Michael, Barbados)",
  "Bridgetown International University School Of Medicine (St Michael, Barbados)",
  "International University Of Barbados School Of Medicine (Welches, Barbados)",
  "Queen's University College Of Medicine (St John, Barbados)",
  "Ross University School Of Medicine (St Michael, Barbados)",
  "University Of The West Indies At Cave Hill Faculty Of Medical Sciences (Cave Hill, Barbados)",
  "Victoria University Of Barbados School Of Medicine (St Michael, Barbados)",
  "Washington University Of Barbados School Of Medicine (St Philip, Barbados)",
  "Belarusian State Medical University (Minsk, Belarus)",
  "Gomel State Medical University (Gomel, Belarus)",
  "Grodno State Medical University (Grodno, Belarus)",
  "Vitebsk State Medical University (Vitebsk, Belarus)",
  "Katholieke Universiteit Leuven (kuleuven) Faculteit Geneeskunde (Leuven, Belgium)",
  "Universiteit Antwerpen Faculteit Geneeskunde En Gezondheidswetenschappen (Antwerpen, Belgium)",
  "Universiteit Gent Faculteit Geneeskunde En Gezondheidswetenschappen (Gent, Belgium)",
  "Université Catholique De Louvain Faculté De Médecine Et De Médecine Dentaire (Brussels, Belgium)",
  "Université De Liège Faculté De Médecine (Liege, Belgium)",
  "Université Libre De Bruxelles Faculté De Médecine (Brussels, Belgium)",
  "Vrije Universiteit Brussel Faculteit Geneeskunde & Farmacie (Brussels, Belgium)",
  "American Northwest University (Belmopan, Belize)",
  "Avicina Medical Academy (Belmopan, Belize)",
  "Central America Health Sciences University Belize Medical College (Ladyville, Belize)",
  "Columbus Central University School Of Medicine (Ladyville, Belize)",
  "Efhre International University (Belize City, Belize)",
  "Grace University School Of Medicine (belize) (Belmopan, Belize)",
  "Hope University School Of Medicine (Belmopan, Belize)",
  "Interamerican Medical University (Corozal, Belize)",
  "Medical University Of The Americas (belize) (Ambergris Caye, Belize)",
  "St Luke's University School Of Medicine (Belize City, Belize)",
  "St Matthew's University School Of Medicine (belize) (San Pedro, Belize)",
  "Washington University Of Health And Sciences (San Pedro, Belize)",
  "International University School Of Medicine (Kralendijk, Bonaire)",
  "American Northwest University School Of Medicine (Travnik, Bosnia And Herzegovina)",
  "International University Of Goražde Faculty Of Health Sciences (Gorazde, Bosnia And Herzegovina)",
  "Sarajevo Medical School, Sarajevo School Of Science And Technology (Sarajevo, Bosnia And Herzegovina)",
  "University Of Banja Luka Faculty Of Medicine (Banja Luka, Bosnia And Herzegovina)",
  "University Of East Sarajevo Faculty Of Medicine (Foca, Bosnia And Herzegovina)",
  "University Of Mostar Faculty Of Medicine (Mostar, Bosnia And Herzegovina)",
  "University Of Sarajevo Faculty Of Medicine (Sarajevo, Bosnia And Herzegovina)",
  "University Of Tuzla Faculty Of Medicine (Tuzla, Bosnia And Herzegovina)",
  "University Of Botswana School Of Medicine (Gaborone, Botswana)",
  "Academia Militar De Medicina Faculdade De Medicina (Rio De Janeiro, Brazil)",
  "Afya Faculdade De Ciências Médicas De Abaetetuba (Abaetetuba, Brazil)",
  "Afya Faculdade De Ciências Médicas De Bragança (Bragança, Brazil)",
  "Afya Faculdade De Ciências Médicas De Itacoatiara (Itacoatiara, Brazil)",
  "Afya Faculdade De Ciências Médicas De Manacapuru (Manacapuru, Brazil)",
  "Centro Universitario Facisa (unifacisa) (Campina Grande, Brazil)",
  "Centro Universitário Alfredo Nasser (Aparecida De Goiania, Brazil)",
  "Centro Universitário Aparício Carvalho (fimca) (Porto Velho, Brazil)",
  "Centro Universitário Atenas (uniatenas) (Paracatu, Brazil)",
  "Centro Universitário Barão De Mauá (cubm) (Ribeirão Preto, Brazil)",
  "Centro Universitário Campo Real (Guarapuava, Brazil)",
  "Centro Universitário Católico Salesiano Auxilium (unisalesiano), Campus Araçatuba (Araçatuba, Brazil)",
  "Centro Universitário Cesmac (Maceio, Brazil)",
  "Centro Universitário Ceuni (fametro) (Manaus, Brazil)",
  "Centro Universitário Christus (unichristus) (Fortaleza, Brazil)",
  "Centro Universitário Claretiano (ceuclar) Rio Claro (Rio Claro, Brazil)",
  "Centro Universitário Das Américas (cam) (São Paulo, Brazil)",
  "Centro Universitário Das Faculdades Associadas De Ensino (São João Da Boa Vista, Brazil)",
  "Centro Universitário De Adamantina (unifai) (Adamantina, Brazil)",
  "Centro Universitário De Anápolis (unievangélica) (Anapolis, Brazil)",
  "Centro Universitário De Belo Horizonte (uni-bh) (Belo Horizonte, Brazil)",
  "Centro Universitário De Brasília (uniceub) Faculdade De Ciências Da Educação E Saúde (Brasilia, Brazil)",
  "Centro Universitário De Caratinga (unec) (Caratinga, Brazil)",
  "Centro Universitário De Excelência (unex) Feira De Santana (Feira De Santana, Brazil)",
  "Centro Universitário De Goiatuba (unicerrado) (Goiatuba, Brazil)",
  "Centro Universitário De Jaguariúna (unifaj) (Jaguariúna, Brazil)",
  "Centro Universitário De João Pessoa (unipê) (João Pessoa, Brazil)",
  "Centro Universitário De Maringá (unicesumar) (Maringa, Brazil)",
  "Centro Universitário De Mineiros (unifimes) Câmpus Mineiros (Mineiros, Brazil)",
  "Centro Universitário De Mineiros (unifimes) Câmpus Trindade (Trindade, Brazil)",
  "Centro Universitário De Pato Branco (unidep) (Pato Branco, Brazil)",
  "Centro Universitário De Patos (Patos, Brazil)",
  "Centro Universitário De Patos De Minas (unipam) (Patos De Minas, Brazil)",
  "Centro Universitário De Santa Fé Do Sul (unifunec) (Santa Fé Do Sul, Brazil)",
  "Centro Universitário De Valença (unifaa) (Valença, Brazil)",
  "Centro Universitário De Volta Redonda (unifoa) Escola De Ciências Médicas De Volta Redonda (Volta Redonda, Brazil)",
  "Centro Universitário De Votuporanga (unifev) (Votuporanga, Brazil)",
  "Centro Universitário De Várzea Grande (univag) (Varzea Grande, Brazil)",
  "Centro Universitário Do Espirito Santo (unesc) (Colatina, Brazil)",
  "Centro Universitário Do Estado Do Pará (cesupa) (Belém, Brazil)",
  "Centro Universitário Do Norte De Minas (ics/funorte) (Montes Claros, Brazil)",
  "Centro Universitário Do Planalto Central Apparecido Dos Santos (uniceplac) (Brasilia, Brazil)",
  "Centro Universitário Dom Pedro Ii (unidomperdo) (Salvador, Brazil)",
  "Centro Universitário Estácio De Ji-paraná (estácio Unijipa) (Ji-paraná, Brazil)",
  "Centro Universitário Estácio De Ribeirão Preto (Ribeirão Preto, Brazil)",
  "Centro Universitário Estácio Do Ceará Iguatu (Iguatu, Brazil)",
  "Centro Universitário Estácio Do Ceará Quixadá (Quixadá, Brazil)",
  "Centro Universitário Faminas, Muriaé (Muriaé, Brazil)",
  "Centro Universitário Governador Ozanam Coelho (unifagoc) (Uba, Brazil)",
  "Centro Universitário Ingá (uningá) (Maringa, Brazil)",
  "Centro Universitário Inta (uninta) (Sobral, Brazil)",
  "Centro Universitário Inta Itapipoca (Itapipoca, Brazil)",
  "Centro Universitário Integrado (Campo Mourão, Brazil)",
  "Centro Universitário Lusíada Faculdade De Ciências Médicas De Santos (Santos, Brazil)",
  "Centro Universitário Maceió (Maceio, Brazil)",
  "Centro Universitário Mauricio De Nassau (uninassau) (Recife, Brazil)",
  "Centro Universitário Maurício De Nassau De Cacoal (Cacoal, Brazil)",
  "Centro Universitário Max Planck (unimax) (Indaiatuba, Brazil)",
  "Centro Universitário Municipal De Franca (uni-facef) (Franca, Brazil)",
  "Centro Universitário Nilton Lins (uniniltonlins) (Manaus, Brazil)",
  "Centro Universitário Padre Albino (unifipa) (Catanduva, Brazil)",
  "Centro Universitário Para O Desenvolvimento Do Alto Vale Do Itajaí (unidavi) (Rio Do Sul, Brazil)",
  "Centro Universitário Serra Dos Órgãos (unifeso) Centro De Ciências Da Saúde (Teresopolis, Brazil)",
  "Centro Universitário São Camilo, São Paulo (scamilo) (São Paulo, Brazil)",
  "Centro Universitário São Lucas (Porto Velho, Brazil)",
  "Centro Universitário Tocantinense Presidente Antônio Carlos (unitpac) (Araguaina, Brazil)",
  "Centro Universitário Unidade De Ensino Superior Dom Bosco (São Luís, Brazil)",
  "Centro Universitário Unieuro, Campus Asa Sul (Brasília, Brazil)",
  "Centro Universitário Unifacig (Manhuaçu, Brazil)",
  "Centro Universitário Unifas (Lauro De Freitas, Brazil)",
  "Centro Universitário Unifg (Guanambi, Brazil)",
  "Centro Universitário Uninorte Faculdade De Medicina Rio Branco (Rio Branco, Brazil)",
  "Centro Universitário Uninovafapi Faculdade De Saúde, Ciências Humanas E Tecnologia Do Piauí (Teresina, Brazil)",
  "Centro Universitário Uniredentor (Itaperuna, Brazil)",
  "Centro Universitário Vértice (Matipó, Brazil)",
  "Escola Bahiana De Medicina E Saúde Pública (ebmsp) (Salvador, Brazil)",
  "Escola De Medicina Anhembi Morumbi (uam) (São Paulo, Brazil)",
  "Escola De Medicina Souza Marques (emsm), Fundação Tecnico Educação Souza Marques (Rio De Janeiro, Brazil)",
  "Escola Superior De Ciências Da Santa Casa De Misericórdia De Vitória Faculdade De Medicina (Vitoria, Brazil)",
  "Escola Superior De Ciências Da Saúde (escs) (Brasilia, Brazil)",
  "Faculdada De Educação De Jaru (fimca Unicentro) (Jaru, Brazil)",
  "Faculdade Ages De Medicina (Jacobina, Brazil)",
  "Faculdade Ages De Medicina De Irecê (Irecê, Brazil)",
  "Faculdade Assis Gurgacz (Cascavel, Brazil)",
  "Faculdade Atenas De Passos (Passos, Brazil)",
  "Faculdade Atenas De Sete Algoas (Sete Lagoas, Brazil)",
  "Faculdade Atenas Sorriso (Sorriso, Brazil)",
  "Faculdade Atenas Valença (Valença, Brazil)",
  "Faculdade Brasileira Multivix Cachoeiro De Itapemirim (Cachoeiro De Itapemirim, Brazil)",
  "Faculdade Brasileira Multivix Vitória (Vitória, Brazil)",
  "Faculdade Ceres (faceres) (São José Do Rio Preto, Brazil)",
  "Faculdade Da Saúde E Ecologia Humana (faseh) (Vespasiano, Brazil)",
  "Faculdade De Ciências Da Saúde De Barretos Dr Paulo Prata (facisb) (Barretos, Brazil)",
  "Faculdade De Ciências Da Saúde Pitágoras De Codó (Codó, Brazil)",
  "Faculdade De Ciências Humanas, Exatas E Da Saúde Do Piauí (fahesp/iesvap) (Parnaíba, Brazil)",
  "Faculdade De Ciências Médicas Da Paraíba (fcmpb) (Joao Pessoa, Brazil)",
  "Faculdade De Ciências Médicas Da Santa Casa De São Paulo (fcmscsp) (São Paulo, Brazil)",
  "Faculdade De Ciências Médicas De Minas Gerais (fcmmg) (Belo Horizonte, Brazil)",
  "Faculdade De Ciências Médicas De São José Dos Campos (humanitas) (São José Dos Campos, Brazil)",
  "Faculdade De Ciências Médicas De Três Rios (Rio De Janeiro, Brazil)",
  "Faculdade De Medicina E Cirurgia De Pernambuco (Recife, Brazil)",
  "Faculdade De Medicina Estácio De Alagoinhas (Alagoinhas, Brazil)",
  "Faculdade De Medicina Estácio De Castanhal (fmec) (Castanhal, Brazil)",
  "Faculdade De Medicina Estácio De Jaraguá Do Sul (Jaraguá Do Sul, Brazil)",
  "Faculdade De Medicina Estácio De Juazeiro (Juazeiro, Brazil)",
  "Faculdade De Medicina Estácio De Juazeiro Do Norte (estácio Fmj) (Juazeiro Do Norte, Brazil)",
  "Faculdade De Medicina Nova Esperança (famene) (Joao Pessoa, Brazil)",
  "Faculdade De Medicina Pitágoras De Eunápolis (Eunápolis​, Brazil)",
  "Faculdade De Medicina Santo Agostinho De Itabuna (fasa) (Itabuna, Brazil)",
  "Faculdade De Minas, Belo Horizonte (faminas-bh) (Belo Horizonte, Brazil)",
  "Faculdade De Tecnologica E Ciências (ftc Salvador) Curso De Medicina (Salvador, Brazil)",
  "Faculdade Dinâmica Do Vale Do Piranga (fadip) (Ponte Nova, Brazil)",
  "Faculdade Estácio De Canindé (Canindé, Brazil)",
  "Faculdade Estácio Do Pantanal (Cáceres, Brazil)",
  "Faculdade Evangélica Mackenzie Do Paraná Faculdade De Medicina (Curitiba, Brazil)",
  "Faculdade Integrada Tiradentes, Universidade Tiradentes Aracaju (Aracaju, Brazil)",
  "Faculdade Integrada Tiredentes, Universidade Tiradentes Estância (Estância, Brazil)",
  "Faculdade Integral Diferencial, Piaui (facid) (Teresina, Brazil)",
  "Faculdade Israelita De Ciências Da Saúde Albert Einstein (ficsae) (São Paulo, Brazil)",
  "Faculdade Itpac Santa Inês (Santa Inês, Brazil)",
  "Faculdade Meridional (imed) Escola De Saúde (Passo Fundo, Brazil)",
  "Faculdade Metropolitana (unnesa) (Porto Velho, Brazil)",
  "Faculdade Metropolitana Da Amazônia (famaz) (Belém, Brazil)",
  "Faculdade Metropolitana São Carlos (famesc) (Bom Jesus Do Itabapoana, Brazil)",
  "Faculdade Morgana Potrich (famp) (Mineiros, Brazil)",
  "Faculdade Nova Esperança De Mossoró (Mossoró, Brazil)",
  "Faculdade Paraíso Araripina (fap) (Araripina, Brazil)",
  "Faculdade Pernambucana De Saúde (fps) (Recife, Brazil)",
  "Faculdade Pitágoras De Bacabal (Bacabal, Brazil)",
  "Faculdade Presidente Antonio Carlos (fapac) (Porto Nacional, Brazil)",
  "Faculdade Santa Marcelina (fasm) (São Paulo, Brazil)",
  "Faculdade Santa Maria (fsm) (Cajazeiras, Brazil)",
  "Faculdade São Leopoldo Mandic, Araras (Araras, Brazil)",
  "Faculdade São Leopoldo Mandic, Campinas (Campinas, Brazil)",
  "Faculdade Tiradentes De Goiana (fits) (Goiana, Brazil)",
  "Faculdade Tiradentes De Jaboatão Dos Guararapes (fits) (Jaboatão Dos Guararapes, Brazil)",
  "Faculdade Unicesumar De Corumbá (Corumbá, Brazil)",
  "Faculdades De Dracena (unifadra) (Dracena, Brazil)",
  "Faculdades Integradas Do Extremo Sul Da Bahia (Eunápolis, Brazil)",
  "Faculdades Integradas Padrão (fip Guanambi) (Guanambi, Brazil)",
  "Faculdades Integradas Pitágoras (fip-moc) (Montes Claros, Brazil)",
  "Faculdades Pequeno Principe (fpp) (Curitiba, Brazil)",
  "Faculdades Santo Agostinho, Vitória Da Conquista (Vitória Da Conquista, Brazil)",
  "Fundação Educacional Do Município De Assis (Assis, Brazil)",
  "Fundação Universidade Federal De Rondônia (unir) Núcleo De Saúde (Porto Velho, Brazil)",
  "Fundação Universidade Norte Mineira De Ensino Superior Faculdade De Medicina Do Norte De Minas (Montes Claros, Brazil)",
  "Instituto De Ensino Superior Presidente Tancredo De Almeida Neves (iptan) (São João Del Rei, Brazil)",
  "Instituto Master De Ensino Presidente Antônio Carlos (imepac) (Araguari, Brazil)",
  "Instituto Metropolitano De Ensino Superior (imes) (Ipatinga, Brazil)",
  "Instituto Tocantinense Presidente Antonio Carlos (itpac) Palmas (Palmas, Brazil)",
  "Itpac Cruzeiro Do Sul (Cruzeiro Do Sul, Brazil)",
  "Pontifícia Universidade Católica De Campinas (puc-campinas) Faculdade De Ciências Médicas (Campinas, Brazil)",
  "Pontifícia Universidade Católica De Goiás (puc Goiás) (Goiania, Brazil)",
  "Pontifícia Universidade Católica De Minas Gerais (puc Minas) Betim (Betim, Brazil)",
  "Pontifícia Universidade Católica De Minas Gerais (puc Minas) Contagem (Contagem, Brazil)",
  "Pontifícia Universidade Católica De Minas Gerais (puc Minas) Poços De Caldas (Poços De Caldas, Brazil)",
  "Pontifícia Universidade Católica De São Paulo (pucsp) Faculdade De Medicina (Sorocaba, Brazil)",
  "Pontifícia Universidade Católica Do Paraná Escola De Medicina, Câmpus Curitiba (Curitiba, Brazil)",
  "Pontifícia Universidade Católica Do Paraná Escola De Medicina, Câmpus Londrina (Londrina, Brazil)",
  "Pontifícia Universidade Católica Do Rio Grande Do Sul (pucrs) Faculdade De Medicina (Porto Alegre, Brazil)",
  "Sociedade Educacional Paulo Vi Faculdade De Medicina (Campo Grande, Brazil)",
  "Universidade Alto Vale Do Rio Do Peixe (uniarp) (Caçador, Brazil)",
  "Universidade Anhanguera (uniderp) (Campo Grande, Brazil)",
  "Universidade Anhembi-morumbi (uam) Piracicaba (Piracicaba, Brazil)",
  "Universidade Anhembi-morumbi (uam) São José Dos Campos (São José Dos Campos, Brazil)",
  "Universidade Brasil (Fernandopolis, Brazil)",
  "Universidade Católica De Brasília (ucb) (Taguatinga, Brazil)",
  "Universidade Católica De Pelotas (ucpel) Faculdade De Medicina (Pelotas, Brazil)",
  "Universidade Católica De Pernambuco (unicap) (Recife, Brazil)",
  "Universidade Ceuma, Campus Imperatriz (Imperatriz, Brazil)",
  "Universidade Ceuma, Campus Renascença São Luís (São Luís, Brazil)",
  "Universidade Cidade De São Paulo (unicid) Faculdade De Medicina (São Paulo, Brazil)",
  "Universidade Comunitária Da Região De Chapecó (unochapecó) (Chapeco, Brazil)",
  "Universidade Da Região De Joinville (univille) (Joinville, Brazil)",
  "Universidade De Araraquara (uniara) (Araraquara, Brazil)",
  "Universidade De Brasília (unb) Faculdade De Medicina (Brasilia, Brazil)",
  "Universidade De Brusque (unifebe) (Brusque, Brazil)",
  "Universidade De Caxias Do Sul (ucs) Faculdade De Medicina (Caxias Do Sul, Brazil)",
  "Universidade De Cuiabá (unic) Faculdade De Medicina (Cuiaba, Brazil)",
  "Universidade De Fortaleza (unifor) (Fortaleza, Brazil)",
  "Universidade De Franca (unifran) (Franca, Brazil)",
  "Universidade De Gurupi (unirg) (Gurupi, Brazil)",
  "Universidade De Gurupi Campus Paraíso (unirg) (Paraíso Do Tocantins, Brazil)",
  "Universidade De Itaúna (uit) (Itauna, Brazil)",
  "Universidade De Marília (unimar) Faculdade De Medicina E Enfermagem (Marilia, Brazil)",
  "Universidade De Mogi Das Cruzes (umc) Faculdade De Medicina (Mogi Das Cruzes, Brazil)",
  "Universidade De Passo Fundo (upf) Faculdade De Medicina (Passo Fundo, Brazil)",
  "Universidade De Pernambuco (upe), Campus Garanhuns (Garanhuns, Brazil)",
  "Universidade De Pernambuco (upe), Campus Santo Amaro (Recife, Brazil)",
  "Universidade De Pernambuco (upe), Campus Serra Talhada (Serra Talhada, Brazil)",
  "Universidade De Ribeirão Preto Faculdade De Medicina Guarujá (Guarujá, Brazil)",
  "Universidade De Ribeirão Preto Faculdade De Medicina Ribeirão Preto (Ribeirão Preto, Brazil)",
  "Universidade De Rio Verde (unirv), Campus Aparecida De Goiânia (Aparecida De Goiania, Brazil)",
  "Universidade De Rio Verde (unirv), Campus Formosa (Formosa, Brazil)",
  "Universidade De Rio Verde (unirv), Campus Goianésia (Goianésia, Brazil)",
  "Universidade De Rio Verde (unirv), Campus Rio Verde (Rio Verde, Brazil)",
  "Universidade De Santa Cruz Do Sul (unisc) (Santa Cruz Do Sul, Brazil)",
  "Universidade De Santo Amaro (unisa) Faculdade De Medicina (São Paulo, Brazil)",
  "Universidade De São Paulo Faculdade De Medicina São Paulo (fmsp-usp) (Sao Paulo, Brazil)",
  "Universidade De São Paulo Faculdade De Odontologia De Bauru (Bauru, Brazil)",
  "Universidade De Taubaté (unitau) Faculdade De Medicina (Taubate, Brazil)",
  "Universidade De Uberaba (uniube) Faculdade De Medicina (Uberaba, Brazil)",
  "Universidade De Vassouras Faculdade De Medicina (Centro Vassouras, Brazil)",
  "Universidade Do Contestado (unc) (Mafra, Brazil)",
  "Universidade Do Estado Da Bahia (uneb) (Salvador, Brazil)",
  "Universidade Do Estado De Mato Grosso (unemat) (Caceres, Brazil)",
  "Universidade Do Estado De Minas Gerais (uemg) (Passos, Brazil)",
  "Universidade Do Estado Do Amazonas (uea) Escola Superior De Ciências Da Saúde (Manaus, Brazil)",
  "Universidade Do Estado Do Pará (uepa) Faculdade De Medicina Marabá (Marabá, Brazil)",
  "Universidade Do Estado Do Pará Faculdade De Medicina Belém (Belém, Brazil)",
  "Universidade Do Estado Do Pará Faculdade De Medicina Santarém (Santarem, Brazil)",
  "Universidade Do Estado Do Rio De Janeiro (uerj) Faculdade De Ciências Médicas (Rio De Janeiro, Brazil)",
  "Universidade Do Estado Do Rio Grande Do Norte (uern) Faculdade De Ciências Da Saúde (Mossoro, Brazil)",
  "Universidade Do Extremo Sul Catarinense (unesc/rct) (Criciuma, Brazil)",
  "Universidade Do Oeste De Santa Catarina (unoesc) (Joacaba, Brazil)",
  "Universidade Do Oeste Paulista (unoeste) Guarujá (Guarujá, Brazil)",
  "Universidade Do Oeste Paulista (unoeste) Jaú (Jaú, Brazil)",
  "Universidade Do Oeste Paulista (unoeste) Presidente Prudente (Presidente Prudente, Brazil)",
  "Universidade Do Planalto Catarinense (uniplac) (Lages, Brazil)",
  "Universidade Do Sul De Santa Catarina (unisul) Palhoça (Palhoca, Brazil)",
  "Universidade Do Sul De Santa Catarina (unisul) Tubarão (Tubarao, Brazil)",
  "Universidade Do Vale Do Itajaí (univali) (Itajai, Brazil)",
  "Universidade Do Vale Do Rio Dos Sinos (unisinos) Escola De Salud (São Leopoldo, Brazil)",
  "Universidade Do Vale Do Taquari (univates) Centro De Ciências Biológicas E Da Saúde (Lajeado, Brazil)",
  "Universidade Estadual Da Região Tocantina Do Maranhão (uemasul) (Imperatriz, Brazil)",
  "Universidade Estadual De Campinas (unicamp) Faculdade De Ciências Médicas (Campinas, Brazil)",
  "Universidade Estadual De Ciências Da Saúde De Alagoas (uncisal) Faculdade De Medicina (Maceio, Brazil)",
  "Universidade Estadual De Feira De Santana (uefs) Colegiado Do Curso De Medicina (Feira De Santana, Brazil)",
  "Universidade Estadual De Goiás, Unidade Universitária De Itumbiara, Câmpus Sudeste (Itumbiara, Brazil)",
  "Universidade Estadual De Londrina (uel) Faculdade De Medicina (Londrina, Brazil)",
  "Universidade Estadual De Maringá (uem) Centro De Ciências Da Saúde (Maringa, Brazil)",
  "Universidade Estadual De Mato Grosso Do Sul (uems) (Campo Grande, Brazil)",
  "Universidade Estadual De Montes Claros (unimontes) Centro De Ciências Biológicas E Da Saúde (Montes Claros, Brazil)",
  "Universidade Estadual De Ponta Grossa (uepg) Faculdade De Medicina (Ponta Grossa, Brazil)",
  "Universidade Estadual De Roraima (uerr) (Boa Vista, Brazil)",
  "Universidade Estadual De Santa Cruz (uesc) (Ilheus, Brazil)",
  "Universidade Estadual Do Ceará (uece) (Fortaleza, Brazil)",
  "Universidade Estadual Do Maranhão (uema) (Caxias, Brazil)",
  "Universidade Estadual Do Oeste Do Paraná (unioeste) Campus De Cascavel (Cascavel, Brazil)",
  "Universidade Estadual Do Oeste Do Paraná (unioeste) Campus De Francisco Beltrão (Francisco Beltrao, Brazil)",
  "Universidade Estadual Do Piauí (uespi) Faculdade De Ciências Médicas (facime) (Teresina, Brazil)",
  "Universidade Estadual Do Sudoeste Da Bahia (uesb) Colegiado De Medicina (Vitoria Da Conquista, Brazil)",
  "Universidade Estadual Do Sudoeste Da Bahia (uesb), Jequié (Jequie, Brazil)",
  "Universidade Estadual Do Tocantins (unitins) (Augustinópolis, Brazil)",
  "Universidade Estadual Paulista 'júlio De Mesquita Filho' (unesp) Faculdade De Medicina (Botucatu, Brazil)",
  "Universidade Estácio De Sá, Campus Angra Dos Reis (Angra Dos Reis, Brazil)",
  "Universidade Estácio De Sá, Campus Città (Rio De Janeiro, Brazil)",
  "Universidade Estácio De Sá, Campus Vista Carioca (Rio De Janeiro, Brazil)",
  "Universidade Federal Da Bahia Instituto Multidisciplinar Em Saúde, Campus Anísio Teixeira (Vitória Da Conquista, Brazil)",
  "Universidade Federal Da Fronteira Sul (uffs), Campus Chapecó (Chapecó, Brazil)",
  "Universidade Federal Da Fronteira Sul (uffs), Campus Passo Fundo (Passo Fundo, Brazil)",
  "Universidade Federal Da Grande Dourados (ufgd) Faculdade De Ciências Da Saúde (Dourados, Brazil)",
  "Universidade Federal Da Integração Latino-americana (unila) (Foz Do Iguacu, Brazil)",
  "Universidade Federal Da Paraíba (ufpb) Centro De Ciências Médicas (Joao Pessoa, Brazil)",
  "Universidade Federal De Alagoas (ufal) Faculdade De Medicina (Maceió, Brazil)",
  "Universidade Federal De Alagoas, Campus Arapiraca (Arapiraca, Brazil)",
  "Universidade Federal De Alfenas (unifal) (Alfenas, Brazil)",
  "Universidade Federal De Campina Grande (ufcg) Faculdade De Medicina Cajazeiras (Cajazeiras, Brazil)",
  "Universidade Federal De Campina Grande (ufcg) Faculdade De Medicina Campina Grande (Campina Grande, Brazil)",
  "Universidade Federal De Catalão (Catalão, Brazil)",
  "Universidade Federal De Ciências Da Saúde De Porto Alegre (ufcspa) Faculdade De Medicina (Porto Alegre, Brazil)",
  "Universidade Federal De Goiás (ufg) Faculdade De Medicina Goiânia (Goiania, Brazil)",
  "Universidade Federal De Jataí (Jataí, Brazil)",
  "Universidade Federal De Juiz De Fora (ufjf) Faculdade De Medicina Governador Valadares (Governador Valadares, Brazil)",
  "Universidade Federal De Juiz De Fora (ufjf) Faculdade De Medicina Juiz De Fora (Juiz De Fora, Brazil)",
  "Universidade Federal De Lavras (ufla) (Lavras, Brazil)",
  "Universidade Federal De Mato Grosso (ufmt), Campus Cuiabá (Cuiaba, Brazil)",
  "Universidade Federal De Mato Grosso (ufmt), Campus Sinop (Sinop, Brazil)",
  "Universidade Federal De Mato Grosso Do Sul (ufms) Faculdade De Medicina Campo Grande (Campo Grande, Brazil)",
  "Universidade Federal De Mato Grosso Do Sul (ufms) Faculdade De Medicina Três Lagoas (Tres Lagoas, Brazil)",
  "Universidade Federal De Minas Gerais (ufmg) Faculdade De Medicina (Belo Horizonte, Brazil)",
  "Universidade Federal De Ouro Preto (ufop) (Ouro Preto, Brazil)",
  "Universidade Federal De Pelotas (ufpel) Faculdade De Medicina (Pelotas, Brazil)",
  "Universidade Federal De Pernambuco (ufpe) Faculdade De Medicina Caruaru (Caruaru, Brazil)",
  "Universidade Federal De Pernambuco (ufpe) Faculdade De Medicina Recife (Recife, Brazil)",
  "Universidade Federal De Piauí (ufpi) Faculdade De Medicina (Teresina, Brazil)",
  "Universidade Federal De Rondonópolis (Rondonopolis, Brazil)",
  "Universidade Federal De Roraima (ufrr) Faculdade De Medicina (Boa Vista, Brazil)",
  "Universidade Federal De Santa Catarina (ufsc) Araranguá (Araranguá, Brazil)",
  "Universidade Federal De Santa Catarina (ufsc) Florianópolis (Florianópolis, Brazil)",
  "Universidade Federal De Santa Maria (ufsm) Faculdade De Medicina (Santa Maria, Brazil)",
  "Universidade Federal De Sergipe (ufs) Campus Aracaju (Aracaju, Brazil)",
  "Universidade Federal De Sergipe (ufs) Campus Lagarto (Lagarto, Brazil)",
  "Universidade Federal De São Carlos (ufscar) (São Carlos, Brazil)",
  "Universidade Federal De São João Del Rei (ufsj), Campus Centro-oeste Dona Lindu (Divinopolis, Brazil)",
  "Universidade Federal De São João Del Rei (ufsj), Campus Dom Bosco (São João Del Rei, Brazil)",
  "Universidade Federal De São Paulo (unifesp) Faculdade De Medicina (Sao Paulo, Brazil)",
  "Universidade Federal De Uberlândia (ufu) Faculdade De Medicina (Uberlandia, Brazil)",
  "Universidade Federal De Viçosa (ufv) (Vicosa, Brazil)",
  "Universidade Federal Do Acre (ufac) (Rio Branco, Brazil)",
  "Universidade Federal Do Amapá (unifap) (Macapa, Brazil)",
  "Universidade Federal Do Amazonas (ufam) Faculdade De Medicina Coari (Coari, Brazil)",
  "Universidade Federal Do Amazonas (ufam) Faculdade De Medicina Manaus (Manaus, Brazil)",
  "Universidade Federal Do Cariri Faculdade De Medicina (Barbalha, Brazil)",
  "Universidade Federal Do Ceará Faculdade De Medicina Fortaleza (Fortaleza, Brazil)",
  "Universidade Federal Do Ceará Faculdade De Medicina Sobral (Sobral, Brazil)",
  "Universidade Federal Do Delta Do Parnaíba (ufdpar) (Parnaiba, Brazil)",
  "Universidade Federal Do Espírito Santo (ufes) Faculdade De Medicina (Vitoria, Brazil)",
  "Universidade Federal Do Estado Do Rio De Janeiro (unirio) Escola De Medicina E Cirurgia (Rio De Janeiro, Brazil)",
  "Universidade Federal Do Maranhão (ufma) Faculdade De Medicina Imperatriz (Imperatiz, Brazil)",
  "Universidade Federal Do Maranhão (ufma) Faculdade De Medicina Pinheiro (Pinheiro, Brazil)",
  "Universidade Federal Do Maranhão (ufma) Faculdade De Medicina São Luís (São Luís, Brazil)",
  "Universidade Federal Do Oeste Da Bahia (ufob) Centro Das Ciências Biológicas E Da Saúde (Barreiras, Brazil)",
  "Universidade Federal Do Pampa (Uruguaiana, Brazil)",
  "Universidade Federal Do Paraná Faculdade De Medicina Curitiba (Curitiba, Brazil)",
  "Universidade Federal Do Paraná Faculdade De Medicina Toledo (Toledo, Brazil)",
  "Universidade Federal Do Pará Faculdade De Medicina Altamira (Altamira, Brazil)",
  "Universidade Federal Do Pará Faculdade De Medicina Belém (Belém, Brazil)",
  "Universidade Federal Do Piauí (ufpi) Campus Senador Elvídio Nuñes De Barros (Picos, Brazil)",
  "Universidade Federal Do Recôncavo Da Bahia (ufrb) Centro De Ciências Da Saúde (Santo Antonio De Jesus, Brazil)",
  "Universidade Federal Do Rio De Janeiro (ufrj) Faculdade De Medicina (Rio De Janeiro, Brazil)",
  "Universidade Federal Do Rio De Janeiro (ufrj) Macaé Faculdade De Medicina (Macae, Brazil)",
  "Universidade Federal Do Rio Grande (furg) Faculdade De Medicina (Rio Grande, Brazil)",
  "Universidade Federal Do Rio Grande Do Norte (ufrn) Faculdade De Medicina Caicó (Caico, Brazil)",
  "Universidade Federal Do Rio Grande Do Norte (ufrn) Faculdade De Medicina Natal (Natal, Brazil)",
  "Universidade Federal Do Rio Grande Do Sul (ufrgs) Faculdade De Medicina (Porto Alegre, Brazil)",
  "Universidade Federal Do Sul Da Bahia (ufsb) Centro De Formação Em Ciências Da Saúde (Teixeira De Freitas, Brazil)",
  "Universidade Federal Do Tocantins (uft) (Palmas, Brazil)",
  "Universidade Federal Do Triângulo Mineiro, Uberaba (uftm) Faculdade De Medicina (Uberaba, Brazil)",
  "Universidade Federal Do Vale Do São Francisco (univasf), Campus Sede (Petrolina, Brazil)",
  "Universidade Federal Do Vale Do São Francisco, Campus Paulo Afonso (Paulo Afonso, Brazil)",
  "Universidade Federal Dos Vales Do Jequitinhonha E Mucuri Faculdade De Medicina Diamantina (Diamantina, Brazil)",
  "Universidade Federal Dos Vales Do Jequitinhonha E Mucuri Faculdade De Medicina Teófilo Otoni (Teofilo Otoni, Brazil)",
  "Universidade Federal Fluminense (uff) Faculdade De Medicina (Niterói, Brazil)",
  "Universidade Federal Rural Do Semi-árido (Mossoró, Brazil)",
  "Universidade Feevale Novo Hamburgo (Novo Hamburgo, Brazil)",
  "Universidade Franciscana (ufn) (Santa Maria, Brazil)",
  "Universidade Gama Filho (ugf) Faculdade De Medicina (Piedade, Brazil)",
  "Universidade Iguaçu (unig) Faculdade De Ciências Biológicas E Da Saúde Itaperuna (Itaperuna, Brazil)",
  "Universidade Iguaçu (unig) Faculdade De Ciências Biológicas E Da Saúde Nova Iguaçu (Nova Iguacu, Brazil)",
  "Universidade José Do Rosário Vellano (unifenas) Faculdade De Medicina Alfenas (Alfenas, Brazil)",
  "Universidade José Do Rosário Vellano (unifenas) Faculdade De Medicina Belo Horizonte (Belo Horizonte, Brazil)",
  "Universidade Luterana Do Brasil (ulbra) Faculdade De Medicina (Canoas, Brazil)",
  "Universidade Metropolitana De Santos (unimes) Faculdade De Ciências Médicas (Santos, Brazil)",
  "Universidade Municipal De São Caetano Do Sul Escola De Saúde, Campus Centro Scs (São Caetano Do Sul, Brazil)",
  "Universidade Municipal De São Caetano Do Sul Escola De Saúde, São Paulo (Bela Vista, Brazil)",
  "Universidade Nove De Julho (uninove) Campus Guarulhos (Guarulhos, Brazil)",
  "Universidade Nove De Julho (uninove) Campus Mauá (Mauá, Brazil)",
  "Universidade Nove De Julho (uninove) Campus Osasco (Osasco, Brazil)",
  "Universidade Nove De Julho (uninove) Campus São Bernardo Do Campo (São Bernardo Do Campo, Brazil)",
  "Universidade Nove De Julho (uninove) Campus Vergueiro (São Paulo, Brazil)",
  "Universidade Nove De Julho (uninove), Campus Bauru (Bauru, Brazil)",
  "Universidade Paranaense (unipar) Umuarama (Umuarama, Brazil)",
  "Universidade Positivo (up) (Curitiba, Brazil)",
  "Universidade Potiguar (unp) Escola De Saúde (Natal, Brazil)",
  "Universidade Presidente Antônio Carlos Faculdade De Medicina De Juiz De Fora (Juiz De Fora, Brazil)",
  "Universidade Regional De Blumenau (furb) Faculdade De Medicina (Blumenau, Brazil)",
  "Universidade Regional Do Noroeste Do Estado Do Rio Grande Do Sul (unijuí) (Ijuí, Brazil)",
  "Universidade Regional Integrada Do Alto Uruguai E Das Missões (Erechim, Brazil)",
  "Universidade Salvador (unifacs) (Salvador, Brazil)",
  "Universidade São Francisco (usf) Faculdade De Ciências Médicas (Braganca Paulista, Brazil)",
  "Universidade São Judas Tadeu (usjt) Cubatão (Cubatão, Brazil)",
  "Universidade Unigranrio Escola De Ciências Da Saúde, Campus Duque De Caxias (Duque De Caxias, Brazil)",
  "Universidade Unigranrio Escola De Ciências Da Saúde, Unidade Barra Da Tijuca (Rio De Janeiro, Brazil)",
  "Universidade Vale Do Rio Doce (Governador Valadares, Brazil)",
  "Universidade Vale Do Rio Verde (unincor) (Belo Horizonte, Brazil)",
  "Universidade Vila Velha (uvv) (Vila Velha, Brazil)",
  "Universitário São Lucas Ji-paraná (Ji-paraná, Brazil)",
  "Univás Faculdade De Ciências Da Saúde Dr José Antônio Garcia Coutinho (Pouso Alegre, Brazil)",
  "União Das Faculdades Dos Grandes Lagos (São José Do Rio Preto, Brazil)",
  "Medical University 'prof Dr Paraskev Stoyanov' Varna Faculty Of Medicine (Varna, Bulgaria)",
  "Medical University Of Plovdiv Faculty Of Medicine (Plovdiv, Bulgaria)",
  "Medical University Of Sofia Faculty Of Medicine (Sofia, Bulgaria)",
  "Pleven Medical University (Pleven, Bulgaria)",
  "Sofia University St Kliment Ohridski Faculty Of Medicine (Sofia, Bulgaria)",
  "Trakia University Faculty Of Medicine (Stara Zagora, Bulgaria)",
  "Université Joseph Ki-zerbo L'unité De Formation Et De Recherche En Sciences De La Santé (Ouagadougou, Burkina Faso)",
  "Faculté De Médecine De Bujumbura, Université Du Burundi (Bujumbura, Burundi)",
  "Frank Ogden School Of Medicine At Hope Africa University (Bujumbura, Burundi)",
  "Université De Ngozi Faculté De Médecine (Ngozi, Burundi)",
  "Universidad Amazónica De Pando Área De Ciencias De La Salud (Cobija, Bolivia (plurinational State Of))",
  "Universidad Autónoma Del Beni 'josé Ballivián' Facultad Ciencias De La Salud (Trinidad, Bolivia (plurinational State Of))",
  "Universidad Autónoma Gabriel René Moreno Facultad De Ciencias De La Salud Humana (Santa Cruz De La Sierra, Bolivia (plurinational State Of))",
  "Universidad Autónoma Gabriel René Moreno Facultad Integral Del Norte (Montero, Bolivia (plurinational State Of))",
  "Universidad Autónoma Juan Misael Saracho Facultad De Ciencias De La Salud (Tarija, Bolivia (plurinational State Of))",
  "Universidad Autónoma Tomás Frías Facultad De Medicina (Potosi, Bolivia (plurinational State Of))",
  "Universidad Boliviana De Informática (Sucre, Bolivia (plurinational State Of))",
  "Universidad Católica Boliviana San Pablo Facultad De Medicina (Santa Cruz, Bolivia (plurinational State Of))",
  "Universidad Central (unicen) Sede Central (Cochabamba, Bolivia (plurinational State Of))",
  "Universidad Central (unicen) Subsede La Paz (La Paz, Bolivia (plurinational State Of))",
  "Universidad Cristiana De Bolivia (ucebol) (Santa Cruz, Bolivia (plurinational State Of))",
  "Universidad De Aquino Bolivia Facultad De Ciencias De La Salud, Sede Cochabamba (Cochabamba, Bolivia (plurinational State Of))",
  "Universidad De Aquino Bolivia Facultad De Ciencias De La Salud, Sede La Paz (La Paz, Bolivia (plurinational State Of))",
  "Universidad De Aquino Bolivia Facultad De Ciencias De La Salud, Sede Oruro (Oruro, Bolivia (plurinational State Of))",
  "Universidad De Aquino Bolivia Facultad De Ciencias De La Salud, Sede Santa Cruz (Santa Cruz, Bolivia (plurinational State Of))",
  "Universidad Del Altiplano Facultad De Medicina (Oruro, Bolivia (plurinational State Of))",
  "Universidad Iberoamericana, La Paz Facultad De Medicina (La Paz, Bolivia (plurinational State Of))",
  "Universidad Latinoamericana Facultad En Ciencias De La Salud (Cochabamba, Bolivia (plurinational State Of))",
  "Universidad Mayor De San Andrés Facultad De Medicina (La Paz, Bolivia (plurinational State Of))",
  "Universidad Mayor De San Simón Facultad De Medicina (Cochabamba, Bolivia (plurinational State Of))",
  "Universidad Mayor, Real Y Pontificia De San Francisco Xavier De Chuquisaca Facultad De Medicina (Sucre, Bolivia (plurinational State Of))",
  "Universidad Nacional 'siglo Xx' Área De La Salud (Llallagua, Bolivia (plurinational State Of))",
  "Universidad Nacional Del Oriente (Santa Cruz De La Sierra, Bolivia (plurinational State Of))",
  "Universidad Nacional Ecologica (une) (Santa Cruz De La Sierra, Bolivia (plurinational State Of))",
  "Universidad Nuestra Señora De La Paz Facultad De Medicina (La Paz, Bolivia (plurinational State Of))",
  "Universidad Privada Abierta Latinoamericana Sede Cochabamba Escuela De Medicina (Cochabamba, Bolivia (plurinational State Of))",
  "Universidad Privada Abierta Latinoamericana Subsede Oruro (Oruro, Bolivia (plurinational State Of))",
  "Universidad Privada De Oruro (Oruro, Bolivia (plurinational State Of))",
  "Universidad Privada Del Valle Facultad De Ciencias De La Salud (Cochabamba, Bolivia (plurinational State Of))",
  "Universidad Privada Del Valle Facultad De Ciencias De La Salud Santa Cruz (Santa Cruz, Bolivia (plurinational State Of))",
  "Universidad Privada Del Valle Subsede La Paz Facultad De Ciencias De La Salud (La Paz, Bolivia (plurinational State Of))",
  "Universidad Privada Franz Tamayo Facultad De Ciencias De La Salud, Cochabamba (Cochabamba, Bolivia (plurinational State Of))",
  "Universidad Privada Franz Tamayo Facultad De Ciencias De La Salud, El Alto (El Alto, Bolivia (plurinational State Of))",
  "Universidad Privada Franz Tamayo Facultad De Ciencias De La Salud, La Paz (La Paz, Bolivia (plurinational State Of))",
  "Universidad Privada Franz Tamayo Facultad De Ciencias De La Salud, Santa Cruz (Santa Cruz, Bolivia (plurinational State Of))",
  "Universidad Pública De El Alto Área De Ciencias De La Salud (El Alto, Bolivia (plurinational State Of))",
  "Universidad Técnica De Oruro Facultad De Ciencias De La Salud (Oruro, Bolivia (plurinational State Of))",
  "Universidad Técnica Privada Cosmos Facultad Ciencias De La Salud Cochabamba (Cochabamba, Bolivia (plurinational State Of))",
  "Universidad Técnica Privada Cosmos Subsede Cobija (Cobija, Bolivia (plurinational State Of))",
  "Universidad Técnica Privada Cosmos Subsede El Alto (El Alto, Bolivia (plurinational State Of))",
  "Universidad Técnica Privada Cosmos Subsede Provincia Germán Busch (Puerto Quijarro, Bolivia (plurinational State Of))",
  "Johannes Kepler Universität Linz Medizinische Fakultät (Linz, Austria)",
  "Karl Landsteiner Privatuniversität Für Gesundheitswissenschaften (Krems, Austria)",
  "Medizinische Universität Graz (Graz, Austria)",
  "Medizinische Universität Innsbruck (Innsbruck, Austria)",
  "Medizinische Universität Wien (Wien, Austria)",
  "Paracelsus Medizinische Privatuniversität (Salzburg, Austria)",
  "Sigmund Freud Privatuniversität Medizinischen Fakultät (Wien, Austria)",
  "Instituto Universitario Cemic Escuela De Medicina (Buenos Aires, Argentina)",
  "Instituto Universitario De Ciencias Biomédicas De Córdoba (Córdoba, Argentina)",
  "Instituto Universitario De Ciencias De La Salud Facultad De Medicina, Sede Buenos Aires (Buenos Aires, Argentina)",
  "Instituto Universitario De Ciencias De La Salud Facultad De Medicina, Sede La Rioja (La Rioja, Argentina)",
  "Instituto Universitario De Ciencias De La Salud Facultad De Medicina, Sede Santo Tomé (Santo Tome, Argentina)",
  "Instituto Universitario Escuela De Medicina Del Hospital Italiano (Buenos Aires, Argentina)",
  "Instituto Universitario Italiano De Rosario Facultad De Medicina (Rosario, Argentina)",
  "Pontificia Universidad Católica Argentina Facultad De Ciencias Médicas (Buenos Aires, Argentina)",
  "Unicen Escuela Superior De Ciencias De La Salud (Olavarria, Argentina)",
  "Universidad Abierta Interamericana Facultad De Medicina Y Ciencias De La Salud, Sede Buenos Aires (Buenos Aires, Argentina)",
  "Universidad Abierta Interamericana Facultad De Medicina Y Ciencias De La Salud, Sede Rosario (Rosario, Argentina)",
  "Universidad Adventista Del Plata Facultad De Ciencias De La Salud (Entre Rios, Argentina)",
  "Universidad Austral Facultad De Ciencias Biomédicas (Buenos Aires, Argentina)",
  "Universidad Católica De Cuyo Facultad De Ciencias Médicas San Juan (San Juan, Argentina)",
  "Universidad Católica De Cuyo Facultad De Ciencias Médicas San Luis (San Luis, Argentina)",
  "Universidad Católica De Córdoba Facultad De Medicina (Cordoba, Argentina)",
  "Universidad Católica De Las Misiones Facultad De Ciencias De La Salud (Posadas, Argentina)",
  "Universidad De Buenos Aires Facultad De Medicina (Buenos Aires, Argentina)",
  "Universidad De Ciencias Empresariales Y Sociales Facultad De Ciencias De La Salud (Buenos Aires, Argentina)",
  "Universidad De Concepción Del Uruguay Facultad De Ciencias Médicas 'bartolomé Vassallo' (Concepción Del Uruguay, Argentina)",
  "Universidad De Mendoza Facultad De Ciencias De La Salud (Mendoza, Argentina)",
  "Universidad De Morón Facultad De Medicina (Morón, Argentina)",
  "Universidad De San Pablo-t (San Miguel De Tucumán, Argentina)",
  "Universidad Del Aconcagua Facultad De Ciencias Médicas (Mendoza, Argentina)",
  "Universidad Del Salvador Facultad De Medicina (Buenos Aires, Argentina)",
  "Universidad Fasta Facultad De Ciencias Médicas (Mar Del Plata, Argentina)",
  "Universidad Favaloro Facultad De Ciencias Médicas (Buenos Aires, Argentina)",
  "Universidad Hebrea Argentina Bar Ilan Facultad De Ciencias Biológicas (Buenos Aires, Argentina)",
  "Universidad Maimónides Escuela De Medicina (Buenos Aires, Argentina)",
  "Universidad Nacional Arturo Jauretche Instituto De Ciencias De La Salud (Buenos Aires, Argentina)",
  "Universidad Nacional De Cuyo Facultad De Ciencias Médicas (Mendoza, Argentina)",
  "Universidad Nacional De Córdoba Facultad De Ciencias Médicas (Cordoba, Argentina)",
  "Universidad Nacional De Entre Ríos Facultad De Ciencias De La Salud (Concepción Del Uruguay, Argentina)",
  "Universidad Nacional De La Matanza Departamento De Ciencias De La Salud (San Justo, Argentina)",
  "Universidad Nacional De La Patagonia San Juan Bosco Facultad De Cs Naturales Y Cs De La Salud (Comodoro Rivadavia, Argentina)",
  "Universidad Nacional De La Plata Facultad De Ciencias Médicas (La Plata, Argentina)",
  "Universidad Nacional De La Rioja Escuela De Medicina (La Rioja, Argentina)",
  "Universidad Nacional De Mar Del Plata Escuela Superior De Medicina (Mar Del Plata, Argentina)",
  "Universidad Nacional De Rosario Facultad De Ciencias Médicas (Rosario De Santa Fe, Argentina)",
  "Universidad Nacional De Santiago Del Estero Facultad De Ciencias Médicas (Santiago Del Estero, Argentina)",
  "Universidad Nacional De Tucumán Facultad De Medicina (San Miguel De Tucumán, Argentina)",
  "Universidad Nacional De Tucumán Salta Facultad De Medicina (Salta, Argentina)",
  "Universidad Nacional De Villa María Instituto Académico Pedagógico De Ciencias Humanas (Villa María, Argentina)",
  "Universidad Nacional Del Chaco Austral (Sáenz Peña, Argentina)",
  "Universidad Nacional Del Comahue Facultad De Ciencias Medicas (Cipolletti, Argentina)",
  "Universidad Nacional Del Litoral Facultad De Ciencias Médicas (Santa Fe, Argentina)",
  "Universidad Nacional Del Nordeste Facultad De Medicina (Corrientes, Argentina)",
  "Universidad Nacional Del Sur Facultad De Medicina (Bahia Blanca, Argentina)",
  "International University Faculty Of Health Sciences (Phnom Penh, Cambodia)",
  "University Of Puthisastra Faculty Of Health Sciences (Phnom Penh, Cambodia)",
  "Université Des Sciences De La Santé Faculté De Médecine (Phnom Penh, Cambodia)",
  "Catholic University Of Cameroon School Of Health Sciences (Bamenda, Cameroon)",
  "Institut Supérieur Des Technologies Médicales De Nkolondom Faculté De Médecine (Yaoundé, Cameroon)",
  "University Of Bamenda Faculty Of Health Sciences (Bambili, Cameroon)",
  "University Of Buea Faculty Of Health Sciences (Buea, Cameroon)",
  "Université De Douala Faculté De Médecine Et Sciences Pharmaceutiques (Douala, Cameroon)",
  "Université De Yaoundé I Faculté De Médecine Et Des Sciences Biomédicales (Yaoundé, Cameroon)",
  "Université Des Montagnes Institut Supérieur Des Sciences De La Santé (Bangangte, Cameroon)",
  "Cumming School Of Medicine, University Of Calgary (Calgary, Canada)",
  "Dalhousie University Faculty Of Medicine (Halifax, Canada)",
  "Max Rady College Of Medicine, University Of Manitoba (Winnipeg, Canada)",
  "Mcgill University Faculty Of Medicine And Health Sciences (Montreal, Canada)",
  "Memorial University Of Newfoundland Faculty Of Medicine (St John's, Canada)",
  "Michael G Degroote School Of Medicine, Mcmaster University (Hamilton, Canada)",
  "Northern Ontario School Of Medicine University (Sudbury, Canada)",
  "Queen's University Faculty Of Health Sciences (Kingston, Canada)",
  "Schulich School Of Medicine And Dentistry, Western University (London, Canada)",
  "University Of Alberta Faculty Of Medicine And Dentistry (Edmonton, Canada)",
  "University Of British Columbia Faculty Of Medicine (Vancouver, Canada)",
  "University Of Ottawa Faculty Of Medicine (Ottawa, Canada)",
  "University Of Saskatchewan College Of Medicine (Saskatoon, Canada)",
  "University Of Toronto Temerty Faculty Of Medicine (Toronto, Canada)",
  "Université De Montréal Faculté De Médecine (Montreal, Canada)",
  "Université De Sherbrooke Faculté De Médecine Et Des Sciences De La Santé (Sherbrooke, Canada)",
  "Université Laval Faculté De Médecine (Québec, Canada)",
  "St Matthew's University School Of Medicine (grand Cayman) (West Bay, Cayman Islands)",
  "Université De Bangui Faculté De Sciences De La Santé (Bangui, Central African Republic)",
  "Faculté De Médecine Bon Samaritain (N'djamena, Chad)",
  "Faculté Des Sciences De La Santé, N'djamena (N'djamena, Chad)",
  "Pontificia Universidad Católica De Chile Escuela De Medicina (Santiago, Chile)",
  "Universidad Andrés Bello Escuela De Medicina Concepción (Talcahuano, Chile)",
  "Universidad Andrés Bello Escuela De Medicina Santiago (Santiago, Chile)",
  "Universidad Andrés Bello Escuela De Medicina Viña Del Mar (Viña Del Mar, Chile)",
  "Universidad Austral De Chile Facultad De Medicina (Valdivia, Chile)",
  "Universidad Autónoma De Chile Facultad De Ciencias De La Salud Providencia (Providencia, Chile)",
  "Universidad Autónoma De Chile Facultad De Ciencias De La Salud Talca (Talca, Chile)",
  "Universidad Católica De La Santísima Concepción Facultad De Medicina (Concepcion, Chile)",
  "Universidad Católica Del Maule Facultad De Medicina (Talca, Chile)",
  "Universidad Católica Del Norte Facultad De Medicina (Coquimbo, Chile)",
  "Universidad De Antofagasta Facultad De Medicina Y Odontología (Antofagasta, Chile)",
  "Universidad De Atacama Facultad De Medicina (Copiapó, Chile)",
  "Universidad De Chile Facultad De Medicina (Santiago, Chile)",
  "Universidad De Concepción Facultad De Medicina (Concepción, Chile)",
  "Universidad De La Frontera Facultad De Medicina (Temuco, Chile)",
  "Universidad De Los Andes Facultad De Medicina (chile) (Las Condes Santiago, Chile)",
  "Universidad De Magallanes Escuela De Medicina (Punta Arenas, Chile)",
  "Universidad De Santiago De Chile Facultad De Ciencias Médicas (Santiago, Chile)",
  "Universidad De Talca Escuela De Medicina (Talca, Chile)",
  "Universidad De Tarapacá Escuela De Medicina (Arica, Chile)",
  "Universidad De Valparaíso Facultad De Medicina San Felipe (San Felipe, Chile)",
  "Universidad De Valparaíso Facultad De Medicina Valparaíso (Valparaíso, Chile)",
  "Universidad Del Desarrollo Facultad De Medicina, Sede Santiago (Santiago, Chile)",
  "Universidad Del Mar Facultad De Medicina (Vina Del Mar, Chile)",
  "Universidad Diego Portales Facultad De Medicina (Santiago, Chile)",
  "Universidad Finis Terrae Facultad De Medicina (Santiago, Chile)",
  "Universidad Mayor Facultad De Medicina (Santiago, Chile)",
  "Universidad Pedro De Valdivia Facultad De Ciencias Médicas (Santiago, Chile)",
  "Universidad San Sebastián Facultad De Medicina Concepción (Concepción, Chile)",
  "Universidad San Sebastián Facultad De Medicina De La Patagonia (Puerto Montt, Chile)",
  "Universidad San Sebastián Facultad De Medicina Santiago (Santiago, Chile)",
  "Corporación Universitaria Empresarial Alexander Von Humboldt (Armenia, Colombia)",
  "Corporación Universitaria Rafael Núñez Facultad De Ciencias De La Salud (Cartagena, Colombia)",
  "Corporación Universitaria Remington Facultad De Ciencias De La Salud (Medellin, Colombia)",
  "Escuela Colombiana De Medicina (Bogotá, Colombia)",
  "Fundación Universitaria De Ciencias De La Salud (fucs) Facultad De Medicina (Bogotá, Colombia)",
  "Fundación Universitaria Juan N Corpas (Bogotá, Colombia)",
  "Fundación Universitaria Navarra (uninavarra) Facultad De Ciencias De La Salud (Neiva, Colombia)",
  "Fundación Universitaria San Martín Facultad De Medicina Bogotá (Bogotá, Colombia)",
  "Fundación Universitaria San Martín Facultad De Medicina Cali (Cali, Colombia)",
  "Fundación Universitaria San Martín Facultad De Medicina Pasto (Pasto, Colombia)",
  "Fundación Universitaria San Martín Facultad De Medicina Puerto Colombia (Puerto Colombia, Colombia)",
  "Fundación Universitaria San Martín Facultad De Medicina Sabaneta (Sabaneta, Colombia)",
  "Fundación Universitaria Sanitas Facultad De Medicina (Bogotá, Colombia)",
  "Institución Universitaria Visión De Las Américas (Pereira, Colombia)",
  "Pontificia Universidad Javeriana Bogotá Facultad De Medicina (Bogotá, Colombia)",
  "Pontificia Universidad Javeriana Cali Facultad De Ciencias De La Salud (Cali, Colombia)",
  "Unidad Central Del Valle Del Cauca Facultad Ciencias De La Salud (Tuluá, Colombia)",
  "Universidad Antonio Nariño Facultad De Medicina (Bogotá, Colombia)",
  "Universidad Autónoma De Bucaramanga Facultad De Medicina (Bucaramanga, Colombia)",
  "Universidad Ces Escuela De Medicina (Medellin, Colombia)",
  "Universidad Cooperativa De Colombia Escuela De Medicina, Medellin (Medellin, Colombia)",
  "Universidad Cooperativa De Colombia Facultad De Ciencias De La Salud, Santa Marta (Santa Marta, Colombia)",
  "Universidad Cooperativa De Colombia Facultad De Medicina, Pasto (Pasto, Colombia)",
  "Universidad Cooperativa De Colombia Facultad De Medicina, Villavicencio (Villavicencio, Colombia)",
  "Universidad De Antioquia Facultad De Medicina (Medellin, Colombia)",
  "Universidad De Boyacá Facultad De Ciencias De La Salud (Tunja, Colombia)",
  "Universidad De Caldas Facultad De Ciencias Para La Salud (Manizales, Colombia)",
  "Universidad De Cartagena Facultad De Medicina (Cartagena, Colombia)",
  "Universidad De Ciencias Aplicadas Y Ambientales Facultad De Ciencias Médicas (Bogotá, Colombia)",
  "Universidad De La Sabana Facultad De Medicina (Chia, Colombia)",
  "Universidad De Los Andes Facultad De Medicina (colombia) (Bogotá, Colombia)",
  "Universidad De Manizales Campo De Ciencias De La Salud (Manizales, Colombia)",
  "Universidad De Nariño Facultad De Ciencias De La Salud (Pasto, Colombia)",
  "Universidad De Pamplona Facultad De Salud (Pamplona, Colombia)",
  "Universidad De Santander (udes) Facultad De Ciencias De La Salud (Bucaramanga, Colombia)",
  "Universidad De Sucre Facultad De Ciencias De La Salud (Sincelejo, Colombia)",
  "Universidad Del Cauca Facultad De Ciencias De La Salud (Popayan, Colombia)",
  "Universidad Del Magdalena Facultad De Ciencias De La Salud (Santa Marta, Colombia)",
  "Universidad Del Norte Programa De Medicina (Barranquilla, Colombia)",
  "Universidad Del Quindío Facultad De Ciencias De La Salud (Armenia, Colombia)",
  "Universidad Del Rosario Escuela De Medicina Y Ciencias De La Salud (Bogotá, Colombia)",
  "Universidad Del Sinú Elias Bechara Zainúm Escuela De Medicina Cartagena (Cartagena, Colombia)",
  "Universidad Del Sinú Facultad De Ciencias De La Salud Montería (Monteria, Colombia)",
  "Universidad Del Tolima Facultad De Ciencias De La Salud (Ibague, Colombia)",
  "Universidad Del Valle Escuela De Medicina, Cali (Cali, Colombia)",
  "Universidad El Bosque, Escuela Medicina Colombiana (Bogotá, Colombia)",
  "Universidad Icesi Facultad De Ciencias De La Salud (Cali, Colombia)",
  "Universidad Industrial De Santander Escuela De Medicina (Bucaramanga, Colombia)",
  "Universidad Libre De Cali Facultad De Ciencias De La Salud (Cali, Colombia)",
  "Universidad Libre De Colombia Facultad De Ciencias De La Salud (Barranquilla, Colombia)",
  "Universidad Metropolitana Programa De Medicina (Barranquilla, Colombia)",
  "Universidad Militar Nueva Granada Facultad De Medicina (Bogotá, Colombia)",
  "Universidad Nacional De Colombia Facultad De Medicina (Bogotá, Colombia)",
  "Universidad Pedagógica Y Tecnológica De Colombia Facultad De Ciencias De La Salud (Tunja, Colombia)",
  "Universidad Pontificia Bolivariana Facultad De Medicina (Medellin, Colombia)",
  "Universidad Santiago De Cali Facultad De Salud, Campus Palmira (Palmira, Colombia)",
  "Universidad Santiago De Cali Facultad De Salud, Campus Pampalinda (Cali, Colombia)",
  "Universidad Simón Bolívar Facultad De Ciencias De La Salud (Barranquilla, Colombia)",
  "Universidad Surcolombiana Facultad De La Salud (Neiva, Colombia)",
  "Universidad Tecnológica De Pereira Facultad De Ciencias De La Salud (Pereira, Colombia)",
  "The International Medical Education-comoro Islands (ime-ci) (Moroni, Comoros)",
  "Université Marien Ngouabi Faculté Des Sciences De La Santé (Brazzaville, Congo)",
  "Université Catholique De Bukavu Faculté De Médecine (Bukavu, Congo, Democratic Republic Of The)",
  "Université Catholique Du Graben Faculté De Médecine (Butembo, Congo, Democratic Republic Of The)",
  "Université Chrétienne De Kinshasa (uckin) Faculté De Médecine (Kinshasa, Congo, Democratic Republic Of The)",
  "Université Chrétienne Internationale Faculté De Médecine (Kinshasa, Congo, Democratic Republic Of The)",
  "Université De Goma Faculté De Médecine (Goma, Congo, Democratic Republic Of The)",
  "Université De Kindu Faculté De Médecine (Kindu, Congo, Democratic Republic Of The)",
  "Université De Kinshasa Faculté De Médecine (Kinshasa, Congo, Democratic Republic Of The)",
  "Université De Kisangani Faculté De Médecine Et De Pharmacie (Kisangani, Congo, Democratic Republic Of The)",
  "Université De Lubumbashi Faculté De Médecine (Lubumbashi, Congo, Democratic Republic Of The)",
  "Université Kongo Faculté De Médecine (Kisantu, Congo, Democratic Republic Of The)",
  "Université Libre Des Pays Des Grands Lacs Faculté De Médecine (Goma, Congo, Democratic Republic Of The)",
  "Université Mbujimayi Faculté De Médecine (Mbuji-mayi, Congo, Democratic Republic Of The)",
  "Université Notre Dame Du Kasayi Faculté De Médecine (Kananga, Congo, Democratic Republic Of The)",
  "Université Officielle De Bukavu Faculté De Médecine (Bukavu, Congo, Democratic Republic Of The)",
  "Université Protestante Au Congo Faculté De Médecine (Kinshasa, Congo, Democratic Republic Of The)",
  "Université Président Joseph Kasa-vubu Faculté De Médecine (Boma, Congo, Democratic Republic Of The)",
  "Université Simon Kimbangu Faculté De Médecine Humaine (Kinshasa, Congo, Democratic Republic Of The)",
  "Université Téchnologique Bel Campus Faculté De Médecine (Kinshasa, Congo, Democratic Republic Of The)",
  "Université Évangélique En Afrique Faculté De Médecine Et Santé Communautaire (Buka, Congo, Democratic Republic Of The)",
  "James Cook School Of Medicine (Rarotonga, Cook Islands)",
  "St Mary's School Of Medicine (cook Islands) (Rarotonga, Cook Islands)",
  "Universidad Autónoma De Centro América Facultad De Ciencias De La Salud (San Jose, Costa Rica)",
  "Universidad De Ciencias Medicas Andrés Vesalio Guzmán (ucimed) Escuela De Medicina (San Jose, Costa Rica)",
  "Universidad De Costa Rica Facultad De Medicina (San Jose, Costa Rica)",
  "Universidad De Iberoamérica Escuela De Medicina (San Jose, Costa Rica)",
  "Universidad Federada San Judas Tadeo Escuela De Medicina (San Jose, Costa Rica)",
  "Universidad Hispanoamericana (San Jose, Costa Rica)",
  "Universidad Internacional De Las Américas Escuela De Medicina Y Cirugía (San Jose, Costa Rica)",
  "Universidad Latina De Costa Rica Facultad De Ciencias De La Salud, Escuela De Medicina (San Jose, Costa Rica)",
  "Medicinski Fakultet Osijek (Osijek, Croatia)",
  "Medicinski Fakultet U Splitu (Split, Croatia)",
  "Medicinski Fakultet, Sveučilišta U Zagrebu (Zagreb, Croatia)",
  "University Of Rijeka Faculty Of Medicine (Rijeka, Croatia)",
  "University Of Zagreb, Split Branch (Split, Croatia)",
  "Escuela Latinoamericana De Medicina (Habana, Cuba)",
  "Facultad De Ciencias Médicas De Mayabeque (Güines, Cuba)",
  "Facultad De Ciencias Médicas Matanzas 'juan Guiteras Gener' (Matanzas, Cuba)",
  "Universidad De Ciencias Médicas De Camagüey (Camaguey, Cuba)",
  "Universidad De Ciencias Médicas De Ciego De Avila Facultad De Medicina (Ciego De Avila, Cuba)",
  "Universidad De Ciencias Médicas De Cienfuegos (Cienfuegos, Cuba)",
  "Universidad De Ciencias Médicas De Granma (Manzanillo, Cuba)",
  "Universidad De Ciencias Médicas De Guantánamo (Guantanamo, Cuba)",
  "Universidad De Ciencias Médicas De Holguín (Holguin, Cuba)",
  "Universidad De Ciencias Médicas De La Habana (Habana, Cuba)",
  "Universidad De Ciencias Médicas De Las Tunas Facultad De Medicina (Las Tunas, Cuba)",
  "Universidad De Ciencias Médicas De Pinar Del Río (Pinar Del Rio, Cuba)",
  "Universidad De Ciencias Médicas De Santiago De Cuba (Santiago De Cuba, Cuba)",
  "Universidad De Ciencias Médicas De Villa Clara Facultad De Medicina (Santa Clara, Cuba)",
  "Universidad De Ciencias Médicas Sancti Spiritus (Sancti Spiritus, Cuba)",
  "Cyprus Health And Social Sciences University Faculty Of Medicine (Güzelyurt, Cyprus)",
  "Cyprus International University Faculty Of Medicine (Nicosia, Cyprus)",
  "European University Cyprus School Of Medicine (Nicosia, Cyprus)",
  "Girne American University Faculty Of Medicine (Kyrenia, Cyprus)",
  "Near East University Faculty Of Medicine (Lefkosa, Cyprus)",
  "University Of Cyprus Medical School (Nicosia, Cyprus)",
  "University Of Kyrenia Faculty Of Medicine (Kyrenia, Cyprus)",
  "University Of Nicosia Medical School (Nicosia, Cyprus)",
  "1 Lékařská Fakulta, Univerzita Karlova (Praha, Czech Republic)",
  "2 Lékařská Fakulta, Univerzita Karlova (Praha, Czech Republic)",
  "3 Lékařská Fakulta, Univerzita Karlova (Praha, Czech Republic)",
  "Fakulta Vojenského Zdravotnictví, Univerzita Obrany (Hradec Kralove, Czech Republic)",
  "Lékařská Fakulta Uk V Hradci Králové (Hradec Kralove, Czech Republic)",
  "Lékařská Fakulta V Plzni, Univerzita Karlova V Praze (Plzen, Czech Republic)",
  "Masarykova Univerzita Lékařská Fakulta (Brno, Czech Republic)",
  "Ostravská Univerzita V Ostravě Lékařská Fakulta (Ostrava, Czech Republic)",
  "Univerzita Palackého V Olomouci Lékařská Fakulta (Olomouc, Czech Republic)",
  "Escuela Superior Politécnica De Chimborazo Facultad De Salud Pública (Riobamba, Ecuador)",
  "Pontificia Universidad Católica Del Ecuador Facultad De Medicina (Quito, Ecuador)",
  "Universidad Católica De Cuenca Azogues Facultad De Medicina (Azogues, Ecuador)",
  "Universidad Católica De Cuenca Unidad Académica De Salud Y Bienestar (Cuenca, Ecuador)",
  "Universidad Católica De Santiago De Guayaquil Facultad De Ciencias Médicas (Guayaquil, Ecuador)",
  "Universidad Central Del Ecuador Facultad De Ciencias Médicas (Quito, Ecuador)",
  "Universidad Cristiana Latinoamericana Escuela De Medicina (Quito, Ecuador)",
  "Universidad De Cuenca Facultad De Ciencias Médicas (Cuenca, Ecuador)",
  "Universidad De Guayaquil Facultad De Ciencias Médicas (Guayaquil, Ecuador)",
  "Universidad De Las Américas Facultad De Ciencias De La Salud (Quito, Ecuador)",
  "Universidad Del Azuay Facultad De Medicina (Cuenca, Ecuador)",
  "Universidad Internacional Del Ecuador Facultad De Ciencias Médicas Y De La Salud (Quito, Ecuador)",
  "Universidad Laica 'eloy Alfaro' De Manabí Facultad De Ciencias Médicas (Manta, Ecuador)",
  "Universidad Nacional De Chimborazo Facultad De Ciencias De La Salud (Riobamba, Ecuador)",
  "Universidad Nacional De Loja Área De La Salud Humana (Loja, Ecuador)",
  "Universidad Particular De Especialidades Espiritu Santo (uees) Facultad De Ciencias Médicas (Guayaquil, Ecuador)",
  "Universidad Regional Autónoma De Los Andes Facultad Ciencias Médicas (Ambato, Ecuador)",
  "Universidad San Francisco De Quito Colegio De Ciencias De La Salud (Quito, Ecuador)",
  "Universidad Tecnólogica Equinoccial Facultad De Ciencias De La Salud Eugenio Espejo (Quito, Ecuador)",
  "Universidad Técnica De Ambato Facultad De Ciencias De La Salud (Ambato, Ecuador)",
  "Universidad Técnica De Machala Escuela De Ciencias Médicas (Machala, Ecuador)",
  "Universidad Técnica De Manabí Facultad De Ciencias De La Salud (Manabí, Ecuador)",
  "Universidad Técnica Particular De Loja Escuela De Medicina (Loja, Ecuador)",
  "Universidad Autónoma De Santa Ana Facultad De Medicina (Santa Ana, El Salvador)",
  "Universidad Católica De El Salvador Facultad De Ciencias De La Salud (Santa Ana, El Salvador)",
  "Universidad De El Salvador Facultad De Medicina (San Salvador, El Salvador)",
  "Universidad De El Salvador Facultad Multidisciplinaria De Occidente (Santa Ana, El Salvador)",
  "Universidad De El Salvador Facultad Multidisciplinaria De Oriente (San Miguel, El Salvador)",
  "Universidad De Oriente 'univo' Facultad De Ciencias De La Salud (San Miguel, El Salvador)",
  "Universidad Dr José Matías Delgado Facultad De Ciencias De La Salud Dr Luis Edmundo Vásquez (Antiguo Cuscatlan, El Salvador)",
  "Universidad Evangélica De El Salvador Facultad De Medicina (San Salvador, El Salvador)",
  "Universidad Nueva San Salvador Facultad De Ciencias De La Salud (San Salvador, El Salvador)",
  "Universidad Salvadoreña Alberto Masferrer Facultad De Medicina (San Salvador, El Salvador)",
  "Orotta School Of Medicine And Dentistry (Asmara, Eritrea)",
  "University Of Tartu Faculty Of Medicine (Tartu, Estonia)",
  "Adama General Hospital And Medical College (Adama, Ethiopia)",
  "Adama Hospital Medical College (Adama, Ethiopia)",
  "Addis Ababa University, College Of Health Sciences, School Of Medicine (Addis Ababa, Ethiopia)",
  "Adigrat University College Of Medicine And Health Science (Adigrat, Ethiopia)",
  "Africa Medical College (Addis Ababa, Ethiopia)",
  "Aksum University College Of Health Science (Axum, Ethiopia)",
  "Ambo University Medical School (Ambo, Ethiopia)",
  "Arba Minch University College Of Medicine And Health Sciences (Arba Minch, Ethiopia)",
  "Arsi University College Of Health Sciences (Asella, Ethiopia)",
  "Bahir Dar University College Of Medicine And Health Sciences (Bahir Dar, Ethiopia)",
  "Bethel Medical College (Addis Ababa, Ethiopia)",
  "Debre Berhan University School Of Health Science (Debre Berhan, Ethiopia)",
  "Debre Markos University School Of Medicine (Debre Markos, Ethiopia)",
  "Debre Tabor University College Of Medicine And Health Science (Debre Tabor, Ethiopia)",
  "Dilla University School Of Health And Medical Science (Dilla, Ethiopia)",
  "Dire Dawa University School Of Medicine (Dire Dawa, Ethiopia)",
  "Ethiopian Catholic University Of St Thomas Aquinas (ecusta) School Of Medicine (Addis Ababa, Ethiopia)",
  "Ethiopian Defense University College Of Health Sciences (Bishoftu, Ethiopia)",
  "Gamby College Of Medical Science (Bahir Dar, Ethiopia)",
  "Haramaya University College Of Health And Medical Sciences (Haramaya, Ethiopia)",
  "Hawassa University College Of Medicine And Health Science (Hawassa, Ethiopia)",
  "Hayat Medical College (Addis Ababa, Ethiopia)",
  "Jigjiga University College Of Medicine And Health Science (Jigjiga, Ethiopia)",
  "Jimma University Institute Of Health Faculty Of Medical Science (Jimma, Ethiopia)",
  "Lorcan Medical College (Addis Ababa, Ethiopia)",
  "Madda Walabu University College Of Medicine (Bale Robe, Ethiopia)",
  "Mekelle University College Of Health Sciences (Mekelle, Ethiopia)",
  "Mizan-tepi University College Of Health Science (Mizan-teferi, Ethiopia)",
  "Myungsung Medical College (Addis Ababa, Ethiopia)",
  "Negele Arsi General Hospital And Medical College (Negelle Arsi, Ethiopia)",
  "Rift Valley University Faculty Of Medicine And Health Sciences (Adama, Ethiopia)",
  "Santé Medical College (Addis Ababa, Ethiopia)",
  "St Paul's Hospital Millennium Medical College (Addis Ababa, Ethiopia)",
  "University Of Gondar College Of Medicine And Health Sciences (Gondar, Ethiopia)",
  "Wachemo University College Of Medicine And Health Sciences (Hosaena, Ethiopia)",
  "Wolaita Sodo University College Of Health Sciences And Medicine (Sodo, Ethiopia)",
  "Wolkite University College Of Medicine And Health Sciences (Wolkite, Ethiopia)",
  "Wollega University College Of Medical And Health Sciences (Nekemte, Ethiopia)",
  "Wollo University College Of Medicine And Health Sciences (Dessie, Ethiopia)",
  "Yekatit 12 Hospital Medical College (Addis Ababa, Ethiopia)",
  "Yirgalem Hospital Medical College (Yirgalem, Ethiopia)",
  "Fiji National University College Of Medicine, Nursing & Health Sciences (Suva, Fiji)",
  "Umanand Prasad School Of Medicine, University Of Fiji (Lautoka, Fiji)",
  "Tampere University Faculty Of Medicine And Health Technology (Tampere, Finland)",
  "University Of Eastern Finland School Of Medicine (Kuopio, Finland)",
  "University Of Helsinki Faculty Of Medicine (Helsinki, Finland)",
  "University Of Oulu Faculty Of Medicine (Oulu, Finland)",
  "University Of Turku Faculty Of Medicine (Turku, Finland)",
  "Faculté De Médecine De Limoges, Université De Limoges (Limoges, France)",
  "Faculté De Médecine De Rennes, Université De Rennes 1 (Rennes, France)",
  "Faculté De Médecine De Sorbonne Université (Paris, France)",
  "Faculté De Médecine Et De Maïeutique Lyon Sud Charles Mérieux (Oullins, France)",
  "Faculté De Médecine Et De Pharmacie De Besançon, Université De Franche-comté (Besançon, France)",
  "Faculté De Médecine Et De Pharmacie, Université De Rouen-haute Normandie (Rouen, France)",
  "Faculté De Médecine Et Des Sciences De La Santé De Brest, Ubo (Brest, France)",
  "Faculté De Médecine Et Maïeutique, Université Catholique De Lille (Lille, France)",
  "Faculté De Médecine Grange Blanche (Lyon, France)",
  "Faculté De Médecine Henri Warembourg, Ufr3s, Université De Lille (Lille, France)",
  "Faculté De Médecine Hyacinthe Bastaraud, Université Des Antilles Et De La Guyane (Pointe-a-pitre, France)",
  "Faculté De Médecine Hyacinthe-vincent (Bordeaux, France)",
  "Faculté De Médecine Jacques Lisfranc, Université Jean Monnet Saint-etienne (Saint-priest En Jarez, France)",
  "Faculté De Médecine Laennec, Université Claude-bernard-lyon-i (Lyon, France)",
  "Faculté De Médecine Lyon Est, Université De Lyon (Lyon, France)",
  "Faculté De Médecine Lyon Nord, Université Claude-bernard-lyon-i (Villeurbanne, France)",
  "Faculté De Médecine Montpellier-nîmes, Université De Montpellier (Montpellier, France)",
  "Faculté De Médecine Necker-enfants Malades (Paris, France)",
  "Faculté De Médecine Paris-ouest, Université De Paris V (Garches, France)",
  "Faculté De Médecine Paul Broca (Bordeaux, France)",
  "Faculté De Médecine Toulouse-purpan (Toulouse, France)",
  "Faculté De Médecine Toulouse-rangueil (Toulouse, France)",
  "Faculté De Médecine Victor Pachon (Bordeaux, France)",
  "Faculté De Médecine, Maïeutique Et Métiers De La Santé À Nancy, Université De Lorraine (Vandoeuvre-les Nancy, France)",
  "Faculté Des Sciences Fondamentales Et Biomédicales, Université De Paris (Paris, France)",
  "Faculté Des Sciences Médicales Et Paramédicales Université D’aix-marseille Amu (Marseille, France)",
  "Ufr De Médecine Broussais Hôtel Dieu, Université Pierre Et Marie Curie (Paris, France)",
  "Ufr De Médecine Cochin-port Royal, Université René Descartes (paris V) (Paris, France)",
  "Ufr De Médecine De L'université Paris Cité (Paris, France)",
  "Ufr De Médecine De Paris Centre (Paris, France)",
  "Ufr De Médecine De Paris Nord (Paris, France)",
  "Ufr De Médecine Et Professions Paramédicales, Université Clermont-auvergne (Clermont-ferrand, France)",
  "Ufr De Médecine Lariboisière-saint-louis, Université Paris Diderot (Paris, France)",
  "Ufr De Médecine Orsay, Université De Paris Sud (paris Xi) Orsay (Orsay, France)",
  "Ufr De Médecine Pitié-salpêtrière, Université Pierre Et Marie Curie (Paris, France)",
  "Ufr De Médecine Saint-antoine, Université Pierre Et Marie Curie (Paris, France)",
  "Ufr De Médecine Xavier-bichat, Université Paris Diderot (Paris, France)",
  "Ufr De Médecine, Université De Picardie Jules Verne (Amiens, France)",
  "Ufr De Santé, Médecine Et Biologie Humaine, Université Sorbonne Paris Nord (Bobigny, France)",
  "Ufr Des Sciences De La Santé Simone Veil (Montigny-le-bretonneux, France)",
  "Ufr Des Sciences De Santé De Dijon, Université De Bourgogne (Dijon, France)",
  "Ufr Santé De L'université Caen Normandie (Caen, France)",
  "Unité De Formation Et De Recherche De Médecine, Université De Reims Champagne-ardenne (Reims, France)",
  "Unité De Formation Et De Recherche Santé, Université De La Réunion (Saint Denis, France)",
  "Université Côte D'azur Faculté De Médecine (Nice, France)",
  "Université D'angers Faculté De Santé (Angers, France)",
  "Université De Bordeaux Ufr De Médecine (Bordeaux, France)",
  "Université De Nantes Faculté De Médecine (Nantes, France)",
  "Université De Paris Faculté De Médecine (Paris, France)",
  "Université De Poitiers Faculté De Médecine Et De Pharmacie (Poitiers, France)",
  "Université De Strasbourg Faculté De Médecine (Strasbourg, France)",
  "Université De Tours Faculté De Médecine (Tours, France)",
  "Université Grenoble Alpes Facultés De Médecine Et De Pharmacie (La Tronche, France)",
  "Université Paris-saclay Faculté De Médecine (Le Kremlin-bicêtre, France)",
  "Upec Faculté De Médecine, Université Paris-est Créteil Val De Marne (Creteil, France)",
  "Université Des Sciences De La Santé Faculté De Médecine (Libreville, Gabon)",
  "American International University West Africa College Of Medicine (Banjul, Gambia)",
  "School Of Medicine And Allied Health Sciences (smahs), University Of The Gambia (Banjul, Gambia)",
  "Albert-ludwigs-universität Freiburg Medizinische Fakultät (Freiburg, Germany)",
  "Charité-universitätsmedizin Berlin Medizinische Fakultät (Berlin, Germany)",
  "Christian-albrechts-universität Zu Kiel Medizinische Fakultät (Kiel, Germany)",
  "Eberhard Karls Universität Tübingen Medizinische Fakultät (Tuebingen, Germany)",
  "European Medical School, Carl Von Ossietzky Universität Oldenburg (Oldenburg, Germany)",
  "Freie Universität Berlin Medizinische Fachbereich (Berlin, Germany)",
  "Friedrich-alexander-universität Erlangen-nürnberg Medizinische Fakultät (Erlangen, Germany)",
  "Friedrich-schiller-universität Jena Medizinische Fakultät (Jena, Germany)",
  "Goethe-universität Frankfurt Am Main Fachbereich Medizin (Frankfurt Am Main, Germany)",
  "Heinrich-heine-universität Düsseldorf Medizinische Fakultät (Dusseldorf, Germany)",
  "Humboldt Universität Zu Berlin (charité) Medizinische Fakultät (Berlin, Germany)",
  "Humboldt Universität Zu Berlin (virchow) Medizinische Fakultät (Berlin, Germany)",
  "Julius-maximilians-universität Würzburg Medizinische Fakultät (Würzburg, Germany)",
  "Justus-liebig-universität Giessen Fachbereich Medizin (Giessen, Germany)",
  "Ludwig-maximilians-universität München Medizinische Fakultät (München, Germany)",
  "Martin-luther-universität Halle-wittenberg Medizinische Fakultät (Halle (saale), Germany)",
  "Medizinische Akademie Erfurt (Erfurt, Germany)",
  "Medizinische Akademie Magdeburg (Magdeburg, Germany)",
  "Medizinische Fakultät Carl Gustav Carus, Technische Universität Dresden (Dresden, Germany)",
  "Medizinische Fakultät Der Universität Augsburg (Augsburg, Germany)",
  "Medizinische Fakultät Heidelberg, Ruprecht-karls-universität Heidelberg (Heidelberg, Germany)",
  "Medizinische Fakultät Mannheim, Ruprecht-karls-universität Heidelberg (Mannheim, Germany)",
  "Medizinische Hochschule Brandenburg Theodor Fontane (Neuruppin, Germany)",
  "Medizinische Hochschule Hannover (Hannover, Germany)",
  "Medizinische Hochschule Lübeck, Universität Lübeck (Lübeck, Germany)",
  "Medizinischen Fakultät Der Universität Des Saarlandes (Homburg, Germany)",
  "Otto-von-guericke-universität Magdeburg Medizinische Fakultät (Magdeburg, Germany)",
  "Philipps-universität Marburg Fachbereich Medizin (Marburg, Germany)",
  "Rheinisch-westfälische Technische Hochschule Aachen Medizinische Fakultät (Aachen, Germany)",
  "Rheinischen Friedrich-wilhelms-universität Bonn Medizinische Fakultät (Bonn, Germany)",
  "Ruhr-universität Bochum Medizinische Fakultät (Bochum, Germany)",
  "Technischen Universität München Fakultät Für Medizin (München, Germany)",
  "Universität Duisburg-essen Medizinische Fakultät (Essen, Germany)",
  "Universität Hamburg Medizinische Fakultät (Hamburg, Germany)",
  "Universität Leipzig Medizinische Fakultät (Leipzig, Germany)",
  "Universität Regensburg Fakultät Für Medizin (Regensburg, Germany)",
  "Universität Rostock Medizinische Fakultät (Rostock, Germany)",
  "Universität Ulm Medizinische Fakultät (Ulm, Germany)",
  "Universität Witten/herdecke Fakultät Für Gesundheit (Witten, Germany)",
  "Universität Zu Köln Medizinische Fakultät (Köln, Germany)",
  "Universitätsmedizin Der Johannes Gutenberg-universität Mainz (Mainz, Germany)",
  "Universitätsmedizin Greifswald (Greifswald, Germany)",
  "Universitätsmedizin Göttingen Medizinische Fakultät (Göttingen, Germany)",
  "Westfälische Wilhelms-universität Medizinische Fakultät (Münster, Germany)",
  "Accra College Of Medicine (Accra, Ghana)",
  "Family Health University Medical School (Accra, Ghana)",
  "Kwame Nkrumah University Of Science & Technology School Of Medical Sciences (Kumasi, Ghana)",
  "University For Development Studies School Of Medicine And Health Sciences (Tamale, Ghana)",
  "University Of Cape Coast School Of Medical Sciences (Cape Coast, Ghana)",
  "University Of Ghana Medical School (Accra, Ghana)",
  "University Of Health And Allied Sciences School Of Medicine (Ho, Ghana)",
  "Aristotle University Of Thessaloniki Medical School (Thessaloniki, Greece)",
  "Demokritos University Of Thrace Department Of Medicine (Alexandroupolis, Greece)",
  "National And Kapodistrian University Of Athens School Of Medicine (Athens, Greece)",
  "University Of Crete Faculty Of Medicine (Heraklion, Greece)",
  "University Of Ioannina Medical School (Ioannina, Greece)",
  "University Of Patras School Of Health Sciences (Patras, Greece)",
  "University Of Thessalia Faculty Of Medicine (Larissa, Greece)",
  "St George's University School Of Medicine (St George's, Grenada)",
  "Universidad De San Carlos Facultad De Ciencias Médicas (Guatemala City, Guatemala)",
  "Universidad Francisco Marroquín Facultad De Medicina (Guatemala City, Guatemala)",
  "Universidad Mariano Gálvez De Guatemala Facultad De Ciencias Médicas Y De La Salud (Guatemala City, Guatemala)",
  "Universidad Mesoamericana De Quetzaltenango Facultad De Medicina (Quetzaltenango, Guatemala)",
  "Universidad Rafael Landívar Facultad De Ciencias De La Salud (Guatemala City, Guatemala)",
  "Université Gamal Abdel Nasser De Conakry Faculté Des Sciences Et Techniques De La Santé (Conakry, Guinea)",
  "Escola Superior De Medicina Eduardo Mondlane (Bissau, Guinea Bissau)",
  "Universidade Jean Piaget Guiné-bissau Faculdade De Ciências Da Saúde (Bissau, Guinea Bissau)",
  "Alexander American University School Of Medicine (Georgetown, Guyana)",
  "American International School Of Medicine (Georgetown, Guyana)",
  "American School Of Medicine (Georgetown, Guyana)",
  "Georgetown American University School Of Medicine (Georgetown, Guyana)",
  "Greenheart Medical University School Of Medicine (Georgetown, Guyana)",
  "Lincoln American University School Of Medicine (Kingston, Guyana)",
  "Narendra Modi School Of Medicine (Georgetown, Guyana)",
  "North Shore Medical Institute (Georgetown, Guyana)",
  "Rajiv Gandhi University Of Science And Technology School Of Medicine (Georgetown, Guyana)",
  "Texila American University College Of Medicine (East Bank Demerara, Guyana)",
  "Twin Island University School Of Medicine (Georgetown, Guyana)",
  "University Of Guyana School Of Medicine (Georgetown, Guyana)",
  "Université Chrétienne De La Communauté De Caïman Faculté De Médecine (Bohoc-pignon, Haiti)",
  "Université D'etat D'haïti Faculté De Médecine Et De Pharmacie (Port-au-prince, Haiti)",
  "Université De La Fondation Dr Aristide Faculté De Médecine (Tabarre, Haiti)",
  "Université Joseph Lafortune (Port-au-prince, Haiti)",
  "Université Lumière Faculté De Médecine Et Des Sciences De La Santé (Port-au-prince, Haiti)",
  "Université Notre Dame D'haïti Faculté De Médecine (Port-au-prince, Haiti)",
  "Université Quisqueya Faculté Des Sciences De La Santé (Port-au-prince, Haiti)",
  "Université Royale D'haïti Faculté Des Sciences De La Santé (Port-au-prince, Haiti)",
  "Université St François D’assise Faculté De Médecine Et Science De La Santé (Delmas, Haiti)",
  "Universidad Católica De Honduras Nuestra Señora Reina De La Paz Facultad En Ciencias De La Salud (Tegucigalpa, Honduras)",
  "Universidad Nacional Autónoma De Honduras Facultad De Ciencias Médicas Tegucigalpa (Tegucigalpa, Honduras)",
  "Universidad Tecnológica Centroamericana (unitec) Facultad De Ciencias De La Salud (Tegucigalpa, Honduras)",
  "Chinese University Of Hong Kong Faculty Of Medicine (Shatin, Hong Kong, S A R China)",
  "Li Ka Shing Faculty Of Medicine, University Of Hong Kong (Pokfulam, Hong Kong, S A R China)",
  "Semmelweis University Faculty Of General Medicine (Budapest, Hungary)",
  "University Of Debrecen Faculty Of General Medicine (Debrecen, Hungary)",
  "University Of Pécs Faculty Of General Medicine (Pecs, Hungary)",
  "University Of Szeged Albert Szent-györgyi Medical School (Szeged, Hungary)",
  "University Of Iceland Faculty Of Medicine (Reykjavik, Iceland)",
  "Jakarta Christian University (ukrida) Faculty Of Medicine (Jakarta Barat, Indonesia)",
  "Universitas Abdurrab Fakultas Kedokteran Dan Ilmu Kesehatan (Pekanbaru, Indonesia)",
  "Universitas Abulyatama Fakultas Kedokteran (Banda Aceh, Indonesia)",
  "Universitas Ahmad Dahlan Fakultas Kedokteran (Yogyakarta, Indonesia)",
  "Universitas Airlangga Fakultas Kedokteran (Surabaya, Indonesia)",
  "Universitas Alkhairaat Fakultas Kedokteran (Palu, Indonesia)",
  "Universitas Andalas Fakultas Kedokteran (Padang, Indonesia)",
  "Universitas Baiturrahmah Fakultas Kedokteran (Padang, Indonesia)",
  "Universitas Baperki Fakultas Kedokteran (Jakarta, Indonesia)",
  "Universitas Batam Fakultas Kedokteran Dan Ilmu Kesehatan (Batam, Indonesia)",
  "Universitas Bengkulu Fakultas Kedokteran Dan Ilmu Kesehatan (Bengkulu, Indonesia)",
  "Universitas Bosowa Fakultas Kedokteran (Makassar, Indonesia)",
  "Universitas Brawijaya Fakultas Kedokteran (Malang, Indonesia)",
  "Universitas Cenderawasih Fakultas Kedokteran (Jayapura, Indonesia)",
  "Universitas Ciputra Fakultas Kedokteran (Surabaya, Indonesia)",
  "Universitas Diponegoro Fakultas Kedokteran (Semarang, Indonesia)",
  "Universitas Gadjah Mada Sekip Fakultas Kedokteran, Kesehatan Masyarakat Dan Keperawatan (Yogyakarta, Indonesia)",
  "Universitas Gunadarma Fakultas Kedokteran (Depok City, Indonesia)",
  "Universitas Haluoleo Fakultas Kedokteran (Kendari, Indonesia)",
  "Universitas Hang Tuah Fakultas Kedokteran (Surabaya, Indonesia)",
  "Universitas Hasanuddin Fakultas Kedokteran (Makassar, Indonesia)",
  "Universitas Hkbp Nommensen Fakultas Kedokteran (Medan, Indonesia)",
  "Universitas Indonesia Fakultas Kedokteran (Jakarta Pusat, Indonesia)",
  "Universitas Islam Al-azhar Fakultas Kedokteran (Mataram, Indonesia)",
  "Universitas Islam Bandung Fakultas Kedokteran (Bandung, Indonesia)",
  "Universitas Islam Indonesia Fakultas Kedokteran (Yogyakarta, Indonesia)",
  "Universitas Islam Malang Fakultas Kedokteran (Malang, Indonesia)",
  "Universitas Islam Negeri Alauddin Fakultas Kedokteran Dan Ilmu Kesehatan (Romangpolong, Indonesia)",
  "Universitas Islam Negeri Maulana Malik Ibrahim Fakultas Kedokteran Dan Ilmu Kesehatan (Kota Batu, Indonesia)",
  "Universitas Islam Negeri Syarif Hidayatullah Fakultas Kedokteran (Ciputat, Indonesia)",
  "Universitas Islam Sultan Agung (unissula) Fakultas Kedokteran (Semarang, Indonesia)",
  "Universitas Islam Sumatra Utara Fakultas Kedokteran (Medan, Indonesia)",
  "Universitas Jambi Fakultas Kedokteran (Mendalo, Indonesia)",
  "Universitas Jember Fakultas Kedokteran (Jember, Indonesia)",
  "Universitas Jenderal Ahmad Yani Fakultas Kedokteran (Cimahi, Indonesia)",
  "Universitas Jenderal Soedirman Fakultas Kedokteran Dan Ilmu-ilmu Kesehatan (Purwokerto, Indonesia)",
  "Universitas Katolik Indonesia Atma Jaya Fakultas Kedokteran (Jakarta Selatan, Indonesia)",
  "Universitas Katolik Soegiapranata Fakultas Kedokteran (Semarang, Indonesia)",
  "Universitas Katolik Widya Mandala Fakultas Kedokteran (Surabaya, Indonesia)",
  "Universitas Khairun Fakultas Kedokteran (Ternate, Indonesia)",
  "Universitas Kristen Duta Wacana Fakultas Kedokteran (Yogyakarta, Indonesia)",
  "Universitas Kristen Indonesia Fakultas Kedokteran (Jakarta Timur, Indonesia)",
  "Universitas Kristen Maranatha Fakultas Kedokteran (Bandung, Indonesia)",
  "Universitas Lambung Mangkurat Fakultas Kedokteran (Banjarbaru, Indonesia)",
  "Universitas Lampung Fakultas Kedokteran (Bandar Lampung, Indonesia)",
  "Universitas Malahayati Fakultas Kedokteran (Bandar Lampung, Indonesia)",
  "Universitas Malikussaleh Fakultas Kedokteran (Muara Batu, Indonesia)",
  "Universitas Mataram Fakultas Kedokteran (Mataram, Indonesia)",
  "Universitas Methodist Indonesia Faculty Of Medicine (Medan, Indonesia)",
  "Universitas Muhammadiyah Jakarta Fakultas Kedokteran Dan Kesehatan (Jakarta Selatan, Indonesia)",
  "Universitas Muhammadiyah Makassar Fakultas Kedokteran (Makassar, Indonesia)",
  "Universitas Muhammadiyah Malang Fakultas Kedokteran (Malang, Indonesia)",
  "Universitas Muhammadiyah Palembang Fakultas Kedokteran (Palembang, Indonesia)",
  "Universitas Muhammadiyah Prof Dr Hamka Fakultas Kedokteran (Tangerang, Indonesia)",
  "Universitas Muhammadiyah Purwokerto Fakultas Kedokteran (Purwokerto, Indonesia)",
  "Universitas Muhammadiyah Semarang Fakultas Kedokteran (Semarang, Indonesia)",
  "Universitas Muhammadiyah Sumatera Utara Fakultas Kedokteran (Medan, Indonesia)",
  "Universitas Muhammadiyah Surabaya Fakultas Kedokteran (Surabaya, Indonesia)",
  "Universitas Muhammadiyah Surakarta Fakultas Kedokteran (Surakarta, Indonesia)",
  "Universitas Muhammadiyah Yogyakarta Fakultas Kedokteran (Yogyakarta, Indonesia)",
  "Universitas Mulawarman Fakultas Kedokteran (Samarinda, Indonesia)",
  "Universitas Muslim Indonesia Fakultas Kedokteran (Makassar, Indonesia)",
  "Universitas Nahdlatul Ulama Fakultas Kedokteran (Surabaya, Indonesia)",
  "Universitas Negeri Gorontalo Fakultas Kedokteran (Gorontalo, Indonesia)",
  "Universitas Nusa Cendana Fakultas Kedokteran (Kupang, Indonesia)",
  "Universitas Padjadjaran Fakultas Kedokteran (Bandung, Indonesia)",
  "Universitas Palangka Raya Fakultas Kedokteran (Palangka Raya, Indonesia)",
  "Universitas Papua Fakultas Kedokteran (Manokwari, Indonesia)",
  "Universitas Pasundan Fakultas Kedokteran (Bandung, Indonesia)",
  "Universitas Pattimura Fakultas Kedokteran (Ambon, Indonesia)",
  "Universitas Pelita Harapan Fakultas Kedokteran (Tangerang, Indonesia)",
  "Universitas Pembangunan Nasional Veteran Fakultas Kedokteran (Jakarta Selatan, Indonesia)",
  "Universitas Pendidikan Ganesha Fakultas Kedokteran (Singaraja, Indonesia)",
  "Universitas Prima Indonesia Fakultas Kedokteran (Medan, Indonesia)",
  "Universitas Riau Fakultas Kedokteran (Pekanbaru, Indonesia)",
  "Universitas Sam Ratulangi Fakultas Kedokteran (Manado, Indonesia)",
  "Universitas Sebelas Maret Fakultas Kedokteran (Surakarta, Indonesia)",
  "Universitas Sriwijaya Fakultas Kedokteran (Palembang, Indonesia)",
  "Universitas Sultan Ageng Tirtayasa Fakultas Kedokteran (Serang, Indonesia)",
  "Universitas Sumatera Utara Fakultas Kedokteran (Medan, Indonesia)",
  "Universitas Surabaya Fakultas Kedokteran (Surabaya, Indonesia)",
  "Universitas Swadaya Gunung Jati Fakultas Kedokteran (Cirebon, Indonesia)",
  "Universitas Syiah Kuala Fakultas Kedokteran (Banda Aceh, Indonesia)",
  "Universitas Tadulako Fakultas Kedokteran Dan Ilmu Kesehatan (Palu, Indonesia)",
  "Universitas Tanjungpura Fakultas Kedokteran (Pontianak, Indonesia)",
  "Universitas Tarumanagara Fakultas Kedokteran (Jakarta Barat, Indonesia)",
  "Universitas Trisakti Fakultas Kedokteran (Jakarta Barat, Indonesia)",
  "Universitas Udayana Fakultas Kedokteran (Denpasar, Indonesia)",
  "Universitas Wahid Hasyim Fakultas Kedokteran (Semarang, Indonesia)",
  "Universitas Warmadewa Fakultas Kedokteran (Denpasar, Indonesia)",
  "Universitas Wijayakusuma Fakultas Kedokteran (Surabaya, Indonesia)",
  "Universitas Yarsi Fakultas Kedokteran (Jakarta Pusat, Indonesia)",
  "Abadan University Of Medical Sciences Medical School (Abadan, Iran (islamic Republic Of))",
  "Ahvaz Jondishapour University Of Medical Sciences (Ahvaz, Iran (islamic Republic Of))",
  "Alborz University Of Medical Sciences School Of Medicine (Karaj, Iran (islamic Republic Of))",
  "Arak University Of Medical Sciences School Of Medicine (Arak, Iran (islamic Republic Of))",
  "Ardabil University Of Medical Sciences Faculty Of Medicine (Ardabil, Iran (islamic Republic Of))",
  "Army University Of Medical Sciences Medical School (Tehran, Iran (islamic Republic Of))",
  "Ayatollah Taleghani Medical Center (Tehran, Iran (islamic Republic Of))",
  "Babol University Of Medical Sciences Faculty Of Medicine (Babol, Iran (islamic Republic Of))",
  "Bam University Of Medical Sciences And Health Services (Bam, Iran (islamic Republic Of))",
  "Baqiyatallah University Of Medical Sciences Faculty Of Medicine (Tehran, Iran (islamic Republic Of))",
  "Birjand University Of Medical Sciences And Health Services Medical School (Birjand, Iran (islamic Republic Of))",
  "Bushehr University Of Medical Sciences And Health Services (Bushehr, Iran (islamic Republic Of))",
  "Dezful University Of Medical Sciences And Health Services (Dezfoul, Iran (islamic Republic Of))",
  "Fasa University Of Medical Sciences Faculty Of Medicine (Fasa, Iran (islamic Republic Of))",
  "Fatemieh University Of Medical Sciences (Qom, Iran (islamic Republic Of))",
  "Golestan University Of Medical Sciences Medical School (Gorgan, Iran (islamic Republic Of))",
  "Gonabad University Of Medical Sciences School Of Medicine (Gonabad, Iran (islamic Republic Of))",
  "Guilan University Of Medical Sciences (Rasht, Iran (islamic Republic Of))",
  "Hamadan University Of Medical Sciences Medical School (Hamadan, Iran (islamic Republic Of))",
  "Hormozgan University Of Medical Sciences (hums) Faculty Of Medicine (Bandar Abbas, Iran (islamic Republic Of))",
  "Ilam University Of Medical Sciences School Of Medicine (Ilam, Iran (islamic Republic Of))",
  "Iran University Of Medical Sciences (iums) School Of Medicine (Tehran, Iran (islamic Republic Of))",
  "Iranshahr University Of Medical Sciences And Health Services School Of Medicine (Iranshahr, Iran (islamic Republic Of))",
  "Isfahan University Of Medical Sciences Faculty Of Medicine (Isfahan, Iran (islamic Republic Of))",
  "Islamic Azad University Ardabil Branch Faculty Of Medicine (Ardabil, Iran (islamic Republic Of))",
  "Islamic Azad University Kazeroon Branch Faculty Of Medicine (Kazeroon, Iran (islamic Republic Of))",
  "Islamic Azad University Kerman Branch Faculty Of Medicine (Kerman, Iran (islamic Republic Of))",
  "Islamic Azad University Mashhad Branch Faculty Of Medicine (Mashhad, Iran (islamic Republic Of))",
  "Islamic Azad University Najafabad Branch Faculty Of Medical Science (Najafabad, Iran (islamic Republic Of))",
  "Islamic Azad University Qeshm Branch Faculty Of Medicine (Qeshm, Iran (islamic Republic Of))",
  "Islamic Azad University Sari Branch Faculty Of Medicine (Sari, Iran (islamic Republic Of))",
  "Islamic Azad University Shahrood Branch Faculty Of Medicine (Shahrood, Iran (islamic Republic Of))",
  "Islamic Azad University Tabriz Branch Faculty Of Medicine (Tabriz, Iran (islamic Republic Of))",
  "Islamic Azad University Tehran Faculty Of Medicine (Tehran, Iran (islamic Republic Of))",
  "Islamic Azad University Tonekabon Branch Faculty Of Medicine (Tonekabon, Iran (islamic Republic Of))",
  "Islamic Azad University Yazd Ali Ibn Abitaleb Medical School (Yazd, Iran (islamic Republic Of))",
  "Islamic Azad University Zahedan Branch Faculty Of Medicine (Zahedan, Iran (islamic Republic Of))",
  "Jahrom University Of Medical Sciences Faculty Of Medicine (Jahrom, Iran (islamic Republic Of))",
  "Jiroft University Of Medical Sciences Medical School (Jiroft, Iran (islamic Republic Of))",
  "Kashan University Of Medical Sciences And Health Services Faculty Of Medicine (Kashan, Iran (islamic Republic Of))",
  "Kerman University Of Medical Sciences Faculty Of Medicine (Kerman, Iran (islamic Republic Of))",
  "Kermanshah University Of Medical Sciences School Of Medicine (Kermanshah, Iran (islamic Republic Of))",
  "Kurdistan University Of Medical Sciences School Of Medicine (Sanandaj, Iran (islamic Republic Of))",
  "Lorestan University Of Medical Sciences School Of Medicine (Khoramabad, Iran (islamic Republic Of))",
  "Mashhad University Of Medical Sciences Faculty Of Medicine (Mashhad, Iran (islamic Republic Of))",
  "Mazandaran University Of Medical Sciences Faculty Of Medicine (Sari, Iran (islamic Republic Of))",
  "Medical Center Of Iran (Tehran, Iran (islamic Republic Of))",
  "North Khorasan University Of Medical Sciences Faculty Of Medicine (Bojnourd, Iran (islamic Republic Of))",
  "Qazvin University Of Medical Sciences Shahid Babaei Faculty Of Medicine (Qazvin, Iran (islamic Republic Of))",
  "Qom Islamic Azad University Medicine College (Qom, Iran (islamic Republic Of))",
  "Qom University Of Medical Sciences Faculty Of Medicine (Qom, Iran (islamic Republic Of))",
  "Rafsanjan University Of Medical Sciences School Of Medicine (Rafsanjan, Iran (islamic Republic Of))",
  "Sabzevar Faculty Of Medical Sciences School Of Medicine (Sabzevar, Iran (islamic Republic Of))",
  "Semnan University Of Medical Sciences Faculty Of Medicine (Semnan, Iran (islamic Republic Of))",
  "Shahed University Faculty Of Medicine (Tehran, Iran (islamic Republic Of))",
  "Shahid Beheshti University Of Medical Sciences Faculty Of Medicine (Tehran, Iran (islamic Republic Of))",
  "Shahid Sadooghi University Of Medical Sciences College Of Medicine (Yazd, Iran (islamic Republic Of))",
  "Shahrekord University Of Medical Sciences Faculty Of Medicine (Shahrekord, Iran (islamic Republic Of))",
  "Shahroud University Of Medical Sciences Faculty Of Medicine (Shahroud, Iran (islamic Republic Of))",
  "Shiraz University Of Medical Sciences School Of Medicine (Shiraz, Iran (islamic Republic Of))",
  "Tabriz University Of Medical Sciences Faculty Of Medicine (Tabriz, Iran (islamic Republic Of))",
  "Tehran School Of Medical Sciences (Tehran, Iran (islamic Republic Of))",
  "Tehran University Of Medical Sciences School Of Medicine (Tehran, Iran (islamic Republic Of))",
  "Urmia University Of Medical Sciences Faculty Of Medicine (Urmia, Iran (islamic Republic Of))",
  "Yasuj University Of Medical Sciences Faculty Of Medicine (Yasuj, Iran (islamic Republic Of))",
  "Zabol University Of Medical Sciences Faculty Of Medicine (Zabol, Iran (islamic Republic Of))",
  "Zahedan University Of Medical Sciences Faculty Of Medicine (Zahedan, Iran (islamic Republic Of))",
  "Zanjan University Of Medical Sciences Faculty Of Medicine (Zanja, Iran (islamic Republic Of))",
  "Al Muthanna University College Of Medicine (Samawah, Iraq)",
  "Al-iraqia University College Of Medicine (Baghdad, Iraq)",
  "Al-kindy College Of Medicine (Baghdad, Iraq)",
  "Al-nahrain University College Of Medicine (Baghdad, Iraq)",
  "Al-qadisiya University College Of Medicine (Diwaniya, Iraq)",
  "Babylon University College Of Medicine (Babylon, Iraq)",
  "Hawler Medical University College Of Medicine (Erbil, Iraq)",
  "Ibn Sina University Of Medical And Pharmaceutical Sciences College Of Medicine (Baghdad, Iraq)",
  "Jabir Ibn Hayyan Medical University (Kufa, Iraq)",
  "Koya University School Of Medicine (Koya, Iraq)",
  "Kufa University College Of Medicine (Kufa, Iraq)",
  "Ninevah College Of Medicine (Mosul, Iraq)",
  "Thi-qar University College Of Medicine (Nasiriyah City, Iraq)",
  "University Of Al-ameed College Of Medicine (Karbala, Iraq)",
  "University Of Al-mustansiriyah College Of Medicine (Baghdad, Iraq)",
  "University Of Alkafeel College Of Medicine (Najaf City, Iraq)",
  "University Of Anbar College Of Medicine (Ramadi, Iraq)",
  "University Of Baghdad College Of Medicine (Baghdad, Iraq)",
  "University Of Basrah College Of Medicine (Basrah, Iraq)",
  "University Of Diyala College Of Medicine (Baquba, Iraq)",
  "University Of Duhok College Of Medicine (Dohuk, Iraq)",
  "University Of Fallujah College Of Medicine (Fallujah, Iraq)",
  "University Of Garmian College Of Medicine (Kalar, Iraq)",
  "University Of Kerbala College Of Medicine (Karbala, Iraq)",
  "University Of Kirkuk College Of Medicine (Kirkuk, Iraq)",
  "University Of Kurdistan Hewlêr School Of Medicine (Erbil, Iraq)",
  "University Of Misan Faculty Of Medicine (Amarah, Iraq)",
  "University Of Mosul College Of Medicine (Mosul, Iraq)",
  "University Of Sulaimani College Of Medicine (Sulaimani City, Iraq)",
  "University Of Tikrit College Of Medicine (Tikrit, Iraq)",
  "University Of Warith Al-anbiyaa College Of Medicine (Karbala, Iraq)",
  "University Of Wasit College Of Medicine (Al Kut, Iraq)",
  "Royal College Of Surgeons In Ireland School Of Medicine (Dublin, Ireland)",
  "Trinity College Dublin School Of Medicine (Dublin, Ireland)",
  "University College Cork School Of Medicine (Cork, Ireland)",
  "University College Dublin School Of Medicine (Dublin, Ireland)",
  "University Of Galway School Of Medicine (Galway, Ireland)",
  "University Of Limerick School Of Medicine (Limerick, Ireland)",
  "Bar-ilan University Azrieli Faculty Of Medicine (Safed, Israel)",
  "Ben-gurion University Of The Negev Faculty Of Health Sciences (Beer-sheva, Israel)",
  "Dr Miriam And Sheldon G Adelson School Of Medicine At Ariel University (Ariel, Israel)",
  "Ruth And Bruce Rappaport Faculty Of Medicine, Technion Israel Institute Of Technology (Haifa, Israel)",
  "Sackler School Of Medicine, Tel Aviv University (Ramat Aviv, Israel)",
  "The Hebrew University-hadassah Medical School (Jerusalem, Israel)",
  "Humanitas University Medical School (Milan, Italy)",
  "Saint Camillus International University Of Health Sciences (Rome, Italy)",
  "Università Campus Bio-medico Di Roma Facoltà Di Medicina E Chirurgia (Rome, Italy)",
  "Università Cattolica Del Sacro Cuore Facoltà Di Medicina E Chirurgia 'agostino Gemelli' (Rome, Italy)",
  "Università Degli Studi Del Molise Facoltà Di Medicina E Chirurgia (Campobasso, Italy)",
  "Università Degli Studi Del Piemonte Orientale Facoltà Di Medicina Chirurgia E Scienze Della Salute (Novara, Italy)",
  "Università Degli Studi Dell'aquila Facoltà Di Medicina E Chirurgia (Aquila, Italy)",
  "Università Degli Studi Dell'insubria Facoltà Di Medicina E Chirurgia (Varese, Italy)",
  "Università Degli Studi Della Campania Luigi Vanvitelli Scuola Di Medicina E Chirurgia (Naples, Italy)",
  "Università Degli Studi Di Bari Aldo Moro Facoltà Di Medicina E Chirurgia (Bari, Italy)",
  "Università Degli Studi Di Bologna Scuola Di Medicina E Chirurgia (Bologna, Italy)",
  "Università Degli Studi Di Brescia Facoltà Di Medicina E Chirurgia (Brescia, Italy)",
  "Università Degli Studi Di Cagliari Facoltà Di Medicina E Chirurgia (Monserrato, Italy)",
  "Università Degli Studi Di Catania Facoltà Di Medicina E Chirurgia (Catania, Italy)",
  "Università Degli Studi Di Ferrara Facoltà Di Medicina E Chirurgia (Ferrara, Italy)",
  "Università Degli Studi Di Firenze Scuola Di Scienze Della Salute Umana (Florence, Italy)",
  "Università Degli Studi Di Foggia Facoltà Di Medicina E Chirurgia (Foggia, Italy)",
  "Università Degli Studi Di Genova Scuola Di Scienze Mediche E Farmaceutiche (Genova, Italy)",
  "Università Degli Studi Di Messina (Messina, Italy)",
  "Università Degli Studi Di Milano Bicocca Facoltà Di Medicina E Chirurgia (Milan, Italy)",
  "Università Degli Studi Di Milano Facoltà Di Medicina E Chirurgia (Milan, Italy)",
  "Università Degli Studi Di Modena E Reggio Emilia Facoltà Di Medicina E Chirurgia (Modena, Italy)",
  "Università Degli Studi Di Napoli Federico Ii Scuola Di Medicina E Chirurgia (Naples, Italy)",
  "Università Degli Studi Di Padova Scuola Di Medicina E Chirurgia (Padua, Italy)",
  "Università Degli Studi Di Palermo Scuola Di Medicina E Chirurgia (Palermo, Italy)",
  "Università Degli Studi Di Parma Facoltà Di Medicina E Chirurgia (Parma, Italy)",
  "Università Degli Studi Di Pavia Facoltà Di Medicina E Chirurgia (Pavia, Italy)",
  "Università Degli Studi Di Perugia Facoltà Di Medicina E Chirurgia (Perugia, Italy)",
  "Università Degli Studi Di Roma 'la Sapienza' Facoltà Di Farmacia E Medicina (Rome, Italy)",
  "Università Degli Studi Di Roma 'la Sapienza' Facoltà Di Medicina E Odontoiatria (Rome, Italy)",
  "Università Degli Studi Di Roma 'la Sapienza' Facoltà Di Medicina E Psicologia (Rome, Italy)",
  "Università Degli Studi Di Roma 'tor Vergata' Facoltà Di Medicina E Chirurgia (Rome, Italy)",
  "Università Degli Studi Di Salerno Facoltà Di Medicina E Chirurgia (Baronissi, Italy)",
  "Università Degli Studi Di Sassari Facoltà Di Medicina E Chirurgia (Sassari, Italy)",
  "Università Degli Studi Di Siena Facoltà Di Medicina E Chirurgia (Siena, Italy)",
  "Università Degli Studi Di Torino Scuola Di Medicina (Torino, Italy)",
  "Università Degli Studi Di Trieste Facoltà Di Medicina E Chirurgia (Trieste, Italy)",
  "Università Degli Studi Di Udine Facoltà Di Medicina E Chirurgia (Udine, Italy)",
  "Università Degli Studi Di Verona Facoltà Di Medicina E Chirurgia (Verona, Italy)",
  "Università Degli Studi G D'annunzio Chieti-pescara Scuola Di Medicina E Scienze Della Salute (Chieti, Italy)",
  "Università Degli Studi Magna Græcia Di Catanzaro Scuola Di Medicina E Chirurgia (Catanzaro, Italy)",
  "Università Di Napoli Facoltà De Medicina (Naples, Italy)",
  "Università Di Napoli, Facoltà De Medicina I (Naples, Italy)",
  "Università Di Pisa Facoltà Di Medicina E Chirurgia (Pisa, Italy)",
  "Università Di Reggio Calabria Facoltà Di Medicina E Chirurgia (Catanzaro, Italy)",
  "Università Politecnica Delle Marche Facoltà Di Medicina E Chirurgia (Ancona, Italy)",
  "Università Vita-salute San Raffaele Facoltà Di Medicina E Chirurgia (Milan, Italy)",
  "All American Institute Of Medical Sciences (Black River, Jamaica)",
  "Caribbean School Of Medical Sciences (Kingston, Jamaica)",
  "University Of The West Indies Faculty Of Medical Sciences, Jamaica (Kingston, Jamaica)",
  "Aichi Medical University (Nagakute, Japan)",
  "Akita University School Of Medicine (Akita, Japan)",
  "Asahikawa Medical University (Asahikawa, Japan)",
  "Chiba University School Of Medicine (Chiba, Japan)",
  "Dokkyo Medical University (Mibu, Japan)",
  "Ehime University School Of Medicine (Tōon, Japan)",
  "Fujita Health University School Of Medicine (Toyoake, Japan)",
  "Fukui Medical University (Yoshida, Japan)",
  "Fukuoka University School Of Medicine (Fukuoka, Japan)",
  "Fukushima Medical University School Of Medicine (Fukushima, Japan)",
  "Gifu University School Of Medicine (Gifu, Japan)",
  "Gunma University Faculty Of Medicine (Maebashi, Japan)",
  "Hamamatsu University School Of Medicine (Hamamatsu, Japan)",
  "Hirosaki University School Of Medicine (Hirosaki, Japan)",
  "Hiroshima University Faculty Of Medicine (Hiroshima, Japan)",
  "Hokkaido University School Of Medicine (Sapporo, Japan)",
  "Hyogo Medical University (Nishinomiya, Japan)",
  "International University Of Health And Welfare (iuhw) School Of Medicine (Narita, Japan)",
  "Iwate Medical University School Of Medicine (Morioka, Japan)",
  "Jichi Medical University School Of Medicine (Shimotsuke, Japan)",
  "Jikei University School Of Medicine (Tokyo, Japan)",
  "Juntendo University School Of Medicine (Tokyo, Japan)",
  "Kagawa University Faculty Of Medicine (Miki, Japan)",
  "Kagoshima University Faculty Of Medicine (Kagoshima, Japan)",
  "Kanazawa Medical University School Of Medicine (Uchinada, Japan)",
  "Kanazawa University School Of Medicine (Kanazawa, Japan)",
  "Kansai Medical University School Of Medicine (Moriguchi, Japan)",
  "Kawasaki Medical School (Kurashiki, Japan)",
  "Keio University School Of Medicine (Tokyo, Japan)",
  "Kindai University School Of Medicine (Osaka Sayama, Japan)",
  "Kitasato University School Of Medicine (Sagamihara, Japan)",
  "Kobe University School Of Medicine (Kobe, Japan)",
  "Kochi Medical School (Nankoku, Japan)",
  "Kumamoto University School Of Medicine (Kumamoto, Japan)",
  "Kurume University School Of Medicine (Kurume, Japan)",
  "Kyorin University School Of Medicine (Mitaka, Japan)",
  "Kyoto Prefectural University Of Medicine (Kyoto, Japan)",
  "Kyoto University Faculty Of Medicine (Kyoto, Japan)",
  "Kyushu University Faculty Of Medicine (Fukuoka, Japan)",
  "Mie University School Of Medicine (Tsu, Japan)",
  "Miyazaki Medical College Faculty Of Medicine (Kiyotake, Japan)",
  "Nagasaki University School Of Medicine (Nagasaki, Japan)",
  "Nagoya City University Medical School (Nagoya, Japan)",
  "Nagoya University School Of Medicine (Nagoya, Japan)",
  "Nara Medical University Faculty Of Medicine (Kashihara, Japan)",
  "National Defense Medical College (Tokorozawa, Japan)",
  "Nihon University School Of Medicine (Itabashi, Japan)",
  "Niigata University School Of Medicine (Niigata, Japan)",
  "Nippon Medical School (Tokyo, Japan)",
  "Oita University Faculty Of Medicine (Yufu, Japan)",
  "Okayama University School Of Medicine (Okayama, Japan)",
  "Osaka Medical And Pharmaceutical University Faculty Of Medicine (Takatsuki, Japan)",
  "Osaka Metropolitan University School Of Medicine (Osaka, Japan)",
  "Osaka University School Of Medicine (Suita, Japan)",
  "Saga University Faculty Of Medicine (Saga, Japan)",
  "Saitama Medical University Faculty Of Medicine (Saitama, Japan)",
  "Sapporo Medical University School Of Medicine (Sapporo, Japan)",
  "Shiga University Of Medical Science (Otsu, Japan)",
  "Shimane University Faculty Of Medicine (Izumo, Japan)",
  "Shinshu University Faculty Of Medicine (Matsumoto, Japan)",
  "Showa University School Of Medicine (Shinagawa, Japan)",
  "St Marianna University School Of Medicine (Kawasaki, Japan)",
  "Teikyo University School Of Medicine (Tokyo, Japan)",
  "Toho University Faculty Of Medicine (Ota, Japan)",
  "Tohoku Medical And Pharmaceutical University School Of Medicine (Sendai, Japan)",
  "Tohoku University School Of Medicine (Sendai, Japan)",
  "Tokai University School Of Medicine (Isehara, Japan)",
  "Tokushima University School Of Medicine (Tokushima, Japan)",
  "Tokyo Medical And Dental University Faculty Of Medicine (Tokyo, Japan)",
  "Tokyo Medical University (Tokyo, Japan)",
  "Tokyo Women's Medical University School Of Medicine (Tokyo, Japan)",
  "Tottori University Faculty Of Medicine (Yonago, Japan)",
  "Toyama Medical And Pharmaceutical University School Of Medicine (Toyama, Japan)",
  "University Of Fukui Faculty Of Medical Sciences (Yoshida, Japan)",
  "University Of Miyazaki Faculty Of Medicine (Miyazaki, Japan)",
  "University Of Occupational & Environmental Health School Of Medicine (Kitakyushu, Japan)",
  "University Of The Ryukyus Faculty Of Medicine (Nishihara, Japan)",
  "University Of Tokyo Faculty Of Medicine (Tokyo, Japan)",
  "University Of Toyama School Of Medicine (Toyama, Japan)",
  "University Of Tsukuba School Of Medicine (Tsukuba, Japan)",
  "University Of Yamanashi Faculty Of Medicine (Chuo, Japan)",
  "Wakayama Medical University School Of Medicine (Wakayama, Japan)",
  "Yamagata University School Of Medicine (Yamagata, Japan)",
  "Yamaguchi University Faculty Of Medicine And Health Sciences (Ube, Japan)",
  "Yokohama City University School Of Medicine (Yokohama, Japan)",
  "Al-balqa Applied University Faculty Of Medicine (Al-salt, Jordan)",
  "Aqaba Medical Sciences University Faculty Of Medicine (Aqaba, Jordan)",
  "Hashemite University Faculty Of Medicine (Zarqa, Jordan)",
  "Ibn Sina University For Medical Sciences (Amman, Jordan)",
  "Jordan University Of Science And Technology Faculty Of Medicine (Irbid, Jordan)",
  "Mu'tah University Faculty Of Medicine (Karak, Jordan)",
  "University Of Jordan Faculty Of Medicine (Amman, Jordan)",
  "Yarmouk University Faculty Of Medicine (Irbid, Jordan)",
  "Al-farabi Kazakh National University Faculty Of Medicine And Health Care (Almaty, Kazakhstan)",
  "Asfendiyarov Kazakh National Medical University (Almaty, Kazakhstan)",
  "Caspian University International School Of Medicine (Almaty, Kazakhstan)",
  "International Kazakh-turkish University Named After Khoja Ahmet Yassawi Faculty Of Medicine (Turkistan, Kazakhstan)",
  "International Medical School, University Of International Business (Almaty, Kazakhstan)",
  "Karaganda Medical University (Karaganda, Kazakhstan)",
  "Kazakh Medical University Of Continuing Education Medical College (Almaty, Kazakhstan)",
  "Kazakh-russian Medical University (Almaty, Kazakhstan)",
  "Nazarbayev University School Of Medicine (Nur-sultan, Kazakhstan)",
  "Njsc Astana Medical University (Astana,, Kazakhstan)",
  "North Kazakhstan State University Named After M Kozybayev Faculty Of Medicine (Petropavlovsk, Kazakhstan)",
  "Semey Medical University (Semey, Kazakhstan)",
  "Shukan Ualikhanov Kokshetau State University Faculty Of Medicine (Kokshetau, Kazakhstan)",
  "South Kazakhstan Medical Academy (Shymkent, Kazakhstan)",
  "West Kazakhstan Marat Ospanov Medical University (Aqtobe, Kazakhstan)",
  "Egerton University Faculty Of Health Sciences (Egerton, Kenya)",
  "Jomo Kenyatta University Of Agriculture And Technology School Of Medicine (Nairobi, Kenya)",
  "Kenya Methodist University School Of Medicine And Health Sciences (Nairobi, Kenya)",
  "Kenyatta University School Of Medicine (Nairobi, Kenya)",
  "Kisii University School Of Health Sciences (Kisii, Kenya)",
  "Maseno University School Of Medicine (Maseno, Kenya)",
  "Masinde Muliro University Of Science And Technology School Of Medicine (Kakamega, Kenya)",
  "Moi University School Of Medicine (Eldoret, Kenya)",
  "Mount Kenya University School Of Health Sciences (Thika, Kenya)",
  "University Of Nairobi School Of Medicine (Nairobi, Kenya)",
  "Uzima University College School Of Medicine (Kisumu, Kenya)",
  "Chongjin Medical University (Chongjin, Korea, Democratic People's Republic Of)",
  "Haeju Medical University (Haeju, Korea, Democratic People's Republic Of)",
  "Hamhung Medical University (Hamhung, Korea, Democratic People's Republic Of)",
  "Hyesan Medical University (Hyesan, Korea, Democratic People's Republic Of)",
  "Kanggye Medical University (Kanggye, Korea, Democratic People's Republic Of)",
  "Pyongsong Medical University (Pyongsong, Korea, Democratic People's Republic Of)",
  "Pyongyang Medical University (Pyongyang, Korea, Democratic People's Republic Of)",
  "Sariwon Medical University (Sariwon, Korea, Democratic People's Republic Of)",
  "Sinuiju Medical University (Sinuiju, Korea, Democratic People's Republic Of)",
  "Wonsan Medical University (Wonsan, Korea, Democratic People's Republic Of)",
  "Ajou University School Of Medicine (Suwon, Korea, Republic Of)",
  "Catholic Kwandong University College Of Medicine (Gangneung, Korea, Republic Of)",
  "Catholic University Of Daegu School Of Medicine (Daegu, Korea, Republic Of)",
  "Catholic University Of Korea College Of Medicine (Seoul, Korea, Republic Of)",
  "Cha University School Of Medicine (Pochon, Korea, Republic Of)",
  "Chonnam National University College Of Medicine (Gwangju, Korea, Republic Of)",
  "Chosun University College Of Medicine (Gwangju, Korea, Republic Of)",
  "Chung-ang University College Of Medicine (Seoul, Korea, Republic Of)",
  "Chungbuk National University College Of Medicine (Cheongju, Korea, Republic Of)",
  "Chungnam National University College Of Medicine (Daejeon, Korea, Republic Of)",
  "Dankook University College Of Medicine (Cheonan, Korea, Republic Of)",
  "Dong-a University College Of Medicine (Busan, Korea, Republic Of)",
  "Dongguk University School Of Medicine (Gyeongju, Korea, Republic Of)",
  "Eulji University College Of Medicine (Daejeon, Korea, Republic Of)",
  "Ewha Womans University School Of Medicine (Seoul, Korea, Republic Of)",
  "Gachon University School Of Medicine (Incheon, Korea, Republic Of)",
  "Gyeongsang National University College Of Medicine (Jinju, Korea, Republic Of)",
  "Hallym University College Of Medicine (Chuncheon, Korea, Republic Of)",
  "Hanyang University College Of Medicine (Seoul, Korea, Republic Of)",
  "Inha University College Of Medicine (Inchon, Korea, Republic Of)",
  "Inje University College Of Medicine (Busan, Korea, Republic Of)",
  "Jeju National University School Of Medicine (Jeju, Korea, Republic Of)",
  "Jeonbuk National University College Of Medicine (Jeonju, Korea, Republic Of)",
  "Kangwon National University School Of Medicine (Chuncheon, Korea, Republic Of)",
  "Keimyung University College Of Medicine (Daegu, Korea, Republic Of)",
  "Konkuk University College Of Medicine (Chungju, Korea, Republic Of)",
  "Konyang University College Of Medicine (Daejeon, Korea, Republic Of)",
  "Korea University College Of Medicine (Seoul, Korea, Republic Of)",
  "Kosin University College Of Medicine (Busan, Korea, Republic Of)",
  "Kyung Hee University College Of Medicine (Seoul, Korea, Republic Of)",
  "Kyungpook National University School Of Medicine (Daegu, Korea, Republic Of)",
  "Pusan National University School Of Medicine (Busan, Korea, Republic Of)",
  "Seonam University College Of Medicine (Namwon City, Korea, Republic Of)",
  "Seoul National University College Of Medicine (Seoul, Korea, Republic Of)",
  "Soon Chun Hyang University College Of Medicine (Asan, Korea, Republic Of)",
  "Sungkyunkwan University School Of Medicine & Graduate School Of Medicine (Seoul, Korea, Republic Of)",
  "University Of Ulsan College Of Medicine (Seoul, Korea, Republic Of)",
  "Wonkwang University College Of Medicine (Iksan, Korea, Republic Of)",
  "Yeungnam University College Of Medicine (Daegu, Korea, Republic Of)",
  "Yonsei University College Of Medicine (Seoul, Korea, Republic Of)",
  "Yonsei University Wonju College Of Medicine (Wonju, Korea, Republic Of)",
  "Kuwait University Faculty Of Medicine (Safat, Kuwait)",
  "Abc Academy (Bishkek, Kyrgyzstan)",
  "Adam University School Of Medicine (Bishkek, Kyrgyzstan)",
  "Ala-too International University (Bishkek, Kyrgyzstan)",
  "Avicenna International Medical University (Bishkek, Kyrgyzstan)",
  "Bishkek International Medical Institute (bimi) (Bishkek, Kyrgyzstan)",
  "Central Asian International Medical University (Jalal-abad, Kyrgyzstan)",
  "Eurasian International University Faculty Of Medicine (Bishkek, Kyrgyzstan)",
  "I K Akhunbaev Kyrgyz State Medical Academy (Bishkek, Kyrgyzstan)",
  "International European University Faculty Of Medicine (Bishkek, Kyrgyzstan)",
  "International Higher School Of Medicine (Bishkek, Kyrgyzstan)",
  "International Medical Institute, International University Of Science & Business (Bishkek, Kyrgyzstan)",
  "International Medical University (Bishkek, Kyrgyzstan)",
  "International School Of Medicine, International University Of Kyrgyzstan (Bishkek, Kyrgyzstan)",
  "International University Of Science And Medicine (iusm) (Bishkek, Kyrgyzstan)",
  "Jalal-abad People's Friendship University A Batirov Medical Faculty (Jalal-abad, Kyrgyzstan)",
  "Jalal-abad State University Medical Faculty (Jalal-abad, Kyrgyzstan)",
  "Kyrgyz Medical And Dental Institute (Kara-balta, Kyrgyzstan)",
  "Kyrgyz National University Named After Jusup Balasagyn (Bishkek, Kyrgyzstan)",
  "Kyrgyz-russian Slavic University (krsu) Named After B N Yelstin Medical Faculty (Bishkek, Kyrgyzstan)",
  "Kyrgyz-uzbek University Medical Faculty (Osh, Kyrgyzstan)",
  "Osh International Medical University (Osh, Kyrgyzstan)",
  "Osh State University Medical Faculty (Osh, Kyrgyzstan)",
  "Royal Metropolitan University (Bishkek, Kyrgyzstan)",
  "S Tentishev Asian Medical Institute (Kant, Kyrgyzstan)",
  "Salymbekov University Faculty Of Medicine (Bishkek, Kyrgyzstan)",
  "University Of Health Sciences (uhs) Faculty Of Medical Sciences (Vientiane, Lao People’s Democratic Republic)",
  "Latvijas Universitātes Medicīnas Fakultātē (Riga, Latvia)",
  "Rīga Stradiņš University Medicīnas Fakultāte (Riga, Latvia)",
  "American University Of Beirut Faculty Of Medicine (Beirut, Lebanon)",
  "Beirut Arab University Faculty Of Medicine (Beirut, Lebanon)",
  "Lau Gilbert And Rose-marie Chagoury School Of Medicine (Byblos, Lebanon)",
  "Lebanese University Faculty Of Medical Sciences (Beirut, Lebanon)",
  "Saint George University Of Beirut Faculty Of Medicine (Beirut, Lebanon)",
  "University Of Balamand Faculty Of Medicine And Medical Sciences (Beirut, Lebanon)",
  "Université Saint Esprit De Kaslik Faculté De Médecine Et Des Sciences Médicales (Jounieh, Lebanon)",
  "Université Saint-joseph Faculté De Médecine (Beirut, Lebanon)",
  "A M Dogliotti College Of Medicine, University Of Liberia (Monrovia, Liberia)",
  "Al Asmarya University Zliten Medical School (Zliten, Libya)",
  "Elmergib University Faculty Of Medicine (Al Khums, Libya)",
  "Libyan International Medical University Faculty Of Medicine (Benghazi, Libya)",
  "Misurata University Faculty Of Medicine (Misurata, Libya)",
  "Omar Al-mukhtar University Faculty Of Medicine (Al-baida, Libya)",
  "Sebha Medical University (Sebha, Libya)",
  "Sirte University Faculty Of Medicine (Sirte, Libya)",
  "Tobruk University Faculty Of Medicine (Tobruk, Libya)",
  "University Of Ajdabiya Faculty Of Medicine (Ajdabiya, Libya)",
  "University Of Benghazi Faculty Of Medicine (Benghazi, Libya)",
  "University Of Derna Faculty Of Medicine (Derna, Libya)",
  "University Of Gharyan Faculty Of Medicine (Gharyan, Libya)",
  "University Of Sabratha Faculty Of Human Medicine (Sabratha, Libya)",
  "University Of Tripoli Faculty Of Medicine (Tripoli, Libya)",
  "University Of Zawia Faculty Of Medicine (Zawia, Libya)",
  "University Of Zintan Faculty Of Medicine (Alzintan, Libya)",
  "Lithuanian University Of Health Sciences Faculty Of Medicine (Kaunas, Lithuania)",
  "Vilnius University Faculty Of Medicine (Vilnius, Lithuania)",
  "Macau University Of Science And Technology Faculty Of Medicine (Taipa, Macau, S A R China)",
  "Ecole De Médecine Et De Pharmacie (Antananarivo, Madagascar)",
  "Université D'antananarivo Faculté De Médecine (Antananarivo, Madagascar)",
  "Université De Mahajanga Faculté De Médecine (Mahajanga, Madagascar)",
  "Kamuzu University Of Health Sciences (Blantyre, Malawi)",
  "Allianze University College Of Medical Sciences (Penang, Malaysia)",
  "Asia Metropolitan University Faculty Of Medicine (Johor Bahru, Malaysia)",
  "Asian Institute Of Medicine, Science And Technology (aimst) (Bedong, Malaysia)",
  "International Islamic University Malaysia Kulliyyah Of Medicine (Kuantan, Malaysia)",
  "International Medical School, Management And Science University (Shah Alam, Malaysia)",
  "International Medical University Faculty Of Medicine And Health (Kuala Lumpur, Malaysia)",
  "Jeffrey Cheah School Of Medicine And Health Sciences (Bandar Sunway, Malaysia)",
  "Kulliyah Of Medicine & Health Sciences Universiti Islam Antarabangsa Sultan Abdul Halim Muadzam Shah (Kuala Ketil, Malaysia)",
  "Lincoln University College Faculty Of Medicine (Petaling Jaya, Malaysia)",
  "Mahsa University Faculty Of Medicine (Kuala Lumpur, Malaysia)",
  "Manipal University College Malaysia (Melaka, Malaysia)",
  "Newcastle University Medicine Malaysia (Nusajaya, Malaysia)",
  "Perdana University Graduate School Of Medicine (Kuala Lumpur, Malaysia)",
  "Perdana University Royal College Of Surgeons In Ireland (pu-rcsi) School Of Medicine (Kuala Lumpur, Malaysia)",
  "Quest International University Perak Faculty Of Medicine (Ipoh, Malaysia)",
  "Rcsi & Ucd Malaysia Campus (George Town, Malaysia)",
  "Segi University (Petaling Jaya, Malaysia)",
  "Taylor's University School Of Medicine (Subang Jaya, Malaysia)",
  "Ucsi University Faculty Of Medicine And Health Sciences (Kuala Lumpur, Malaysia)",
  "Universiti Kebangsaan Malaysia Fakulti Perubatan (Kuala Lumpur, Malaysia)",
  "Universiti Kuala Lumpur Royal College Of Medicine Perak (Ipoh, Malaysia)",
  "Universiti Malaya Fakulti Perubatan (Kuala Lumpur, Malaysia)",
  "Universiti Malaysia Sabah Fakulti Perubatan Dan Sains Kesihatan (Sabah, Malaysia)",
  "Universiti Malaysia Sarawak Fakulti Perubatan Dan Sains Kesihatan (Sarawak, Malaysia)",
  "Universiti Pertahanan Nasional Malaysia Fakulti Perubatan Dan Kesihatan Pertahanan (Kuala Lumpur, Malaysia)",
  "Universiti Putra Malaysia Fakulti Perubatan Dan Sains Kesihatan (Selangor Darul Ehsan, Malaysia)",
  "Universiti Sains Islam Malaysia Fakulti Perubatan Dan Sains Kesihatan (Kuala Lumpur, Malaysia)",
  "Universiti Sains Malaysia School Of Medical Sciences (Kelantan, Malaysia)",
  "Universiti Sultan Zainal Abidin Faculty Of Medicine (Kuala Terengganu, Malaysia)",
  "Universiti Teknologi Mara Faculty Of Medicine (Shah Alam, Malaysia)",
  "Universiti Tunku Abdul Rahman Faculty Of Medicine And Health Sciences (Kajang, Malaysia)",
  "University Of Cyberjaya Faculty Of Medicine (Cyberjaya, Malaysia)",
  "Widad University College Faculty Of Medicine (Kuantan, Malaysia)",
  "School Of Medicine At The Maldives National University (Malé, Maldives)",
  "Université Des Sciences, Des Techniques Et Des Technologies De Bamako Faculté De Médecine (Bamako, Mali)",
  "Université Kankou Moussa Faculté Des Sciences De La Santé (Bamako, Mali)",
  "Queen Mary University Of London Faculty Of Medicine And Dentistry Malta (Victoria, Malta)",
  "University Of Malta Faculty Of Medicine And Surgery (Msida, Malta)",
  "Université De Nouakchott Al Aasriya Faculté De Médecine (Nouakchott, Mauritania)",
  "Anna Medical College (Montagne Blanche, Mauritius)",
  "Padmashree Dr D Y Patil Medical College, University Of Technology Mauritius (Ebene Cybercity, Mauritius)",
  "Ssr Medical College (Belle Rive, Mauritius)",
  "University Of Mauritius Faculty Of Medicine And Health Sciences, Department Of Medicine (Réduit, Mauritius)",
  "Benemérita Universidad Autónoma De Puebla Facultad De Medicina Campus Puebla (Puebla, Mexico)",
  "Benemérita Universidad Autónoma De Puebla Facultad De Medicina Campus Tehuacán (Tehuacán, Mexico)",
  "Benemérita Universidad Autónoma De Puebla Facultad De Medicina Campus Teziutlán (Teziutlan, Mexico)",
  "Centro Biomédico Cuauhnáhuac Escuela De Medicina (Cuernavaca, Mexico)",
  "Centro De Estudios Superiores De Tepeaca (Tepeaca, Mexico)",
  "Centro De Estudios Universitarios Xochicalco, Escuela De Medicina Ensenada (Ensenada, Mexico)",
  "Centro De Estudios Universitarios Xochicalco, Escuela De Medicina Mexicali (Mexicali, Mexico)",
  "Centro De Estudios Universitarios Xochicalco, Escuela De Medicina Tijuana (Tijuana, Mexico)",
  "Centro Universitario Cultural Del Soconusco (Tapachula, Mexico)",
  "Centro Universitario De Estudios De La Salud De Aguascalientes (Aguascalientes, Mexico)",
  "Centro Universitario Guadalajara Lamar (Guadalajara, Mexico)",
  "Escuela De Medicina Intermédica (Pachuca, Mexico)",
  "Escuela De Medicina José Bernardo Gutiérrez De Lara, A C (Guerrero, Mexico)",
  "Escuela Libre De Homeopatía De México (México, Mexico)",
  "Escuela Médico Militar, Universidad Del Ejército Y Fuerza Aérea (México, Mexico)",
  "Escuela Médico Naval De La Secretaría De Marina (México, Mexico)",
  "Instituto De Ciencias Y Estudios Superiores De Tamaulipas Matamoros Escuela De Medicina (Matamoros, Mexico)",
  "Instituto De Ciencias Y Estudios Superiores De Tamaulipas Tampico Escuela De Medicina (Tampico, Mexico)",
  "Instituto De Estudios Superiores De Chiapas Escuela De Medicina Tapachula (Tapachula, Mexico)",
  "Instituto De Estudios Superiores De Chiapas Escuela De Medicina Tuxtla Gutierrez (Tuxtla Gutierrez, Mexico)",
  "Instituto De Estudios Superiores De Tamaulipas (iest Anáhuac) Escuela De Medicina (Altamira, Mexico)",
  "Instituto Politécnico Nacional Centro Interdisciplinario De Ciencias De La Salud (México, Mexico)",
  "Instituto Politécnico Nacional Escuela Nacional De Medicina Y Homeopatía (México, Mexico)",
  "Instituto Politécnico Nacional Escuela Superior De Medicina (México, Mexico)",
  "Instituto Universitario De Ciencias Médicas Y Humanísticas De Nayarit (inumedh) (Tepic, Mexico)",
  "St Luke Escuela De Medicina (México, Mexico)",
  "Tecnológico De Monterrey Escuela De Medicina Ciudad De México (México, Mexico)",
  "Tecnológico De Monterrey Escuela De Medicina Guadalajara (Zapopan, Mexico)",
  "Tecnológico De Monterrey Escuela De Medicina Monterrey (Monterrey, Mexico)",
  "Universidad Anáhuac Cancún Escuela Internacional De Medicina (Cancún, Mexico)",
  "Universidad Anáhuac Mayab Escuela De Medicina (Merida, Mexico)",
  "Universidad Anáhuac México Escuela De Medicina (Huixquilucan, Mexico)",
  "Universidad Anáhuac Oaxaca Escuela De Medicina (Oaxaca, Mexico)",
  "Universidad Anáhuac Puebla Escuela De Medicina (San Andrés Cholula, Mexico)",
  "Universidad Anáhuac Querétaro Escuela De Medicina (El Marqués, Mexico)",
  "Universidad Anáhuac Sede Ciudad De México (Mexico City, Mexico)",
  "Universidad Anáhuac Xalapa Escuela De Medicina (Xalapa, Mexico)",
  "Universidad Autónoma Benito Juárez De Oaxaca Facultad De Medicina Y Cirugía (Oaxaca De Juarez, Mexico)",
  "Universidad Autónoma De Aguascalientes Centro De Ciencias De La Salud (Aguascalientes, Mexico)",
  "Universidad Autónoma De Baja California Escuela De Ciencias De La Salud Ensenada (Ensenada, Mexico)",
  "Universidad Autónoma De Baja California Escuela De Ciencias De La Salud, Unidad Valle De Las Palmas (Tijuana, Mexico)",
  "Universidad Autónoma De Baja California Facultad De Medicina Mexicali (Mexicali, Mexico)",
  "Universidad Autónoma De Baja California Tijuana Facultad De Medicina Y Psicología (Tijuana, Mexico)",
  "Universidad Autónoma De Campeche Facultad De Medicina (San Francisco De Campeche, Mexico)",
  "Universidad Autónoma De Chiapas Escuela De Medicina Tapachula (Tapachula, Mexico)",
  "Universidad Autónoma De Chiapas Facultad De Medicina Humana (Tuxtla Gutierrez, Mexico)",
  "Universidad Autónoma De Chihuahua Facultad De Medicina (Chihuahua, Mexico)",
  "Universidad Autónoma De Chihuahua Facultad De Medicina Hidalgo Del Parral (Hidalgo Del Parral, Mexico)",
  "Universidad Autónoma De Ciudad Juárez Instituto De Ciencias Biomédicas (Ciudad Juárez, Mexico)",
  "Universidad Autónoma De Coahuila Escuela De Medicina De Torreón (Torreon, Mexico)",
  "Universidad Autónoma De Coahuila Facultad De Medicina Saltillo (Saltillo, Mexico)",
  "Universidad Autónoma De Coahuila, Escuela De Medicina Unidad Norte (Piedras Negras, Mexico)",
  "Universidad Autónoma De Durango Campus Chihuahua Escuela De Medicina (Chihuahua, Mexico)",
  "Universidad Autónoma De Durango Campus Ciudad Juárez Escuela De Medicina (Ciudad Juárez, Mexico)",
  "Universidad Autónoma De Durango Campus Culiacán Escuela De Medicina (Culiacán, Mexico)",
  "Universidad Autónoma De Durango Campus Durango Facultad De Medicina (Durango, Mexico)",
  "Universidad Autónoma De Durango Campus Laguna Escuela De Medicina (Gómez Palacio, Mexico)",
  "Universidad Autónoma De Durango Campus Los Mochis Facultad De Medicina (Los Mochis, Mexico)",
  "Universidad Autónoma De Durango Campus Mazatlán Facultad De Medicina (Mazatlán, Mexico)",
  "Universidad Autónoma De Durango Campus Saltillo Escuela De Medicina (Saltillo, Mexico)",
  "Universidad Autónoma De Durango Campus Torreón Escuela De Medicina (Torreón, Mexico)",
  "Universidad Autónoma De Durango Campus Zacatecas Escuela De Medicina (Zacatecas, Mexico)",
  "Universidad Autónoma De España Escuela De Ciencias Médicas (Durango, Mexico)",
  "Universidad Autónoma De Guadalajara Facultad De Medicina Guadalajara (Zapopan, Mexico)",
  "Universidad Autónoma De Guadalajara Facultad De Medicina Tabasco (Villahermosa, Mexico)",
  "Universidad Autónoma De Guerrero Facultad De Medicina (Acapulco, Mexico)",
  "Universidad Autónoma De Nayarit Escuela De Medicina (Tepic, Mexico)",
  "Universidad Autónoma De Nuevo León Facultad De Medicina (Monterrey, Mexico)",
  "Universidad Autónoma De Querétaro Facultad De Medicina (Queretaro, Mexico)",
  "Universidad Autónoma De San Luis Potosí Facultad De Medicina (San Luis Potosi, Mexico)",
  "Universidad Autónoma De Sinaloa Facultad De Medicina Culiacán (Culiacan, Mexico)",
  "Universidad Autónoma De Tamaulipas Facultad De Medicina De Tampico 'dr Alberto Romo Caballero' (Tampico, Mexico)",
  "Universidad Autónoma De Tamaulipas Facultad De Medicina Humana De Matamoros (Matamoros, Mexico)",
  "Universidad Autónoma De Tlaxcala Facultad De Ciencias De La Salud (Zacatelco, Mexico)",
  "Universidad Autónoma De Yucatán Facultad De Medicina (Merida, Mexico)",
  "Universidad Autónoma De Zacatecas Unidad Académica De Medicina Y Ciencias De La Salud (Zacatecas, Mexico)",
  "Universidad Autónoma Del Carmen Facultad De Ciencias De La Salud (Ciudad Del Carmen, Mexico)",
  "Universidad Autónoma Del Estado De Hidalgo Instituto De Ciencias De La Salud (Pachuca, Mexico)",
  "Universidad Autónoma Del Estado De Morelos Facultad De Medicina (Cuernavaca, Mexico)",
  "Universidad Autónoma Del Estado De México Facultad De Medicina (Toluca, Mexico)",
  "Universidad Autónoma Metropolitana Unidad Xochimilco (México, Mexico)",
  "Universidad Cristóbal Colón Escuela De Medicina (Boca Del Rio, Mexico)",
  "Universidad Cuauhtémoc Campus San Luis Potosí Escuela De Medicina (San Luis Potosi, Mexico)",
  "Universidad Cuauhtémoc Plantel Aguascalientes Escuela De Medicina (Aguascalientes, Mexico)",
  "Universidad Cuauhtémoc Plantel Guadalajara Escuela De Medicina (Zapopan, Mexico)",
  "Universidad De Campeche Escuela De Medicina (Campeche, Mexico)",
  "Universidad De Celaya Escuela De Medicina (Celaya, Mexico)",
  "Universidad De Colima Facultad De Medicina (Colima, Mexico)",
  "Universidad De Guadalajara Centro Universitario De Ciencias De La Salud (Guadalajara, Mexico)",
  "Universidad De Guadalajara Centro Universitario De La Costa (Puerto Vallarta, Mexico)",
  "Universidad De Guadalajara Centro Universitario De Los Altos (Tepatitlán De Morelos, Mexico)",
  "Universidad De Guadalajara Centro Universitario De Tonalá (Tonala, Mexico)",
  "Universidad De Guadalajara Centro Universitario Del Sur (Guzman, Mexico)",
  "Universidad De Guanajuato División De Ciencias De La Salud (León, Mexico)",
  "Universidad De Las Américas Puebla (Cholula, Mexico)",
  "Universidad De Montemorelos Escuela De Medicina (Montemorelos, Mexico)",
  "Universidad De Monterrey Facultad De Medicina (San Pedro Garza Garcia, Mexico)",
  "Universidad De Quintana Roo División De Ciencias De La Salud (Chetumal, Mexico)",
  "Universidad De Sonora Cajeme División De Ciencias Biológicas Y De La Salud (Ciudad Obregón, Mexico)",
  "Universidad De Sonora Hermosillo División De Ciencias Biológicas Y De La Salud (Hermosillo, Mexico)",
  "Universidad Del Noreste Escuela De Medicina 'dr José Sierra Flores' (Tampico, Mexico)",
  "Universidad Del Soconusco Escuela De Medicina (Tapachula, Mexico)",
  "Universidad Del Sureste (uds) Escuela De Medicina Comitán (Comitán, Mexico)",
  "Universidad Del Valle De Atemajac (univa) (Guadalajara, Mexico)",
  "Universidad Del Valle De México Campus Ciudad Victoria Facultad De Medicina (Victoria, Mexico)",
  "Universidad Del Valle De México Campus Coyoacán (Coyoacán, Mexico)",
  "Universidad Del Valle De México Campus Hermosillo (Hermosillo, Mexico)",
  "Universidad Del Valle De México Campus Monterrey Cumbres (Monterrey Cumbres, Mexico)",
  "Universidad Del Valle De México Campus Querétaro Facultad De Medicina (Queretaro, Mexico)",
  "Universidad Del Valle De México Campus Reynosa Escuela De Medicina (Reynosa, Mexico)",
  "Universidad Del Valle De México Campus Saltillo (Saltillo, Mexico)",
  "Universidad Del Valle De México Campus San Luis Potosí Escuela De Medicina Ciencias De La Salud (San Luis Potosi, Mexico)",
  "Universidad Del Valle De México Campus Villahermosa Escuela De Medicina (Villahermosa, Mexico)",
  "Universidad Del Valle De México Campus Zapopan (Zapopan, Mexico)",
  "Universidad Durango Santander Campus Hermosillo Escuela De Medicina (Hermosillo, Mexico)",
  "Universidad Hipócrates Area De Ceincias De La Salud (Acapulco, Mexico)",
  "Universidad Intercultural De Chiapas (unich) Escuela De Medicina San Cristóbal De Las Casas (San Cristóbal De Las Casas, Mexico)",
  "Universidad Justo Sierra Escuela De Medicina (México, Mexico)",
  "Universidad Juárez Autónoma De Tabasco División Académica Multidisciplinaria De Comalcalco (Comalcalco, Mexico)",
  "Universidad Juárez Autónoma De Tabasco Escuela De Medicina Humana (Villahermosa, Mexico)",
  "Universidad Juárez Del Estado De Durango Facultad De Ciencias De La Salud De Gómez Palacio (Gomez Palacio, Mexico)",
  "Universidad Juárez Del Estado De Durango Facultad De Medicina Y Nutrición (Durango, Mexico)",
  "Universidad La Salle Facultad Mexicana De Medicina (México, Mexico)",
  "Universidad La Salle Victoria Facultad De Ciencias De La Salud (Victoria, Mexico)",
  "Universidad Latina De México Escuela De Medicina (Celaya, Mexico)",
  "Universidad Latinoamericana Escuela De Medicina, Campus Cuernavaca (Cuernavaca, Mexico)",
  "Universidad Marista De Mérida (Merida, Mexico)",
  "Universidad Michoacana De San Nicolas De Hidalgo Facultad De Ciencias Médicas (Morelia, Mexico)",
  "Universidad México Americana Del Norte Escuela De Medicina (Ciudad Reynosa, Mexico)",
  "Universidad Nacional Autónoma De México (unam) Campus Universitario Siglo Xxi (Zinacantepec, Mexico)",
  "Universidad Nacional Autónoma De México (unam) Universidad Ixtlahuaca Cui (Ixtlahuaca, Mexico)",
  "Universidad Nacional Autónoma De México Facultad De Estudios Superiores Iztacala (Tlalnepantla, Mexico)",
  "Universidad Nacional Autónoma De México Facultad De Estudios Superiores Zaragoza (México, Mexico)",
  "Universidad Nacional Autónoma De México Facultad De Medicina (México, Mexico)",
  "Universidad Pablo Guajardo Chávez (Tuxtla Gutiérrez, Mexico)",
  "Universidad Pachuca Siglo Xxi Escuela De Medicina (Pachuca, Mexico)",
  "Universidad Panamericana Escuela De Medicina (México, Mexico)",
  "Universidad Politécnica De Pachuca Escuela De Medicina (Pachuca, Mexico)",
  "Universidad Popular Autónoma Del Estado De Puebla Facultad De Medicina (Puebla, Mexico)",
  "Universidad Quetzalcóatl En Irapuato Escuela De Medicina (Irapuato, Mexico)",
  "Universidad Regional Del Sureste Escuela De Medicina E Cirugía (Oaxaca De Juarez, Mexico)",
  "Universidad Tominaga Nakamoto Escuela De Medicina (Naucalpan De Juarez, Mexico)",
  "Universidad Vasco De Quiroga Escuela De Medicina General (Morelia, Mexico)",
  "Universidad Veracruzana Facultad De Medicina Minatitlán (Minatitlan, Mexico)",
  "Universidad Veracruzana Facultad De Medicina Orizaba-córdoba (Ciudad Mendoza, Mexico)",
  "Universidad Veracruzana Facultad De Medicina Poza Rica (Poza Rica, Mexico)",
  "Universidad Veracruzana Facultad De Medicina Veracruz (Veracruz, Mexico)",
  "Universidad Veracruzana Facultad De Medicina Xalapa (Xalapa, Mexico)",
  "Universidad Villa Rica Escuela De Medicina (Boca Del Rio, Mexico)",
  "Universidad Westhill Facultad De Medicina (México, Mexico)",
  "Universidad Xochicalco Escuela De Medicina Mexicali (Mexicali, Mexico)",
  "New Tokyo Medical College (Pohnpei, Micronesia (federated States Of))",
  "Pacific Basin University School Of Medicine (Pohnpei, Micronesia (federated States Of))",
  "Free International University Of Moldova (Chisinau, Moldova, Republic Of)",
  "Nicolae Testemitanu State University Of Medicine And Pharmacy Of The Republic Of Moldova (Chisinau, Moldova, Republic Of)",
  "Ach Medical University (Ulaanbaatar, Mongolia)",
  "Darkhan-uul Medical College (Darkhan, Mongolia)",
  "Etugen University School Of Medicine (Ulaanbaatar, Mongolia)",
  "Gobi-altai Medical College (Altai City, Mongolia)",
  "Mongolian National University Of Medical Sciences School Of Medicine (Ulaanbaatar, Mongolia)",
  "Otoch Manramba Traditional Medical University (Ulaanbaatar, Mongolia)",
  "Sainshand Medical College (Sainshand, Mongolia)",
  "Ulaanbaatar Medical College (Ulaanbaatar, Mongolia)",
  "University Of Montenegro Faculty Of Medicine (Podgorica, Montenegro)",
  "Université Abdelmalek Essadi Faculté De Médecine Et De Pharmacie De Tanger (Tanger, Morocco)",
  "Université Cadi Ayyad Faculté De Médecine Et De Pharmacie De Marrakech (Marrakech, Morocco)",
  "Université Hassan Ii De Casablanca Faculté De Médecine Et De Pharmacie (Casablanca, Morocco)",
  "Université Ibn Zohr Faculté De Médecine Et De Pharmacie D'agadir (Agadir, Morocco)",
  "Université Internationale Abulcasis Des Sciences De La Santé Faculté De Médecine (Rabat, Morocco)",
  "Université Mohammed I Faculté De Médecine Et De Pharmacie Oujda (Oujda, Morocco)",
  "Université Mohammed V De Rabat Faculté De Médecine Et De Pharmacie (Rabat, Morocco)",
  "Université Mohammed Vi Des Sciences De La Santé Faculté De Médecine (Casablanca, Morocco)",
  "Université Privée De Marrakech Tensift El Haouaz Faculté Privée De Médecine De Marrakech (Marrakech, Morocco)",
  "Université Sidi Mohammed Ben Abdellah Faculté De Médecine Et De Pharmacie De Fès (Fes, Morocco)",
  "Instituto Superior De Ciências E Tecnologia De Moçambique Escola Superior De Ciências Da Saúde (Maputo, Mozambique)",
  "Universidade Católica De Moçambique Faculdade De Ciências De Saúde (Beira, Mozambique)",
  "Universidade Eduardo Mondlane Faculdade De Medicina (Maputo, Mozambique)",
  "Universidade Lúrio Faculdade De Ciências De Saúde (Nampula, Mozambique)",
  "Defence Services Medical Academy (Mingaladon, Myanmar)",
  "University Of Medicine 1 (Yangon, Myanmar)",
  "University Of Medicine 2 (Yangon, Myanmar)",
  "University Of Medicine Magway (Magway, Myanmar)",
  "University Of Medicine Mandalay (Mandalay, Myanmar)",
  "University Of Medicine Taunggyi (Taunggyi, Myanmar)",
  "University Of Namibia School Of Medicine (Windhoek, Namibia)",
  "International University School Of Medicine (Kralendijk, Bonaire)",
  "Erasmus Universiteit Faculteit Der Geneeskunde (Rotterdam, Netherlands)",
  "Faculteit Der Geneeskunde, Uva, Amsterdam Umc (Amsterdam, Netherlands)",
  "Faculty Of Medicine Vu Amsterdam, Amsterdam Umc (Amsterdam, Netherlands)",
  "Leiden University Medical Center (Leiden, Netherlands)",
  "Maastricht University Faculty Of Health, Medicine And Life Sciences (Maastricht, Netherlands)",
  "Radboud Universiteit Nijmegen Faculteit Der Medische Wetenschappen (Nijmegen, Netherlands)",
  "Rijksuniversiteit Groningen Faculteit Der Medische Wetenschappen (Groningen, Netherlands)",
  "Universiteit Utrecht Faculteit Geneeskunde (Utrecht, Netherlands)",
  "Saba University School Of Medicine (The Bottom, Saba)",
  "James Cook School Of Medicine (Rarotonga, Cook Islands)",
  "St Mary's School Of Medicine (cook Islands) (Rarotonga, Cook Islands)",
  "Otago Medical School (Dunedin, New Zealand)",
  "University Of Auckland Faculty Of Medicine And Health Sciences (Auckland, New Zealand)",
  "Lord Liverpool University, George Washington School Of Medicine (Alofi, Niue)",
  "Universidad Americana (uam) Facultad De Medicina (Managua, Nicaragua)",
  "Universidad Católica 'redemptoris Máter' (unica) Facultad De Ciencias Médicas (Managua, Nicaragua)",
  "Universidad Central De Nicaragua Facultad De Medicina (Managua, Nicaragua)",
  "Universidad Centroamericana De Ciencias Empresariales Facultad De Ciencias Médicas (Managua, Nicaragua)",
  "Universidad Cristiana Autónoma De Nicaragua Facultad De Medicina (León, Nicaragua)",
  "Universidad De Ciencias Médicas (Managua, Nicaragua)",
  "Universidad De Defensa De Nicaragua '4 De Mayo' Udenic Facultad De Ciencias Médicas (Managua, Nicaragua)",
  "Universidad Internacional Para El Desarrollo Sostenible (unides) Juigalpa (Juigalpa, Nicaragua)",
  "Universidad Internacional Para El Desarrollo Sostenible (unides) Managua (Managua, Nicaragua)",
  "Universidad Internacional Para El Desarrollo Sostenible (unides) Matagalpa (Solingalpa, Nicaragua)",
  "Universidad Nacional Autónoma De Nicaragua Facultad De Ciencias Médicas León (León, Nicaragua)",
  "Universidad Nacional Autónoma De Nicaragua Facultad De Ciencias Médicas Managua (Managua, Nicaragua)",
  "Universidad Nacional Francisco Luis Espinoza Pineda (Esteli, Nicaragua)",
  "Maryam Abacha American University Of Niger School Of Health Sciences (Maradi, Niger)",
  "Université Abdou Moumouni De Niamey Faculté Des Sciences De La Santé (Niame, Niger)",
  "Abia State University Faculty Of Medicine (Uturu, Nigeria)",
  "Abubakar Tafawa Balewa University College Of Medicine (Bauchi, Nigeria)",
  "Afe Babalola University College Of Medicine And Health Sciences (Ado Ekiti, Nigeria)",
  "Ahmadu Bello University Faculty Of Medicine (Zaria, Nigeria)",
  "Alex Ekwueme Federal University Ndufu Alike Ikwo Faculty Of Basic Medical Sciences (Abakaliki, Nigeria)",
  "Ambrose Alli University College Of Medicine (Ekpoma, Nigeria)",
  "Bayero University Faculty Of Medicine (Kano, Nigeria)",
  "Benjamin S Carson College Of Health And Medical Sciences, Babcock University (Ilishan Remo, Nigeria)",
  "Benue State University College Of Health Sciences (Makurdi, Nigeria)",
  "Bingham University College Of Health Sciences (Karu, Nigeria)",
  "Bowen University College Of Health Sciences (Iwo, Nigeria)",
  "Chukwuemeka Odumegwu Ojukwu University College Of Medical Sciences (Uli, Nigeria)",
  "Delta State University College Of Health Sciences (Abraka, Nigeria)",
  "Ebonyi State University College Of Health Sciences (Abakaliki, Nigeria)",
  "Edo University Iyamho College Of Medical Sciences (Iyamho, Nigeria)",
  "Ekiti State University College Of Medicine (Ado Ekiti, Nigeria)",
  "Enugu State University Of Science And Technology College Of Medicine (Enugu, Nigeria)",
  "Federal University Dutse College Of Medicine And Allied Medical Sciences (Dutse, Nigeria)",
  "Gombe State University College Of Medical Sciences (Gombe, Nigeria)",
  "Gregory University College Of Medicine And Health Sciences (Uturu, Nigeria)",
  "Imo State University College Of Medicine (Owerri, Nigeria)",
  "Kaduna State University Faculty Of Medicine (Kaduna, Nigeria)",
  "Ladoke Akintola University Of Technology (lautech) College Of Health Sciences (Ogbomoso, Nigeria)",
  "Lagos State University College Of Medicine (Ikeja, Nigeria)",
  "Madonna University College Of Medicine (Elele, Nigeria)",
  "Niger Delta University College Of Medical Sciences (Amassoma, Nigeria)",
  "Nile University Of Nigeria College Of Health Sciences (Abuja, Nigeria)",
  "Nnamdi Azikiwe University College Of Health Sciences (Nnewi, Nigeria)",
  "Oba Okunade Sijuade College Of Health Sciences, Igbinedion University (Benin, Nigeria)",
  "Obafemi Awolowo College Of Health Sciences, Olabisi Onabanjo University (Ago Iwoye, Nigeria)",
  "Obafemi Awolowo University College Of Health Sciences (Ile-ife, Nigeria)",
  "Ondo State University Of Medical Sciences (Ondo, Nigeria)",
  "Osun State University College Of Health Sciences (Osogbo, Nigeria)",
  "Pamo University Of Medical Sciences (Port Harcourt, Nigeria)",
  "Rivers State University College Of Medical Sciences (Port Harcourt, Nigeria)",
  "University Of Abuja College Of Health Sciences (Abuja, Nigeria)",
  "University Of Benin College Of Medical Sciences (Benin, Nigeria)",
  "University Of Calabar College Of Medical Sciences (Calabar, Nigeria)",
  "University Of Ibadan College Of Medicine (Ibadan, Nigeria)",
  "University Of Ilorin College Of Health Sciences (Ilorin, Nigeria)",
  "University Of Jos College Of Health Sciences (Jos, Nigeria)",
  "University Of Lagos College Of Medicine (Lagos, Nigeria)",
  "University Of Maiduguri College Of Medical Sciences (Maiduguri, Nigeria)",
  "University Of Nigeria College Of Medicine (Ituku, Nigeria)",
  "University Of Port Harcourt College Of Health Sciences (Port Harcourt, Nigeria)",
  "University Of Uyo College Of Health Sciences (Uyo, Nigeria)",
  "Usmanu Danfodiyo University College Of Health Sciences (Sokoto, Nigeria)",
  "Lord Liverpool University, George Washington School Of Medicine (Alofi, Niue)",
  "Goce Delcev University Faculty Of Medical Sciences (Stip, North Macedonia)",
  "Ss Cyril And Methodius University In Skopje Faculty Of Medicine (Skopje, North Macedonia)",
  "State University Of Tetova Faculty Of Medical Sciences (Tetova, North Macedonia)",
  "Norwegian University Of Science And Technology (ntnu) Faculty Of Medicine (Trondheim, Norway)",
  "Universitetet I Bergen Medisinske Fakultet (Bergen, Norway)",
  "Universitetet I Oslo Medisinske Fakultet (Oslo, Norway)",
  "Universitetet I Tromsø Helsevitenskapelige Fakultet (Tromso, Norway)",
  "Universitetet I Trondheim Medisinske Fakultet (Trondheim, Norway)",
  "National University Of Science And Technology College Of Medicine And Health Sciences (Sohar, Oman)",
  "Sultan Qaboos University College Of Medicine (Muscat, Oman)",
  "Columbus University College Of Medicine And Health Sciences (Panama, Panama)",
  "Universidad Autónoma De Chiriquí Facultad De Medicina (David, Panama)",
  "Universidad De Panamá Facultad De Medicina (Panama, Panama)",
  "Universidad Interamericana De Panamá Facultad De Ciencias De La Salud (Panama, Panama)",
  "Universidad Latina De Panamá Facultad De Ciencias De La Salud Dr William C Gorgas (Panam, Panama)",
  "Divine Word University Faculty Of Medicine And Health Sciences (Madang, Papua New Guinea)",
  "University Of Papua New Guinea School Of Medicine And Health Sciences (Boro, Papua New Guinea)",
  "Medicina Universidad Sudamericana Pedro Juan Caballero (Pedro Juan Caballero, Paraguay)",
  "Medicina Universidad Sudamericana Salto Del Guairá (Salto Del Guairá, Paraguay)",
  "Universidad Autônoma De San Sebastian Facultad De Ciencias Médicas Y De La Salud (uass) (San Lorenzo, Paraguay)",
  "Universidad Católica Nuestra Señora De La Asunción Facultad De Ciencias De La Salud, Asunción (Asunción, Paraguay)",
  "Universidad Católica Nuestra Señora De La Asunción Facultad De Ciencias De La Salud, Guairá (Villarrica, Paraguay)",
  "Universidad Central Del Paraguay Facultad De Medicina, Ciudad Del Este (Ciudad Del Este, Paraguay)",
  "Universidad Central Del Paraguay Facultad De Medicina, Pedro Juan Caballero (Pedro Juan Caballero, Paraguay)",
  "Universidad De La Integración De Las Américas (unida) Facultad De La Salud (Asunción, Paraguay)",
  "Universidad Del Norte Facultad De Medicina Sede Asunción (Asunción, Paraguay)",
  "Universidad Del Pacífico Privada Facultad De Medicina Asunción (Asunción, Paraguay)",
  "Universidad Del Pacífico Privada Facultad De Medicina Pedro Juan Caballero (Pedro Juan Caballero, Paraguay)",
  "Universidad Leonardo Da Vinci Facultad De Ciencias De La Salud, Salto De Guairá (Salto Del Guairá, Paraguay)",
  "Universidad María Auxiliadora (umax) Facultad De Medicina (Asunción, Paraguay)",
  "Universidad María Serrana Facultad De Medicina (Asunción, Paraguay)",
  "Universidad Nacional De Asunción Facultad De Medicina (Asunción, Paraguay)",
  "Universidad Nacional De Concepción Facultad De Medicina (Concepción, Paraguay)",
  "Universidad Nacional De Itapúa Facultad De Medicina (Encarnación, Paraguay)",
  "Universidad Nacional Del Este Facultad De Ciencias De La Salud (Minga Guazú, Paraguay)",
  "Universidad Alas Peruanas Facultad De Medicina Humana Y Ciencias De La Salud (Lima, Peru)",
  "Universidad Andina Del Cusco Facultad De Ciencias De La Salud (San Jerónimo, Peru)",
  "Universidad Andina Néstor Cáceres Velásquez Escuela Profesional Medicina Humana (Juliaca, Peru)",
  "Universidad Católica De Santa María Facultad De Medicina Humana (Arequipa, Peru)",
  "Universidad Católica Santo Toribio De Mogrovejo (usat) Facultad De Medicina (Chiclayo, Peru)",
  "Universidad Científica Del Sur Facultad De Ciencias De La Salud (Lima, Peru)",
  "Universidad Continental Escuela De Medicina Humana (Huancayo, Peru)",
  "Universidad César Vallejo Facultad De Ciencias Médicas (Trujillo, Peru)",
  "Universidad César Vallejo Facultad De Ciencias Médicas Piura (Piura, Peru)",
  "Universidad De Piura Facultad De Medicina Humana (Piura, Peru)",
  "Universidad De San Martin De Porres Facultad De Medicina Chiclayo (Chiclayo, Peru)",
  "Universidad De San Martin De Porres Facultad De Medicina Humana (Lima, Peru)",
  "Universidad Nacional Daniel Alcídes Carrión Facultad De Medicina Humana (Cerro De Pasco, Peru)",
  "Universidad Nacional De Cajamarca Facultad De Medicina Humana (Cajamarca, Peru)",
  "Universidad Nacional De La Amazonía Peruana Facultad De Medicina Humana (Iquitos, Peru)",
  "Universidad Nacional De Piura Facultad De Medicina Humana (Piura, Peru)",
  "Universidad Nacional De San Agustín Facultad De Medicina (Arequipa, Peru)",
  "Universidad Nacional De San Cristóbal De Huamanga Escuela Profesional De Medicina Humana (Ayacucho, Peru)",
  "Universidad Nacional De San Martín Facultad De Medicina Humana (Tarapoto, Peru)",
  "Universidad Nacional De Trujillo Facultad De Medicina (Trujillo, Peru)",
  "Universidad Nacional De Tumbes Escuela De Medicina Humana (Tumbes, Peru)",
  "Universidad Nacional De Ucayali Facultad De Medicina Humana (Pucallpa, Peru)",
  "Universidad Nacional Del Altiplano Escuela Profesional De Medicina Humana (Puno, Peru)",
  "Universidad Nacional Del Centro De Perú Facultad De Medicina Humana (Huancayo, Peru)",
  "Universidad Nacional Del Santa Escuela Profesional De Medicina Humana (Nuevo Chimbote, Peru)",
  "Universidad Nacional Federico Villarreal Facultad De Medicina 'hipólito Unanue' (Lima, Peru)",
  "Universidad Nacional Hermilio Valdizán Escuela De Medicina Humana (Huanuco, Peru)",
  "Universidad Nacional Jorge Basadre Grohmann Escuela Profesional De Medicina Humana (Tacna, Peru)",
  "Universidad Nacional José Faustino Sánchez Carrión Facultad De Medicina Humana (Lima, Peru)",
  "Universidad Nacional Mayor De San Marcos Facultad De Medicina San Fernando (Lima, Peru)",
  "Universidad Nacional Pedro Ruiz Gallo Facultad De Medicina Humana (Lambayeque, Peru)",
  "Universidad Nacional San Antonio Abad Del Cusco Facultad De Medicina Humana (Cusco, Peru)",
  "Universidad Nacional San Luis Gonzaga De Ica Facultad De Medicina Humana Dr Daniel Alcides Carrión (Ica, Peru)",
  "Universidad Nacional Toribio Rodríguez De Mendoza De Amazonas Facultad De Ciencias De La Salud (Chachapoyas, Peru)",
  "Universidad Nacional Técnica De Cajamarca Facultad De Medicina (Cajamarca, Peru)",
  "Universidad Particular De Chiclayo Escuela Profesional De Medicina Humana (Chiclayo, Peru)",
  "Universidad Peruana Cayetano Heredia Facultad De Medicina Alberto Hurtado (Lima, Peru)",
  "Universidad Peruana De Ciencias Aplicadas (upc) Escuela De Medicina (Lima, Peru)",
  "Universidad Peruana Los Andes Facultad De Medicina Humana (Huancayo, Peru)",
  "Universidad Peruana Unión Facultad De Ciencias De La Salud (Chaclacayo, Peru)",
  "Universidad Privada Antenor Orrego Facultad De Medicina Humana (upao) (Trujillo, Peru)",
  "Universidad Privada Antenor Orrego Facultad De Medicina Piura (Piura, Peru)",
  "Universidad Privada De Tacna Facultad De Ciencias De La Salud (Tacna, Peru)",
  "Universidad Privada Norbert Wiener Facultad De Ciencias De La Salud (Lima, Peru)",
  "Universidad Privada San Juan Bautista Escuela Profesional De Medicina Humana Chincha (Chincha, Peru)",
  "Universidad Privada San Juan Bautista Escuela Profesional De Medicina Humana Chorrillos (Chorrillos, Peru)",
  "Universidad Privada San Juan Bautista Escuela Profesional De Medicina Humana Ica (Ica, Peru)",
  "Universidad Privada Telesup Escuela De Medicina Humana Lurigancho (Lurigancho, Peru)",
  "Universidad Ricardo Palma Facultad De Medicina Humana (Lima, Peru)",
  "Universidad San Ignacio Del Loyola Facultad De Ciencias De La Salud (Lima, Peru)",
  "Universidad San Pedro Facultad De Medicina Humana (Nuevo Chimbote, Peru)",
  "Universidad Señor De Sipán Escuela De Medicina Humana (Chiclayo, Peru)",
  "Instituto De Ciências Biomédicas Abel Salazar (Porto, Portugal)",
  "Universidade Católica Portuguesa Faculdade De Medicina (Rio De Mouro, Portugal)",
  "Universidade Da Beira Interior Faculdade De Ciências Da Saúde (Covilha, Portugal)",
  "Universidade De Coimbra Faculdade De Medicina (Coimbra, Portugal)",
  "Universidade De Lisboa Faculdade De Medicina (Lisboa, Portugal)",
  "Universidade Do Algarve Departamento De Ciências Biomédicas E Medicina (Faro, Portugal)",
  "Universidade Do Minho Escola De Medicina (Braga, Portugal)",
  "Universidade Do Porto Faculdade De Medicina (Porto, Portugal)",
  "Universidade Nova De Lisboa Faculdade De Ciências Médicas (Lisboa, Portugal)",
  "Qatar University College Of Medicine (Doha, Qatar)",
  "Weill Cornell Medical College In Qatar (Doha, Qatar)",
  "Universitatea 'dunărea De Jos' Din Galați Facultatea De Medicină Și Farmacie (Galati, Romania)",
  "Universitatea De Medicină Și Farmacie Carol Davila Facultatea De Medicină (Bucharest, Romania)",
  "Universitatea De Medicină Și Farmacie Din Craiova Facultatea De Medicină (Craiova, Romania)",
  "Universitatea De Medicină Și Farmacie Grigore T Popa Facultatea De Medicină (Iasi, Romania)",
  "Universitatea De Medicină Și Farmacie Iuliu Haţieganu Facultatea De Medicină (Cluj-napoca, Romania)",
  "Universitatea De Medicină Și Farmacie Victor Babeş Facultatea De Medicină (Timisoara, Romania)",
  "Universitatea De Medicină, Farmacie, Științe Și Tehnologie 'george Emil Palade' Târgu Mureș (Târgu Mureș, Romania)",
  "Universitatea De Vest Vasile Goldis Din Arad Facultatea De Medicină (Arad, Romania)",
  "Universitatea Din Oradea Facultatea De Medicină Și Farmacie (Oradea, Romania)",
  "Universitatea Lucian Blaga Din Sibiu Facultatea De Medicină 'victor Papilian' (Sibiu, Romania)",
  "Universitatea Ovidius Constanta Facultatea De Medicină (Constanta, Romania)",
  "Universitatea Titu Maiorescu Facultatea De Medicină Și Medicină Dentara (Bucharest, Romania)",
  "Universitatea Transilvania Din Brașov Facultatea De Medicină (Brasov, Romania)",
  "Adventist School Of Medicine Of East-central Africa (Kigali, Rwanda)",
  "University Of Global Health Equity (Kigali, Rwanda)",
  "University Of Rwanda College Of Medicine And Health Sciences (Butare, Rwanda)",
  "Saba University School Of Medicine (The Bottom, Saba)",
  "21 September University For Medical And Applied Sciences (Sana'a, Yemen)",
  "Aden University Faculty Of Medicine (Aden, Yemen)",
  "Ai-baydha University Faculty Of Medicine And Health Sciences (Rada'a, Yemen)",
  "Ai-jeel Ai-jadeed University Faculty Of Medicine And Health Sciences (Sana'a, Yemen)",
  "Al-arab University College Of Medicine And Health Sciences (Mukalla, Yemen)",
  "Al-mahweet University Faculty Of Medicine And Health Sciences (Hajrat Anbar, Yemen)",
  "Al-razi University Faculty Of Medicine And Health Sciences (Sana'a, Yemen)",
  "Al-saeeda University Faculty Of Medicine (Dhamar, Yemen)",
  "Alyemenia University College Of Medical Sciences (Sana'a, Yemen)",
  "Amran University Faculty Of Medicine And Health Sciences (Amran, Yemen)",
  "Applied And Social Sciences University Faculty Of Medicine (Sana'a, Yemen)",
  "Ar-rasheed Smart University Faculty Of Medicine And Health Science (Haddah, Yemen)",
  "Civilization University Faculty Of Medicine (Haddah, Yemen)",
  "Dar Al Salam International University Of Science And Technology College Of Human Medicine (Sana'a, Yemen)",
  "Emirates International University College Of Medicine And Health Sciences (Sana'a, Yemen)",
  "Genius University For Sciences And Technology College Of Medicine And Health Sciences (Dhamar, Yemen)",
  "Hadhramout University Of Science And Technology College Of Medicine And Health Sciences (Mukalla, Yemen)",
  "Hikma University College Of Medical Sciences (Sana'a, Yemen)",
  "Hodeidah University Faculty Of Medicine And Health Science (Al Hudaydah, Yemen)",
  "Ibb University Faculty Of Medicine And Health Sciences (Ibb, Yemen)",
  "Jiblah University For Medical And Health Sciences (Jiblah, Yemen)",
  "National University College Of Medicine And Health Sciences (Taiz, Yemen)",
  "Saada University Faculty Of Medicine And Health Sciences (Saada, Yemen)",
  "Seiyun University College Of Medicine And Health Sciences (Seiyun, Yemen)",
  "Shabwah University College Of Medicine And Health Sciences (Ataq, Yemen)",
  "Taiz University Faculty Of Medicine And Health Science (Taiz, Yemen)",
  "Thamar University Faculty Of Medicine & Health Sciences (Dhamar, Yemen)",
  "University Of Saba Region Faculty Of Medicine (Marib, Yemen)",
  "University Of Sana'a Faculty Of Medicine And Health Sciences (Sana'a, Yemen)",
  "University Of Science And Technology Aden Faculty Of Medicine And Health Sciences (Aden, Yemen)",
  "University Of Science And Technology Sana'a Faculty Of Medicine And Health Sciences (Sana'a, Yemen)",
  "Yemen University College Of Medical Sciences (Sana'a, Yemen)",
  "Cavendish University School Of Medicine (Lusaka, Zambia)",
  "Eden University School Of Medicine And Health Sciences (Lusaka, Zambia)",
  "Lusaka Apex Medical University (Lusaka, Zambia)",
  "Michael Chilufya Sata School Of Medicine Of The Copperbelt University (Ndola, Zambia)",
  "Mulungushi University School Of Medicine And Health Sciences (Kabwe, Zambia)",
  "Texila American University College Of Medicine (Lusaka, Zambia)",
  "University Of Lusaka School Of Medicine And Health Sciences (Lusaka, Zambia)",
  "University Of Zambia School Of Medicine (Lusaka, Zambia)",
  "National University Of Science And Technology Faculty Of Medicine (Bulawayo, Zimbabwe)",
  "University Of Zimbabwe Faculty Of Medicine And Health Science (Harare, Zimbabwe)",
  "Busitema University Faculty Of Health Science (Busitema, Uganda)",
  "Gulu University Faculty Of Medicine (Gulu, Uganda)",
  "Habib Medical School, Islamic University In Uganda (Mbale, Uganda)",
  "Kampala International University Faculty Of Medicine And Dentistry (Ishaka, Uganda)",
  "Kigezi International School Of Medicine (Kabale, Uganda)",
  "King Ceasor University College Of Medicine, Health And Life Sciences (Kampala, Uganda)",
  "Makerere University School Of Medicine (Kampala, Uganda)",
  "Mbarara University Of Science And Technology Faculty Of Medicine (Mbarara, Uganda)",
  "Uganda Christian University Medical School (Mukono, Uganda)",
  "Bogomolets National Medical University (Kyiv, Ukraine)",
  "Bohdan Khmelnytsky National University Of Cherkasy Institute Of Medicine (Cherkasy, Ukraine)",
  "Bukovinian State Medical University (Chernivtsi, Ukraine)",
  "Danylo Halytsky Lviv National Medical University (Lviv, Ukraine)",
  "Dnipro Institute Of Medicine And Public Health (Dnipro, Ukraine)",
  "Dnipro State Medical University (Dnipro, Ukraine)",
  "Donetsk National Medical University Faculty Of General Medicine (Lyman, Ukraine)",
  "European Medical University (Dnipro, Ukraine)",
  "I Ya Horbachevsky Ternopil National Medical University (Ternopil, Ukraine)",
  "International Academy Of Ecology And Medicine (Kyiv, Ukraine)",
  "International European University European Medical School (Kyiv, Ukraine)",
  "International Humanitarian University Odessa Medical Institute (Odessa, Ukraine)",
  "Ivano-frankivsk National Medical University (Ivano-frankivsk, Ukraine)",
  "Kharkiv Institute Of Medicine And Biomedical Sciences (Kharkiv, Ukraine)",
  "Kharkiv International Medical University (Kharkiv, Ukraine)",
  "Kharkiv National Medical University (Kharkiv, Ukraine)",
  "Kyiv International University Medical Institute (Kyiv, Ukraine)",
  "Kyiv Medical University Faculty Of Medicine (Kyiv, Ukraine)",
  "Lesya Ukrainka Volyn National University Medical Institute (Lutsk, Ukraine)",
  "Lugansk State Medical University (Rivne, Ukraine)",
  "Lviv Medical Institute (Lviv, Ukraine)",
  "Medical Academy Named After S I Georgievsky Of Vernadsky Cfu (Simferopol, Ukraine)",
  "National Pirogov Memorial Medical University, Vinnitsa (Vinnytsya, Ukraine)",
  "Odessa National Medical University (Odessa, Ukraine)",
  "Oles Honchar Dnipro National University (Dnipro, Ukraine)",
  "Petro Mohyla Black Sea National University Faculty Of Medicine (Mykolayiv, Ukraine)",
  "Poltava State Medical University (Poltava, Ukraine)",
  "South Ukrainian National Pedagogical University Named After K D Ushynsky Faculty Of Medicine (Odessa, Ukraine)",
  "Stalino Medical Institute (Stalino, Ukraine)",
  "Stanislav Medical Institute (Stanislav, Ukraine)",
  "Sumy State University Medical Institute (Sumy, Ukraine)",
  "Taras Shevchenko National University Of Kyiv Institute Of Biology And Medicine (Kyiv, Ukraine)",
  "Ukrainian Military Medical Academy (Kyiv, Ukraine)",
  "Uzhhorod National University Faculty Of Medicine (Uzhhorod, Ukraine)",
  "V N Karazin Kharkiv National University School Of Medicine (Kharkiv, Ukraine)",
  "Zaporizhzhia State Medical And Pharmaceutical University (Zaporizhzhia, Ukraine)",
  "Centro Latinoamericana De Economia Humana (claeh) Facultad De Medicina (Punta Del Este, Uruguay)",
  "Universidad De La República Facultad De Medicina (Montevideo, Uruguay)",
  "Andizhan State Medical Institute (Andizhan, Uzbekistan)",
  "Asia International University Faculty Of Medicine (Bukhara City, Uzbekistan)",
  "Bukhara Innovative Medical Institute (Bukhara, Uzbekistan)",
  "Bukhara State Medical Institute Named After Abu Ali Ibn Sino (Bukhara, Uzbekistan)",
  "Central Asian Medical University (Ferghana, Uzbekistan)",
  "Central Asian University Medical School (Tashkent, Uzbekistan)",
  "Emu University Faculty Of Medicine (Tashkent, Uzbekistan)",
  "Fergana Medical Institute Of Public Health (Fergana City, Uzbekistan)",
  "Fergana State University Medical Centre (Fergana, Uzbekistan)",
  "First Tashkent State Medical Institute (Tashkent, Uzbekistan)",
  "Impuls Medical Institute (Namangan, Uzbekistan)",
  "Medical Institute Of Karakalpakstan (Nukus, Uzbekistan)",
  "Samarkand State Medical University (Samarkand, Uzbekistan)",
  "Second Tashkent State Medical Institute (Tashkent, Uzbekistan)",
  "Tashkent Medical Academy Faculty Of Medicine Tashkent (Tashkent, Uzbekistan)",
  "Tashkent Medical Academy, Termiz Branch (Termez, Uzbekistan)",
  "Tashkent Pediatric Medical Institute (Tashkent, Uzbekistan)",
  "Tashkent State Dental Institute Faculty Of Medicine (Tashkent, Uzbekistan)",
  "Turon Zarmed University Faculty Of Medicine (Bukhara, Uzbekistan)",
  "Urgench Branch Of Tashkent Medical Academy (Urgench, Uzbekistan)",
  "Hills College Of Medicine (Port Vila, Vanuatu)",
  "Vanuatu College Of Medicine (Port Vila, Vanuatu)",
  "Universidad Central De Venezuela Escuela De Medicina Jose Maria Vargas (Caracas, Venezuela (bolivarian Republic Of))",
  "Universidad Central De Venezuela Escuela De Medicina Luis Razetti (Caracas, Venezuela (bolivarian Republic Of))",
  "Universidad Centro-occidental Lisandro Alvarado Decanato De Ciencias De La Salud (Barquisimeto, Venezuela (bolivarian Republic Of))",
  "Universidad De Carabobo Facultad De Ciencias De La Salud, Aragua (Aragua, Venezuela (bolivarian Republic Of))",
  "Universidad De Carabobo Facultad De Ciencias De La Salud, Valencia (Valencia, Venezuela (bolivarian Republic Of))",
  "Universidad De Las Ciencias De La Salud Hugo Chávez Frías (Caracas, Venezuela (bolivarian Republic Of))",
  "Universidad De Los Andes (venezuela) Facultad De Medicina (Merida, Venezuela (bolivarian Republic Of))",
  "Universidad De Oriente Escuela De Medicina, Nucleo De Anzoategui (Barcelona, Venezuela (bolivarian Republic Of))",
  "Universidad De Oriente Escuela De Medicina, Nucleo De Bolivar (Ciudad Bolivar, Venezuela (bolivarian Republic Of))",
  "Universidad Del Zulia Facultad De Medicina (Maracaibo, Venezuela (bolivarian Republic Of))",
  "Universidad Nacional Experimental De Los Llanos Centrales Rómulo Gallegos Escuela De Medicina (San Juan De Los Morros, Venezuela (bolivarian Republic Of))",
  "Universidad Nacional Experimental Francisco De Miranda Área De Ciencias De La Salud (Coro, Venezuela (bolivarian Republic Of))",
  "Buon Ma Thuot Medical University (Buon Ma Thuot, Viet Nam)",
  "Can Tho University Of Medicine And Pharmacy Faculty Of Medicine (Can Tho, Viet Nam)",
  "Dai Nam University Faculty Of Medicine (Hanoi, Viet Nam)",
  "Duy Tan University Faculty Of Medicine (Da Nang, Viet Nam)",
  "Hai Phong University Of Medicine And Pharmacy (Hai Phong, Viet Nam)",
  "Hanoi Medical University (Hanoi, Viet Nam)",
  "Hong Bang International University Faculty Of Medicine (Ho Chi Minh, Viet Nam)",
  "Hue University Of Medicine And Pharmacy (Hue, Viet Nam)",
  "Nam Can Tho University Faculty Of Medicine (Can Tho City,, Viet Nam)",
  "Nguyen Tat Thanh University Faculty Of Medicine (Ho Chi Minh, Viet Nam)",
  "Pham Ngoc Thach University Of Medicine Faculty Of Medicine (Ho Chi Minh, Viet Nam)",
  "Phan Chau Trinh University (Dien Ban Town, Viet Nam)",
  "Phenikaa University Faculty Of Medicine (Hanoi, Viet Nam)",
  "Tan Tao University School Of Medicine (Duc Hoa Ha, Viet Nam)",
  "Tay Nguyen University Faculty Of Medicine And Pharmacy (Buon Ma Thuot, Viet Nam)",
  "Thai Binh University Of Medicine And Pharmacy (Thai Binh, Viet Nam)",
  "Thai Nguyen University Of Medicine And Pharmacy Faculty Of Medicine (Thai Nguyen, Viet Nam)",
  "Tra Vinh University School Of Medicine And Pharmacy (Tra Vinh, Viet Nam)",
  "University Of Danang Faculty Of Medicine And Pharmacy (Da Nang, Viet Nam)",
  "University Of Medicine And Pharmacy Of Ho Chi Minh City (Ho Chi Minh, Viet Nam)",
  "University Of Medicine And Pharmacy, Vietnam National University, Hanoi (Hanoi, Viet Nam)",
  "Vietnam Military Medical University Faculty Of Medicine (Hanoi, Viet Nam)",
  "Vietnam National University Of Ho Chi Minh City School Of Medicine (Di An City, Viet Nam)",
  "Vietnam University Of Traditional Medicine (Hanoi, Viet Nam)",
  "Vinh Medical University Faculty Of Medicine (Vinh, Viet Nam)",
  "Vinuniversity College Of Health Sciences (Hanoi, Viet Nam)",
  "Vo Truong Toan University Faculty Of Medicine (Tan Phu Thanh, Viet Nam)",
  "Avicenna Tajik State Medical University (Dushanbe, Tajikistan)",
  "Avicenna Tajik State Medical University, Khujand Branch (Khujand, Tajikistan)",
  "Khatlon State Medical University (Dangara, Tajikistan)",
  "Medical Social Institute Of Tajikistan (Dushanbe, Tajikistan)",
  "Stalinabad Medical Institute (Dushanbe, Tajikistan)",
  "Tajik National University Faculty Of Medicine (Dushanbe City, Tajikistan)",
  "Hubert Kairuki Memorial University Faculty Of Medicine (Dar Es Salaam, Tanzania, United Republic Of)",
  "International Medical & Technological University College Of Medicine (Dar Es Salaam, Tanzania, United Republic Of)",
  "Kampala International University In Tanzania Faculty Of Medicine And Pharmaceutical Sciences (Dar Es Salaam, Tanzania, United Republic Of)",
  "Kilimanjaro Christian Medical University College (Moshi, Tanzania, United Republic Of)",
  "Muhimbili University Of Health And Allied Sciences School Of Medicine (Dar Es Salaam, Tanzania, United Republic Of)",
  "St Francis University College Of Health And Allied Sciences, St Augustine University Of Tanzania (Ifakara, Tanzania, United Republic Of)",
  "St Joseph College Of Health And Allied Sciences (Dar Es Salaam, Tanzania, United Republic Of)",
  "State University Of Zanzibar School Of Medicine & Health Sciences (smhs) (Tunguu, Tanzania, United Republic Of)",
  "University Of Dar Es Salaam Mbeya College Of Health And Allied Sciences (udsm-mchas) (Mbeya, Tanzania, United Republic Of)",
  "University Of Dodoma School Of Medicine And Dentistry (Dodoma, Tanzania, United Republic Of)",
  "Weill Bugando School Of Medicine, Catholic University Of Health And Allied Sciences (Mwanza, Tanzania, United Republic Of)",
  "Bangkok Thonburi University Faculty Of Medicine (Bangkok, Thailand)",
  "Burapha University Faculty Of Medicine (Saensook, Thailand)",
  "Chiang Mai University Faculty Of Medicine (Chiang Mai, Thailand)",
  "Chulalongkorn University Faculty Of Medicine (Bangkok, Thailand)",
  "Faculty Of Medicine Vajira Hospital (Bangkok, Thailand)",
  "Khon Kaen University Faculty Of Medicine (Kohn Kaen, Thailand)",
  "King Mongkut's Institute Of Technology Ladkrabang Faculty Of Medicine (Bangkok, Thailand)",
  "Mae Fah Luang University School Of Medicine (Chiang Rai, Thailand)",
  "Mahasarakham University Faculty Of Medicine (Mahasarakham, Thailand)",
  "Naresuan University Faculty Of Medicine (Phitsanulok, Thailand)",
  "Phramongkutklao Medical College (Bangkok, Thailand)",
  "Prince Of Songkla University Faculty Of Medicine (Songkhla, Thailand)",
  "Princess Of Naradhiwas University Faculty Of Medicine (Narathiwat, Thailand)",
  "Princess Srisavangavadhana College Of Medicine, Chulabhorn Royal Academy (Bangkok, Thailand)",
  "Ramathibodi Hospital Faculty Of Medicine (Bangkok, Thailand)",
  "Rangsit University Faculty Of Medicine (Bangkok, Thailand)",
  "Siam University Faculty Of Medicine (Bangkok, Thailand)",
  "Siriraj Hospital Faculty Of Medicine (Bangkok, Thailand)",
  "Srinakharinwirot University Faculty Of Medicine (Bangkok, Thailand)",
  "Suranaree University Of Technology Institute Of Medicine (Nakhon Ratchasima, Thailand)",
  "Thammasat University Faculty Of Medicine (Klong Nueng, Thailand)",
  "Ubon Ratchathani University College Of Medicine And Public Health (Warin Chamrap, Thailand)",
  "University Of Phayao School Of Medicine (Phayao, Thailand)",
  "Walailak University School Of Medicine (Tha Sala, Thailand)",
  "Western University Faculty Of Medicine (Lat Sawai, Thailand)",
  "Université De Lomé Au Togo Faculté Des Sciences De La Santé (Lome, Togo)",
  "University Of The West Indies Faculty Of Medicine St Augustine, Trinidad (St Augustine, Trinidad And Tobago)",
  "Faculté De Médecine 'ibn El Jazzar' De Sousse (Sousse, Tunisia)",
  "Faculté De Médecine De Monastir (Monastir, Tunisia)",
  "Faculté De Médecine De Sfax (Sfax, Tunisia)",
  "Faculté De Médecine De Tunis (Tunis, Tunisia)",
  "Myrat Garryyev State Medical University Of Turkmenistan (Ashkhabat, Turkmenistan)",
  "Al Andalus University For Medical Sciences Faculty Of Medicine (Al Qadmus, Syrian Arab Republic)",
  "Al Sham Private University Faculty Of Medicine (Al-tal, Syrian Arab Republic)",
  "Al-baath University Faculty Of Medicine (Homs, Syrian Arab Republic)",
  "Al-furat University Faculty Of Medicine (Deir Ezzor, Syrian Arab Republic)",
  "Al-hawash Private University Faculty Of Medicine (Homs, Syrian Arab Republic)",
  "Idleb University Faculty Of Medicine (Idleb, Syrian Arab Republic)",
  "Kalamoon University Faculty Of Medicine (Deratiah, Syrian Arab Republic)",
  "Syrian Private University (Damascus, Syrian Arab Republic)",
  "University Of Aleppo Faculty Of Medicine (Aleppo, Syrian Arab Republic)",
  "University Of Damascus Faculty Of Medicine (Damascus, Syrian Arab Republic)",
  "University Of Hama Faculty Of Human Medicine (Hama, Syrian Arab Republic)",
  "University Of Tartous College Of Medicine (Tartus, Syrian Arab Republic)",
  "University Of Tishreen Faculty Of Medicine (Lattakia, Syrian Arab Republic)",
  "Università Della Svizzera Italiana Facoltà Di Scienze Biomediche (Lugano, Switzerland)",
  "Universität Basel Medizinische Fakultät (Basel, Switzerland)",
  "Universität Bern Medizinische Fakultät (Bern, Switzerland)",
  "Universität Zürich Medizinische Fakultät (Zürich, Switzerland)",
  "Université De Fribourg Faculté Des Sciences Et De Médecine (Fribourg, Switzerland)",
  "Université De Genève Faculté De Médecine (Geneva, Switzerland)",
  "Université De Lausanne Faculté De Biologie Et De Médecine (Lausanne, Switzerland)",
  "Karolinska Institute Faculty Of Medicine (Stockholm, Sweden)",
  "Linköping University Faculty Of Medicine And Health Sciences (Linkoping, Sweden)",
  "Lund University Faculty Of Medicine (Lund, Sweden)",
  "Sahlgrenska Academy, University Of Gothenburg (Goteborg, Sweden)",
  "Umea University Faculty Of Medicine (Umeå, Sweden)",
  "Uppsala University Faculty Of Medicine (Uppsala, Sweden)",
  "Örebro University School Of Medical Sciences (Orebro, Sweden)",
  "Anton De Kom Universiteit Van Suriname Faculteit Der Medische Wetenschappen (Paramar, Suriname)",
  "Ahfad University For Women School Of Medicine (Omdurman, Sudan)",
  "Al Neelain University Faculty Of Medicine (Khartoum, Sudan)",
  "Al-fajr College For Science And Technology (Khartoum, Sudan)",
  "Al-farabi College Of Science And Technology (Khartoum, Sudan)",
  "Al-madain College For Medical Sciences And Technology (Khartoum, Sudan)",
  "Al-safwaa College Of Sciences And Technology (Khartoum North, Sudan)",
  "Al-yarmouk College Faculty Of Medical Sciences (Khartoum, Sudan)",
  "Alfashir University Faculty Of Medicine And Health Sciences (Al Fasher, Sudan)",
  "Almughtaribeen University Faculty Of Medicine (Khartoum, Sudan)",
  "Alzaiem Alazhari University Faculty Of Medicine (Khartoum, Sudan)",
  "Azza University College For Women Faculty Of Medicine (Khartoum, Sudan)",
  "Bayan College Of Science And Technology (Khartoum, Sudan)",
  "Eastern Sudan College For Medical Sciences And Technology (Port Sudan, Sudan)",
  "Elrazi University (Khartoum, Sudan)",
  "Elsheikh Abdallah Elbadri University Faculty Of Medicine (Berber, Sudan)",
  "Faculty Of Medicine, National University - Sudan (Khartoum, Sudan)",
  "Gezira College Of Medical Sciences And Technology (Wad Medani, Sudan)",
  "Hayatt University College (Khartoum, Sudan)",
  "Ibn Sina University Faculty Of Medicine & Surgery (Khartoum, Sudan)",
  "Imperial University College (Khartoum, Sudan)",
  "International University Of Africa Faculty Of Medicine & Health Sciences (Khartoum, Sudan)",
  "Karary University College Of Medicine And Surgery (Omdurman, Sudan)",
  "Kassala University Faculty Of Medicine (Kassala, Sudan)",
  "Nahda College (Khartoum, Sudan)",
  "Napata College (Khartoum, Sudan)",
  "National Ribat University College Of Medicine (Burri, Sudan)",
  "Nile University Faculty Of Medicine (Khartoum, Sudan)",
  "Nile Valley University Faculty Of Medicine And Health Sciences (Atbara, Sudan)",
  "Omdurman Ahlia University Faculty Of Medicine (Omdurman, Sudan)",
  "Omdurman Islamic University Faculty Of Medicine And Health Sciences (Omdurman, Sudan)",
  "Red Sea University Faculty Of Medicine And Health Sciences (Port Sudan, Sudan)",
  "Riyadh International College (Khartoum, Sudan)",
  "Shendi University Faculty Of Medicine And Health Sciences (Shendi, Sudan)",
  "Sinnar University Faculty Of Medicine (Sinnar, Sudan)",
  "Sudan International University Faculty Of Medicine (Khartoum, Sudan)",
  "Sudan University Of Science And Technology College Of Medicine (Khartoum, Sudan)",
  "University Of Bahri College Of Medicine (Khartoum, Sudan)",
  "University Of Bakht Alruda Faculty Of Medicine And Health Sciences (Elduim, Sudan)",
  "University Of Blue Nile Faculty Of Medicine And Health Sciences (Ad-damazin, Sudan)",
  "University Of Dongola Faculty Of Medicine And Health Sciences (Dongola, Sudan)",
  "University Of El Imam El Mahdi Faculty Of Medicine Kosti (Khartoum, Sudan)",
  "University Of Gadarif Faculty Of Medicine And Health Science (Gadarif, Sudan)",
  "University Of Garden City Faculty Of Medicine (Khartoum, Sudan)",
  "University Of Gezira Faculty Of Medicine (Wad Medani, Sudan)",
  "University Of Khartoum Faculty Of Medicine (Khartoum, Sudan)",
  "University Of Kordofan Faculty Of Medicine And Health Sciences (El Obied, Sudan)",
  "University Of Medical Sciences & Technology (umst) Faculty Of Medicine (Khartoum, Sudan)",
  "University Of Nyala Faculty Of Medicine (Nyala, Sudan)",
  "University Of Science And Technology Faculty Of Medicine (Omdurman, Sudan)",
  "Wad Medani College Of Medical Sciences And Technology (Wad Medani, Sudan)",
  "West Kordufan University Faculty Of Medicine And Health Science (Elnuhod, Sudan)",
  "Faculty Of Health-care Sciences, Eastern University, Sri Lanka (Batticaloa, Sri Lanka)",
  "General Sir John Kotelawala Defence University Faculty Of Medicine (Rathmalana, Sri Lanka)",
  "Rajarata University Of Sri Lanka Faculty Of Medicine And Allied Sciences (Saliyapura, Sri Lanka)",
  "South Asian Institute Of Technology And Medicine Faculty Of Medicine (Malabe, Sri Lanka)",
  "University Of Colombo Faculty Of Medicine (Colombo, Sri Lanka)",
  "University Of Jaffna Faculty Of Medicine (Jaffna, Sri Lanka)",
  "University Of Kelaniya Faculty Of Medicine (Ragama, Sri Lanka)",
  "University Of Peradeniya Faculty Of Medicine (Peradeniya, Sri Lanka)",
  "University Of Ruhuna Faculty Of Medicine (Galle, Sri Lanka)",
  "University Of Sri Jayewardenepura Faculty Of Medical Sciences (Nugegoda, Sri Lanka)",
  "Euskal Herriko Unibertsitatea Medikuntza Eta Erizaintza Fakultatea (Leioa, Spain)",
  "Universidad Alfonso X El Sabio Facultad De Ciencias De La Salud (Villanueva De La Cañada, Spain)",
  "Universidad Autónoma De Madrid Facultad De Medicina (Madrid, Spain)",
  "Universidad Católica De Valencia San Vicente Mártir Facultad De Medicina Y Ciencias De La Salud (Valencia, Spain)",
  "Universidad Católica San Antonio De Murcia Facultad De Ciencias De La Salud (Murcia, Spain)",
  "Universidad Ceu Cardenal Herrera Facultad De Ciencias De La Salud Castellón (Castellón De La Plana, Spain)",
  "Universidad Ceu Cardenal Herrera Facultad De Ciencias De La Salud Valencia (Alfara Del Patriarca, Spain)",
  "Universidad Ceu San Pablo Facultad De Medicina (Madrid, Spain)",
  "Universidad Complutense De Madrid Facultad De Medicina (Madrid, Spain)",
  "Universidad De Alcalá Facultad De Medicina (Alcala De Henares, Spain)",
  "Universidad De Alicante Facultad De Medicina (Alicante, Spain)",
  "Universidad De Cantabria Facultad De Medicina (Santander, Spain)",
  "Universidad De Castilla-la Mancha Facultad De Medicina De Albacete (Albacete, Spain)",
  "Universidad De Castilla-la Mancha Facultad De Medicina De Ciudad Real (Ciudad Real, Spain)",
  "Universidad De Cádiz Facultad De Medicina (Cadiz, Spain)",
  "Universidad De Córdoba Facultad De Medicina Y Enfermería (Cordoba, Spain)",
  "Universidad De Extremadura Facultad De Medicina (Badajoz, Spain)",
  "Universidad De Granada Facultad De Medicina (Granada, Spain)",
  "Universidad De La Laguna Facultad De Medicina (La Laguna, Spain)",
  "Universidad De Las Palmas De Gran Canaria Facultad De Ciencias De La Salud (Las Palmas De Gran Canaria, Spain)",
  "Universidad De Murcia Facultad De Medicina (Murcia, Spain)",
  "Universidad De Málaga Facultad De Medicina (Malaga, Spain)",
  "Universidad De Navarra Facultad De Medicina (Pamplona, Spain)",
  "Universidad De Oviedo Facultad De Medicina Y Ciencias De La Salud (Oviedo Asturias, Spain)",
  "Universidad De Salamanca Facultad De Medicina (Salamanca, Spain)",
  "Universidad De Sevilla Facultad De Medicina (Sevilla, Spain)",
  "Universidad De Valladolid Facultad De Medicina (Valladolid, Spain)",
  "Universidad De Zaragoza Facultad De Medicina (Zaragoza, Spain)",
  "Universidad Europea De Madrid Facultad De Ciencias Biomédicas (Villaviciosa De Odón, Spain)",
  "Universidad Francisco De Vitoria Facultad De Ciencias Biosanitarias (Pozuelo De Alarcón, Spain)",
  "Universidad Miguel Hernández De Elche Facultad De Medicina (Sant Joan D'alacant, Spain)",
  "Universidad Rey Juan Carlos Facultad De Ciencias De La Salud (Alcorcon, Spain)",
  "Universidad Santiago De Compostela Facultade De Medicina E Odontoloxía (Santiago De Compostela, Spain)",
  "Universitat Autònoma De Barcelona Facultat De Medicina (Barcelona, Spain)",
  "Universitat De Barcelona Facultat De Medicina (Barcelona, Spain)",
  "Universitat De Girona Facultat De Medicina (Girona, Spain)",
  "Universitat De Les Illes Balears Facultat De Medicina (Palma, Spain)",
  "Universitat De Lleida Facultat De Medicina (Lleida, Spain)",
  "Universitat De València Facultat De Medicina E Odontologia (Valencia, Spain)",
  "Universitat De Vic - Universitat Central De Catalunya (uvic-ucc) Facultat De Medicina (Vic, Spain)",
  "Universitat Internacional De Catalunya Facultat De Medicina I Ciències De La Salut (Sant Cugat Del Valles, Spain)",
  "Universitat Jaume I Facultat De Ciències De La Salut (Castelló De La Plana, Spain)",
  "Universitat Pompeu Fabra Facultat De Ciències De La Salut I De La Vida (Barcelona, Spain)",
  "Universitat Rovira I Virgili Facultat De Medicina I Ciències De La Salut De Reus (Reus, Spain)",
  "Bahr El Ghazal University Faculty Of Medicine (Wau, South Sudan)",
  "University Of Juba College Of Medicine (Juba, South Sudan)",
  "Upper Nile University Faculty Of Medicine And Health Sciences (Malakal, South Sudan)",
  "Medical University Of Southern Africa (medunsa) Faculty Of Health Sciences (Pretoria, South Africa)",
  "Sefako Makgatho Health Science University (Ga-rankuwa, South Africa)",
  "University Of Cape Town Faculty Of Health Sciences (Cape Town, South Africa)",
  "University Of Kwazulu-natal College Of Health Sciences (Durban, South Africa)",
  "University Of Pretoria School Of Medicine (Arcadia, South Africa)",
  "University Of Stellenbosch Faculty Of Medicine And Health Sciences (Cape Town, South Africa)",
  "University Of The Free State Faculty Of Health Sciences (Bloemfontein, South Africa)",
  "University Of The Witwatersrand Faculty Of Health Sciences (Parktown, South Africa)",
  "University Of Transkei Faculty Of Health Sciences (Mthatha, South Africa)",
  "Walter Sisulu University Faculty Of Health Sciences (Mthatha, South Africa)",
  "Abrar University College Of Medicine And Health Science (Mogadishu, Somalia)",
  "Aden Adde International University Faculty Of Medicine And Surgery (Mogadishu, Somalia)",
  "Amoud University School Of Medicine And Surgery (Borama, Somalia)",
  "Benadir University Faculty Of Medicine And Surgery (Mogadishu, Somalia)",
  "Capital University Faculty Of Medicine (Mogadishu, Somalia)",
  "East Africa University Faculty Of Medicine (Bosaso, Somalia)",
  "Frantz Fanon University Faculty Of Medicine And Surgery (Hargeisa, Somalia)",
  "Global Science University Faculty Of Medicine (Galkacyo, Somalia)",
  "Gollis University Faculty Of Medicine And Surgery (Hargeisa, Somalia)",
  "Hope University Faculty Of Medicine (Mogadishu, Somalia)",
  "Jamhuriya University Of Science And Technology Faculty Of Medicine & Health Science (Mogadishu, Somalia)",
  "Jazeera University Faculty Of Medicine And Surgery (Mogadishu, Somalia)",
  "Kismayo University Faculty Of Medicine And Health Science (Kismayo, Somalia)",
  "Mogadishu University Faculty Of Medicine & Surgery (Mogadishu, Somalia)",
  "Plasma University Faculty Of Medicine & Surgery (Mogadishu, Somalia)",
  "Salaam University Faculty Of Medicine And Surgery (Mogadishu, Somalia)",
  "Simad University Faculty Of Medicine And Health Sciences (Mogadishu, Somalia)",
  "Somali International University Faculty Of Medicine And Health Sciences (Mogadishu, Somalia)",
  "Somali National University Faculty Of Medicine And Health Science (Mogadishu, Somalia)",
  "Somaville University Faculty Of Health Science (Mogadishu, Somalia)",
  "University Of Burao Faculty Of Medicine And Health Sciences (Burao City, Somalia)",
  "University Of Hargeisa School Of Medicine And Health Sciences (Hargeisa, Somalia)",
  "University Of Health Sciences Faculty Of Medicine (Bosaso, Somalia)",
  "University Of Somalia Faculty Of Health Sciences (Mogadishu, Somalia)",
  "Zamzam University Of Science And Technology Faculty Of Medicine (Mogadis, Somalia)",
  "King's University School Of Medicine (Honiara, Solomon Islands)",
  "University Of Ljubljani Faculty Of Medicine (Ljubljana, Slovenia)",
  "University Of Maribor Faculty Of Medicine (Maribor, Slovenia)",
  "Duke-nus Medical School (Singapore, Singapore)",
  "Lee Kong Chian School Of Medicine (Singapore, Singapore)",
  "Yong Loo Lin School Of Medicine, National University Of Singapore (Singapor, Singapore)",
  "University Of Sierra Leone College Of Medicine & Allied Health Sciences (Freetow, Sierra Leone)",
  "University Of Seychelles American Institute Of Medicine (usaim) (Victoria, Seychelles)",
  "Military Medical Academy Medical Faculty, University Of Defense (Belgrade, Serbia)",
  "University Of Belgrade Faculty Of Medicine (Belgrade, Serbia)",
  "University Of Kragujevac Faculty Of Medical Sciences (Kragujevac, Serbia)",
  "University Of Nis Faculty Of Medicine (Nis, Serbia)",
  "University Of Nis International College Of Medicine (Nis, Serbia)",
  "University Of Novi Sad Faculty Of Medicine (Novi Sad, Serbia)",
  "St Christopher Iba Mar Diop College Of Medicine (Dakar, Senegal)",
  "Université Cheikh Anta Diop Faculté De Médecine, De Pharmacie Et D'odontologie-stomatologie (Dakar, Senegal)",
  "Université Iba Der Thiam De Thiès Ufr Sciences De La Santé (Thiès, Senegal)",
  "Al Baha University School Of Medicine (Al Baha, Saudi Arabia)",
  "Al Jouf University College Of Medicine (Al Jouf, Saudi Arabia)",
  "Al Rayan National College Of Medicine (Medina, Saudi Arabia)",
  "Al-imam Muhammad Bin Saud Islamic University College Of Medicine (Riyadh, Saudi Arabia)",
  "Alfaisal University College Of Medicine (Riyadh, Saudi Arabia)",
  "Almaarefa University, College Of Medicine (Riyadh, Saudi Arabia)",
  "Almajmaah University College Of Medicine (Al Majma'ah, Saudi Arabia)",
  "Batterjee Medical College For Sciences And Technology (Jeddah, Saudi Arabia)",
  "College Of Medicine Jeddah, King Saud Bin Abdulaziz University For Health Sciences (Jeddah, Saudi Arabia)",
  "College Of Medicine Of Vision Colleges (Riyadh, Saudi Arabia)",
  "Dar Al Uloom University College Of Medicine (Riyadh, Saudi Arabia)",
  "Faculty Of Medicine In Al-qunfudah, Umm Al-qura University (Al-qunfudah, Saudi Arabia)",
  "Fakeeh College For Medical Sciences (Jeddah, Saudi Arabia)",
  "Global Medical College (Riyadh, Saudi Arabia)",
  "Ibn Sina National College For Medical Studies Faculty Of Medicine (Jeddah, Saudi Arabia)",
  "Imam Abdulrahman Bin Faisal University College Of Medicine (Dammam, Saudi Arabia)",
  "Jazan University Faculty Of Medicine (Jazan, Saudi Arabia)",
  "King Abdulaziz University College Of Medicine (Jeddah, Saudi Arabia)",
  "King Abdulaziz University Faculty Of Medicine In Rabigh (Rabigh, Saudi Arabia)",
  "King Fahad Medical City Faculty Of Medicine (Riyadh, Saudi Arabia)",
  "King Faisal University, College Of Medicine In Al-ahsa (Al-ahsa, Saudi Arabia)",
  "King Khalid University College Of Medicine And Health Sciences (Abha, Saudi Arabia)",
  "King Saud Bin Abdulaziz University For Health Sciences College Of Medicine (Riyadh, Saudi Arabia)",
  "King Saud University, Riyadh College Of Medicine (Riyadh, Saudi Arabia)",
  "Najran University College Of Medicine (Najran, Saudi Arabia)",
  "Northern Borders University College Of Medicine (Arar, Saudi Arabia)",
  "Prince Sattam Bin Abdulaziz University College Of Medicine (Al Kharj, Saudi Arabia)",
  "Princess Nourah Bint Abdulrahman University College Of Medicine (Riyadh, Saudi Arabia)",
  "Qassim University College Of Medicine (Burayda, Saudi Arabia)",
  "Shaqra University College Of Medicine In Dawadmi (Dawadmi, Saudi Arabia)",
  "Shaqra University College Of Medicine In Shaqra (Shaqra, Saudi Arabia)",
  "Sulaiman Alrajhi University College Of Medicine (Al Bukairyah, Saudi Arabia)",
  "Taibah University College Of Medicine (Al Munawarah, Saudi Arabia)",
  "Taif University College Of Medicine (Taif, Saudi Arabia)",
  "Umm Al-qura University Faculty Of Medicine (Mecca, Saudi Arabia)",
  "Unaizah College Of Medicine And Medical Sciences, Qassim University (Unaizah, Saudi Arabia)",
  "University Of Bisha Faculty Of Medicine (Bisha, Saudi Arabia)",
  "University Of Ha’il College Of Medicine (Ha’il, Saudi Arabia)",
  "University Of Jeddah Faculty Of Medicine (Jeddah, Saudi Arabia)",
  "University Of Tabuk Faculty Of Medicine (Tabuk, Saudi Arabia)",
  "National University Of Samoa School Of Medicine (To'omatagi, Samoa)",
  "Oceania University Of Medicine (Apia, Samoa)",
  "All Saints University School Of Medicine (Kingstown, Saint Vincent And The Grenadines)",
  "Kingstown Medical College (Saint Vincent, Saint Vincent And The Grenadines)",
  "Richmond Gabriel University College Of Medicine (Kingstown, Saint Vincent And The Grenadines)",
  "Saint James School Of Medicine St Vincent And The Grenadines (Arnos Vale, Saint Vincent And The Grenadines)",
  "Saint Teresa University School Of Medicine (Kingstown, Saint Vincent And The Grenadines)",
  "Trinity School Of Medicine (Kingstown, Saint Vincent And The Grenadines)",
  "American International Medical University School Of Medicine (Gros Islet, Saint Lucia)",
  "Atlantic University School Of Medicine (Rodney Bay, Saint Lucia)",
  "College Of Medicine And Health Sciences, St Lucia (Rodney Bay, Saint Lucia)",
  "Commonwealth University College Of Medicine (Gros Islet, Saint Lucia)",
  "International American University College Of Medicine (Vieux Fort, Saint Lucia)",
  "Spartan Health Sciences University School Of Medicine (Vieux Fort, Saint Lucia)",
  "St Helen University School Of Medicine (Vieux Fort, Saint Lucia)",
  "St Mary's School Of Medicine (st Lucia) (Castries, Saint Lucia)",
  "Washington Medical Sciences Institute (Gros Islet, Saint Lucia)",
  "Burnett International University School Of Medicine And Health Sciences (Basseterre, Saint Kitts And Nevis)",
  "Grace University School Of Medicine (nevis) (Cades Bay, Saint Kitts And Nevis)",
  "International University Of The Health Sciences (iuhs) (Basseterre, Saint Kitts And Nevis)",
  "Medical University Of The Americas (nevis) (Charlestown, Saint Kitts And Nevis)",
  "Milik University (Basseterre, Saint Kitts And Nevis)",
  "Saint Theresa's Medical University (Basseterre, Saint Kitts And Nevis)",
  "Universal Caribbean University School Of Medicine (Bird Rock, Saint Kitts And Nevis)",
  "University Of Medicine And Health Sciences, St Kitts (Basseterre, Saint Kitts And Nevis)",
  "Windsor University School Of Medicine (Basseterre, Saint Kitts And Nevis)",
  "Adventist University Of The Philippines College Of Medicine (Silang, Philippines)",
  "Ama School Of Medicine (Makati, Philippines)",
  "Angeles University Foundation School Of Medicine (Angeles, Philippines)",
  "Ateneo De Zamboanga University College Of Medicine (Zamboanga, Philippines)",
  "Ateneo School Of Medicine And Public Health (Pasig City, Philippines)",
  "Baguio Central University College Of Medicine (Baguio, Philippines)",
  "Bicol Christian College Of Medicine (Legazpi, Philippines)",
  "Bicol University College Of Medicine (Legazpi, Philippines)",
  "Brokenshire College School Of Medicine (Davao City, Philippines)",
  "Cagayan State University College Of Medicine And Surgery (Tuguegarao, Philippines)",
  "Cebu Doctors' University College Of Medicine (Mandaue, Philippines)",
  "Cebu Institute Of Medicine (Cebu, Philippines)",
  "Central Philippine University College Of Medicine (Iloilo City, Philippines)",
  "Centro Escolar University College Of Medicine (Manila, Philippines)",
  "Chinese General Hospital Colleges (Manila, Philippines)",
  "Davao Medical School Foundation College Of Medicine (Davao, Philippines)",
  "De La Salle Medical And Health Sciences Institute College Of Medicine (Dasmarinas, Philippines)",
  "Divine Word University School Of Medicine (liceo Del Verbo Divino) (Tacloban, Philippines)",
  "Dr Francisco Q Duque Medical Foundation College Of Medicine (Dagupan, Philippines)",
  "Dr Jose P Rizal College Of Medicine (Cagayan De Oro City, Philippines)",
  "Emilio Aguinaldo College School Of Medicine (Manila, Philippines)",
  "Far Eastern University Institute Of Medicine, Nicanor Reyes Medical Foundation (Quezon City, Philippines)",
  "Gullas College Of Medicine, University Of The Visayas (Mandaue, Philippines)",
  "Iloilo Doctors' College Of Medicine (Iloilo City, Philippines)",
  "Jose Maria College Of Medicine Foundation (Davao City, Philippines)",
  "La Consolacion University Philippines College Of Medicine (Malolos, Philippines)",
  "Liceo De Cagayan University College Of Medicine (Cagayan De Oro City, Philippines)",
  "Manila Central University-filemon D Tanchoco Foundation College Of Medicine (Caloocan, Philippines)",
  "Manila Theological College Of Medicine (Manila, Philippines)",
  "Mariano Marcos State University College Of Medicine (Batac, Philippines)",
  "Matias H Aznar Memorial College Of Medicine (Cebu, Philippines)",
  "Metropolitan Medical Center College Of Medicine (Manila, Philippines)",
  "Mindanao State University College Of Medicine (Iligan, Philippines)",
  "New Era University College Of Medicine (Quezon City, Philippines)",
  "Our Lady Of Fatima University College Of Medicine (Valenzuela, Philippines)",
  "Philippine Muslim-christian College (Antipolo, Philippines)",
  "Plt College Inc College Of Medicine (Solano, Philippines)",
  "Remedios T Romualdez Medical Foundation College Of Medicine (Tacloban, Philippines)",
  "San Beda University College Of Medicine (Manila, Philippines)",
  "School Of Health Sciences, University Of The Philippines System (Palo, Philippines)",
  "Silliman University Medical School (Dumaguete, Philippines)",
  "Southwestern University School Of Medicine (Cebu City, Philippines)",
  "St Louis University School Of Medicine (Baguio, Philippines)",
  "St Luke's Medical Center College Of Medicine - William H Quasha Memorial (slmccm-whqm) (Quezon City, Philippines)",
  "St Paul University Philippines School Of Medicine (Tuguegarao City, Philippines)",
  "The Manila Times College Of Subic (tmtcs) College Of Medicine (Olongapo City, Philippines)",
  "Universidad De Santa Isabel College Of Medicine (Naga City, Philippines)",
  "University Of Cebu College Of Medicine (Mandaue City, Philippines)",
  "University Of Northern Philippines College Of Medicine (Ilocos Sur, Philippines)",
  "University Of Perpetual Help System Dalta Jonelta Foundation School Of Medicine (Las Piñas, Philippines)",
  "University Of Perpetual Help System Dr Jose G Tamayo Medical University (Binan, Philippines)",
  "University Of Saint La Salle College Of Medicine (Bacolod, Philippines)",
  "University Of Santo Tomas Faculty Of Medicine And Surgery (Manila, Philippines)",
  "University Of The City Of Manila College Of Medicine (Manila, Philippines)",
  "University Of The East/ramon Magsaysay Memorial Medical Center College Of Medicine (Quezon, Philippines)",
  "University Of The Philippines Manila College Of Medicine (Manila, Philippines)",
  "Virgen Milagrosa University Foundation College Of Medicine (San Carlos, Philippines)",
  "West Visayas State University College Of Medicine (Iloilo City, Philippines)",
  "American University Of Antigua College Of Medicine (Coolidge, Antigua And Barbuda)",
  "Atlantic University School Of Medicine (St John's, Antigua And Barbuda)",
  "Metropolitan University College Of Medicine (St John's, Antigua And Barbuda)",
  "University Of Health Sciences Antigua School Of Medicine (St John's, Antigua And Barbuda)",
  "Western Atlantic University, School Of Medicine (Freeport, Bahamas)",
  "American University Of Barbados School Of Medicine (St Michael, Barbados)",
  "American University Of Integrative Sciences School Of Medicine (St Michael, Barbados)",
  "Bridgetown International University School Of Medicine (St Michael, Barbados)",
  "International University Of Barbados School Of Medicine (Welches, Barbados)",
  "Queen's University College Of Medicine (St John, Barbados)",
  "Ross University School Of Medicine (St Michael, Barbados)",
  "University Of The West Indies At Cave Hill Faculty Of Medical Sciences (Cave Hill, Barbados)",
  "Victoria University Of Barbados School Of Medicine (St Michael, Barbados)",
  "Washington University Of Barbados School Of Medicine (St Philip, Barbados)",
  "American Northwest University (Belmopan, Belize)",
  "Avicina Medical Academy (Belmopan, Belize)",
  "Central America Health Sciences University Belize Medical College (Ladyville, Belize)",
  "Columbus Central University School Of Medicine (Ladyville, Belize)",
  "Efhre International University (Belize City, Belize)",
  "Grace University School Of Medicine (belize) (Belmopan, Belize)",
  "Hope University School Of Medicine (Belmopan, Belize)",
  "Interamerican Medical University (Corozal, Belize)",
  "Medical University Of The Americas (belize) (Ambergris Caye, Belize)",
  "St Luke's University School Of Medicine (Belize City, Belize)",
  "St Matthew's University School Of Medicine (belize) (San Pedro, Belize)",
  "Washington University Of Health And Sciences (San Pedr, Belize)",
  "A T Still University Kirksville College Of Osteopathic Medicine (Kirksville, United States Of America)",
  "A T Still University School Of Osteopathic Medicine In Arizona (Mesa, United States Of America)",
  "Alabama College Of Osteopathic Medicine (Dothan, United States Of America)",
  "Albany Medical College (Albany, United States Of America)",
  "Albert Einstein College Of Medicine (Bronx, United States Of America)",
  "Arkansas College Of Osteopathic Medicine (Fort Smith, United States Of America)",
  "Baylor College Of Medicine (Houston, United States Of America)",
  "Boston University School Of Medicine (Boston, United States Of America)",
  "Brody School Of Medicine At East Carolina University (Greenville, United States Of America)",
  "Burrell College Of Osteopathic Medicine At New Mexico State University (Las Cruces, United States Of America)",
  "California Health Sciences University College Of Osteopathic Medicine (Clovis, United States Of America)",
  "California Northstate University College Of Medicine (Elk Grove, United States Of America)",
  "California University Of Science And Medicine School Of Medicine (San Bernardino, United States Of America)",
  "Campbell University Jerry M Wallace School Of Osteopathic Medicine (Lillington, United States Of America)",
  "Carle Illinois College Of Medicine (Champaign, United States Of America)",
  "Case Western Reserve University School Of Medicine (Cleveland, United States Of America)",
  "Central Michigan University College Of Medicine (Mount Pleasant, United States Of America)",
  "Charles E Schmidt College Of Medicine At Florida Atlantic University (Boca Raton, United States Of America)",
  "Chicago Medical School, Rosalind Franklin University Of Medicine & Science (North Chicago, United States Of America)",
  "Columbia University Vagelos College Of Physicians And Surgeons (New York, United States Of America)",
  "Cooper Medical School Of Rowan University (Camden, United States Of America)",
  "Creighton University School Of Medicine (Omaha, United States Of America)",
  "Cuny School Of Medicine (New York, United States Of America)",
  "David Geffen School Of Medicine At Ucla (Los Angeles, United States Of America)",
  "Dell Medical School At The University Of Texas At Austin (Austin, United States Of America)",
  "Des Moines University College Of Osteopathic Medicine (Des Moines, United States Of America)",
  "Donald And Barbara Zucker School Of Medicine At Hofstra/northwell (Hempstead, United States Of America)",
  "Drexel University College Of Medicine (Philadelphia, United States Of America)",
  "Duke University School Of Medicine (Durham, United States Of America)",
  "Eastern Virginia Medical School (Norfolk, United States Of America)",
  "Edward Via College Of Osteopathic Medicine Auburn (Auburn, United States Of America)",
  "Edward Via College Of Osteopathic Medicine Carolinas (Spartanburg, United States Of America)",
  "Edward Via College Of Osteopathic Medicine Louisiana (Monroe, United States Of America)",
  "Edward Via College Of Osteopathic Medicine Virginia (Blacksburg, United States Of America)",
  "Elson S Floyd College Of Medicine, Washington State University (Spokane, United States Of America)",
  "Emory University School Of Medicine (Atlanta, United States Of America)",
  "Escuela De Medicina San Juan Bautista (Caguas, United States Of America)",
  "F Edward Hébert School Of Medicine, Uniformed Services University Of The Health Sciences (Bethesda, United States Of America)",
  "Fiu Herbert Wertheim College Of Medicine (Miami, United States Of America)",
  "Florida State University College Of Medicine (Tallahassee, United States Of America)",
  "Frank H Netter Md School Of Medicine At Quinnipiac University (North Haven, United States Of America)",
  "Geisel School Of Medicine At Dartmouth (Hanover, United States Of America)",
  "Geisinger Commonwealth School Of Medicine (Scranton, United States Of America)",
  "George Washington University School Of Medicine And Health Sciences (Washington, United States Of America)",
  "Georgetown University School Of Medicine (Washington, United States Of America)",
  "Hackensack Meridian School Of Medicine (Nutley, United States Of America)",
  "Hahnemann University School Of Medicine (Philadelphia, United States Of America)",
  "Harvard Medical School (Boston, United States Of America)",
  "Howard University College Of Medicine (Washington, United States Of America)",
  "Icahn School Of Medicine At Mount Sinai (New York, United States Of America)",
  "Idaho College Of Osteopathic Medicine (Meridian, United States Of America)",
  "Indiana University School Of Medicine (Indianapolis, United States Of America)",
  "Jacobs School Of Medicine And Biomedical Sciences, University At Buffalo (Buffalo, United States Of America)",
  "Joan C Edwards School Of Medicine At Marshall University (Huntington, United States Of America)",
  "John A Burns School Of Medicine, University Of Hawaii At Manoa (Honolulu, United States Of America)",
  "Johns Hopkins University School Of Medicine (Baltimore, United States Of America)",
  "Kaiser Permanente Bernard J Tyson School Of Medicine (Pasadena, United States Of America)",
  "Kansas City University College Osteopathic Medicine, Joplin (Joplin, United States Of America)",
  "Kansas City University Of Medicine & Biosciences College Of Osteopathic Medicine (Kansas City, United States Of America)",
  "Keck School Of Medicine Of The University Of Southern California (Los Angeles, United States Of America)",
  "Kirk Kerkorian School Of Medicine At Unlv (Las Vegas, United States Of America)",
  "Lake Erie College Of Osteopathic Medicine - Bradenton (Bradenton, United States Of America)",
  "Lake Erie College Of Osteopathic Medicine - Erie (Erie, United States Of America)",
  "Lake Erie College Of Osteopathic Medicine - Seton Hill (Greensburg, United States Of America)",
  "Lake Erie College Of Osteopathic Medicine, Elmira (Elmira, United States Of America)",
  "Lewis Katz School Of Medicine At Temple University (Philadelphia, United States Of America)",
  "Liberty University College Of Osteopathic Medicine (Lynchburg, United States Of America)",
  "Lincoln Memorial University Debusk College Of Osteopathic Medicine (Harrogate, United States Of America)",
  "Loma Linda University School Of Medicine (Loma Linda, United States Of America)",
  "Louisiana State University School Of Medicine In New Orleans (New Orleans, United States Of America)",
  "Louisiana State University School Of Medicine In Shreveport (Shreveport, United States Of America)",
  "Marian University College Of Osteopathic Medicine (Indianapolis, United States Of America)",
  "Mayo Clinic Alix School Of Medicine (Rochester, United States Of America)",
  "Mcgovern Medical School At The University Of Texas Health Science Center At Houston (Houston, United States Of America)",
  "Medical College Of Georgia (Augusta, United States Of America)",
  "Medical College Of Pennsylvania (Philadelphia, United States Of America)",
  "Medical College Of Wisconsin (Milwaukee, United States Of America)",
  "Medical University Of South Carolina (Charleston, United States Of America)",
  "Meharry Medical College School Of Medicine (Nashville, United States Of America)",
  "Mercer University School Of Medicine (Macon, United States Of America)",
  "Michigan State University College Of Human Medicine (East Lansing, United States Of America)",
  "Michigan State University College Of Osteopathic Medicine (East Lansing, United States Of America)",
  "Michigan State University College Of Osteopathic Medicine, Detroit Medical Center (Detroit, United States Of America)",
  "Michigan State University College Of Osteopathic Medicine, Macomb University Center (Clinton Township, United States Of America)",
  "Midwestern University Arizona College Of Osteopathic Medicine (Glendale, United States Of America)",
  "Midwestern University Chicago College Of Osteopathic Medicine (Downers Grove, United States Of America)",
  "Morehouse School Of Medicine (Atlanta, United States Of America)",
  "New York Institute Of Technology College Of Osteopathic Medicine (Old Westbury, United States Of America)",
  "New York Institute Of Technology College Of Osteopathic Medicine, Arkansas State (State Univeristy, United States Of America)",
  "New York Medical College (Valhalla, United States Of America)",
  "Noorda College Of Osteopathic Medicine (Provo, United States Of America)",
  "Northeast Ohio Medical University (neomed) (Rootstown, United States Of America)",
  "Northwestern University Feinberg School Of Medicine (Chicago, United States Of America)",
  "Nova Southeastern University Dr Kiran C Patel College Of Allopathic Medicine (Fort Lauderdale, United States Of America)",
  "Nova Southeastern University Dr Kiran C Patel College Of Osteopathic Medicine Fort Lauderdale (Fort Lauderdale, United States Of America)",
  "Nyu Grossman School Of Medicine (New York, United States Of America)",
  "Nyu Long Island School Of Medicine (Mineola, United States Of America)",
  "Oakland University William Beaumont School Of Medicine (Rochester, United States Of America)",
  "Ohio University Heritage College Of Osteopathic Medicine Athens (Athens, United States Of America)",
  "Ohio University Heritage College Of Osteopathic Medicine, Cleveland (Warrensville Heights, United States Of America)",
  "Oklahoma State University Center For Health Sciences College Of Osteopathic Medicine (Tulsa, United States Of America)",
  "Oregon Health And Science University School Of Medicine (Portland, United States Of America)",
  "Pacific Northwest University Of Health Sciences College Of Osteopathic Medicine (Yakima, United States Of America)",
  "Paul L Foster School Of Medicine, Texas Tech University (El Paso, United States Of America)",
  "Penn State University College Of Medicine (Hershey, United States Of America)",
  "Perelman School Of Medicine At University Of Pennsylvania (Philadelphia, United States Of America)",
  "Philadelphia College Of Osteopathic Medicine (Philadelphia, United States Of America)",
  "Philadelphia College Of Osteopathic Medicine, Georgia Campus (Suwanee, United States Of America)",
  "Ponce Health Sciences University School Of Medicine (Ponce, United States Of America)",
  "Quillen College Of Medicine, East Tennessee State University (Johnson City, United States Of America)",
  "Renaissance School Of Medicine At Stony Brook University (Stony Brook, United States Of America)",
  "Rocky Vista University College Of Osteopathic Medicine (Parker, United States Of America)",
  "Rowan-virtua School Of Osteopathic Medicine (Stratford, United States Of America)",
  "Rush Medical College Of Rush University (Chicago, United States Of America)",
  "Rutgers New Jersey Medical School (Newark, United States Of America)",
  "Rutgers Robert Wood Johnson Medical School (Piscataway, United States Of America)",
  "Saint Louis University School Of Medicine (St Louis, United States Of America)",
  "Sam Houston State University College Of Osteopathic Medicine (Conroe, United States Of America)",
  "Sanford School Of Medicine Of The University Of South Dakota (Sioux Falls, United States Of America)",
  "Sidney Kimmel Medical College At Thomas Jefferson University (Philadelphia, United States Of America)",
  "Southern Illinois University School Of Medicine (Springfield, United States Of America)",
  "Stanford School Of Medicine (Stanford, United States Of America)",
  "Stritch School Of Medicine, Loyola University Of Chicago (Maywood, United States Of America)",
  "Suny Downstate Medical Center College Of Medicine (Brooklyn, United States Of America)",
  "Suny Upstate Medical University College Of Medicine (Syracuse, United States Of America)",
  "Tcu Burnett School Of Medicine (Fort Worth, United States Of America)",
  "Texas A&m University School Of Medicine (Bryan, United States Of America)",
  "Texas Tech University Health Sciences Center School Of Medicine (Lubbock, United States Of America)",
  "The Ohio State University College Of Medicine (Columbus, United States Of America)",
  "Tilman J Fertitta Family College Of Medicine, University Of Houston (Houston, United States Of America)",
  "Touro College Of Osteopathic Medicine (New York, United States Of America)",
  "Touro University College Of Osteopathic Medicine, California (Vallejo, United States Of America)",
  "Touro University Nevada College Of Osteopathic Medicine (Henderson, United States Of America)",
  "Tufts University School Of Medicine (Boston, United States Of America)",
  "Tulane University School Of Medicine (New Orleans, United States Of America)",
  "Uc Davis School Of Medicine (Sacramento, United States Of America)",
  "Uc Riverside School Of Medicine (Riverside, United States Of America)",
  "Uc San Diego School Of Medicine (La Jolla, United States Of America)",
  "Umass Chan Medical School (Worcester, United States Of America)",
  "Universidad Central Del Caribe School Of Medicine (Bayamon, United States Of America)",
  "University Of Alabama Heersink School Of Medicine (Birmingham, United States Of America)",
  "University Of Arizona College Of Medicine Phoenix (Phoenix, United States Of America)",
  "University Of Arizona College Of Medicine Tucson (Tucson, United States Of America)",
  "University Of Arkansas For Medical Sciences College Of Medicine (Little Rock, United States Of America)",
  "University Of California, Irvine, School Of Medicine (Irvine, United States Of America)",
  "University Of California, San Francisco, School Of Medicine (San Francisco, United States Of America)",
  "University Of Central Florida College Of Medicine (Orlando, United States Of America)",
  "University Of Chicago Pritzker School Of Medicine (Chicago, United States Of America)",
  "University Of Cincinnati College Of Medicine (Cincinnati, United States Of America)",
  "University Of Colorado School Of Medicine (Aurora, United States Of America)",
  "University Of Connecticut School Of Medicine (Farmington, United States Of America)",
  "University Of Florida College Of Medicine (Gainesville, United States Of America)",
  "University Of Illinois College Of Medicine (Chicago, United States Of America)",
  "University Of Iowa Carver College Of Medicine (Iowa City, United States Of America)",
  "University Of Kansas School Of Medicine (Kansas City, United States Of America)",
  "University Of Kentucky College Of Medicine (Lexington, United States Of America)",
  "University Of Louisville School Of Medicine (Louisville, United States Of America)",
  "University Of Maryland School Of Medicine (Baltimore, United States Of America)",
  "University Of Miami Miller School Of Medicine (Miami, United States Of America)",
  "University Of Michigan Medical School (Ann Arbor, United States Of America)",
  "University Of Minnesota Medical School (Minneapolis, United States Of America)",
  "University Of Mississippi School Of Medicine (Jackson, United States Of America)",
  "University Of Missouri Kansas City School Of Medicine (Kansas City, United States Of America)",
  "University Of Missouri School Of Medicine (Columbia, United States Of America)",
  "University Of Nebraska College Of Medicine (Omaha, United States Of America)",
  "University Of Nevada School Of Medicine (Reno, United States Of America)",
  "University Of New England College Of Osteopathic Medicine (Biddeford, United States Of America)",
  "University Of New Mexico School Of Medicine (Albuquerque, United States Of America)",
  "University Of North Carolina School Of Medicine (Chapel Hill, United States Of America)",
  "University Of North Dakota School Of Medicine And Health Sciences (Grand Forks, United States Of America)",
  "University Of North Texas Health Science Center Texas College Of Osteopathic Medicine (Fort Worth, United States Of America)",
  "University Of Oklahoma College Of Medicine (Oklahoma City, United States Of America)",
  "University Of Pikeville Kentucky College Of Osteopathic Medicine (Pikeville, United States Of America)",
  "University Of Pittsburgh School Of Medicine (Pittsburgh, United States Of America)",
  "University Of Puerto Rico School Of Medicine (San Juan, United States Of America)",
  "University Of Rochester School Of Medicine And Dentistry (Rochester, United States Of America)",
  "University Of South Alabama Frederick P Whiddon College Of Medicine (Mobile, United States Of America)",
  "University Of South Carolina School Of Medicine (Columbia, United States Of America)",
  "University Of South Carolina School Of Medicine Greenville (Greenville, United States Of America)",
  "University Of Tennessee Health Science Center College Of Medicine (Memphis, United States Of America)",
  "University Of Texas Medical Branch School Of Medicine (Galveston, United States Of America)",
  "University Of Texas Medical School At San Antonio (San Antonio, United States Of America)",
  "University Of Texas Rio Grande Valley School Of Medicine (Brownsville, United States Of America)",
  "University Of Texas Southwestern Medical School (Dallas, United States Of America)",
  "University Of The Incarnate Word School Of Osteopathic Medicine (San Antonio, United States Of America)",
  "University Of Toledo College Of Medicine (Toledo, United States Of America)",
  "University Of Utah School Of Medicine (Salt Lake City, United States Of America)",
  "University Of Vermont Larner College Of Medicine (Burlington, United States Of America)",
  "University Of Virginia School Of Medicine (Charlottesville, United States Of America)",
  "University Of Washington School Of Medicine (Seattle, United States Of America)",
  "University Of Wisconsin School Of Medicine And Public Health (Madison, United States Of America)",
  "Usf Health Morsani College Of Medicine (Tampa, United States Of America)",
  "Vanderbilt University School Of Medicine (Nashville, United States Of America)",
  "Virginia Commonwealth University School Of Medicine (Richmond, United States Of America)",
  "Virginia Tech Carilion School Of Medicine (Roanoke, United States Of America)",
  "Wake Forest University School Of Medicine (Winston-salem, United States Of America)",
  "Warren Alpert Medical School Of Brown University (Providence, United States Of America)",
  "Washington University School Of Medicine In St Louis (St Louis, United States Of America)",
  "Wayne State University School Of Medicine (Detroit, United States Of America)",
  "Weill Cornell Medical College (New York, United States Of America)",
  "West Virginia School Of Osteopathic Medicine (Lewisburg, United States Of America)",
  "West Virginia University School Of Medicine (Morgantown, United States Of America)",
  "Western Michigan University Homer Stryker M D School Of Medicine (Kalamazoo, United States Of America)",
  "Western University Of Health Sciences College Of Osteopathic Medicine Of The Pacific (Pomona, United States Of America)",
  "Western University Of Health Sciences College Of Osteopathic Medicine, Pacific-northwest (Lebanon, United States Of America)",
  "William Carey University College Of Osteopathic Medicine (Hattiesburg, United States Of America)",
  "Wright State University Boonshoft School Of Medicine (Dayton, United States Of America)",
  "Yale School Of Medicine (New Haven, United States Of America)"
]
