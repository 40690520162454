// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCtsu8d5m10sHZM4FsAAdz8wzAfwJGb_Mw",
    authDomain: "usmles.firebaseapp.com",
    databaseURL: "https://usmles.firebaseio.com",
    projectId: "usmles",
    storageBucket: "usmles.appspot.com",
    messagingSenderId: "467549083017",
    appId: "1:467549083017:web:2a7ddce908a26686ca3d58",
    measurementId: "G-ZD8V8F5MEP"
  }
};*/
export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyBn4oLPQIbb8i7st5iDRDJ2qrg2W6MKQgY",
    authDomain: "usmlesarthi.com",
    databaseURL: "https://usmlesarthi-residency-match.firebaseio.com",
    projectId: "usmlesarthi-residency-match",
    storageBucket: "usmlesarthi-residency-match.appspot.com",
    messagingSenderId: "461306056844",
    appId: "1:461306056844:web:cb605fb0befd9f400daba9",
    measurementId: "G-9JEJSMV4VW"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
