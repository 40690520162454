import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../common/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { medicalSchoolOptions } from './MedicalSchoolOptions';
import { CountryWithStates } from './countriesWithStates';

medicalSchoolOptions.push("Other");

function buildNext4Years() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const dateStrings = [];

  for (let i = 0; i < 5; i++) {
    const year = currentYear + i;
    const dateString = `Sept ${year}`;
    dateStrings.push(dateString);
  }

  return dateStrings;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  landing: string;
  loading: boolean = false;
  phoneError : boolean = false;
  phone : string = '';
  countryOptions: any[] = [];
  selectedCountry: any;
  medicalSchoolOptions: string[] = medicalSchoolOptions || [];
  medicalSchoolOptionsList: any[] = [];
  yearOfApplytingOptions: string[] = buildNext4Years();
  otherMedicalSchool: string = '';

  selectedMedicalSchool: any;

  constructor( public authService: AuthenticationService, private router: Router, private toast: ToastrService) { }

  ngOnInit() {
    this.findLanding();
    this.loadCountries(); 
  }
  loadCountries() {
    this.countryOptions = Object.keys(CountryWithStates).map((key) => ({
      value: key,
      label: CountryWithStates[key].name,
      flag: `https://flagcdn.com/w320/${CountryWithStates[key].code2.toLowerCase()}.png`,
    }));
  }
  onCountryChange(selectedCountryLabel: string) {
    // Find the full object based on the label
    this.selectedCountry = this.countryOptions.find(
      (country) => country.label === selectedCountryLabel
    );
  
    console.log('selectedCountry===>', this.selectedCountry);
  
    if (this.selectedCountry.label) {
      // Filter medical schools based on the selected country
      const filteredSchools = medicalSchoolOptions.filter((college) =>
        college.includes(`, ${this.selectedCountry.label}`)
      );
  
      // Map filtered options for ng-select
      this.medicalSchoolOptionsList = [
        ...filteredSchools.map((college) => ({
          value: college,
          label: college,
        })),
        { value: 'Other', label: 'Other' },
      ];
    } else {
      this.medicalSchoolOptionsList = []; // Reset if no country is selected
    }
  }

  async findLanding()
  {
    this.landing = "Login";
    let  userData = JSON.parse(localStorage.getItem('user'));
    if(userData!= null && userData.emailVerified==false)
    {
      this.landing = "Verify Email";
      return;
    }
    else if(userData!= null && (userData.Role=="NA" || userData.Role=="Default"))
    {
      this.landing = "Verify User";
      return;
    }
    else if(userData!= null)
    {
      this.router.navigate(['']);
      return;
    }
  }

  async signIn(email: string, password: string)
  {
    this.loading= true;
    await this.authService.SignIn(email, password).then(()=>{
      this.findLanding();
      this.toast.success("Welcome to USMLESarthi");
    })
    .catch(
      (err) => {
        this.toast.error("Please check the credentials again");
      }
    )
    this.loading= false;
  }

  hasError(errEvent){
    this.phoneError = !errEvent;
  }

  getNumber(number){
    this.phone = number;
  }


  async register(email: string, password: string, repassword: string, name: string, yearOfApplying : string)
  {
    if (!email || !password || !repassword || !name || !yearOfApplying){
      return;
    }
    if (!this.phone || this.phoneError){
      this.toast.error("Phone is invalid");
        return;
    }
    if (!this.selectedMedicalSchool){
      this.toast.error("Select a Medical School");
      return;
    }
    if (this.selectedMedicalSchool === "Other" && !this.otherMedicalSchool){
      return;
    }
    this.loading=true;
    const medicalSchoolName = this.selectedMedicalSchool === "Other" ? this.otherMedicalSchool : this.selectedMedicalSchool;
    if (password==repassword){
      await this.authService.SignUp(email, password, name, this.phone, medicalSchoolName, yearOfApplying).then(()=>{
        this.findLanding();
        this.toast.success("You are successfully registered","Hello "+ name);
    }).catch(
      (err: any) => {
        this.toast.error("Error while registering: "+err.message);
      }
    )
    }
    else{
      this.toast.error("Your password don't match re-entered password");
    }
    this.loading=false;
  }
  async sendVerificationEmail()
  {
    this.loading = true;
    try {
      await this.authService.SendVerificationMail(); 
      this.toast.success("Verification Email has been sent.")
    }
    catch(error){
      this.toast.error("Error while sending verification email: "+error.message);
    }
    this.loading=false;
  }
  async forgetPassword(email: string)
  {
    this.loading=true;
    try{
      await this.authService.ForgotPassword(email);
      this.toast.success("Email has been sent for password resetting.")
    }
    catch(error){
      this.toast.error(error.message);
    }
    this.loading=false;
  }

}
