import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminServicesService } from "../services/admin-services.service";

@Component({
  selector: "app-add-rotations",
  templateUrl: "./add-rotations.component.html",
  styleUrls: ["./add-rotations.component.scss"],
})
export class AddRotationsComponent implements OnInit {
  loading: boolean = false;
  landing: string = "";
  rotationsList: any = [];
  selectedRotation: any = {};
  addNew: boolean = false;
  newRotation: any = {};
  query: any = "";
  defaultVisaLetterText: any = `The Visa Invitation Letter comes from a US-based law firm 
partnered with Sarthi. The Visa Invitation letter fee is 
non-refundable $225. An additional $200 is required as an advance 
fee for the rotation, and it will be refunded after the start of 
your rotation. In case your visa is denied, the $200 advance will 
be adjusted for any other Sarthi services or you can do 
Telerotations.`;
  constructor(
    private toastr: ToastrService,
    private dbService: AdminServicesService
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.resetnewRotation();
    this.landing = "list";
    this.loading = false;
  }

  async fetchSome(rotation) {
    if (rotation == "") {
      this.toastr.info(
        "Please write some prefix of rotation's name, city or state in the input"
      );
      return;
    }
    try {
      this.rotationsList = [];
      this.loading = true;
      this.landing = "list";
      this.rotationsList = Object.values(
        await this.dbService.getSomeRotations(rotation)
      );
      this.query = rotation;
      this.loading = false;
    } catch (err) {
      console.log(err.message);
      this.toastr.error("Error while fetching rotations, please try again");
    }
  }

  async takeMeToList() {
    if (this.query) {
      await this.fetchSome(this.query);
    } else {
      this.rotationsList = [];
      this.landing = "list";
    }
  }

  async takeMeToRotation(rotation) {
    try {
      this.loading = true;
      this.selectedRotation = {
        ...rotation,
        ...(await this.dbService.getRotationEmail(rotation)),
      };
      this.landing = "rotation";
      this.loading = false;
    } catch (err) {
      console.log(err.message);
      this.toastr.error(
        "Error while getting information about the rotation, please try again"
      );
    }
  }

  async updateRotation() {
    if (this.validateRotation(this.selectedRotation)) {
      try {
        await this.dbService.updateRotation(this.selectedRotation);
        this.toastr.success("The rotation has been updated");
        await this.fetchSome(this.query);
      } catch (err) {
        console.log(err.message);
        this.toastr.error("Error while saving the changes, please try again");
      }
    }
  }

  async addNewRotation() {
    if (this.validateRotation(this.newRotation)) {
      try {
        await this.dbService.addNewRotation(this.newRotation);
        this.toastr.success("New Rotation has been added");
        this.resetnewRotation();
      } catch (err) {
        console.log(err.message);
        this.toastr.error("Error while adding new rotation, please try again");
      }
    }
  }

  validateRotation(dataObject) {
    function camlize(string: string) {
      string.replace("_", " ");
      return string[0].toUpperCase() + string.substr(1).toLowerCase();
    }
    let toCheck = [
      "title",
      "city",
      "state",
      "duration",
      "type",
      "specialty",
      "rotation_setting",
      "affiliations",
      "fee",
      "residency",
      "registration_fee",
      "visa_letter",
      "rounds",
      "lor_type",
      "description",
      "location_code",
      "admin_email",
      "rank",
      "zipCode",
    ];
    for (let key of toCheck) {
      if (key == "fee" || key == "registration_fee") {
        if (dataObject[key] <= 0) {
          this.toastr.info("Rotation Fee cannot be zero or negative");
          return false;
        }
      } else if (key == "rank") {
        if (dataObject[key] < 0) {
          this.toastr.info("Rank can be a positive number or put 999");
          return false;
        }
      } else {
        try {
          let data = dataObject[key] && dataObject[key].trim();
          let label = camlize(key);
          if (data === "") {
            this.toastr.info(`Rotation ${label} cannot be empty`);
            return false;
          }
        } catch (err) {}
      }
    }
    return true;
  }

  async deleteRotation(rotation) {
    try {
      await this.dbService.deleteRotation(rotation);
      this.toastr.success("Deleted");
      this.rotationsList = this.rotationsList.filter(
        (item) => item.rotationId != rotation.rotationId
      );
    } catch (err) {
      console.log(err);
      this.toastr.error("Error while performing action, Please try again");
    }
  }

  resetnewRotation() {
    this.addNew = false;
    this.newRotation = {
      title: "",
      city: "",
      state: "",
      duration: "",
      type: "",
      specialty: "",
      rotation_setting: "",
      affiliations: "",
      fee: 0,
      residency: "",
      rounds: "",
      lor_type: "",
      description: "",
      location_code: "",
      admin_email: "",
      registration_fee: "",
      visa_letter: "",
      zipCode: "",
      payment_policy: "",
      refund_policy_link: "",
      visa_letter_cost: 0,
      program_director: "",
      rank: 999,
    };
  }
}
